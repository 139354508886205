
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { PageOptions } from '../../../util/backend-util-module';
import { ApiGateway } from '../../../api-gateway';
import { Address } from '../../../models/tables/address/address';
import { CwUtils } from 'cw-utils';
@Injectable()
export class AddressListService implements Resolve<any>
{
    address: Address[];
    addressEdit: Address;
    onDataChanged: BehaviorSubject<any>;
    routeParams: any;
    state: any;
    pageOptions: PageOptions;
    currentPage: PageOptions;

    constructor(
        public gw: ApiGateway,
        private location: Location,
    ) {
        this.onDataChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        // Get state for current or new page
        this.state = this.location.getState();
           if (this.state.currentPage && state.url.includes(this.state.path)) {
            this.currentPage = this.state.currentPage;
        }
        this.addressEdit = this.state.currentAddress;

        if (this.currentPage) {
            this.pageOptions = this.currentPage;
        }
        else {
            this.pageOptions = new PageOptions();
            this.pageOptions.fieldSort = 'name';
        }
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this.getPage(this.pageOptions)
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    get(id: number): Observable<Address> {
        if (!id) {
            return null;
        }
        return this.gw.get('address/:id', { id: id });
    }

    get currentAddress(): Address {
        return this.addressEdit;
    }

    getByZipCode(zipCode) {
        if (!zipCode) {
            return null;
        }
        return this.gw.get('address/zipCode/:zipCode', { zipCode: zipCode });
    }

    getByAddress(address) {
        if (!address) {
            return null;
        }
        return this.gw.post('address/search/', address);
    }

    getAll(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('address/').subscribe((res: HttpResponse<any>) => {
                this.address = res.body;
                this.onDataChanged.next(this.address);
                resolve(res);
            }, reject);
        });

    }

    getPage(pageOptions: PageOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('address/search', pageOptions).subscribe((res: HttpResponse<any>) => {
                this.address = res.body.content;
                this.onDataChanged.next(this.address);
                resolve(res);
            }, reject);
        });
    }

    getPageSettings() {
        if (this.currentPage) {
            return this.pageOptions;
        } else {
            return;
        }
    }
}

