import { CwUtils } from 'cw-utils';
import { ApiGateway } from '../../api-gateway';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';
import { isObject } from 'lodash';

@Injectable()
export class DashboardAdminService implements Resolve<any>
{
    companys: any[];
    widgets: any[];

    users: any[];
    onDataChanged: BehaviorSubject<any>;
    urlWeatherForecast = 'https://api.openweathermap.org/'

    constructor(
        private _httpClient: HttpClient,
        private gw: ApiGateway
    ) {
        this.onDataChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

        return new Promise<void>((resolve, reject) => {

            Promise.all([
                this.getAdmins(),
                this.getWidgets(),
                this.getUsers(),
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getAdmins(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get('api/project-dashboard-projects')
                .subscribe((response: any) => {
                    this.companys = response;
                    resolve(response);
                }, reject);
        });
    }

    getWidgets(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get('api/project-dashboard-widgets')
                .subscribe((response: any) => {
                    this.widgets = response;
                    resolve(response);
                }, reject);
        });
    }

    getUsers(change?): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('user/ws').subscribe((res: HttpResponse<any>) => {
                this.users = res.body;
                this.onDataChanged.next(this.users);
                resolve(res);
            }, reject);
        });
    }

    getweatherForecast(lat, long): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(`${this.urlWeatherForecast}data/2.5/weather?lat=${lat}&lon=${long}&units=metric&appid=736f7246bfd80f1dbabb433d8fc47887&lang=pt_br`, isObject).subscribe({
                next: (res: HttpResponse<any>) => { resolve(res) },
                error(error) { console.error(error) }
            })

        });
    }

    // getOnlineUsers(): Observable<any> {
    //     // var stream = this.gw.get("user/ws");
    //     return stream;
    // }


}
