export class Transaction {
    public id?: number;
    public view_id?: number;
    public code?: string;
    public description?: string;
    public name?: string;
    constructor(
    ) {
    }

}