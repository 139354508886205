
import { NgModule } from '@angular/core';

import { GroupEditProvider } from './group-edit.provider';
import { GroupListProvider } from './group-list.provider';

export * from './group-edit.provider';
export * from './group-list.provider';

export {
  GroupListProvider,
  GroupEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    GroupEditProvider,
    GroupListProvider
  ]
})
export class GroupBackendModule { }
