import { CwUtils } from '../../../../cw-utils/cw-utils.service';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { PageOptions } from '../../../util/backend-util-module';
import { Country } from '../../../models/backend-definitions.module';
import { ApiGateway } from '../../../api-gateway';

@Injectable()
export class CountryListProvider implements Resolve<any>
{
    country: Country[];
    countryEdit: Country;
    onDataChanged: BehaviorSubject<any>;
    routeParams: any;
    state: any;
    pageOptions: PageOptions;
    currentPage: PageOptions;

    /**
     * Constructor
     *
     * @param {ApiGateway} gw
     */
    constructor(
        public gw: ApiGateway,
        private location: Location,
    ) {
        this.onDataChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        // Get state for current or new page
        this.state = this.location.getState();
           if (this.state.currentPage && state.url.includes(this.state.path)) {
            this.currentPage = this.state.currentPage;
        }
        this.countryEdit = this.state.currentCountry;

        if (this.currentPage) {
            this.pageOptions = this.currentPage;
        }
        else {
            this.pageOptions = new PageOptions();
            this.pageOptions.fieldSort = 'name';
        }
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this.getPage(this.pageOptions)
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    get(id: number): Observable<Country> {
        if (!id) {
            return null;
        }
        return this.gw.get('country/:id', { id: id });
    }

    get currentCountry(): Country {
        return this.countryEdit;
    }

    getAll(entity?: String): Promise<any> {
        let urlPath = 'country/';
        if (entity){
            urlPath = entity + urlPath
        }
        return new Promise((resolve, reject) => {
            this.gw.get(urlPath).subscribe((res: HttpResponse<any>) => {
                this.country = res.body;
                this.onDataChanged.next(this.country);
                resolve(res);
            }, reject);
        });
    }

    getPage(pageOptions: PageOptions, entity?: String): Promise<any> {
        let urlPath = 'country/search';
        if (entity){
            urlPath = entity + urlPath
        }
        return new Promise((resolve, reject) => {
            this.gw.post(urlPath, pageOptions).subscribe((res: HttpResponse<any>) => {
                if (!CwUtils.compare(this.country, res.body.content) && res.body.content.length > 0) {
                    this.country = res.body.content;
                    this.onDataChanged.next(this.country);
                } else {
                    this.country = res.body.content;
                }
                resolve(res);
            }, reject);
        });
    }

    getPageSettings() {
        if (this.currentPage) {
            return this.pageOptions;
        } else {
            return;
        }
    }
}

