export class ModuleControl {
    constructor(
        public modules: Modules[]
    ) {
    }

}

export class ModuleList {
    constructor(
        public code: string,
        public name: string,
        // public subModules: SubModuleList[],
    ) {
    }
}

export class SubModuleList {
    constructor(
        public code: string,
        public name: string,
        // public views: ViewList[],
    ) {
    }
}

export class ViewList {
    constructor(
        public code: string,
        public name: string,
        public path: string,
    ) {
    }
}

export interface Modules {
    name: string;
    subModules: SubModules[];
}

export interface SubModules {
    name: string,
    views: Views[];
}

export interface Views {
    code: string,
    name: string,
    path: string,
}