
import { NgModule } from '@angular/core';

import { QuotationEditProvider } from './quotation-edit.provider';
import { QuotationListProvider } from './quotation-list.provider';

export * from './quotation-edit.provider';
export * from './quotation-list.provider';

export {
  QuotationListProvider,
  QuotationEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    QuotationEditProvider,
    QuotationListProvider
  ]
})
export class QuotationBackendModule { }
