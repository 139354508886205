import { UserLogService } from "../../../../../shared/cw-backend/providers/security/user/user-log-service";
import { Component, OnInit, ViewChild } from "@angular/core";
import { UserLog } from "cw-backend";
import { MatDialog } from "@angular/material/dialog";
import { LastAccessComponent } from "./last-access-modal/last-access-modal.component";
import { ScheduleService } from "cw-services";
import { MedicalProvider } from "../../../../../shared/cw-backend/providers/operational/medical/medical.provider";
import { interval } from "rxjs";
import { SnackBarService } from "../../../../../shared/cw-utils/snackbar.service";

@Component({
    selector: "footer",
    templateUrl: "./footer.component.html",
    styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
    @ViewChild("dialogContent")
    successLogon: UserLog;
    dialogRef: any;
    lastAccessList: UserLog[];
    eventCodeList: any[];
    sucessDate: Date;
    lastLogon: Date;
    patientSelected: any;
    dateSelected: any;
    timeSelected: any;
    signatureWating: boolean = false;
    loadingDots: string = '.';
    loadingInterval: any;

    constructor(
        private userLogService: UserLogService,
        private scheduleService: ScheduleService,
        private medicalProvider: MedicalProvider,
        private snackBar: SnackBarService,
        public _matDialog: MatDialog
    ) { }

    ngOnInit() {
        this.verifyingSignature();
        this.lastLogon = JSON.parse(localStorage.getItem("user_log_access"));
        this.lastAccessList = JSON.parse(localStorage.getItem("user_log"));
        this.userLogService.userLogged.subscribe((userLogged) => {
            if (userLogged) {
                this.lastLogon = JSON.parse(
                    localStorage.getItem("user_log_access")
                );
            }
        });

        this.scheduleService.scheduleSource.subscribe((scheduleSource) => {

            if (scheduleSource) {
                const scheduleSouce = sessionStorage.getItem("scheduleSource");
                this.selectSchedule(JSON.parse(scheduleSouce));
            } else {
                this.clearSelected();
            }
        });

        this.loadingInterval = setInterval(() => {
            this.loadingDots = this.loadingDots.length < 3 ? this.loadingDots + '.' : '.';
          }, 500); 
        
        MedicalProvider.signatureProgress.subscribe({
            next: (signatureProgress: any) => {
                console.log("🚀 ~ e:", signatureProgress)
                if (signatureProgress) {
                    this.signatureWating = true;
                    const intervaloVerificacao = interval(1000);

                    

                    intervaloVerificacao.subscribe(() => {
                        const documentSignatureId = localStorage.getItem("signatureProgress");

                        if (documentSignatureId) {
                            this.medicalProvider.getById(new Number(documentSignatureId).valueOf()).then((result) => {
                                if (result.body.signed != null) {
                                    this.signatureWating = false;
                                    this.snackBar.successMessage("Documento assinado.");
                                    localStorage.removeItem("signatureProgress");
                                }
                            })
                        }

                    });
                }
            }
        })

    }

    verifyingSignature() {
        const documentSignatureId = localStorage.getItem("signatureProgress");

        if (documentSignatureId) {
            this.signatureWating = true;
            const intervaloVerificacao = interval(1000);
            intervaloVerificacao.subscribe(() => {
                const documentSignatureId = localStorage.getItem("signatureProgress");

                if (documentSignatureId) {

                    this.medicalProvider.getById(new Number(documentSignatureId).valueOf()).then((result) => {
                        console.log(result);

                        if (result.body.signed != null) {
                            this.signatureWating = false;
                            this.snackBar.successMessage("Documento assinado.");
                            localStorage.removeItem("signatureProgress");
                        }


                    })
                }
            });
        }
    }

    openLastAccessModal(): void {
        this.dialogRef = this._matDialog.open(LastAccessComponent, {
            panelClass: "user-log-modal",
            data: {
                lastAccessList: this.lastAccessList,
            },
        });

        this.dialogRef.afterClosed().subscribe((response) => {
            if (!response) {
                return;
            }
        });
    }

    selectSchedule(schedule) {
        if (schedule.patientId) {
            this.patientSelected = schedule.patientPersonName;
        } else if (schedule.personId) {
            this.patientSelected = schedule.personName;
        }
        this.dateSelected = schedule.date;
        this.timeSelected = schedule.hour;
    }

    clearSelected() {
        sessionStorage.removeItem("scheduleSource");
        // this.unregisterSchedule(this.scheduleSelected.id);
        this.patientSelected = null;
        this.dateSelected = null;
        this.timeSelected = null;
    }
}
