import { AbstractPO } from 'cw-backend';

export class UserLog {
    id: number;
    accessDate: AccessDate;
    accessType: string;
    event_code: string;
    ipAddress: string;
    name: string;
    success: boolean;
    user: any;
}

export class AccessDate {
    chronology: {};
    dayOfMonth: number;
    dayOfWeek: string;
    dayOfYear: number;
    hour: number;
    minute: number;
    month: string;
    monthValue: number;
    nano: number;
    second: number;
    year: number;
}