import { CwUtils } from './../../../../cw-utils/cw-utils.service';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { PageOptions } from '../../../util/backend-util-module';
import { SnackBarService } from '../../../../cw-utils/cw-utils.module';
import { ApiGateway } from '../../../api-gateway';
import { TableType } from '../../../models/backend-definitions.module';

@Injectable()
export class TableTypeListProvider implements Resolve<any>
{
    tableType: TableType[];
    tableTypeEdit: TableType;
    onDataChanged: BehaviorSubject<any>;
    routeParams: any;
    state: any;
    pageOptions: PageOptions;
    currentPage: PageOptions;

    constructor(
        public gw: ApiGateway,
        private location: Location,
    ) {
        this.onDataChanged = new BehaviorSubject({}); 
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        // Get state for current or new page
        this.state = this.location.getState();
           if (this.state.currentPage && state.url.includes(this.state.path)) {
            this.currentPage = this.state.currentPage;
        }
        this.tableTypeEdit = this.state.currentTableType;

        if (this.currentPage) {
            this.pageOptions = this.currentPage;
        }
        else {
            this.pageOptions = new PageOptions();
            this.pageOptions.fieldSort = 'name';
        }
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this.getPage(this.pageOptions)
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    get(id: number): Observable<TableType> {
        if (!id) {
            return null;
        }
        return this.gw.get('tableType/:id', { id: id });
    }

     get currentTableType(): TableType {
        return this.tableTypeEdit;
    }

    getTypeTableType(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('tableType/typeTableType')
                .subscribe((res: HttpResponse<any>) => {
                    resolve(res);
                }, reject);
        });
    }

    getAll(entity?: string): Promise<any> {
        let urlPath = 'tableType/';
        if (entity){
            urlPath = entity + urlPath;
        }
        return new Promise((resolve, reject) => {
            this.gw.get(urlPath).subscribe((res: HttpResponse<any>) => {
                this.tableType = res.body;
                this.onDataChanged.next(this.tableType);
                resolve(res);
            }, reject);
        });

    }
 
    getPage(pageOptions: PageOptions, entity?: String): Promise<any> {
        return new Promise((resolve, reject) => {
            let urlPath = 'tableType/search';
            if (entity){
                urlPath = entity + urlPath;
            }
            this.gw.post(urlPath, pageOptions).subscribe((res: HttpResponse<any>) => {
                if (!CwUtils.compare(this.tableType, res.body.content)){
                    this.tableType = res.body.content;
                    this.onDataChanged.next(this.tableType);
                } else {
                    this.tableType = res.body.content;
                }
                resolve(res);
            }, reject);
        });
    }

     getPageSettings() {
        if (this.currentPage) {
            return this.pageOptions;
        } else {
            return;
        }
    }
}

