import { Component, OnInit, ViewEncapsulation, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationExtras } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';

import { ModuleControl, UserLogService, UserService, Person } from 'cw-backend';
import { SnackBarService } from 'cw-utils';
import { AuthService } from 'cw-services';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';


@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  roleNavigation: ModuleControl;

  constructor(
    private _fuseConfigService: FuseConfigService,
    private _formBuilder: FormBuilder,
    public _dialog: MatDialog,
    private authService: AuthService,
    private router: Router,
    private snackBar: SnackBarService,
    private userService: UserService,
    private userLogService: UserLogService,
    private progressBar: FuseProgressBarService
  ) {

    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        toolbar: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };
  }

  ngOnInit(): void {
    // $('#user_login').focus();
    this.loginForm = this._formBuilder.group({
      login: ['', [Validators.required]],
      password: ['', [Validators.required]],
      acceptTermsUse: [''],
    });
  }

  login() {
    const data = this.loginForm.getRawValue();
    this.progressBar.show();
    this.userService.login(data).then(res => {
      // get token from header
      let token = res.headers.get('X-Auth-Token');
      if (!token) {
        this.snackBar.unexpectedMessage();
        return;
      }

      // set user's credencial in localstorage
      this.authService.setAuthenticated(token, res.body);
      // set user's permissions
      this.getPermissions().then(() => {
        // set time of login in localstorage
        this.userLogService.setUserLogAcess();
        // get last logons of user
        this.userLogService.getUserLastLogons();
        // redirect
        this.progressBar.hide();
        if (this.router.url === '/auth/login'&& res.body?.performerId) {
          this.router.navigate(['operacional/atendimento/agenda-profissional']);
        }else{
          this.router.navigate(['aplicacao/dashboards/admin']);
        }
      });
    }, error => {
      const UserExecption = error.error;
      if (error.status == 0) {
        this.snackBar.unexpectedMessage();
      } else switch (UserExecption.code) {
        case "PASSWORD_EXPIRED":
          this.resetPassword(this.loginForm.value);
          break;
        case "PENDING_ACCEPT_TERMS_USE":
          this.termsOfUseDialog();
          break;
        default:
          this.snackBar.errorMessage(UserExecption.message);
          break;
      }
      this.progressBar.hide();
    });
  }

  termsOfUseDialog() {
    const dialogRef = this._dialog.open(TermsOfUseComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loginForm.controls['acceptTermsUse'].setValue(result);
        this.login();
        // const data = this.loginForm.getRawValue();
        // this.userService.login(data).then(() => { });
        // this.resetPassword(this.loginForm.value)
      }
    });
  }

  resetPassword(user) {
    const navigationExtras: NavigationExtras = {
      state: { user: user }
    };
    this.router.navigate(['auth/redefinir-senha'], navigationExtras);
  }

  loginDigital() {
    this.router.navigate(['aplicacao/dashboards/admin']);
  }

  async getPermissions() {
    return await this.userService.getPermissions().then(res => {
      this.authService.setPermission(res.body.modules);
    })
  }
}

@Component({
  selector: 'terms-of-use',
  templateUrl: 'terms-of-use.component.html',
})

export class TermsOfUseComponent {
}


