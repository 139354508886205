import { Role, Person, Company, CompanyCostCenter } from 'cw-backend';
import { UserLogonActivity } from './user-logon-activities';

export class User {
    constructor(
        public id?: number,
        public login?: string,
        public password?: string,
        public email?: string,
        public status?: string,
        public roles?: Role[],
        public rolesList?: string,
        public userLogonActivities?: UserLogonActivity[],
        public company?: Company,
        public companyCostCenter?: CompanyCostCenter,
        public person?: Person,
        // public acceptTermsUse?: boolean,

    ) {

    }

}