import { Moment } from 'moment'

export class AuditFilter {
    constructor(
        public tableName: string,
        public userId: number,
        public startat: Moment,
        public endat: Moment,
        public operation: string,
        public auditFilterFields: AuditFilterField[],
    ) {
    }
}

export class AuditFilterField {
    constructor(
        public name: string,
        public value: string,
    ) {
    }
}





 