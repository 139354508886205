import { DashboardAdminService } from './../../shared/cw-backend/providers/dashboard-admin/dashboard-admin.service';
import { UserService } from '../../shared/cw-backend/providers/security/user/user-service';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { NgModule, LOCALE_ID, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';
import { FakeDbService } from 'app/fake-db/fake-db.service';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { MatSnackBarModule, MatSnackBarConfig } from '@angular/material/snack-bar';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { AppStoreModule } from 'app/store/store.module';

import { SnackBarService } from 'cw-utils';
import { WebSocketService, ViewListService, ParameterProvider, RoleListProvider, CustomPaginator, RoleEditProvider, ScheduleListProvider } from 'cw-backend';
import { MomentModule } from 'ngx-moment';
import { LoginComponent } from './main/pages/authentication/login/login.component';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { RolePermissionService, AuthGuard, HotKeyService } from 'cw-services';
import { routingModule } from './routing.modules';
import { ModalModule } from './main/modals';

import { registerLocaleData } from '@angular/common';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { CanDeactivateGuard } from '../../shared/cw-utils/can-deactivate.service';
import { AdminDashboardComponent } from './main/modules/application/dashboards/admin/admin-dashboard.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { CustomDateAdapter } from './config/custom-date-adapter';
export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};
import localeBr from '@angular/common/locales/pt';
import localeBrExtra from '@angular/common/locales/extra/pt';
import { MedicalModuleModule } from 'app/main/modules/operational/medical-module/medical-module.module';
import { FuseProgressBarComponent } from '@fuse/components/progress-bar/progress-bar.component';
import { ProfileProvider } from '../../shared/cw-backend/providers/security/profile/profile.provider';
import { DialogOrderTissModule } from './layout/components/dialog-order-tiss/dialog-order-tiss.module';
registerLocaleData(localeBr, 'pt', localeBrExtra)
export const MY_FORMATS = {
    parse: {
      dateInput: 'DD/MM/YYYY',
    },
    display: {
      dateInput: 'DD/MM/YYYY',
      monthYearLabel: 'DD/MM/YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'DD/MM/YYYY'
    },
  };

const appRoutes: Routes = [
    {
        path: '',
        redirectTo: '/auth/login',
        pathMatch: 'full'
    },
    {
        path: '',
        loadChildren: () => import('./main/apps/apps.module').then(m => m.AppsModule),
        canActivate: [AuthGuard],
    },
    {
        path: '',
        loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule),
    },
    {
        path: '',
        loadChildren: () => import('./main/modules/modules.module').then(m => m.ModulesModule),
    },
    {
        path: '**',
        redirectTo: '/error/404',
        pathMatch: 'full'
    },
];

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        routingModule,
        // KeyboardShortcutsModule.forRoot(),
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),
        TranslateModule.forRoot(),
        InMemoryWebApiModule.forRoot(FakeDbService, {
            delay: 0,
            passThruUnknownUrl: true
        }),
        NgIdleKeepaliveModule.forRoot(),
        MomentModule.forRoot({
            relativeTimeThresholdOptions: {
                'm': 59
            }
        }),
        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatSnackBarModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        AppStoreModule,   
        DialogOrderTissModule,    

        ModalModule,
        NgxMaskModule.forRoot(options),
        CurrencyMaskModule
    ],
    providers: [
        AuthGuard,
        ProfileProvider,
        CanDeactivateGuard,
        SnackBarService,
        UserService,
        WebSocketService,
        RolePermissionService,
        ViewListService,
        LoginComponent,
        ParameterProvider,
        AdminDashboardComponent,
        DashboardAdminService,
        RoleListProvider,
        RoleEditProvider,
        HotKeyService,
        MatSnackBarConfig,
        ScheduleListProvider,
        { provide: LOCALE_ID, useValue: "pt-BR" },
        
       { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
       {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
        {
            provide: MatPaginatorIntl,
            useClass: CustomPaginator
        },
        // { provide: DateAdapter, useClass: CustomDateAdapter },
        // {
        //     provide: ErrorHandler,
        //     useClass: FuseProgressBarComponent,
        // }
    ],
    entryComponents: [
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}