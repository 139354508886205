
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { Pending, ApiGateway } from 'cw-backend';
import { SnackBarService } from 'cw-utils';

@Injectable()
export class PendingEditProvider implements Resolve<any>
{
    routeParams: any;
    state: any;
    onDataChanged: BehaviorSubject<any>;
    pending: Pending;

    constructor(
        public gw: ApiGateway,
        public snackBar: SnackBarService
    ) {
        this.onDataChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        return new Promise((resolve, reject) => {
            Promise.all([this.getPending()]).then(
                () => {
                    resolve(true);
                },
                reject
            );
        });
    }

    getPending(): Promise<any> {
        return new Promise((resolve, reject) => {
            let data = this.checkLastModifications()
            if (data && data.id == this.routeParams.id) {
                this.pending = data;
                this.onDataChanged.next(this.pending);
                resolve(this.pending);
            } else if (this.routeParams.id === 'novo') {
                this.onDataChanged.next(false);
                resolve(false);
            }
            else {
                this.gw.get('pending/' + this.routeParams.id).subscribe({
                    next: (res: Pending) => {
                        this.pending = res;
                        this.onDataChanged.next(this.pending);
                        resolve(res);
                    },
                    error: (err) => {
                        reject(err);
                    },
                });
            }
        });
    }

    insert(pending): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('pending/', pending).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    }

    edit(pending): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('pending/', pending).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                }, error: (err) => {
                    reject(err);
                },
            });
        });
    }

    delete(pending): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.delete('pending/' + pending.id).subscribe({next:(response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, error:(err)=> {
                reject(err);
            },});
        });
    }

    checkLastModifications(): Pending {
        let wasLocked = JSON.parse(localStorage.getItem('session_locked'));
        let data;
        if (wasLocked) {
            data = JSON.parse(localStorage.getItem('last_modification'));
            localStorage.removeItem('session_locked');
        }
        return data;
    }
}
