
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { PageOptions } from '../../../util/backend-util-module';
import { View } from '../../../models/backend-definitions.module';
import { ApiGateway } from '../../../api-gateway';

@Injectable()
export class ViewListService implements Resolve<any>
{
    view: View[];
    onDataChanged: BehaviorSubject<any>;
    routeParams: any;
    state: any;
    pageOptions: PageOptions;
    currentPage: PageOptions;

    constructor(
        public gw: ApiGateway,
        private location: Location,
    ) {
        // Set the defaults
        this.onDataChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        // Get state for current or new page
        this.state = this.location.getState();
           if (this.state.currentPage && state.url.includes(this.state.path)) {
            this.currentPage = this.state.currentPage;
        }
        if (this.currentPage) {
            this.pageOptions = this.currentPage;
        }
        else {
            this.pageOptions = new PageOptions();
        }
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this.getPage(this.pageOptions)
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    get(id: number): Observable<View> {
        if (!id) {
            return null;
        }
        return this.gw.get('view/:id', { id: id });
    }

    getViewType(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('view/viewType/simpleList')
                .subscribe((res: HttpResponse<any>) => {
                    resolve(res);
                }, reject);
        });
    }

    getComponents(viewId: number): Observable<any> {
        if (!viewId) {
            return null;
        }
        return this.gw.get('view/components/:id', { id: viewId });
    }

    getTransactions(viewId): Promise<any> {
        if (!viewId) {
            return null;
        }
        return new Promise((resolve, reject) => {
            this.gw.get('view/:viewId/transactions', { viewId: viewId })
                .subscribe((res: HttpResponse<any>) => {
                    resolve(res);
                }, reject);
        });
    }

    getTransactionFilter(id){
        return new Promise<any>((resolve, reject) => {
            this.gw.get("transaction/:id/filter", { id: id })
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        res.body ? resolve(res.body) : resolve(res);
                    },
                    error: (err: any) => { reject(err); }
                })
        })
    }

    getAll(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('view/').subscribe((res: HttpResponse<any>) => {
                this.view = res.body;
                this.onDataChanged.next(this.view);
                resolve(res);
            }, reject);
        });
    }
    /**
     * Get page 
     * @returns page
     */
    getPage(pageOptions: PageOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('view/search', pageOptions).subscribe((res: HttpResponse<any>) => {
                this.view = res.body.content;
                this.onDataChanged.next(this.view);
                resolve(res);
            }, reject);
        });
    }

    getRestrictionTypeCombo(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('components/restrictionType/simpleList').subscribe((res: HttpResponse<any>) => {
                resolve(res);
            }, reject);
        });
    }

    getComponentTypeList(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('components/componentType/simpleList').subscribe((res: HttpResponse<any>) => {
                resolve(res.body.values);
            }, reject);
        });
    }

    getPageSettings() {
        if (this.currentPage) {
            return this.pageOptions;
        } else {
            return;
        }
    }
}

