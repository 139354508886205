import { UserService } from '../../../../../../shared/cw-backend/providers/security/user/user-service';
// default
import { Component, OnInit, ViewEncapsulation, ViewChild, TemplateRef, Inject, AfterViewInit } from '@angular/core';
import { Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

// fuse
import { fuseAnimations } from '@fuse/animations';
import { Validators, ValidatorFn, AbstractControl, ValidationErrors, FormGroup, FormBuilder } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { SnackBarService } from 'cw-utils';


@Component({
    selector: 'user-change-password',
    templateUrl: './user-change-password.component.html',
    styleUrls: ['./user-change-password.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class UserChangePasswordComponent implements OnInit, AfterViewInit {
    @ViewChild('dialogContent')
    dialogContent: TemplateRef<any>;
    user: any;
    changePasswordForm: FormGroup;

    private _unsubscribeAll: Subject<any>;

    constructor(
        private _formBuilder: FormBuilder,
        public _matDialogRef: MatDialogRef<UserChangePasswordComponent>,
        private userService: UserService,
        private snackBar: SnackBarService,
        @Inject(MAT_DIALOG_DATA) private _data: any,
    ) {

        this._unsubscribeAll = new Subject();
        // Set the defaults

        if (this._data.user) {
            this.user = this._data.user;
        }
    }




    /**
     * 
     * On init
     * 
     */
    ngOnInit(): void {
        if (!this.user) {
            this.snackBar.warnMessage("Ocorreu um erro, tente novamente.");
            this.dimiss();
        }

        this.changePasswordForm = this._formBuilder.group({
            login: [this.user.login, [Validators.required]],
            currentPassword: ['', Validators.compose([
                Validators.required,
            ])],
            newPassword: ['', Validators.compose([
                Validators.required,
                // Validators.pattern('((?=.*\\d)(?=.*[a-zA-Z]).{8,20})')
            ])],
            newPasswordConfirm: ['', [Validators.required, confirmPasswordValidator]]
        });

        // Update the validity of the 'passwordConfirm' field
        // when the 'password' field changes
        this.changePasswordForm.get('newPassword').valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.changePasswordForm.get('newPasswordConfirm').updateValueAndValidity();
            });
    }

    confirmResetPassword() {
        const data = this.changePasswordForm.getRawValue();

        let updatePassword = {
            currentPassword: data.currentPassword,
            newPassword: data.newPassword,
            newPasswordConfirm: data.newPasswordConfirm,
        }

        this.userService.changePassword(updatePassword).subscribe((res: HttpResponse<any>) => {
            this.snackBar.successMessage(res.body.message);
        }, error => {
            const UserExecption = error.error;
            switch (UserExecption.code) {
                case "WRONG_CURRENT_PASSWORD":
                    this.changePasswordForm.controls['currentPassword'].setErrors({ 'incorrect': true });
                    this.snackBar.errorMessage(UserExecption.message);
                    break;
                case "NEW_PASSWORD_SAME_LAST_PASSWORD":
                case "PASSWORD_NOT_MEET_SECURITY_REQUIREMENTS":
                    this.changePasswordForm.controls['newPassword'].setErrors({ 'incorrect': true });
                    this.changePasswordForm.controls['newPasswordConfirm'].setErrors({ 'incorrect': true });
                    this.snackBar.errorMessage(UserExecption.message);
                    break;
                default:
                    this.snackBar.unexpectedMessage();
                    break;
            }
        });
    }

    ngAfterViewInit() {
    }

    dimiss() {
        this._matDialogRef.close();
    }
}

/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

    if (!control.parent || !control) {
        return null;
    }

    const newPassword = control.parent.get('newPassword');
    const newPasswordConfirm = control.parent.get('newPasswordConfirm');

    if (!newPassword || !newPasswordConfirm) {
        return null;
    }

    if (newPasswordConfirm.value === '') {
        return null;
    }

    if (newPassword.value === newPasswordConfirm.value) {
        return null;
    }

    return { passwordsNotMatching: true };
};

