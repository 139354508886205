import { Directive, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import * as $ from 'jquery';

@Directive({
    selector: '[cwgAutoFocusDirective]'
})
export class AutoFocusDirectiveDirective implements OnInit {

    @Input() time: number = 0;
    @Input() scrollEvent: boolean;
    @Input() active:boolean = true;

    constructor(private elRef: ElementRef, private _redender: Renderer2) { }


    ngOnInit() {

        $(document).ready(() => {
            setTimeout(() => {
              
                if (!this.elRef.nativeElement.value || this.elRef.nativeElement.value == null && this.active) {                    
                    $(`#${this.elRef.nativeElement.id}`).focus();
                    $(`#${this.elRef.nativeElement.id}`).click();
                }
            }, this.time > 0 ? this.time : 300);
        })


    }

}
