import { Account } from './account';
import { AccountPlan } from './account-plan';
import { Brand } from './brand';
import { CompanyAccountPlan } from './company-account-plan';

export class PayType {
    name: string;
    id: number;
    type: string;
    brand: Brand;
    account: Account;
    accountPlan: AccountPlan;
    companyAccountPlan: CompanyAccountPlan;
    deadline: number;
    fee: number;
    tax: number;
    interval: number;
    split: boolean;
    splitMax: number;
    approximationRule: string;
    weekendRule: string;
    active:boolean;
}