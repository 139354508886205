import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiGateway } from 'cw-backend';
import { RoleControl } from '../../../models/backend-definitions.module';

@Injectable()
export class RoleEditProvider implements Resolve<any>
{
    routeParams: any;
    role: RoleControl;
    onDataChanged: BehaviorSubject<any>;

    /**
        * Constructor
        *
        * @param {ApiGateway} gw
        */
    constructor(
        public gw: ApiGateway,
    ) {
        // Set the defaults
        this.onDataChanged = new BehaviorSubject({});
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        return new Promise<void>((resolve, reject) => {
            Promise.all([this.getRole()]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get role
     *
     * @returns {Promise<any>}
     */
    getRole(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this.routeParams.id === 'novo') {
                this.onDataChanged.next(false);
                resolve(false);
            }
            else {
                this.gw.get('role/wAll/' + this.routeParams.id).subscribe((res: any) => {
                    this.role = res;
                    this.role.restrictableComponents = [];
                    this.onDataChanged.next(this.role);
                    resolve(res);
                }, reject);
            }
        });
    }

    /**
     * Edit role
     *
     * @param role
     * @returns {Promise<any>}
     */
    edit(role): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.put('role/', role).subscribe((res: HttpResponse<any>) => {
                resolve(res);
            }, reject);
        });
    }

    /**
     * Insert role
     *
     * @param role
     * @returns {Promise<any>}
     */
    insert(role): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('role/', role).subscribe((res) => {
                resolve(res);
            }, reject);
        });
    }

    /**
    * Delete role
    *
    * @param role
    * @returns {Promise<any>}
    */
    delete(role): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.delete('role/' + role.id).subscribe((response: HttpResponse<any>) => {
                resolve(response);
            }, reject);
        });
    }
}
