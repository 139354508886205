
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiGateway, Participation } from 'cw-backend';
import { SnackBarService } from 'cw-utils';

@Injectable()
export class ParticipationEditProvider implements Resolve<any>
{
    routeParams: any;
    state: any;
    onDataChanged: BehaviorSubject<any>;
    participation: Participation;

    constructor(
        public gw: ApiGateway,
        public snackBar: SnackBarService
    ) {
        this.onDataChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        return new Promise<void>((resolve, reject) => {
            Promise.all([this.getParticipation()]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getParticipation(): Promise<any> {
        return new Promise((resolve, reject) => {
            let data = this.checkLastModifications()
            if (data && data.id == this.routeParams.id) {
                this.participation = data;
                this.onDataChanged.next(this.participation);
                resolve(this.participation);
            } else if (this.routeParams.id === 'novo') {
                this.onDataChanged.next(false);
                resolve(false);
            } else {
                this.gw.get('professional/participation/wAll/' + this.routeParams.id).subscribe((res: Participation) => {
                    this.participation = res;
                    this.onDataChanged.next(this.participation);
                    resolve(res);
                }, reject);
            };
        });
    }

    insert(participation): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('professional/participation/', participation).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }

    insertSpec(participationSpec): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('professional/participation/spec/', participationSpec).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }

    insertPartElement(participationElement): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('professional/participation/element/', participationElement).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                }, error(err) {
                    reject(err);
                }
            });
        });
    }

    deleteElement(participationSpec): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.delete('professional/participation/element/' + participationSpec.id).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                }, error(err) {
                    reject(err);
                },
            });
        });
    }


    edit(participation): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.put('professional/participation/', participation).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }

    delete(participation): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.delete('professional/participation/' + participation.id).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }

    deleteSpec(participationSpec): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.delete('professional/participation/spec/' + participationSpec.id).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }

    copyParticipation(professionalId, participationId, method, withSpec): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('professional/:professionalId/participationCopy/:participationId/:method/spec/:withSpec', { professionalId: professionalId, participationId: participationId, method: method, withSpec: withSpec }).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject => {
                if (reject.status == 403) {
                    this.snackBar.warnMessage("Acesso não permitido");
                }
            });
        });
    }

    copyParticipationToProfessional(professionalSource, professionalTarget, type, withSpec): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('professional/:professionalSource/participationCopyAll/target/:professionalTarget/:type/spec/:withSpec', { professionalSource: professionalSource, professionalTarget: professionalTarget, type: type, withSpec: withSpec }).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject => {
                if (reject.status == 403) {
                    this.snackBar.warnMessage("Acesso não permitido");
                }
            });
        });
    }


    setAllParticipationDefault(performerId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.put(`professional/participation/all-procedure-default/${performerId}`).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                },
                error: (err =>{
                    reject(err);
                })
            })
        });
    }

   

checkLastModifications(): Participation {
    let wasLocked = JSON.parse(localStorage.getItem('session_locked'));
    let data;
    if (wasLocked) {
        data = JSON.parse(localStorage.getItem('last_modification'));
        localStorage.removeItem('session_locked');
    }
    return data;
}
}
