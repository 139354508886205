
import { NgModule } from '@angular/core';

import { ClosingReasonEditProvider } from './closing-reason-edit.provider';
import { ClosingReasonListProvider } from './closing-reason-list.provider';

export * from './closing-reason-edit.provider';
export * from './closing-reason-list.provider';

export {
  ClosingReasonListProvider,
  ClosingReasonEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    ClosingReasonEditProvider,
    ClosingReasonListProvider
  ]
})
export class ClosingReasonBackendModule { }
