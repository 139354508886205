import { UserService, UserListProvider, User } from 'cw-backend';
import { Component, OnDestroy, OnInit, ViewEncapsulation, HostListener, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { navigation } from 'app/navigation/navigation';
import { Router } from '@angular/router';
import { UserChangePasswordComponent } from 'app/main/apps/profile/user-change-password/user-change-password.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../../../../../shared/cw-services/auth/auth.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ProfileComponent } from '../profile/profile.component';

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent implements OnInit, OnDestroy {
    @Output() userLogout: EventEmitter<boolean> = new EventEmitter();

    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    user: any;
    dialogRef: any;
    imagePath: any;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        private authService: AuthService,
        private router: Router,
        private userService: UserService,
        private userListProvider: UserListProvider,
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        private _matDialog: MatDialog,
        private _sanitizer: DomSanitizer
    ) {
        // Set the defaults
        this.userStatusOptions = [
            {
                title: 'Online',
                icon: 'icon-checkbox-marked-circle',
                color: '#4CAF50'
            },
            {
                title: 'Away',
                icon: 'icon-clock',
                color: '#FFC107'
            },
            {
                title: 'Do not Disturb',
                icon: 'icon-minus-circle',
                color: '#F44336'
            },
            {
                title: 'Invisible',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#BDBDBD'
            },
            {
                title: 'Offline',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#616161'
            }
        ];

        this.languages = [
            {
                id: 'en',
                title: 'English',
                flag: 'us'
            },
            {
                id: 'tr',
                title: 'Turkish',
                flag: 'tr'
            }
        ];

        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.user = this.authService.currentUser;

        if (this.user) {
            this.userListProvider.getSimpleUser(this.user.id).subscribe(res => {
                let personImageList = res.body.person?.personImageList;
                if (res && personImageList != null &&  personImageList.length > 0) {
                    let index = personImageList.findIndex(personImage => personImage.main);
                    if (index !== -1) {
                        this.showImg(personImageList[index].image.content);
                    } else {
                        this.imagePath = null;
                    }
                } else {
                    this.imagePath = null;
                }
            })
        }

        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, { id: this._translateService.currentLang });
    }

    showImg(file) {
        if (file) {
            this.imagePath = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,'
                + file);
        }
    };

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(true);
        this._unsubscribeAll.complete();
    }

    logout() {
        this.userService.logout().then(() => {
            this.authService.logout();
            this.router.navigate(['auth/login']);
            localStorage.clear();
            sessionStorage.clear();
        })
    }

    lock() {
        localStorage.setItem('last_navigation', this.router.url)
        this.router.navigate(['auth/tela-de-bloqueio']);
    }

    onLock($event) {
        localStorage.setItem('last_navigation', this.router.url)
        this.router.navigate(['auth/tela-de-bloqueio']);
        return false;
    }

    changePassword() {
        this.dialogRef = this._matDialog.open(UserChangePasswordComponent, {
            panelClass: 'change-pw-modal',
            data: {
                user: this.user,
            }
        });

        this.dialogRef.afterClosed()
            .subscribe((response) => {
                if (!response) {
                    return;
                }
            });
    }
    openPerfil() {
        this._matDialog.open(ProfileComponent, {
            panelClass: 'clearPadding'
        });

    }
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    search(value): void {
        // Do your search here...
    }

    setLanguage(lang): void {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);
    }


    @HostListener('window:keyup.control.alt.l', ['$event'])
    keyEvent(event: KeyboardEvent) {
        this.lock();
    }

    @HostListener('window:onbeforeunload', ['$event'])
    logoutOnClose(event: any) {
        let webSessionCount = JSON.parse(localStorage.getItem('user_sessions'));
        if (webSessionCount < 1) {
            this.logout();
            localStorage.clear();
        }
    }


}
