import { Firm } from "cw-backend";
import { Order } from "./order";

export class OrderAuthorization {
    id: number;
    order: Order;
    firm: Firm;
    responsibleCode: string;
    releasePassword: string;
    releaseControl: string;
    requesterCnpj: string;
    requesterCnes: string;
    releaseValidity: Date;
    releaseDate: Date;
    statusAuthorization: string;
    statusAuthorizationCancelation: string;
    statusAuthorizationRequest: string;
}