import { PageOptions } from '../../../util/page';
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiGateway } from '../../../api-gateway';
import { ModulePO, Module } from 'cw-backend';
import { Location } from '@angular/common';

@Injectable()
export class ModuleListService implements Resolve<any>
{
    module: Module[];
    onDataChanged: BehaviorSubject<any>;
    pageOptions: PageOptions;
    routeParams: any;

    /**
     * Constructor
     *
     * @param {ApiGateway} gw
     */
    constructor(
        public gw: ApiGateway,
        private location: Location
    ) {
        // Set the defaults
        this.onDataChanged = new BehaviorSubject({});
        this.pageOptions = new PageOptions();
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        let stateData: any;
        stateData = this.location.getState();

        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this.getPage(this.pageOptions)
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }


    /**
     * Get module
     * @param id
     */
    get(moduleId: number): Observable<ModulePO> {
        if (!moduleId) {
            return null;
        }
        return this.gw.get('module/:moduleId', { moduleId: moduleId });
    }

    /**
     * Get all module
     * @returns modules
     */
    getAll(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('module/').subscribe((response: any) => {
                this.module = response.body;
                this.onDataChanged.next(this.module);
                resolve(response);
            }, reject);
        });
    }

    /**
 * Get all module
 * @returns modules
 */
    getModuleSimpleList(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('module/simpleList').subscribe((response: any) => {
                this.module = response.body;
                this.onDataChanged.next(this.module);
                resolve(response);
            }, reject);
        });
    }

    getSubModule(moduleId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('module/subModule/:id', { id: moduleId }).subscribe((response: any) => {
                this.module = response.body;
                this.onDataChanged.next(this.module);
                resolve(response);
            }, reject);
        });
    }

    /**
     * Get all module
     * @returns modules
     */
    getNested(moduleId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('module/wAll/:moduleId', { moduleId: moduleId }).subscribe((response: any) => {
                // this.module = response.body;
                // this.onDataChanged.next(this.module);
                resolve(response);
            }, reject);
        });

    }

    getPage(pageOptions: PageOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('module/search', pageOptions).subscribe((res: any) => {
                this.module = res.body.content;
                this.onDataChanged.next(this.module);
                resolve(res);
            }, reject);

        });
    }

    /**
    * Delete module
    *
    * @param module
    */
    delete(module): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.delete('module/' + module.id)
                .subscribe((response: HttpResponse<any>) => {
                    resolve(response);
                }, reject);
        });
    }
}

