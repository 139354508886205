import { NgModule } from '@angular/core';
import { UserEditProvider } from './user-edit-service';
import { UserListProvider } from './user-list.provider';
import { UserService } from './user-service';
import { UserProfile } from '../../../models/security/user/user-profile';
import { UserLogService } from './user-log-service';


export { UserService, UserProfile, UserEditProvider, UserListProvider, UserLogService };

@NgModule({
  declarations: [

  ],
  imports: [

  ],
  exports: [

  ],
  providers: [
    UserService,
    UserListProvider,
    UserEditProvider,
    UserLogService
  ]
})
export class UserBackendModule { }
