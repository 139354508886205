
import { NgModule } from '@angular/core';

import { TableTypeListProvider } from './table-type-list.provider';
import { TableTypeEditProvider } from './table-type-edit.provider';

export * from './table-type-edit.provider';
export * from './table-type-list.provider';

export {
  TableTypeListProvider,
  TableTypeEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    TableTypeEditProvider,
    TableTypeListProvider
  ]
})
export class TableTypeBackendModule { }
