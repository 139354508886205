
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { Procedure, ApiGateway, Gender } from 'cw-backend';
import { SnackBarService } from 'cw-utils';

@Injectable()
export class GenderEditProvider implements Resolve<any>
{
    routeParams: any;
    state: any;
    onDataChanged: BehaviorSubject<any>;
    gender: Gender;

    constructor(
        public gw: ApiGateway,
        public snackBar: SnackBarService
    ) {
        this.onDataChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        return new Promise<void>((resolve, reject) => {
            Promise.all([this.getGender()]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getGender(): Promise<any> {
        return new Promise((resolve, reject) => {
            let data =  this.checkLastModifications()
            if (data && data.id == this.routeParams.id) {
                this.gender = data;
                this.onDataChanged.next(this.gender);
                resolve(this.gender);
            } else if (this.routeParams.id === 'novo') {
                this.onDataChanged.next(false);
                resolve(false);
            }
            else {
                this.gw.get('gender/' + this.routeParams.id).subscribe((res: Gender ) => {
                    this.gender = res;
                    this.onDataChanged.next(this.gender);
                    resolve(res);
                }, reject);
            }
        });
    }

    insert(gender): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('gender/', gender).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }

    edit(gender): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.put('gender/', gender).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }

    delete(gender): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.delete('gender/' + gender.id).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                 this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }

    checkLastModifications(): Gender {
        let wasLocked = JSON.parse(localStorage.getItem('session_locked'));
        let data;
        if (wasLocked){
            data = JSON.parse(localStorage.getItem('last_modification'));
            localStorage.removeItem('session_locked');
        }  
        return data;
    }
}
