
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiGateway, Port } from 'cw-backend';
import { SnackBarService } from 'cw-utils';

@Injectable()
export class PortEditProvider implements Resolve<any>
{
    routeParams: any;
    state: any;
    onDataChanged: BehaviorSubject<any>;
    port: Port;

    constructor(
        public gw: ApiGateway,
        public snackBar: SnackBarService
    ) {
        this.onDataChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        return new Promise<void>((resolve, reject) => {
            Promise.all([this.getPort()]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getPort(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this.routeParams.id === 'novo') {
                this.onDataChanged.next(false);
                resolve(false);
            }
            else {
                this.gw.get('port/wAll/' + this.routeParams.id).subscribe((res: Port ) => {
                    this.port = res;
                    this.onDataChanged.next(this.port);
                    resolve(res);
                }, reject);
            }
        });
    }

    insert(port): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('port/', port).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }

    edit(port): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.put('port/', port).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }

    delete(port): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.delete('port/' + port.id).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                 this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }
}
