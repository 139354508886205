import { Injectable } from '@angular/core';
import { CwUtils } from '../../cw-utils/cw-utils.module';
import { Person } from '../../cw-backend/models/tables/general/person';
import { PersonCertificate, PersonIdentity, PersonElector, PersonWorkCard, PersonImage, User, Professional, Performer, Address } from '../../cw-backend/models/backend-definitions.module';
import { Foreign } from '../../cw-backend/models/tables/general/foreing';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';


@Injectable()
export class PersonService {
    isActive: boolean;
    static person: FormGroup;

    constructor(
    ) {
    }
    public static checkDependecies(person): any {

        let elector = person.personElector
        let electorResult = this.validateFields(elector);
        if (!electorResult) {
            person.personElector = null;
        }

        let certificate = person.personCertificate
        let certificateResult = this.validateFields(certificate);
        if (!certificateResult) {
            person.personCertificate = null;
        }

        let workCard = person.personWorkCard
        let workCardResult = this.validateFields(workCard);
        if (!workCardResult) {
            person.personWorkCard = null;
        }

        let identity = person.personIdentity
        let identityResult = this.validateFields(identity);
        if (!identityResult) {
            person.personIdentity = null;
        }
        let foreign = person.foreign
        let foreignResult = this.validateFields(foreign);
        if (!foreignResult) {
            person.foreign = null;
        }

        if (person.country && person.country.code == '105') {
            person.foreign = null;
        }

        if (person.breed && person.breed == 5) {
            person.ethnicity = null;
        }
        return person;
    };

    public static validateFields(obj, length?): any {
        let result = Object.values(obj).filter((item: any) => item != null);
        if (length && result.length < length) {
            return false
        } else if (result.length == 0 || result.length == 1) {
            return false
        }
        return true;
    };



    public static dataConverter(person) {
        if (person.birthday) {
            person.birthday = CwUtils.dateConverterToLocalDate(person.birthday);
        }
        if (person.personIdentity && person.personIdentity.dated) {
            person.personIdentity.dated = CwUtils.dateConverterToLocalDate(person.personIdentity.dated);
        }
        if (person.personCertificate && person.personCertificate.dated) {
            person.personCertificate.dated = CwUtils.dateConverterToLocalDate(person.personCertificate.dated);
        }
        if (person.personWorkCard && person.personWorkCard.dated) {
            person.personWorkCard.dated = CwUtils.dateConverterToLocalDate(person.personWorkCard.dated);
        }
        if (person.elector && person.elector.entryDate) {
            person.elector.entryDate = CwUtils.dateConverterToLocalDate(person.elector.entryDate);
        }
        if (person.foreign && person.foreign.entryDate) {
            person.foreign.entryDate = CwUtils.dateConverterToLocalDate(person.foreign.entryDate);
        }
        return person;
    };

    public static checkFilledsDocs(data, entity) {
        // TODO - Review
        if (!data.person) {
            data.person = new Person();
        }
        if (!data.person.professional && entity == 'user') {
            data.person.professional = new Professional();
            data.person.professional.active = true;
            data.person.professional.performer = new Performer();
            data.person.professional.performer.active = true;
            data.person.professional.performer.staff = true;
        }
        if (!data.person.personCertificate) {
            data.person.personCertificate = new PersonCertificate();
        }
        if (!data.person.personIdentity) {
            data.person.personIdentity = new PersonIdentity();
        }
        if (!data.person.personElector) {
            data.person.personElector = new PersonElector();
        }
        if (!data.person.personWorkCard) {
            data.person.personWorkCard = new PersonWorkCard();
        }
        if (!data.person.personImageList) {
            data.person.personImageList = new Array<PersonImage>();
        }
        if (!data.person.foreign) {
            data.person.foreign = new Foreign();
        }
        return data;
    };

    public static createForm(person, entity?) {
        let _formBuilder = new FormBuilder();
        if (!person.professional && entity == 'user') {
            person.professional = new Professional();
            person.professional.active = true;
            person.professional.staff = true;
            person.professional.performer = new Performer()
            person.professional.performer.active = true;
        }

        if (!person.pcd) {
            person.pcd = false;
        }

        if (!person.address){
            person.address = new Address();
        }

        this.person = _formBuilder.group({
            id: [person.id],
            name: [person.name],
            alias: [person.alias],
            socialName: [person.socialName != null || person.socialName != "" ? person.socialName : null],
            pcd: [person.pcd],
            cpf: [person.cpf == "00000000515" ? null : person.cpf],
            birthday: [person.birthday],
            cns: new FormControl(person.cns, [Validators.maxLength(16)]),
            nis: new FormControl(person.nis, [Validators.maxLength(20)]),
            address:_formBuilder.group({
                id: [person.address.id],
                district: [person.address.district, Validators.required],
                address: new FormControl(person.address.address, Validators.required),
                zipcode: new FormControl(person.address.zipcode, Validators.required),
                addressType: new FormControl(person.address.addressType, Validators.required),
                city: new FormControl(person.address.city, Validators.required),
                description: [person.address.description],
                addon: [person.address.addon],
                number: [person.address.number],
                country: [person.address.country],
              }),
            gender: new FormControl(person.gender),
            breed: new FormControl(person.breed),
            ethnicity: new FormControl(person.ethnicity),
            city: new FormControl(person.city),
            country: new FormControl(person.country),
            civilStatus: new FormControl(person.civilStatus),
            schooling: new FormControl(person.schooling),
            occupation: new FormControl(person.occupation),
            contactList: new FormControl(person.contactList),
            attendSchool: new FormControl(person.attendSchool),
            mother: new FormControl(person.mother, [Validators.maxLength(250)]),
            dad: new FormControl(person.dad, [Validators.maxLength(250)]),
            treatment: new FormControl(person.treatment, [Validators.maxLength(50)]),
            personCertificate: _formBuilder.group({
                id: [person.personCertificate.id],
                type: [person.personCertificate.type],
                notory: [person.personCertificate.notory],
                book: [person.personCertificate.book],
                leaf: [person.personCertificate.leaf],
                term: [person.personCertificate.term],
                dated: [person.personCertificate.dated],
            }),
            personElector: _formBuilder.group({
                id: [person.personElector.id],
                number: [person.personElector.number],
                zone: [person.personElector.zone],
                section: [person.personElector.section],
            }),
            personIdentity: _formBuilder.group({
                id: [person.personIdentity.id],
                state: [person.personIdentity.state],
                number: [person.personIdentity.number],
                adjunct: [person.personIdentity.adjunct],
                dated: [person.personIdentity.dated],
                emitter: [person.personIdentity.emitter],
            }),
            personWorkCard: _formBuilder.group({
                id: [person.personWorkCard.id],
                state: [person.personWorkCard.state],
                number: [person.personWorkCard.number],
                sequence: [person.personWorkCard.sequence],
                dated: [person.personWorkCard.dated],
            }),
            // personImageList: [person.personImageList],
            foreign: _formBuilder.group({
                id: [person.foreign.id],
                country: [person.foreign.country],
                passport: [person.foreign.passport],
                entryDate: [person.foreign.entryDate]
            }),

        });

        if (entity == 'user') {
            this.person.addControl('professional', _formBuilder.group({
                id: [person.professional.id],
                council: new FormControl(person.professional.council,
                    ),
                state: new FormControl(person.professional.state,
                    ),
                councilNumber: new FormControl(person.professional.councilNumber,
                    [Validators.maxLength(10)]),
                councilType: new FormControl(person.professional.councilType,
                    [Validators.maxLength(1)]),
                active: [person.professional.active],
                staff: [person.professional.staff],
                performer: _formBuilder.group({
                    id: [person?.professional?.performer?.id],
                    active: [person?.professional?.performer?.active],
                })

            }));
        }

        return this.person.controls;
    };
}