import { Moment } from 'moment';

export class PersonCertificate {
    id: number;
    type: string;
    code: string;
    notory: string;
    book: string;
    leaf: string;
    term: string;
    dated: Moment;
}