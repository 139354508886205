
import { NgModule } from '@angular/core';

import { PatientEditProvider } from './patient-edit.provider';
import { PatientListProvider } from './patient-list.provider';
import { AuthorizationModelProvider } from './authorization-model.provider';

export * from './patient-edit.provider';
export * from './patient-list.provider';

export {
  PatientListProvider,
  PatientEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    PatientEditProvider,
    PatientListProvider,
    AuthorizationModelProvider
  ]
})
export class PatientBackendModule { }
