import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Order } from '../../../../../shared/cw-backend/models/backend-definitions.module';

@Component({
    selector: 'app-dialog-order-tiss',
    templateUrl: './dialog-order-tiss.component.html',
    styleUrls: ['./dialog-order-tiss.component.scss']
})
export class DialogOrderTissComponent implements OnInit {

    public order: Order;

    constructor(
        public _matDialogRef: MatDialogRef<DialogOrderTissComponent>,
    ) { }

    ngOnInit() {
    }

    dimiss() {
        this._matDialogRef.close();
    }

}
