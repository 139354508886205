import { Company } from 'cw-backend';
import { CwUtils } from 'cw-utils';
import * as shape from 'd3-shape';

import { fuseAnimations } from '@fuse/animations';

import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { ViewEncapsulation, Component, OnInit, AfterViewInit } from '@angular/core';
import { Subject, Observable, BehaviorSubject, merge } from 'rxjs';
import { DataSource } from '@angular/cdk/table';
import { map } from 'rxjs/operators';
import { DashboardAdminService, User, CompanyListProvider } from 'cw-backend';
import moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
    selector: 'admin-dashboard',
    templateUrl: './admin-dashboard.component.html',
    styleUrls: ['./admin-dashboard.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class AdminDashboardComponent implements OnInit, AfterViewInit {

    user: User;
    dataSource: any;
    displayedColumns = ['avatar', 'name', 'date', 'ip'];
    refresh: BehaviorSubject<any>;
    users: BehaviorSubject<any>;

    companys: any[];
    selectedCompany: Company;

    widgets: any;
    widget5: any = {};
    widget6: any = {};
    widget7: any = {};
    widget8: any = {};
    widget9: any = {};
    widget11: any = {};

    weatherForecast: any;

    dateNow = Date.now();
    _unsubscribeAll: Subject<any>;

    constructor(
        private _fuseSidebarService: FuseSidebarService,
        private adminService: DashboardAdminService,
        private companyListProvider: CompanyListProvider,
        private _sanitizer: DomSanitizer,
        private cwUtils: CwUtils,
    ) {
        this.weatherForecast = JSON.parse(localStorage.getItem('weatherForecast'));
        this.selectedCompany = new Company();
        this.getCompany();
        this.refresh = new BehaviorSubject<any>(false);
        this.users = new BehaviorSubject([{}]);
        this._unsubscribeAll = new Subject();
        /**
         * Widget 5
         */
        this.widget5 = {
            currentRange: 'TW',
            xAxis: true,
            yAxis: true,
            gradient: false,
            legend: false,
            showXAxisLabel: false,
            xAxisLabel: 'Days',
            showYAxisLabel: false,
            yAxisLabel: 'Isues',
            scheme: {
                domain: ['#42BFF7', '#C6ECFD', '#C7B42C', '#AAAAAA']
            },
            onSelect: (ev) => {
            },
            supporting: {
                currentRange: '',
                xAxis: false,
                yAxis: false,
                gradient: false,
                legend: false,
                showXAxisLabel: false,
                xAxisLabel: 'Days',
                showYAxisLabel: false,
                yAxisLabel: 'Isues',
                scheme: {
                    domain: ['#42BFF7', '#C6ECFD', '#C7B42C', '#AAAAAA']
                },
                curve: shape.curveBasis
            }
        };

        /**
         * Widget 6
         */
        this.widget6 = {
            currentRange: 'TW',
            legend: false,
            explodeSlices: false,
            labels: true,
            doughnut: true,
            gradient: false,
            scheme: {
                domain: ['#f44336', '#9c27b0', '#03a9f4', '#e91e63']
            },
            onSelect: (ev) => {
            }
        };

        /**
         * Widget 7
         */
        this.widget7 = {
            currentRange: 'T'
        };

        /**
         * Widget 8
         */
        this.widget8 = {
            legend: false,
            explodeSlices: false,
            labels: true,
            doughnut: false,
            gradient: false,
            scheme: {
                domain: ['#f44336', '#9c27b0', '#03a9f4', '#e91e63', '#ffc107']
            },
            onSelect: (ev) => {
            }
        };

        /**
         * Widget 9
         */
        this.widget9 = {
            currentRange: 'TW',
            xAxis: false,
            yAxis: false,
            gradient: false,
            legend: false,
            showXAxisLabel: false,
            xAxisLabel: 'Days',
            showYAxisLabel: false,
            yAxisLabel: 'Isues',
            scheme: {
                domain: ['#42BFF7', '#C6ECFD', '#C7B42C', '#AAAAAA']
            },
            curve: shape.curveBasis
        };

        setInterval(() => {
            this.dateNow = Date.now();
        }, 1000);

    }
    ngAfterViewInit(): void {

    }

    ngOnInit(): void {

        this.user = JSON.parse(localStorage.getItem('user'));

        this.widgets = this.adminService.widgets;

        this.adminService.getUsers().then(res => {
            let users = res.body;
            users?.forEach(user => {
                if (user.photo) {
                    user.photo = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + user.photo);
                }
            });
            this.users.next(res.body)
            this.dataSource = users;

        })
        if (!this.weatherForecast?.id) {
            this.getCurrentLocation();
        }
        //this.dataSource = new OnlineUsersDataSource(this.adminService, this.refresh)
    }

    getCurrentLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                const lat = position.coords.latitude;
                const long = position.coords.longitude;
                this.getweatherForecast(lat, long);
            });
        }
    }


    getweatherForecast(lat, long) {
        this.adminService.getweatherForecast(lat, long)
            .then(res => {
                this.weatherForecast = res;
                localStorage.setItem('weatherForecast', JSON.stringify(this.weatherForecast))
            }).catch(err => {
                return {
                }
            })
    }

    round(value) {
        return Math.floor(value);
    }

    getIcon(value) {
        let icon = '';
        switch (value) {
            case '01d':
                icon = 'icon-sun';
                break;
            case '01n':
                icon = 'icon-sun';
                break;
            case '02d':
                icon = 'icon-cloudy';
                break;
            case '02n':
                icon = 'icon-cloudy';
                break;
            case '03d':
                icon = 'icon-cloudy2';
                break;
            case '03n':
                icon = 'icon-cloudy2';
                break;
            case '04d':
                icon = 'icon-cloudy4';
                break;
            case '04n':
                icon = 'icon-cloudy4';
                break;
            case '09d':
                icon = 'icon-rainy3';
                break;
            case '09n':
                icon = 'icon-rainy3';
                break;
            case '10d':
                icon = 'icon-rainy3';
                break;
            case '10n':
                icon = 'icon-rainy3';
                break;
            case '11d':
                icon = 'icon-lightning3';
                break;
            case '11n':
                icon = 'icon-lightning3';
                break;

            default:
                break;
        }
        return icon;
    }

    getCompany() {
        this.companyListProvider.getCompanySimpleList().then(res => {
            this.companys = res.body;
            this.selectedCompany = res.body[0];
        })

    }

    // TODO - REVIEW
    // doAction(messageReceived) {
    //     let token = localStorage.getItem('token');
    //     if (token) {
    //         switch (messageReceived) {
    //             case "RELOAD_LIST_USER_CONNECT":
    //                 break;
    //         }
    //     }
    // }

    getDate(accessDate) {
        moment.locale('pt-br')
        return moment(accessDate).calendar();
        // return CwUtils.dateArrayConverter(accessDate);
    }

    toggleSidebar(name): void {
        this._fuseSidebarService.getSidebar(name).toggleOpen();
    }


}

export class OnlineUsersDataSource extends DataSource<any>
{
    public _filteredDataChange = new BehaviorSubject(false);
    public data: any;
    public qtd: number;

    constructor(
        public adminService: DashboardAdminService,
        public refresh: BehaviorSubject<any>

    ) {
        super();

        this.data = this.adminService.users;
    }

    connect(): Observable<any[]> {
        const displayDataChanges = [
            this.adminService.onDataChanged,
            this.refresh
        ];

        return merge(...displayDataChanges)
            .pipe(
                map(() => {
                    setTimeout(() => {
                        this.adminService.getUsers().then(res => {
                            this.data = res.body;
                        })
                    }, 6000);
                    return this.data;
                }
                ));
    }

    disconnect(): void {
    }
}

