

import { NgModule } from '@angular/core';
import { RoleEditProvider } from './role-edit.provider';
import { RoleListProvider } from './role-list.provider';
import { RoleControlService } from './role-control.service';

export * from './role-edit.provider';
export * from './role-list.provider';
export * from './role-control.service';

export {
  RoleListProvider,
  RoleEditProvider,
  RoleControlService
};

@NgModule({
  declarations: [
  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    RoleEditProvider,
    RoleListProvider,
    RoleControlService
  ]
})
export class RoleBackendModule { }
