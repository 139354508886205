
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { OrderAuthorization, ApiGateway } from 'cw-backend';
import { SnackBarService } from 'cw-utils';

@Injectable()
export class OrderClosureProvider implements Resolve<any>
{
    routeParams: any;
    state: any;
    onDataChanged: BehaviorSubject<any>;
    orderAuthorization: OrderAuthorization;

    constructor(
        public gw: ApiGateway,
        public snackBar: SnackBarService
    ) {
        this.onDataChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

       /* return new Promise((resolve, reject) => {
            Promise.all([this.getOrderAuthorization()]).then(
                () => {
                    resolve();
                },
                reject
            );
        });*/
    }

    getResons(){
        return new Promise((resolve, reject) => {
            this.gw.get(`order/closing`).subscribe((response: HttpResponse<any>) => {
                resolve(response);
            }, reject);
        });
    }

    get(orderId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get(`order/closure/${orderId}`).subscribe((response: HttpResponse<any>) => {
                resolve(response);
            }, reject);
        });
    }


    insert(orderClosure): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('order/closure/', orderClosure).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }

    edit(orderClosure): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.put('order/closure/', orderClosure).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }

    delete(orderClosure): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.delete('order/closure/' + orderClosure.id).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                 this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }

}
