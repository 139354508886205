import { State } from './state';

export class City {
    id: number;
    state_id: number;
    code: string;
    name: string;
    initials: string;
    gentile: string;
    state: State;
    constructor(
    ) { }
}