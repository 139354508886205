import { getTreeNoValidDataSourceError } from "@angular/cdk/tree";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from "@angular/forms";
import moment from "moment";
import { debounceTime, distinctUntilChanged, Subject, takeUntil } from "rxjs";
import {
    BatchEntry,
    Insurance,
    Patient,
    Professional,
    TypeGuide,
    User,
} from "../../../../../../../../shared/cw-backend/models/backend-definitions.module";
import { Filter } from "../../../../../../../../shared/cw-backend/models/operational/filter/filter";
import { InsuranceListProvider } from "../../../../../../../../shared/cw-backend/providers/accreditation/insurance/insurance-list.provider";
import { FilterListProvider } from "../../../../../../../../shared/cw-backend/providers/billing/filter/filter-list.provider";
import { PatientListProvider } from "../../../../../../../../shared/cw-backend/providers/register/operational/patient/patient-list.provider";
import { TypeGuideListProvider } from "../../../../../../../../shared/cw-backend/providers/tiss/type-guide/type-guide-list.provider";
import { PageOptions } from "../../../../../../../../shared/cw-backend/util/page";
import { SnackBarService } from "../../../../../../../../shared/cw-utils/snackbar.service";
import { UserListProvider } from "../../../../../../../../shared/cw-backend/providers/security/user/user-list.provider";
import { UserSimple } from "../../../../../../../../shared/cw-backend/models/security/user/user-simple";
import { ProfessionalListProvider } from "../../../../../../../../shared/cw-backend/providers/organizational/professional/professional-list.provider";
import { BatchEntryListProvider } from "../../../../../../../../shared/cw-backend/providers/operational/operational/reception/batchentry/batchentry-list.provider";

export interface ItemData {
    element: any;
    selected: boolean;
}
@Component({
    selector: "app-filter",
    templateUrl: "./filter.component.html",
    styleUrls: ["./filter.component.scss"],
})
export class FilterComponent implements OnInit {
    //Retorno dos tipos de filtros existentes e entrada de dados
    @Input("entity") entity: any;
    @Input("lastFilter") lastFilter: boolean = false;
    @Input("entityFilter") entityFilter = Array();
    @Input("scope") scope: string;
    @Output("filterValues") filterValues = new EventEmitter();
    @Output("statusCurrent") statusCurrent = new EventEmitter();

    //variables
    insuranceControl = new FormControl();
    administratorControl = new FormControl();
    specialityControl = new FormControl();
    professionalControl = new FormControl();
    batchEntryControl = new FormControl();
    filter = new Filter();
    statusFilter: string;
    lastBillingFilter: any;

    //list
    patientList = new Array<Patient>();
    filterTypes = new Array<Filter>();
    filteAndValues = new Array<Filter>();
    typeGuideList = new Array<TypeGuide>();
    insuranceList = new Array<Insurance>();
    administratorList = new Array<UserSimple>();
    specialityList = new Array<Professional>();
    professionalList = new Array<Professional>();
    batchEntryList = new Array<BatchEntry>();

    insuranceSelectd = new Array<ItemData>();
    administratorSelectd = new Array<ItemData>();
    specialitySelectd = new Array<ItemData>();
    professionalSelectd = new Array<ItemData>();
    batchEntrySelectd = new Array<ItemData>();

    /*
     *Controle de formulário do filtro período
     */
    rangeFilterForm: FormGroup;
    /*
     *Controle de formulário do filtro paciente
     */
    patientFilterForm: FormGroup;
    /*
     * Controle de formulário do filtro data
     */
    dateFilterForm: FormGroup;
    /*
     * controle de formulartio filtro status
     */
    statusFilterForm: FormGroup;
    /*
     * controle de formulartio filtro status autorização
     */
    statusAuthorizationForm: FormGroup;
    /*
     * controle de formulartio filtro status paciente
     */
    statusPatientForm: FormGroup;
    /*
     * controle de formulartio orderCuston status
     */
    statusOrderCustonForm: FormGroup;
    /*
     * controle de formulartio tipo  de guia
     */
    typeGuideForm: FormGroup;
    /*
     * controle de formulartio convênio
     */
    insuranceFilterForm: FormGroup;
    /*
     * controle de formulartio Administrador
     */
    administratorFilterForm: FormGroup;
    /*
     * controle de formulartio Speciality
     */
    specialityFilterForm: FormGroup;
    /*
     * controle de formulartio professional
     */
    professionalFilterForm: FormGroup;
    /*
     * controle de formulartio professional
     */
    batchEntryFilterForm: FormGroup;
    /*
     * controle de formulartio professional
     */
    glossStatusFilterForm: FormGroup;
    /*
     * controle de formulartio Faturar todas as unidades
     */
    billingAllUnitsFilterForm: FormGroup;

    /* pageSettings */
    patientPgaeOptions: PageOptions;
    insurancePageOptions: PageOptions;
    administratorPageOptions: PageOptions;
    specialityPageOptions: PageOptions;
    professionalPageOptions: PageOptions;
    batchEntryPageOptions: PageOptions;

    /*
     *controle de select para o convênio
     */
    rawDataInsurance: Array<ItemData> = [];
    selectDataInsurance: Array<ItemData> = [];
    /*
     *controle de select para o administrator
     */
    rawDataAdministrator: Array<ItemData> = [];
    selectDataAdministrator: Array<ItemData> = [];
    /*
     *controle de select para o speciality
     */
    rawDataSpeciality: Array<ItemData> = [];
    selectDataSpeciality: Array<ItemData> = [];
    /*
     *controle de select para o professional
     */
    rawDataProfessional: Array<ItemData> = [];
    selectDataProfessional: Array<ItemData> = [];
    /*
     *controle de select para o professional
     */
    rawDataBatchEntry: Array<ItemData> = [];
    selectDataBatchEntry: Array<ItemData> = [];

    private _unsubscribeAll: Subject<any>;

    downloadAndSendStatus = [
        {
            name: "E-mail não enviado",
            status: "1",
        },
        {
            name: "E-mail enviado",
            status: "2",
        },
        {
            name: "Baixado",
            status: "3",
        },
        {
            name: "Não baixado",
            status: "4",
        },
    ];

    autorizationStatus = [
        {
            name: "Autorizado",
            status: "1",
        },
        {
            name: "Em Análise",
            status: "2",
        },
        {
            name: "Negado",
            status: "3",
        },
        {
            name: "Aguardando justificativa técnica do solicitante",
            status: "4",
        },
        {
            name: "Sem Autorização",
            status: "7",
        },
        {
            name: "Aguardando documentação do prestador",
            status: "5",
        },
        {
            name: "Solicitação cancelada",
            status: "6",
        },
        {
            name: "Autorizado parcialmente",
            status: "7",
        },
    ];

    statusPatientList = [
        {
            name: "Todos",
            status: "0",
        },
        {
            name: "Ativos",
            status: "1",
        },
        {
            name: "Inativos",
            status: "2",
        },
    ];

    constructor(
        private filterListProvider: FilterListProvider,
        private _formBuilder: FormBuilder,
        private patientListProvider: PatientListProvider,
        private typeGuideListProvider: TypeGuideListProvider,
        private insuranceListProvider: InsuranceListProvider,
        private userListProvider: UserListProvider,
        private professionalListProvider: ProfessionalListProvider,
        private batchEntryListProvider: BatchEntryListProvider,
        private _snackBar: SnackBarService
    ) {
        this._unsubscribeAll = new Subject();
        this.patientPgaeOptions = new PageOptions();
        this.insurancePageOptions = new PageOptions();
        this.administratorPageOptions = new PageOptions();
        this.specialityPageOptions = new PageOptions();
        this.professionalPageOptions = new PageOptions();
        this.batchEntryPageOptions = new PageOptions();
        this.batchEntryPageOptions.fieldSort = "opened";
        this.batchEntryPageOptions.sortDirection = "desc";
    }
    ngOnInit() {
        if (this.scope == "transfer") {
            this.statusFilter = "new";
        } else if (this.scope === "lotLaunch") {
            if (this.entity?.closed != null) {
                this.statusFilter = "edit";
                this.getBatchEntryFilter();
            } else {
                this.statusFilter = "new";
            }
        } else {
            this.statusFilter = this.entity?.id ? "edit" : "new";
        }

        // this.lastBillingFilter = JSON.parse(localStorage.getItem('last_filterBilling'));
        // if (this.lastBillingFilter) {
        //     this.billingFilter = this.lastBillingFilter.lastBillingFilter.filterList;
        //     this.lastFilter = true;
        // } else {
        //     this.lastFilter = false;
        // }

        this.getFilterList();
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.emitFilter();
        }, 2000);
    }

    /* buscar lista de filtros */
    getFilterList() {
        this.filterTypes = new Array();
        this.filterListProvider.getByScope(this.scope).then((res) => {
            this.filterTypes = res.body;
            this.lastFilter ? this.initForm("lastFilter") : this.initForm();
        });
    }

    /* buscar lista de pacientes */
    getPatientList() {
        this.patientPgaeOptions.fieldSort = "person.name";
        this.patientListProvider
            .getPageActive(this.patientPgaeOptions)
            .then((res) => {
                this.patientList = res.body.content;
            });
    }

    // Get all typeGuides
    getTypeGuideList(value?) {
        this.typeGuideListProvider.getAll().then((res) => {
            this.typeGuideList = res.body;
            if (value) {
                const typeGuide = this.typeGuideList.filter(
                    (type) => type.id == value
                );
                this.typeGuideForm.controls["typeGuide"].setValue(typeGuide[0]);
            }
        });
    }

    // Get all typeGuides
    getBatchEntryList(value?) {
        this.batchEntryListProvider
            .getPageSimpleList(this.batchEntryPageOptions)
            .then((res) => {
                this.batchEntryList = res.body.content;
                this.rawDataBatchEntry = [];
                this.batchEntryList.forEach((element) => {
                    this.rawDataBatchEntry.push({
                        element: element,
                        selected: this.checkSelected(element, "BATCHENTRY"),
                    });
                });
            });
    }

    /* Buscar lista de convênios */
    getInsuranceList() {
        this.insurancePageOptions.fieldSort = "name";
        this.insuranceListProvider
            .getPageSimpleList(this.insurancePageOptions)
            .then((res) => {
                this.insuranceList = res.body.content;
                this.rawDataInsurance = [];
                this.insuranceList.forEach((element) => {
                    this.rawDataInsurance.push({
                        element: element,
                        selected: this.checkSelected(element, "INSURANCE"),
                    });
                });
            });
    }

    getInsuranceWebserviceList() {
        this.insurancePageOptions.fieldSort = "name";
        this.insuranceListProvider
            .getPageWebservice(this.insurancePageOptions)
            .then((res) => {
                this.insuranceList = res.body.content;
                this.rawDataInsurance = [];
                this.insuranceList.forEach((element) => {
                    this.rawDataInsurance.push({
                        element: element,
                        selected: this.checkSelected(element, "INSURANCE"),
                    });
                });
            });
    }

    async getInsuranceByMultipleIds(values: string) {
        let result = null;
        return new Promise((resolve, reject) => {
            let idList = values.split(",").map((v) => parseInt(v, 10));
            this.insuranceListProvider
                .getInsuranceByMultipleIds(idList)
                .then((res) => {
                    result = res.body;
                    resolve(result);
                })
                .catch((errt) => {
                    reject(result);
                });
        });
    }

    async getUserByMultipleIds(values: string) {
        let result = null;
        return new Promise((resolve, reject) => {
            let idList = values.split(",").map((v) => parseInt(v, 10));
            this.userListProvider
                .getUserByMultipleIds(idList)
                .then((res) => {
                    result = res.body;
                    resolve(result);
                })
                .catch((errt) => {
                    reject(result);
                });
        });
    }

    async getProfessionalAndEspecialistaByMultipleIds(values: string) {
        let result = null;
        return new Promise((resolve, reject) => {
            let idList = values.split(",").map((v) => parseInt(v, 10));
            this.professionalListProvider
                .getProfessionalByMultipleIdsAndType(idList, "I")
                .then((res) => {
                    result = res.body;
                    resolve(result);
                })
                .catch((errt) => {
                    reject(result);
                });
        });
    }

    /* Buscar lista de specialista */
    getSpecialistList() {
        this.specialityPageOptions.fieldSort = "person.name";
        this.professionalListProvider
            .getPage(this.specialityPageOptions, "I")
            .then((res) => {
                this.specialityList = res.body.content;
                this.rawDataSpeciality = [];
                this.specialityList.forEach((element) => {
                    this.rawDataSpeciality.push({
                        element: element,
                        selected: this.checkSelected(element, "SPECIALISTY"),
                    });
                });
            });
    }

    /* Buscar lista de profissional */
    getProfessionalList() {
        this.professionalPageOptions.fieldSort = "person.name";
        this.professionalListProvider
            .getSimplePage(this.professionalPageOptions, "I")
            .then((res) => {
                this.professionalList = res.body.content;
                this.rawDataProfessional = [];
                this.professionalList.forEach((element) => {
                    this.rawDataProfessional.push({
                        element: element,
                        selected: this.checkSelected(element, "PROFESSIONAL"),
                    });
                });
            });
    }

    /* Buscar lista de administrador */
    getAdministratorList() {
        this.administratorPageOptions.fieldSort = "person.name";
        this.userListProvider
            .getPageUserSimpleList(this.administratorPageOptions)
            .then((res) => {
                this.administratorList = res.body.content;
                this.rawDataAdministrator = [];
                this.administratorList.forEach((insurance) => {
                    this.rawDataAdministrator.push({
                        element: insurance,
                        selected: this.checkSelected(
                            insurance,
                            "ADMINISTRATOR"
                        ),
                    });
                });
            });
    }

    checkSelected(element: any, type): boolean {
        let result: number;
        switch (type) {
            case "ADMINISTRATOR":
                result = this.administratorSelectd.findIndex(
                    (item) => item.element.id == element.id
                );
                break;
            case "SPECIALISTY":
                result = this.specialitySelectd.findIndex(
                    (item) => item.element.id == element.id
                );
                break;
            case "INSURANCE":
                result = this.insuranceSelectd.findIndex(
                    (item) => item.element.id == element.id
                );
                break;
            case "PROFESSIONAL":
                result = this.professionalSelectd.findIndex(
                    (item) => item.element.id == element.id
                );
                break;
                break;
            case "BATCHENTRY":
                result = this.batchEntrySelectd.findIndex(
                    (item) => item.element.id == element.id
                );
                break;

            default:
                break;
        }

        if (result == -1) {
            return false;
        } else {
            return true;
        }
    }

    /*
     *Buscar lista de pacientes
     */
    getPatientById(id) {
        if (!id) {
            return;
        }
        let patient;
        return new Promise((resolve, reject) => {
            this.patientListProvider.getById(id).subscribe({
                next: (res) => {
                    res ? resolve(res.body) : resolve(null);
                },
            });
        });
    }

    /*
     *  Controle de selecção dos convenios
     */
    optionClicked(event, data, type) {
        event.stopPropagation();
        this.toggleSelection(data, type);
    }

    toggleSelection(data: any, type: string) {
        data.selected = !data.selected;
        switch (type) {
            case "INSURANCE":
                if (data.selected === true) {
                    this.insuranceSelectd.push(data);
                } else {
                    const i = this.insuranceSelectd.findIndex(
                        (value) => value.element.id === data.element.id
                    );
                    this.insuranceSelectd.splice(i, 1);
                }

                this.insuranceFilterForm.controls["insurance"].setValue(
                    this.selectDataInsurance
                );
                break;

            case "ADMINISTRATOR":
                if (data.selected === true) {
                    this.administratorSelectd.push(data);
                } else {
                    const i = this.administratorSelectd.findIndex(
                        (value) => value.element.id === data.element.id
                    );
                    this.administratorSelectd.splice(i, 1);
                }

                this.administratorFilterForm.controls["administrator"].setValue(
                    this.selectDataAdministrator
                );
                break;

            case "SPECIALITY":
                if (data.selected === true) {
                    this.specialitySelectd.push(data);
                } else {
                    const i = this.specialitySelectd.findIndex(
                        (value) => value.element.id === data.element.id
                    );
                    this.specialitySelectd.splice(i, 1);
                }

                this.specialityFilterForm.controls["specialisty"].setValue(
                    this.selectDataSpeciality
                );
                break;

            case "PROFESSIONAL":
                if (data.selected === true) {
                    this.professionalSelectd.push(data);
                } else {
                    const i = this.professionalSelectd.findIndex(
                        (value) => value.element.id === data.element.id
                    );
                    this.professionalSelectd.splice(i, 1);
                }

                this.professionalFilterForm.controls["professional"].setValue(
                    this.selectDataProfessional
                );
                break;

            case "BATCHENTRY":
                if (data.selected === true) {
                    this.batchEntrySelectd.push(data);
                } else {
                    const i = this.batchEntrySelectd.findIndex(
                        (value) => value.element.id === data.element.id
                    );
                    this.batchEntrySelectd.splice(i, 1);
                }

                this.batchEntryFilterForm.controls["batchEntry"].setValue(
                    this.selectDataBatchEntry
                );
                break;

            default:
                break;
        }

        this.emitFilter();
    }

    /*
     *Controle para remover os itens do combo
     */
    removeChip(data: ItemData, type) {
        this.toggleSelection(data, type);
        this.emitFilter();
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(true);
        this._unsubscribeAll.complete();
    }

    /* inicia formularios */
    initForm(type?: string) {
        if (type === "addOrder") {
            this.statusFilter = "new";
            this.entityFilter.forEach((billFilter) => {
                this.removeFilter(billFilter.filter.key);
            });
            setTimeout(() => {
                this.initForm();
            }, 500);
        } else if (this.statusFilter === "edit" || type === "edit") {
            this.entityFilter.forEach((billFilter) => {
                this.createForm(billFilter.filter, billFilter.value);
            });
        } else if (type == "lastFilter") {
            this.entityFilter.forEach((billFilter) => {
                this.createForm(billFilter, billFilter.value);
            });
        } else {
            this.filterTypes.forEach((filterType) => {
                filterType.mandatory === true
                    ? this.createForm(filterType)
                    : "";
            });
        }
    }

    /* Funções para remover os filtros */
    removeFilter(type) {
        switch (type) {
            case "patient":
                delete this.patientFilterForm;
                break;

            case "period":
                delete this.rangeFilterForm;
                break;

            case "dateLimit":
                delete this.dateFilterForm;
                break;

            case "Status":
                delete this.statusFilterForm;
                break;

            case "Status da Glosa":
                delete this.glossStatusFilterForm;
                break;

            case "typeGuide":
                delete this.typeGuideForm;
                break;

            case "insurance":
                delete this.insuranceFilterForm;
                break;

            case "administrator":
                delete this.administratorFilterForm;
                break;

            case "speciality":
                delete this.specialityFilterForm;
                break;

            case "professional":
                delete this.professionalFilterForm;
                break;

            case "batchEntry":
                delete this.batchEntryFilterForm;
                break;
            case "statusPatient":
                delete this.statusPatientForm;
                break;
            case "billingAllUnitsForm":
                delete this.billingAllUnitsFilterForm;
                break;

            default:
                break;
        }

        this.emitFilter();
    }

    /*
     * campo dedicados a filtros  e a emisão de eventos
     */

    observableStatusFilter() {
        this.statusFilterForm.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(500),
                distinctUntilChanged()
            )
            .subscribe({
                next: (result: any) => {
                    this.emitFilter();
                },
            });
    }

    observableGlossStatusFilter() {
        this.glossStatusFilterForm.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(500),
                distinctUntilChanged()
            )
            .subscribe({
                next: (result: any) => {
                    this.emitFilter();
                },
            });
    }

    observableStatusAuthorization() {
        this.statusAuthorizationForm.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(500),
                distinctUntilChanged()
            )
            .subscribe({
                next: (result: any) => {
                    this.emitFilter();
                },
            });
    }

    observableStatusPatient() {
        this.statusPatientForm.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(500),
                distinctUntilChanged()
            )
            .subscribe({
                next: (result: any) => {
                    this.emitFilter();
                },
            });
    }

    observableStatusOrderCustonForm() {
        this.statusOrderCustonForm.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(500),
                distinctUntilChanged()
            )
            .subscribe({
                next: (result: any) => {
                    this.emitFilter();
                },
            });
    }

    observableRangeFilter() {
        this.rangeFilterForm.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(500),
                distinctUntilChanged()
            )
            .subscribe({
                next: (result: any) => {
                    this.emitFilter();
                },
            });
    }

    observableTypeGuide() {
        this.typeGuideForm.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(500),
                distinctUntilChanged()
            )
            .subscribe({
                next: (result: any) => {
                    this.emitFilter();
                },
            });
    }

    observableDateFilter() {
        this.dateFilterForm.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(500),
                distinctUntilChanged()
            )
            .subscribe({
                next: (result: any) => {
                    this.emitFilter();
                },
            });
    }

    observableBatchEntryilter() {
        this.batchEntryFilterForm.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(500),
                distinctUntilChanged()
            )
            .subscribe({
                next: (result: any) => {
                    this.emitFilter();
                },
            });
    }

    observablePatientFilter() {
        this.patientFilterForm.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(500),
                distinctUntilChanged()
            )
            .subscribe({
                next: (res: any) => {
                    if (res.patient && res.patient.person) {
                        this.emitFilter();
                        //this.patientPgaeOptions.argument = res.patient.person;
                    } else if (res) {
                        this.patientPgaeOptions.argument = res.patient;
                    } else {
                        this.patientPgaeOptions.argument = "";
                        this.patientFilterForm.controls["patient"].setValue(
                            null
                        );
                    }
                    this.getPatientList();
                },
            });
    }

    observableInsurance() {
        this.insuranceControl.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(500),
                distinctUntilChanged()
            )
            .subscribe({
                next: (res) => {
                    if (res && typeof res === "string") {
                        this.insurancePageOptions.argument = res;
                    } else {
                        this.insurancePageOptions.argument = "";
                    }
                    // if (this.scope === 'AUTHORIZATION') {
                    //     this.getInsuranceWebserviceList();
                    // } else {
                    this.getInsuranceList();
                    // }
                },
            });
    }
    observableSpecialisty() {
        this.specialityControl.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(500),
                distinctUntilChanged()
            )
            .subscribe({
                next: (res) => {
                    if (res && typeof res === "string") {
                        this.specialityPageOptions.argument = res;
                    } else {
                        this.specialityPageOptions.argument = "";
                    }
                    this.getSpecialistList();
                },
            });
    }

    observableProfessional() {
        this.professionalControl.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(500),
                distinctUntilChanged()
            )
            .subscribe({
                next: (res) => {
                    if (res && typeof res === "string") {
                        this.professionalPageOptions.argument = res;
                    } else {
                        this.professionalPageOptions.argument = "";
                    }
                    this.getProfessionalList();
                },
            });
    }
    observableBatchEntry() {
        this.batchEntryControl.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(500),
                distinctUntilChanged()
            )
            .subscribe({
                next: (res) => {
                    if (res && typeof res === "string") {
                        this.batchEntryPageOptions.argument = res;
                    } else {
                        this.batchEntryPageOptions.argument = "";
                    }
                    this.getBatchEntryList();
                },
            });
    }

    observableBillingAllUnits() {
        this.billingAllUnitsFilterForm.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(500),
                distinctUntilChanged()
            )
            .subscribe({
                next: (res) => {},
            });
    }

    observableAdministrator() {
        this.administratorControl.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(500),
                distinctUntilChanged()
            )
            .subscribe({
                next: (res) => {
                    if (res && typeof res === "string") {
                        this.administratorPageOptions.argument = res;
                    } else {
                        this.administratorPageOptions.argument = "";
                    }
                    this.getAdministratorList();
                },
            });
    }

    /*
     *cria o formulario de acordo com o filtro escolhido na tela
     */
    async createForm(filterType: Filter, value?: any) {
        switch (filterType.key) {
            case "Periodo":
                if (this.rangeFilterForm instanceof FormGroup) {
                    this._snackBar.warnMessage("Filtro já Selecionado!");
                    return;
                }
                if (this.dateFilterForm instanceof FormGroup) {
                    delete this.dateFilterForm;
                }
                const period = value ? value.split(",") : null;

                this.rangeFilterForm = this._formBuilder.group({
                    filter: filterType,
                    start: [period ? period[0] : null, Validators.required],
                    end: [period ? period[1] : null, Validators.required],
                });
                this.statusFilter === "new"
                    ? this.enableForm(this.rangeFilterForm)
                    : this.disableForm(this.rangeFilterForm);
                this.observableRangeFilter();
                break;

            case "Status":
                if (this.statusFilterForm instanceof FormGroup) {
                    this._snackBar.warnMessage("Filtro já Selecionado!");
                    return;
                }

                this.statusFilterForm = this._formBuilder.group({
                    filter: filterType,
                    status: [value ? value : "3", Validators.required],
                });
                this.statusFilter === "new"
                    ? this.enableForm(this.statusFilterForm)
                    : this.disableForm(this.statusFilterForm);
                this.observableStatusFilter();
                break;

            case "Status da Glosa":
                if (this.glossStatusFilterForm instanceof FormGroup) {
                    this._snackBar.warnMessage("Filtro já Selecionado!");
                    return;
                }

                this.glossStatusFilterForm = this._formBuilder.group({
                    filter: filterType,
                    status: [value ? value : "T", Validators.required],
                });
                this.statusFilter === "new"
                    ? this.enableForm(this.glossStatusFilterForm)
                    : this.disableForm(this.glossStatusFilterForm);
                this.observableGlossStatusFilter();
                break;

            case "Status Authorization":
                if (this.statusAuthorizationForm instanceof FormGroup) {
                    this._snackBar.warnMessage("Filtro já Selecionado!");
                    return;
                }

                this.statusAuthorizationForm = this._formBuilder.group({
                    filter: filterType,
                    status: [value ? value : ""],
                });
                this.statusFilter === "new"
                    ? this.enableForm(this.statusAuthorizationForm)
                    : this.disableForm(this.statusAuthorizationForm);
                this.observableStatusAuthorization();
                break;
            case "Status Paciente":
                if (this.statusPatientForm instanceof FormGroup) {
                    this._snackBar.warnMessage("Filtro já Selecionado!");
                    return;
                }

                let valueStandart = {
                    name: "Ativos",
                    status: "1",
                };

                this.statusPatientForm = this._formBuilder.group({
                    filter: filterType,
                    status: [value ? value : valueStandart],
                });
                this.statusFilter === "new"
                    ? this.enableForm(this.statusPatientForm)
                    : this.disableForm(this.statusPatientForm);
                this.observableStatusPatient();
                break;
            case "STATUS_ORDER_CUSTON":
                if (this.statusOrderCustonForm instanceof FormGroup) {
                    this._snackBar.warnMessage("Filtro já Selecionado!");
                    return;
                }

                this.statusOrderCustonForm = this._formBuilder.group({
                    filter: filterType,
                    status: [value ? value : ""],
                });
                this.statusFilter === "new"
                    ? this.enableForm(this.statusOrderCustonForm)
                    : this.disableForm(this.statusOrderCustonForm);
                this.observableStatusOrderCustonForm();
                break;

            case "Data Limite":
                if (this.dateFilterForm instanceof FormGroup) {
                    this._snackBar.warnMessage("Filtro já Selecionado!");
                    return;
                }
                if (this.rangeFilterForm instanceof FormGroup) {
                    delete this.rangeFilterForm;
                }
                this.dateFilterForm = this._formBuilder.group({
                    filter: filterType,
                    date: [
                        value ? moment(value) : moment(),
                        Validators.required,
                    ],
                });
                this.statusFilter === "new"
                    ? this.enableForm(this.dateFilterForm)
                    : this.disableForm(this.dateFilterForm);
                this.observableDateFilter();
                break;

            case "Tipo de Guia":
                if (this.typeGuideForm instanceof FormGroup) {
                    this._snackBar.warnMessage("Filtro já Selecionado!");
                    return;
                }
                this.getTypeGuideList(value);
                this.typeGuideForm = this._formBuilder.group({
                    filter: filterType,
                    typeGuide: [value ? value : "", Validators.required],
                });
                this.statusFilter === "new"
                    ? this.enableForm(this.typeGuideForm)
                    : this.disableForm(this.typeGuideForm);
                this.observableTypeGuide();
                break;

            case "Paciente":
                if (this.patientFilterForm instanceof FormGroup) {
                    this._snackBar.warnMessage("Filtro já Selecionado!");
                    return;
                }

                this.getPatientList();

                value = await this.getPatientById(value);
                this.patientFilterForm = this._formBuilder.group({
                    filter: filterType,
                    patient: [value ? value : "", Validators.required],
                });
                this.statusFilter === "new"
                    ? this.enableForm(this.patientFilterForm)
                    : this.disableForm(this.patientFilterForm);
                this.observablePatientFilter();
                break;

            case "Convênio":
                if (this.insuranceFilterForm instanceof FormGroup) {
                    this._snackBar.warnMessage("Filtro já Selecionado!");
                    return;
                }
                // if (this.scope === 'AUTHORIZATION') {
                //     this.getInsuranceWebserviceList();
                // } else {
                this.getInsuranceList();
                // }

                if (value) {
                    value = await this.getInsuranceByMultipleIds(value);
                }
                if (value?.length > 0) {
                    value.forEach((element) => {
                        let itemdata = {
                            element: element,
                            selected: true,
                        };
                        this.insuranceSelectd.push(itemdata);
                    });
                } else {
                    value = null;
                }
                this.insuranceFilterForm = this._formBuilder.group({
                    filter: filterType,
                    insurance: [value ? value : "", Validators.required],
                });
                if (this.statusFilter === "edit") {
                    this.disableForm(this.insuranceFilterForm);
                    this.insuranceControl.disable();
                }
                this.observableInsurance();
                break;

            case "Administrador":
                if (this.administratorFilterForm instanceof FormGroup) {
                    this._snackBar.warnMessage("Filtro já Selecionado!");
                    return;
                }
                this.getAdministratorList();
                if (value) {
                    value = await this.getUserByMultipleIds(value);
                }
                if (value?.length > 0) {
                    value.forEach((element) => {
                        let itemdata = {
                            element: element,
                            selected: true,
                        };
                        this.administratorSelectd.push(itemdata);
                    });
                } else {
                    value = null;
                }
                this.administratorFilterForm = this._formBuilder.group({
                    filter: filterType,
                    administrator: [value ? value : "", Validators.required],
                });
                if (this.statusFilter === "edit") {
                    this.disableForm(this.administratorFilterForm);
                    this.administratorControl.disable();
                }

                this.observableAdministrator();
                break;

            case "Especialista":
                if (this.specialityFilterForm instanceof FormGroup) {
                    this._snackBar.warnMessage("Filtro já Selecionado!");
                    return;
                }
                this.getSpecialistList();
                if (value) {
                    value =
                        await this.getProfessionalAndEspecialistaByMultipleIds(
                            value
                        );
                }
                if (value?.length > 0) {
                    value.forEach((element) => {
                        let itemdata = {
                            element: element,
                            selected: true,
                        };
                        this.specialitySelectd.push(itemdata);
                    });
                } else {
                    value = null;
                }
                this.specialityFilterForm = this._formBuilder.group({
                    filter: filterType,
                    specialisty: [value ? value : "", Validators.required],
                });
                if (this.statusFilter === "edit") {
                    this.disableForm(this.specialityFilterForm);
                    this.specialityControl.disable();
                }
                this.observableSpecialisty();
                break;

            case "PROFESSIONAL":
                if (this.professionalFilterForm instanceof FormGroup) {
                    this._snackBar.warnMessage("Filtro já Selecionado!");
                    return;
                }
                this.getProfessionalList();
                if (value) {
                    value =
                        await this.getProfessionalAndEspecialistaByMultipleIds(
                            value
                        );
                }
                if (value?.length > 0) {
                    value.forEach((element) => {
                        let itemdata = {
                            element: element,
                            selected: true,
                        };
                        this.professionalSelectd.push(itemdata);
                    });
                } else {
                    value = null;
                }
                this.professionalFilterForm = this._formBuilder.group({
                    filter: filterType,
                    professional: [value ? value : "", Validators.required],
                });
                if (this.statusFilter === "edit") {
                    this.disableForm(this.professionalFilterForm);
                    this.professionalControl.disable();
                }
                this.observableProfessional();
                break;

            case "BATCH_ENTRY":
                if (this.batchEntryFilterForm instanceof FormGroup) {
                    this._snackBar.warnMessage("Filtro já Selecionado!");
                    return;
                }
                this.getBatchEntryList();
                if (value) {
                    // value = await this.getProfessionalAndEspecialistaByMultipleIds(value);
                }
                if (value?.length > 0) {
                    value.forEach((element) => {
                        let itemdata = {
                            element: element,
                            selected: true,
                        };
                        this.batchEntrySelectd.push(itemdata);
                    });
                } else {
                    value = null;
                }
                this.batchEntryFilterForm = this._formBuilder.group({
                    filter: filterType,
                    batchEntry: [value ? value : "", Validators.required],
                });
                if (this.statusFilter === "edit") {
                    this.disableForm(this.batchEntryFilterForm);
                    this.batchEntryControl.disable();
                }
                this.observableBatchEntry();
                break;
            case "BILLING_ALL_UNITS":
                if (this.billingAllUnitsFilterForm instanceof FormGroup) {
                    this._snackBar.warnMessage("Filtro já Selecionado!");
                    return;
                }

                this.billingAllUnitsFilterForm = this._formBuilder.group({
                    filter: filterType,
                    billingAllUnits: [
                        this.checkValue(value),
                        Validators.required,
                    ],
                });
                if (this.statusFilter === "edit") {
                    this.disableForm(this.billingAllUnitsFilterForm);
                    this.billingAllUnitsFilterForm.disable();
                }
                this.observableBillingAllUnits();
                break;

            default:
                break;
        }
    }
    checkValue(value: any): any {
        if (typeof value === "string") {
            if (value === "true") {
                return true;
            }
            if (value === "false") {
                return false;
            }
        } else {
            return true;
        }

        return true;
    }

    disableForm(form: FormGroup) {
        form.disable();
    }

    enableForm(form: FormGroup) {
        form.enable();
    }

    //Verifica se o formularios está instanciado e faz a tratativa para manda para o component Billing
    emitFilter(event?: Event) {
        this.filteAndValues = new Array<any>();

        if (this.patientFilterForm instanceof FormGroup) {
            let filter = this.patientFilterForm.getRawValue();
            filter.filter.value = filter.patient.id;
            delete filter.patient;
            this.filteAndValues.push(filter.filter);
        }

        if (this.insuranceFilterForm instanceof FormGroup) {
            let filter = this.insuranceFilterForm.getRawValue();
            let insuranceIdList = new Array<String>();
            if (this.insuranceSelectd.length > 0) {
                this.insuranceSelectd.forEach((item) => {
                    insuranceIdList.push(String(item.element.id));
                });
                filter.filter.value = insuranceIdList.toString();
                delete filter.insurance;
                this.filteAndValues.push(filter.filter);
            }
        }

        if (this.administratorFilterForm instanceof FormGroup) {
            let filter = this.administratorFilterForm.getRawValue();
            let administratorIdList = new Array<String>();
            if (this.administratorSelectd.length > 0) {
                this.administratorSelectd.forEach((item) => {
                    administratorIdList.push(String(item.element.id));
                });
                filter.filter.value = administratorIdList.toString();
                delete filter.administrator;
                this.filteAndValues.push(filter.filter);
            }
        }

        if (this.batchEntryFilterForm instanceof FormGroup) {
            let filter = this.batchEntryFilterForm.getRawValue();
            let batchEntryIdList = new Array<String>();
            if (this.batchEntrySelectd.length > 0) {
                this.batchEntrySelectd.forEach((item) => {
                    batchEntryIdList.push(String(item.element.id));
                });
                filter.filter.value = batchEntryIdList.toString();
                delete filter.batchEntry;
                this.filteAndValues.push(filter.filter);
            }
        }

        if (this.specialityFilterForm instanceof FormGroup) {
            let filter = this.specialityFilterForm.getRawValue();
            let specialityIdList = new Array<String>();
            if (this.specialitySelectd.length > 0) {
                this.specialitySelectd.forEach((item) => {
                    specialityIdList.push(String(item.element.id));
                });
                filter.filter.value = specialityIdList.toString();
                delete filter.speciality;
                this.filteAndValues.push(filter.filter);
            }
        }

        if (this.professionalFilterForm instanceof FormGroup) {
            let filter = this.professionalFilterForm.getRawValue();
            let professionalIdList = new Array<String>();
            if (this.professionalSelectd.length > 0) {
                this.professionalSelectd.forEach((item) => {
                    professionalIdList.push(String(item.element.id));
                });
                filter.filter.value = professionalIdList.toString();
                delete filter.professional;
                this.filteAndValues.push(filter.filter);
            }
        }

        if (this.rangeFilterForm instanceof FormGroup) {
            if (!this.rangeFilterForm.invalid) {
                let filter = this.rangeFilterForm.getRawValue();
                const date = `${
                    filter.start == "Invalid date"
                        ? "0"
                        : moment(filter.start).format("yyyy-MM-DD")
                },${
                    filter.end === "Invalid date"
                        ? "0"
                        : moment(filter.end).format("yyyy-MM-DD")
                }`;
                filter.filter.value = date;
                delete filter.start;
                delete filter.end;
                this.filteAndValues.push(filter.filter);
            }
        }

        if (this.dateFilterForm instanceof FormGroup) {
            let filter = this.dateFilterForm.getRawValue();
            filter.filter.value = `${moment(filter.date).format("yyyy-MM-DD")}`;
            delete filter.date;
            this.filteAndValues.push(filter.filter);
        }

        if (this.billingAllUnitsFilterForm instanceof FormGroup) {
            let filter = this.billingAllUnitsFilterForm.getRawValue();
            filter.filter.value = filter.billingAllUnits;
            delete filter.billingAllUnits;
            this.filteAndValues.push(filter.filter);
        }

        if (this.statusFilterForm instanceof FormGroup) {
            let filter = this.statusFilterForm.getRawValue();
            filter.filter.value = filter.status;
            delete filter.status;
            this.filteAndValues.push(filter.filter);
            this.statusCurrent.emit(filter.filter.value);
        }

        if (this.glossStatusFilterForm instanceof FormGroup) {
            let filter = this.glossStatusFilterForm.getRawValue();
            filter.filter.value = filter.status;
            delete filter.status;
            this.filteAndValues.push(filter.filter);
            this.statusCurrent.emit(filter.filter.value);
        }

        if (this.typeGuideForm instanceof FormGroup) {
            let filter = this.typeGuideForm.getRawValue();
            filter.filter.value = filter.typeGuide.id;
            delete filter.typeGuide;
            this.filteAndValues.push(filter.filter);
        }

        if (this.statusAuthorizationForm instanceof FormGroup) {
            let filter = this.statusAuthorizationForm.getRawValue();
            filter.filter.value = filter.status?.status;
            delete filter.status;
            if (filter.filter?.value) {
                this.filteAndValues.push(filter.filter);
            }
        }
        if (this.statusPatientForm instanceof FormGroup) {
            let filter = this.statusPatientForm.getRawValue();
            filter.filter.value = filter.status?.status;
            delete filter.status;
            if (filter.filter?.value) {
                this.filteAndValues.push(filter.filter);
            }
        }

        if (this.statusOrderCustonForm instanceof FormGroup) {
            let filter = this.statusOrderCustonForm.getRawValue();
            filter.filter.value = filter.status?.status;
            delete filter.status;
            if (filter.filter?.value) {
                this.filteAndValues.push(filter.filter);
            }
        }

        this.filterValues.emit(this.filteAndValues);
    }

    compareSelectStatus(o1: any, o2: any): boolean {
        if (!o1 || !o2) {
            return false;
        }
        return o1.status === o2.status;
    }

    displayPatientFn(patient?: any) {
        if (!patient) {
            return "";
        }
        return patient.person.name ? patient.person.name : patient;
    }

    displayInsuranceFn(insurance?: any) {
        if (!insurance) {
            return "";
        }
        return insurance.name ? insurance.name : insurance;
    }

    displaySpecialityFn(speciality?: any) {
        if (!speciality) {
            return "";
        }
        return speciality.person.name ? speciality.person.name : speciality;
    }

    displayBatchEntryFn(speciality?: any) {
        if (!speciality) {
            return "";
        }
        return speciality.number ? speciality.number : speciality;
    }

    getBatchEntryFilter() {
        this.batchEntryListProvider
            .getBatchEntryFilter(this.entity.id)
            .then((res) => {
                const batchEntryFilter = res.body.data;
                batchEntryFilter.forEach((element) => {
                    this.createForm(element.filter, element.value);
                });
            });
    }
}
