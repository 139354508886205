
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { PageOptions, ApiGateway } from 'cw-backend';
import { CwUtils } from 'cw-utils';

@Injectable()
export class AuditProvider implements Resolve<any>
{
    audit: any[];
    onDataChanged: BehaviorSubject<any>;
    routeParams: any;
    state: any;
    pageOptions: PageOptions;
    currentPage: PageOptions;

    constructor(
        public gw: ApiGateway,
        private location: Location,
    ) {
        this.onDataChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        // Get state for current or new page
        this.state = this.location.getState();
           if (this.state.currentPage && state.url.includes(this.state.path)) {
            this.currentPage = this.state.currentPage;
        }

        if (this.currentPage) {
            this.pageOptions = this.currentPage;
        }
        else {
            this.pageOptions = new PageOptions();
            this.pageOptions.fieldSort = 'name';
        }
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this.getPage(this.pageOptions)
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    get(id: number): Observable<any> {
        if (!id) {
            return null;
        }
        return this.gw.get('audit/:id', { id: id });
    }

    getAll(entity?: String): Promise<any> {
        let urlPath = 'audit/';
        if (entity) {
            urlPath = entity + urlPath
        }
        return new Promise((resolve, reject) => {
            this.gw.get(urlPath).subscribe((res: HttpResponse<any>) => {
                this.audit = res.body;
                this.onDataChanged.next(this.audit);
                resolve(res);
            }, reject);
        });
    }

    getPage(pageOptions: PageOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('audit/search', pageOptions).subscribe((res: HttpResponse<any>) => {
                if (!CwUtils.compare(this.audit, res.body.content) && res.body.content.length > 0) {
                    this.audit = res.body.content;
                    this.onDataChanged.next(this.audit);
                }
                else {
                    this.audit = res.body.content;
                };
                resolve(res);
            }, reject);
        });
    }

    getPageSettings() {
        if (this.currentPage) {
            return this.pageOptions;
        } else {
            return;
        }
    }

    getRevision(tableName, data): Promise<any>{
         return new Promise((resolve, reject) => {
            this.gw.post('audit/:tableName/search', {tableName}, data).subscribe((res: HttpResponse<any>) => {
                resolve(res);
            }, reject);
        });
    }

    getTableList(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('audit/tableName/simpleList').subscribe((res: HttpResponse<any>) => {
                resolve(res);
            }, reject);
        });
    }

    getFieldList(tableName): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('audit/:tableName/columnName/simpleList', {tableName}).subscribe((res: HttpResponse<any>) => {
                resolve(res);
            }, reject);
        });
    }
}

