import { Council } from '../../tables/general/council';
import { State } from '../../tables/address/state';
import { Firm } from '../../tables/general/firm';
import { Person } from '../../tables/general/person';
import { Speciality } from '../../tables/accreditation/speciality';
import { Performer } from './performer';
import { Participation } from './participation';

export class Professional {
    name: string;
    id: number;
    person: Person;
    council: Council;
    state: State;
    firm: Firm;
    councilNumber: string;
    councilType: string;
    active: boolean;
    staff: boolean;
    specialityList: Speciality[];
    speciality: Speciality;
    participationList: Participation[];
    performer: Performer;
}