
import { NgModule } from '@angular/core';
import { CategoryListProvider } from './category-list.provider';
import { CategoryEditProvider } from './category-edit.provider';


export * from './category-edit.provider';
export * from './category-list.provider';

export {
  CategoryListProvider,
  CategoryEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    CategoryListProvider,
    CategoryEditProvider
  ]
})
export class CategoryBackendModule { }
