import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';


@Injectable()
export class HotKeyService {
    isActive: boolean;

    constructor(
        private router: Router,
    ) {
        this.isActive = true;
    }

    public static listKeyBiddings(event, dataSource, selectedData, pageId: string): any {
        let command: { action: string, selectedData: any };
        let keyCode = event.keyCode;
        if (dataSource) {

            const index = dataSource.findIndex(user => user.id == selectedData.id);

            // CTRL + F = FIND
            if (event.ctrlKey && keyCode == 70) {
                event.preventDefault();
                $('#filter-data').focus()
            }
            // CTRL + INSERT = NEW USER
            if (event.ctrlKey && keyCode == 45) {
                command = {
                    action: null,
                    selectedData: null
                }
                pageId = pageId.replace('-list', '-edit')
                $('#insert-' + pageId).trigger('click');
                return command
            }

            // CTRL + DELETE = DELETE REGISTER
            if (event.ctrlKey && keyCode == 46) {
                command = {
                    action: 'delete',
                    selectedData: null
                }
                return command
            }
            // CTRL + ENTER = FIRST USER IN LIST
            if (event.ctrlKey && keyCode == 13) {
                selectedData = dataSource[0];
                command = {
                    action: 'firstData',
                    selectedData: selectedData
                }
                return command
            }

            switch (keyCode) {
                // F2 - EDIT USER
                case 113:
                    if (selectedData && index != -1) {
                        command = {
                            action: 'edit',
                            selectedData: null
                        };
                        return command
                    }
                    break;

                // ARROW DOWN - NEXT USER
                case 40:
                    $('#filter-data').blur()

                    const hasNext = dataSource[index + 1];
                    if (hasNext) {
                        command = {
                            action: 'down',
                            selectedData: hasNext
                        };
                        return command
                    }
                    break;

                // ARROW UP - PREVIOUS USER
                case 38:
                    const hasPrevious = dataSource[index - 1];
                    if (hasPrevious) {
                        command = {
                            action: 'up',
                            selectedData: hasPrevious
                        };
                        return command
                    }
                    break;

                // ESCAPE - CLEAN FILTER
                case 27:
                    $('#filter-data').focus()
                    command = {
                        action: 'escape',
                        selectedData: null
                    };
                    return command
            }
        }
    }

    public static editKeyBiddings(event, pageId, pageType) {
        let command: { action: string, selectedData: any };
        let id: number = Math.floor((Math.random() * 5000) + 1000);
        let keyCode = event.keyCode;


        // CTRL + BACKSPACE = RETURN
        if (event.ctrlKey && keyCode == 8) {
            $('#navigate_back').trigger('click');
        }
        // CTRL + INSERT = NEW REGISTER
        if (event.ctrlKey && keyCode == 45) {
            $('#insert-' + pageId).trigger('click');
        }
        // CTRL + DELETE = DELETE REGISTER
        if (event.ctrlKey && keyCode == 46) {
            $('#delete-' + pageId).trigger('click');
        }
        // CTRL + ARROW RIGHT = NEXT PAGE
        if (event.ctrlKey && keyCode == 39) {
            command = {
                action: 'next',
                selectedData: null
            };
            return command
        }
        // CTRL + ARROW LEFT = PREVIOUS PAGE
        if (event.ctrlKey && keyCode == 37) {
            command = {
                action: 'previous',
                selectedData: null
            };
            return command
        }

        switch (keyCode) {
            // F2 - EDIT USER
            case 113:
                if (pageType === 'edit') {
                    $('#update-' + pageId).trigger('click');
                } else if ((pageType === 'new')) {
                    $('#insert-' + pageId).trigger('click');
                }
                break;
        }
    }

    public static paginatorHotKey(page, matPaginator, eventKey, keyCode) {
        if (page.content) {
            // CTRL + ARROW RIGHT = NEXT PAGE
            if (eventKey.ctrlKey && keyCode == 39) {
                matPaginator.nextPage();
            }

            // CTRL + ARROW LEFT = PREVIOUS PAGE
            if (eventKey.ctrlKey && keyCode == 37) {
                matPaginator.previousPage();
            }
        }
    }

    public static genericKeyCode(keyCode, position) {
        let command;
        if (keyCode == position) {
            event.preventDefault();
            return command = true;
        }
    }

    public static masterViewMode(keyCode) {
        let command;
        if (keyCode == 69) {
            event.preventDefault();
            return command = {
                action: 'show',
            };
        } else if (keyCode == 70) {
            event.preventDefault();
            return command = {
                action: 'hide',
            };
        }

    }
}