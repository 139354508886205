import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
    selector: 'app-notifier',
    templateUrl: './notifier.component.html',
    styleUrls: ['./notifier.component.scss']
})
export class NotifierComponent implements OnInit, OnDestroy {

    showDetail = false;

    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any,
        public snackBarRef: MatSnackBarRef<NotifierComponent>) { }


    ngOnDestroy(): void {
        
    }

    ngOnInit() {
        this.showDetail = !this.showDetail;
    }

    showDetails() {
        this.showDetail = !this.showDetail;
    }

}
