
import { NgModule } from '@angular/core';

import { ViewEditService } from './view-edit-service';
import { ViewListService } from './view-list-service';

export * from './view-edit-service';
export * from './view-list-service';

export {
  ViewListService,
  ViewEditService
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    ViewEditService,
    ViewListService
  ]
})
export class ViewBackendModule { }
