import { Person } from "../../tables/general/person";
import { Plan } from "../../tables/accreditation/plan";
import { Insurance } from "../../accreditation/insurance";
import { Moment } from "moment";
import {
    OrderAuthorization,
    Procedure,
} from "../../backend-definitions.module";

export class Patient {
    id: number;
    parentId: number;
    active: boolean;
    pcd: boolean;
    medicalRecord: number;
    person: Person;
    insurance: Insurance;
    plan: Plan;
    enrollment: string;
    validity: Moment;
    dated: Moment;
    holder: string;
    product: string;
    userCreated: string;
}

export class PatientSimple {
    id: string;
    name: string;
    medicalRecord: string;
    status: string;
    cpf: string;
    insuranceName: string;
    enrolment: string;
    enrolmentDate: string;
    birthday: Moment;
}

export class AuthorizationModel {
    id: string;
    patient: Patient;
    insurance: Insurance;
    procedure: Procedure;
    quantity: string;
    active: boolean = true;
    orderAuthorization?: OrderAuthorization;
    valueLauncher?: number;
}

export class ModelAuthorization {
    authorizationModel: AuthorizationModel;
    orderAuthorization: OrderAuthorization;
}
