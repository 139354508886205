
import { NgModule } from '@angular/core';

import { BreedEditProvider } from './breed-edit.provider';
import { BreedListProvider } from './breed-list.provider';

export * from './breed-edit.provider';
export * from './breed-list.provider';

export {
  BreedListProvider,
  BreedEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    BreedEditProvider,
    BreedListProvider
  ]
})
export class BreedBackendModule { }
