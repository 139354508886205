import { FilterAutoCompleteService } from './filter-autocomplete.service';
import { NgModule } from '@angular/core';

export { FilterAutoCompleteService }

@NgModule({
  declarations: [

  ],
  imports: [

  ],
  exports: [

  ],
  providers: [
    FilterAutoCompleteService,
  ]
})
export class FilterAutoCompleteModule { }
