import { View } from '../view-model';
import { Transaction } from '../transaction-model';

export class Module {
    constructor(
        public id?: number,
        public parent_id?: string,
        public name?: string,
        public views?: View[],
        public transactions?: Transaction[]

    ) {
    }
}