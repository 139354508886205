
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Agreement, PageOptions, ApiGateway } from 'cw-backend';
import { CwUtils } from 'cw-utils';

@Injectable()
export class AgreementListProvider implements Resolve<any>
{
    agreement: Agreement[];
    agreementEdit: Agreement;
    onDataChanged: BehaviorSubject<any>;
    routeParams: any;
    state: any;
    pageOptions: PageOptions;
    currentPage: PageOptions;

    constructor(
        public gw: ApiGateway,
        private location: Location,
    ) {
        this.onDataChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        // Get state for current or new page
        this.state = this.location.getState();
        if (this.state.currentPage && state.url.includes(this.state.path)) {
            this.currentPage = this.state.currentPage;
        }
        this.agreementEdit = this.state.currentAgreement;

        if (this.currentPage) {
            this.pageOptions = this.currentPage;
            const agreement = new Agreement();
            if (!Object.getOwnPropertyNames(agreement).includes(this.pageOptions.fieldSort)) {
                this.pageOptions.fieldSort = 'endDate';
            }
        }
        else {
            this.pageOptions = new PageOptions();
            this.pageOptions.fieldSort = 'endDate';
        }
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this.getPage(this.pageOptions)
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    get(id: number): Observable<Agreement> {
        if (!id) {
            return null;
        }
        return this.gw.get('agreement/:id', { id: id });
    }

    get currentAgreement(): Agreement {
        return this.agreementEdit;
    }

    getAll(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('agreement/').subscribe((res: HttpResponse<any>) => {
                this.agreement = res.body;
                this.onDataChanged.next(this.agreement);
                resolve(res);
            }, reject);
        });

    }

    getAgreementByInsuranceId(pageOptions: PageOptions, insuranceId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('agreement/insurance/' + insuranceId, pageOptions).subscribe((res: HttpResponse<any>) => {
                if (!CwUtils.compare(this.agreement, res.body.content) && res.body.content.length > 0) {
                    this.agreement = res.body.content;
                    this.onDataChanged.next(this.agreement);
                } else {
                    this.agreement = res.body.content;
                }
                resolve(res);
            }, reject);
        });
    }
    getAgreementByInsuranceIdAndCompanyIdActive(pageOptions: PageOptions, insuranceId: number, companyId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post(`agreement/insurance/${insuranceId}/company/${companyId}/active`, pageOptions).subscribe({
                next: (res: HttpResponse<any>) => {
                    resolve(res);
                },
                error(err) {
                    reject(err)
                },
            })
        });
    }

    getPage(pageOptions: PageOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('agreement/search', pageOptions).subscribe((res: HttpResponse<any>) => {
                if (!CwUtils.compare(this.agreement, res.body.content) && res.body.content.length > 0) {
                    this.agreement = res.body.content;
                    this.onDataChanged.next(this.agreement);
                } else {
                    this.agreement = res.body.content;
                }
                resolve(res);
            }, reject);
        });
    }

    getActives(pageOptions: PageOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('agreement/searchActives', pageOptions).subscribe((res: HttpResponse<any>) => {
                this.agreement = res.body.content;
                this.onDataChanged.next(this.agreement);
                resolve(res);
            }, reject);
        });
    }

    getActivesAgreementByProcedureId(pageOptions: PageOptions, procedureId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('agreement/search/' + procedureId, pageOptions).subscribe((res: HttpResponse<any>) => {
                this.agreement = res.body.content;
                this.onDataChanged.next(this.agreement);
                resolve(res);
            }, reject);
        });
    }

    getPageSettings() {
        if (this.currentPage) {
            return this.pageOptions;
        } else {
            return;
        }
    }
}

