import { ModuleControl } from '../module/module-control-model';

export class UserProfile {
    constructor(
        public id?: number,
        public login?: string,
        public email?: string,
        public modules?: ModuleControl,
    ) {

    }

}