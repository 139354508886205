import { NgModule } from "@angular/core";

import { FilterListProvider } from "../filter/filter-list.provider";

export * from "../filter/filter-list.provider";

export { FilterListProvider };

@NgModule({
    declarations: [],
    imports: [],
    exports: [],
    providers: [FilterListProvider],
})
export class FilterBackendModule {}
