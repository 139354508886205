import { Moment } from "moment";
import { Address, ImageObj } from "../../backend-definitions.module";

export class Contacts {
    id: number;
    name: string;
    nickName: string;
    address: Address;
    company; string;
    contactList: any;
    job: string;
    birthday: Moment;
    notes: string;
    image: ImageObj
}
