
import { NgModule } from '@angular/core';

import { TypeSurveyEditProvider } from './type-survey-edit.provider';
import { TypeSurveyListProvider } from './type-survey-list.provider';

export * from './type-survey-edit.provider';
export * from './type-survey-list.provider';

export {
  TypeSurveyListProvider,
  TypeSurveyEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    TypeSurveyEditProvider,
    TypeSurveyListProvider
  ]
})
export class TypeSurveyBackendModule { }
