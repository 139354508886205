
import { NgModule } from '@angular/core';

import { ServiceCharacterEditProvider } from './service-character-edit.provider';
import { ServiceCharacterListProvider } from './service-character-list.provider';

export * from './service-character-edit.provider';
export * from './service-character-list.provider';

export {
  ServiceCharacterListProvider,
  ServiceCharacterEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    ServiceCharacterEditProvider,
    ServiceCharacterListProvider
  ]
})
export class ServiceCharacterBackendModule { }
