
import { NgModule } from '@angular/core';

import { ExclusionMotiveEditProvider } from './exclusion-motive-edit.provider';
import { ExclusionMotiveListProvider } from './exclusion-motive-list.provider';

export * from './exclusion-motive-edit.provider';
export * from './exclusion-motive-list.provider';

export {
  ExclusionMotiveListProvider,
  ExclusionMotiveEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    ExclusionMotiveEditProvider,
    ExclusionMotiveListProvider
  ]
})
export class ExclusionMotiveBackendModule { }
