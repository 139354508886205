import { Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from "@angular/router";
import { Location } from "@angular/common";
import { HttpResponse } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { Environment, PageOptions, ApiGateway } from "cw-backend";
import { CwUtils } from "cw-utils";

@Injectable()
export class EnvironmentListProvider implements Resolve<any> {
    environment: Environment[];
    environmentEdit: Environment;
    onDataChanged: BehaviorSubject<any>;
    routeParams: any;
    state: any;
    pageOptions: PageOptions;
    currentPage: PageOptions;

    constructor(public gw: ApiGateway, private location: Location) {
        this.onDataChanged = new BehaviorSubject({});
    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        // Get state for current or new page
        this.state = this.location.getState();
        this.currentPage = this.state.currentPage;
        this.environmentEdit = this.state.currentEnvironment;

        if (this.currentPage) {
            this.pageOptions = this.currentPage;
        } else {
            this.pageOptions = new PageOptions();
            this.pageOptions.fieldSort = "name";
        }
        return new Promise<void>((resolve, reject) => {
            Promise.all([this.getPage(this.pageOptions)]).then(() => {
                resolve();
            }, reject);
        });
    }

    get(id: number): Observable<Environment> {
        if (!id) {
            return null;
        }
        return this.gw.get("environment/:id", { id: id });
    }

    get currentEnvironment(): Environment {
        return this.environmentEdit;
    }

    getAll(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get("environment/").subscribe((res: HttpResponse<any>) => {
                this.environment = res.body;
                resolve(res);
            }, reject);
        });
    }

    getPage(pageOptions: PageOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post("environment/search", pageOptions)
                .subscribe((res: HttpResponse<any>) => {
                    this.environment = res.body.content;
                    this.onDataChanged.next(this.environment);
                    resolve(res);
                }, reject);
        });
    }

    getPageByCompany(companyId, pageOptions: PageOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post(`environment/search/companyId/${companyId}`, pageOptions)
                .subscribe((res: HttpResponse<any>) => {
                    resolve(res.body);
                }, reject);
        });
    }

    getCostCenterList(companyId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .get("environment/costCenter/:companyId", {
                    companyId: companyId,
                })
                .subscribe((res: HttpResponse<any>) => {
                    this.environment = res.body;
                    resolve(res);
                }, reject);
        });
    }

    getFree(companyId, scheduleDate): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .get(`environment/free/${companyId}/${scheduleDate}`)
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        resolve(res);
                    },
                    error: (err)=>{
                        reject(err)
                    }
                });
        });
    }

    getEnvironmentStatusList(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get("environment/status/simpleList").subscribe({
                next: (res: HttpResponse<any>) => {
                    this.environment = res.body;
                    resolve(res);
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    }

    getEnvironmentTypeList(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get("environment/type/simpleList").subscribe({
                next: (res: HttpResponse<any>) => {
                    this.environment = res.body;
                    resolve(res);
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    }

    getPageSettings() {
        if (this.currentPage) {
            return this.pageOptions;
        } else {
            return;
        }
    }
}
