import { Component, OnInit, ViewEncapsulation, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import * as $ from 'jquery'
import { Patient } from '../../../../../../shared/cw-backend/models/backend-definitions.module';

@Component({
    selector: 'crud-header',
    templateUrl: './crud-header.component.html',
    styleUrls: ['./crud-header.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class CrudHeaderComponent implements OnInit, OnChanges {
    @Input('header') header: string;
    @Input('urlPath') urlPath?: string;
    @Input('mainName') mainName?: string;
    @Input('isBackPage') isBackPage?: boolean = false;
    @Input("contextType") context?: string = "";
    @Input("patient") patient: Patient;
    public breadcrumb: any[];

    constructor(
        private _route: Router
    ) { }


    ngOnChanges(changes: SimpleChanges): void {
       if(changes.urlPath){
        this.urlPath = changes.urlPath.currentValue;
       }
    }

    ngOnInit() {

        this.capitalizeBreadcrumb();
        var accents = require('remove-accents')
        this.urlPath = accents.remove(this.urlPath);
        if (this.header == 'list') {
            this.mainName = this.breadcrumb.slice(this.breadcrumb.lastIndexOf(null)).toString();
            this.mainName = this.mainName.replace(/-/g, ' ');
        } else if (this.mainName && this.mainName.split('-')[0] === 'undefined') {
            this.mainName = 'Novo registro';
        } else if (!this.mainName) {
            this.mainName = 'Novo registro';
        }
    }

    backPage() {
        let person = JSON.parse(sessionStorage.getItem('scheduledPerson'));

        if (this.context === 'person' && person && person.id && this.patient && this.patient.person.id === person.id) {
            this._route.navigate(['operacional/agendamento/marcacao'])
            return;
        }else{

            window.history.back();
        }
    }

    scrollTop() {
        $('html, body').animate({
            scrollTop: 0
        });
    }

    capitalizeBreadcrumb() {
        this.breadcrumb = this.urlPath.split('/');
        this.breadcrumb.forEach((word: string, index: number) => {
            let breadcrumb = word.charAt(0).toUpperCase() + word.slice(1);
            this.breadcrumb[index] = breadcrumb.replace(/-/g, ' ');
        });
    }

}
