
import { NgModule } from '@angular/core';

import { PlanEditProvider } from './plan-edit.provider';
import { PlanListProvider } from './plan-list.provider';

export * from './plan-edit.provider';
export * from './plan-list.provider';

export {
  PlanListProvider,
  PlanEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    PlanEditProvider,
    PlanListProvider
  ]
})
export class PlanBackendModule { }
