
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiGateway } from "../../../api-gateway";
import { SnackBarService } from "../../../../cw-utils/snackbar.service";
import { Billing } from "../../../models/backend-definitions.module";
@Injectable()
export class BillingEditProvider implements Resolve<any>
{
    routeParams: any;
    state: any;
    onDataChanged: BehaviorSubject<any>;
    billing: Billing;

    constructor(
        public gw: ApiGateway,
        public snackBar: SnackBarService
    ) {
        this.onDataChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        return new Promise<void>((resolve, reject) => {
            Promise.all([this.getBilling()]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getBilling(): Promise<any> {
        return new Promise((resolve, reject) => {
            let data = this.checkLastModifications()
            if (data && data.id == this.routeParams.id) {
                this.billing = data;
                this.onDataChanged.next(this.billing);
                resolve(this.billing);
            } else if (this.routeParams.id === 'novo') {
                this.onDataChanged.next(false);
                resolve(false);
            }
            else {
                this.gw.get('billing/' + this.routeParams.id).subscribe((res: Billing) => {
                    this.billing = res;
                    this.onDataChanged.next(this.billing);
                    resolve(res);
                }, reject);
            }
        });
    }

    getOrderItemByFilter(billingFilter): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('filter/order-items', billingFilter)
                .subscribe({
                    next: (res) => { resolve(res) },
                    error: (err) => { reject(err) }
                })
        })
    }

    getForBilling(billingFilter): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('billing/for-billing/', billingFilter)
                .subscribe({
                    next: (res) => { resolve(res) },
                    error: (err) => { reject(err) }
                })
        })
    }

    addOrderItPreBilling(billing): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('billing/pre-billing/add-order-item', billing).subscribe({
                next: (response) => { resolve(response); this.snackBar.successMessage(response.body.message); },
                error: (err) => { reject(err) }
            });
        });
    }

    deletePreBillingOrdeItem(billingOrderItemId, billingId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.delete('billing/pre-billing-order-item/:billingOrderItemId/:billingId', { billingOrderItemId: billingOrderItemId, billingId: billingId }).subscribe({
                next: (response) => { resolve(response); this.snackBar.successMessage(response.body.message); },
                error: (err) => { reject(err) }
            })
        })
    }

    deleteBillingOrdeItem(billingOrderItemId, billingId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.delete('billing/billing-order-item/:billingOrderItemId/:billingId', { billingOrderItemId: billingOrderItemId, billingId: billingId }).subscribe({
                next: (response) => { resolve(response); this.snackBar.successMessage(response.body.message); },
                error: (err) => { reject(err) }
            })
        })
    }

    insert(billing): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('billing/', billing).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                }, error: (err) => { reject(err) }
            });
        });
    }

    edit(billing): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('billing/', billing).subscribe({next:(response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, error: (err) => { reject(err) }});
        });
    }

    delete(billing): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.delete('billing/' + billing.id).subscribe({next:(response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, error: (err) => { reject(err) }});
        });
    }

    // /open-billing/{billingId}/{userId}
    openBilling(bilingid: number, userId: number, releaseRason: string): Promise<any> {
        releaseRason = typeof releaseRason == "string" ? releaseRason : releaseRason = " ";

        return new Promise<any>((resolve, reject) => {
            this.gw.put(`billing/open/:billingId/:userId`, { billingId: bilingid, userId: userId }, releaseRason)
                .subscribe({
                    next: (res: any) => {
                        resolve(res);
                    },
                    error: (err: any) => { reject(err); }
                });
        });
    }

    generateXml(billingId, xmlOrZip, typeTiss, version) {
        return new Promise<any>((resolve, reject) => {
            this.gw.get("billing/eletronic-billing/:billingId/zip-xml/:xmlOrZip/tiss/:typeTiss/version/:version", { billingId: billingId, xmlOrZip: xmlOrZip, typeTiss: typeTiss, version: version }).subscribe({
                next: (res: any) => {
                    resolve(res);
                },
                error: (err: any) => { reject(err); }
            });
        })
    }

    getEartefactByBillingId(billingId) {
        return new Promise<any>((resolve, reject) => {
            this.gw.get("billing/eartefact/:billingId", { billingId: billingId }).subscribe({
                next: (res: any) => {
                    resolve(res);
                },
                error: (err: any) => { reject(err); }
            });
        })
    }

    insertProtocol(billingEartefactId, protocol){
        return new Promise<any>((resolve, reject) => {
            this.gw.post(`billing/protocol/${billingEartefactId}`, protocol).subscribe({
                next: (res: any) => {
                    resolve(res);
                },
                error: (err: any) => { reject(err); }
            });
        })
    }

    getEartefactXml(eartefactId) {
        return new Promise<any>((resolve, reject) => {
            this.gw.get("billing/xml/:eartefactId", { eartefactId: eartefactId }).subscribe({
                next: (res: any) => {
                    resolve(res);
                },
                error: (err: any) => { reject(err); }
            });
        })
    }



    checkLastModifications(): Billing {
        let wasLocked = JSON.parse(localStorage.getItem('session_locked'));
        let data;
        if (wasLocked) {
            data = JSON.parse(localStorage.getItem('last_modification'));
            localStorage.removeItem('session_locked');
        }
        return data;
    }

    calcImpostos(insuranceId, invoice): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('billing/invoice/impostos/insurance/:insuranceId', { insuranceId: insuranceId }, invoice).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);

                }, error: (error: HttpResponse<any>) => { error }
            });
        });
    }
}
