import { CompanyCostCenter } from './company';

export class Environment {
    id: number;
    name: string;
    description: string;
    type: string;
    status: string;
    active: boolean;
    parent: Environment;
    companyCostCenter: CompanyCostCenter;
}
''