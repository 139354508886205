
import { HttpResponse, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiGateway } from '../../../api-gateway';
import { SnackBarService } from '../../../../cw-utils/snackbar.service';
import { Company } from '../../../models/backend-definitions.module';
import { Environment } from 'app/decorators/environment.decorator';
import { C } from '@angular/cdk/keycodes';

@Injectable()
export class CompanyEditService implements Resolve<any>
{
    @Environment('baseUrl') baseUrl: string;
    routeParams: any;
    state: any;
    onDataChanged: BehaviorSubject<any>;
    company: Company;

    constructor(
        public gw: ApiGateway,
        public snackBar: SnackBarService,
        private httpClient: HttpClient
    ) {
        this.onDataChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        return new Promise<void>((resolve, reject) => {
            Promise.all([this.getCompany()]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getCompany(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this.routeParams.id === 'novo') {
                this.onDataChanged.next(false);
                resolve(false);
            }
            else {
                this.gw.get('company/wAll/' + this.routeParams.id).subscribe((res: Company) => {
                    this.company = res;
                    this.onDataChanged.next(this.company);
                    resolve(res);
                }, reject);
            }
        });
    }

    insert(company): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('company/', company).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }

    edit(company): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.put('company/', company).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }

    delete(company): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.delete('company/' + company.id).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }

    deletePhoto(companyId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.put('company/:companyId/image', { companyId: companyId }).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }

    uploadImage(file: File, companyId): Promise<any> {
        return new Promise((resolve, reject) => {

            const endpoint = this.baseUrl + 'company/uploadLogo/' + companyId;
            const formData: FormData = new FormData();
            formData.append('file', file, file.name);

            let authToken = localStorage.getItem('token');
            let headers = new HttpHeaders();
            headers = headers.append('X-Auth-Token', authToken);

            this.httpClient.post(endpoint, formData, { headers: headers })
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                    },
                    error: (err) => {
                        reject(err)
                    }
                })
        })
    };
    uploadCertification(file: File, uploadCertiticateTO): Promise<any> {
        return new Promise((resolve, reject) => {


            const endpoint = this.baseUrl + 'company/upload-certification';
            const formData: FormData = new FormData();
            formData.append('file', file, file.name);

            const paramsBlob = new Blob([JSON.stringify(uploadCertiticateTO)], { type: 'application/json' });

            formData.append('params', paramsBlob);

            let authToken = localStorage.getItem('token');
            let headers = new HttpHeaders();
            headers = headers.append('X-Auth-Token', authToken);

            this.httpClient.post(endpoint, formData, { headers: headers })
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                    },
                    error: (err) => {
                        reject(err)
                    }
                })
        })
    };
    getCertification(companyId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('company/upload-certification/:companyId', { companyId: companyId }).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);
                },
                error: (err) => {
                    reject(err)
                }
            })
        });
    }
    deleteCertification(companyId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.delete('company/certification/:companyId', { companyId: companyId }).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);
                },
                error: (err) => {
                    reject(err)
                }
            })
        });
    }
}
