import { NgModule } from '@angular/core';
import { ScheduleService } from './schedule.service';

export { ScheduleService };

@NgModule({
  declarations: [

  ],
  imports: [

  ],
  exports: [

  ],
  providers: [
    ScheduleService,
  ]
})
export class ScheduleModule { }
