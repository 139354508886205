
import { NgModule } from '@angular/core';

import { OrderAuthorizationEditProvider } from './order-authorization-edit.provider';
import { OrderAuthorizationListProvider } from './order-authorization-list.provider';

export * from './order-authorization-edit.provider';
export * from './order-authorization-list.provider';

export {
  OrderAuthorizationListProvider,
  OrderAuthorizationEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    OrderAuthorizationEditProvider,
    OrderAuthorizationListProvider
  ]
})
export class OrderAuthorizationBackendModule { }
