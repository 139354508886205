
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { PageOptions } from '../../../util/backend-util-module';
import { ApiGateway } from '../../../api-gateway';
import { Agreement, Insurance, Kit } from '../../../models/backend-definitions.module';
import { CwUtils } from 'cw-utils';

@Injectable()
export class KitListProvider implements Resolve<any>
{
    kit: Kit[];
    kitEdit: Kit;
    onDataChanged: BehaviorSubject<any>;
    routeParams: any;
    state: any;
    pageOptions: PageOptions;
    currentPage: PageOptions;

    constructor(
        public gw: ApiGateway,
        private location: Location,
    ) {
        this.onDataChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        // Get state for current or new page
        this.state = this.location.getState();
        if (this.state.currentPage && state.url.includes(this.state.path)) {
            this.currentPage = this.state.currentPage;
        }
        this.kitEdit = this.state.currentKit;

        if (this.currentPage) {
            this.pageOptions = this.currentPage;
        }
        else {
            this.pageOptions = new PageOptions();
            this.pageOptions.fieldSort = 'name';
        }
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this.getPage(this.pageOptions, null)
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    get(id: number): Observable<Kit> {
        if (!id) {
            return null;
        }
        return this.gw.get('kit/:id', { id: id });
    }

    get currentKit(): Kit {
        return this.kitEdit;
    }

    getAll(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('kit/').subscribe((res: HttpResponse<any>) => {
                this.kit = res.body;
                this.onDataChanged.next(this.kit);
                resolve(res);
            }, reject);
        });

    }

    getPage(pageOptions: PageOptions, kitType): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('kit/search/' + kitType, pageOptions).subscribe((res: HttpResponse<any>) => {
                if (!CwUtils.compare(this.kit, res.body.content) && res.body.content.length > 0) {
                    this.kit = res.body.content;
                    this.onDataChanged.next(this.kit);
                } else {
                    this.kit = res.body.content;
                }
                resolve(res);
            }, reject);
        });
    }

    //kit/search/{kitType}/agreement/{agreementId}
    getKitByAgreement(kitType, agreement: Agreement, pageOptions: PageOptions) {
        return new Promise<any>((resolve, reject) => {
            this.gw.post(`kit/search/${kitType}/agreement/${agreement.id}`, pageOptions).subscribe((res: HttpResponse<any>) => {
                if (!CwUtils.compare(this.kit, res.body.content) && res.body.content.length > 0) {
                    this.kit = res.body.content;
                    this.onDataChanged.next(this.kit);
                } else {
                    this.kit = res.body.content;
                }
                resolve(res);
            }, reject);
        });
    }

    getKitByInsurance(pageOptions: PageOptions, kitType: string, insuranceId): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.gw.post(`attendance/search/accreditationType/${kitType}/insurance/${insuranceId}`, pageOptions).subscribe((res: HttpResponse<any>) => {
                if (!CwUtils.compare(this.kit, res.body.content) && res.body.content.length > 0) {
                    this.kit = res.body.content;
                    this.onDataChanged.next(this.kit);
                } else {
                    this.kit = res.body.content;
                }
                resolve(res);
            }, reject);
        });
    }

    getKitType(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('kit/kitType')
                .subscribe((res: HttpResponse<any>) => {
                    resolve(res);
                }, reject);
        });
    }

    getPageSettings() {
        if (this.currentPage) {
            return this.pageOptions;
        } else {
            return;
        }
    }
}

