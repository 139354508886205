import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FuseSharedModule } from '@fuse/shared.module';

import { CrudButtonsComponent } from './buttons/crud-buttons.component';
import { CrudHeaderComponent } from './header/crud-header.component';

@NgModule({
    declarations: [
        CrudButtonsComponent,
        CrudHeaderComponent
    ],
    imports: [
        RouterModule,

        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatTooltipModule,
        MatCheckboxModule,
        FuseSharedModule,
    ],
    exports: [
        CrudButtonsComponent,
        CrudHeaderComponent
    ],
    schemas: [
        NO_ERRORS_SCHEMA,
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class CrudModule {
}
