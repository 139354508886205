import { SnackBarService } from 'cw-utils';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import * as $ from 'jquery';


@Component({
    selector: 'crud-buttons',
    templateUrl: './crud-buttons.component.html',
    styleUrls: ['./crud-buttons.component.scss']
})
export class CrudButtonsComponent implements OnInit, OnDestroy {
    @Input('pageType') pageType: string;
    @Input('groupForm') groupForm?: FormGroup;
    @Input('viewCode') viewCode?: string;
    @Input('componentType') componentType?: string;
    @Input('functionType') functionType?: string;
    @Input('hideDeleteBtn') hideDeleteBtn?: boolean;
    @Input('ignorePristine') ignorePristine?: boolean;
    @Input('ignoreInvalid') ignoreInvalid?: boolean;
    @Input('checkTouched') checkTouched?: boolean;
    @Output('clickEvent') clickEvent: EventEmitter<any> = new EventEmitter();

    

    isDisabled: boolean = false;

    invalidFields: any;
    constructor(
        private _router: Router,
        private _location: Location,
        private snackBar: SnackBarService
    ) {
    }
    ngOnDestroy(): void {
        $("#update-" + this.viewCode).prop('disabled', false);
        $("#insert-" + this.viewCode).prop('disabled', false);
        $("#insert_new-" + this.viewCode).prop('disabled', false);
    }

    ngOnInit() {
    }

    onClick(action: string) {
        $("#update-" + this.viewCode).prop('disabled', true);
        $("#insert-" + this.viewCode).prop('disabled', true);
        $("#insert_new-" + this.viewCode).prop('disabled', true);
        setTimeout(() => {
            $("#update-" + this.viewCode).prop('disabled', false);
            $("#insert-" + this.viewCode).prop('disabled', false);
            $("#insert_new-" + this.viewCode).prop('disabled', false);
        }, 2000)

        if (this.groupForm) {
            // let data = this.groupForm.getRawValue();
            this.findInvalidControls();
        }
        switch (action) {
            case 'insert':
            case 'edit':

                if (!this.ignoreInvalid) {
                    if (this.groupForm.invalid && !this.ignorePristine) {
                        this.snackBar.errorMessage(
                            "Verifique o(s) campo(s) em destaque"
                        );
                        action = "invalid";
                    } else if (!this.groupForm.touched && this.checkTouched) {
                        this.snackBar.warnMessage(
                            "Não houve alteração no registro"
                        );
                        return false;
                    }
                }
                break;
            case 'reset':
                if (this._router.url.includes('/novo')) {
                    this._location.back();
                }
                else if (this.groupForm.pristine) {
                    this.snackBar.warnMessage("Não houve alteração no registro atual");
                    return false
                }
                break;
        }

        this.clickEvent.emit(action);
    }

    // TODO - USE IN FUTURE
    // public validateAllFormFields(formGroup: FormGroup) {
    //   this.findInvalidControls(this.groupForm);
    //   Object.keys(formGroup.controls).forEach(field => {
    //     const control = formGroup.get(field);
    //     if (control instanceof FormControl) {
    //       control.markAsTouched({ onlySelf: true });
    //     } else if (control instanceof FormGroup) {
    //       this.findInvalidControls(control);
    //     }
    //   });
    // }

    public findInvalidControls() {

        const invalid = [];
        const controls = this.groupForm.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                invalid.push(name);
            }
        }
        if (invalid.length > 0) {
            this.groupForm.updateValueAndValidity();
            this.groupForm.markAllAsTouched();
        }
        return invalid;
    }
}
