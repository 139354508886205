
import { NgModule } from '@angular/core';

import { BankEditProvider } from './bank-edit.provider';
import { BankListProvider } from './bank-list.provider';

export * from './bank-edit.provider';
export * from './bank-list.provider';

export {
  BankListProvider,
  BankEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    BankEditProvider,
    BankListProvider
  ]
})
export class BankBackendModule { }
