import { Company, Patient, Performer, User } from "../../backend-definitions.module";

export class Event {

     id: number;

     parent:Event;

     userId:User;

     company:Company;

     performer:Performer;

     patient:Patient;

     name:string;

     content:string;

     dated:string;

     hour:string;

     type: string;

     color: string;
}
