import { Moment } from 'moment';
import { Company } from '../organizational/company';
import { Performer } from '../organizational/performer';
import { User } from '../../security/user/user-model';
import { Time } from '@angular/common';

export class Operation {
    id: number;
    company: Company;
    companyParent?:Company;
    processAllUnits: boolean;
    changesPatientsScheduledToBlock: boolean;
    performer: any;
    user: User;
    type: string;
    created: string;
    started: Moment;
    ended: Moment;
    startTime: Time;
    endTime: Time;
    intervalTime: Moment;
    weeks: string;
}