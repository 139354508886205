
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Participation, PageOptions, ApiGateway } from 'cw-backend';
import { CwUtils } from 'cw-utils';
import { Horary, ParticipationSpec } from '../../../models/backend-definitions.module';

@Injectable()
export class ParticipationListProvider implements Resolve<any>
{
    horary: Horary[];
    participation: Participation[];
    participationSpecList: ParticipationSpec[];
    participationEdit: Participation;
    onDataChanged: BehaviorSubject<any>;
    onHoraryChanged: BehaviorSubject<any>;
    routeParams: any;
    state: any;
    pageOptions: PageOptions;
    currentPage: PageOptions;

    standardParticipation: Participation[];
    procedureParticipation: Participation[];
    procedureExternalParticipation: Participation[];
    groupParticipation: Participation[];
    groupExternalParticipation: Participation[];

    onSpecListChanged: BehaviorSubject<any>;
    onStandardChanged: BehaviorSubject<any>;
    onProcedureChanged: BehaviorSubject<any>;
    onGroupChanged: BehaviorSubject<any>;
    onExternalProcedureChanged: BehaviorSubject<any>;
    onExternalGroupChanged: BehaviorSubject<any>;

    constructor(
        public gw: ApiGateway,
        private location: Location,
    ) {
        this.onDataChanged = new BehaviorSubject({});
        this.onProcedureChanged = new BehaviorSubject({});
        this.onExternalProcedureChanged = new BehaviorSubject({});
        this.onExternalGroupChanged = new BehaviorSubject({});
        this.onStandardChanged = new BehaviorSubject({});
        this.onSpecListChanged = new BehaviorSubject({});
        this.onGroupChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        // Get state for current or new page
        this.state = this.location.getState();
        if (this.state.currentPage && state.url.includes(this.state.path)) {
            this.currentPage = this.state.currentPage;
        }
        this.participationEdit = this.state.currentParticipation;

        if (this.currentPage) {
            this.pageOptions = this.currentPage;
        }
        else {
            this.pageOptions = new PageOptions();
            this.pageOptions.fieldSort = 'procedure';
        }
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this.getPage(this.pageOptions)
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    get(id: number): Observable<Participation> {
        if (!id) {
            return null;
        }
        return this.gw.get('participation/:id', { id: id });
    }

    get currentParticipation(): Participation {
        return this.participationEdit;
    }

    getAll(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('participation/').subscribe((res: HttpResponse<any>) => {
                this.participation = res.body;
                resolve(res);
            }, reject);
        });

    }

    getPage(pageOptions: PageOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('participation/search', pageOptions).subscribe((res: HttpResponse<any>) => {
                if (!CwUtils.compare(this.participation, res.body.content) && res.body.content.length > 0) {
                    this.participation = res.body.content;
                    this.onDataChanged.next(this.participation);
                }
                else {
                    this.participation = res.body.content;
                };
                resolve(res);
            }, reject);
        });
    }

    getPageSettings() {
        if (this.currentPage) {
            return this.pageOptions;
        } else {
            return;
        }
    }

    getPageByParticipationType(pageOptions: PageOptions, participationType, professionalId, renew?: boolean): Promise<any> {
        if (!professionalId) {
            professionalId = 0;
        }
        switch (participationType) {
            case "P":
            case "A":
                pageOptions.fieldSort = "procedure.name";
                break;
            case "G":
            case "B":
                pageOptions.fieldSort = "group.name";
                break;

            default:
                break;
        }
        pageOptions.sizePage = 5;
        return new Promise((resolve, reject) => {
            this.gw.post("professional/:professionalId/search/type/:participationType", { participationType: participationType, professionalId: professionalId }, pageOptions).subscribe((res: HttpResponse<any>) => {
                let incParticipation = res.body.content;
                let result = this.getParticipationListByType(participationType, incParticipation, renew);
                resolve(res);
            }, reject);
        });
    };

    getSimpleParticipationList(pageOptions: PageOptions, participationType, professionalId): Promise<any> {
        if (!professionalId) {
            professionalId = 0;
        }
        pageOptions.sizePage = 5;
        return new Promise((resolve, reject) => {
            this.gw.post("professional/:professionalId/search/type/:participationType", { participationType: participationType, professionalId: professionalId }, pageOptions).subscribe((res: HttpResponse<any>) => {
                let incParticipation = res.body.content;
                resolve(res);
            }, reject);
        });
    };

    getParticipationListByType(participationType, incParticipation?, renew?): boolean {
        switch (participationType) {
            case 'S':
                if (this.compareParticipation(this.standardParticipation, incParticipation, renew)) {
                    setTimeout(() => {
                        this.onStandardChanged.next(incParticipation);
                    }, 150);
                }
                this.standardParticipation = incParticipation;
                break;
            case 'P':
                if (this.compareParticipation(this.procedureParticipation, incParticipation, renew)) {
                    setTimeout(() => {
                        this.onProcedureChanged.next(incParticipation);
                    }, 150)
                }
                this.procedureParticipation = incParticipation;
                break;
            case 'A':
                if (this.compareParticipation(this.procedureExternalParticipation, incParticipation, renew)) {
                    setTimeout(() => {
                        this.onExternalProcedureChanged.next(incParticipation);
                    }, 150)
                }
                this.procedureExternalParticipation = incParticipation;
                break;
            case 'G':
                if (this.compareParticipation(this.groupParticipation, incParticipation, renew)) {

                    setTimeout(() => {
                        this.onGroupChanged.next(incParticipation);
                    }, 150)
                }
                this.groupParticipation = incParticipation;
                break;
            case 'B':
                if (this.compareParticipation(this.groupExternalParticipation, incParticipation, renew)) {

                    setTimeout(() => {
                        this.onExternalGroupChanged.next(incParticipation);
                    }, 150)
                }
                this.groupExternalParticipation = incParticipation;
                break;
        }
        return true
    }

    getParticipationSpecPage(pageOptions: PageOptions, id, renew?: boolean): Promise<any> {
        if (!id) {
            id = 0;
        }
        pageOptions.sizePage = 5;
        return new Promise((resolve, reject) => {
            this.gw.post("professional/participation/:id/spec/search", { id: id }, pageOptions).subscribe((res: HttpResponse<any>) => {
                let incParticipationSpec = res.body.content;
                if ((!CwUtils.compare(incParticipationSpec, this.participationSpecList) && res.body.content.length > 0) || renew) {
                    setTimeout(() => {
                        this.onSpecListChanged.next(incParticipationSpec);
                    }, 100);
                    this.onSpecListChanged.next(incParticipationSpec);
                }
                this.participationSpecList = res.body.content;
                resolve(res);
            }, reject);
        });
    }


    getPartElement(participationId, pageOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post(`professional/participation/${participationId}/element/search`, pageOptions).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);
                }, error(err) {
                    reject(err);
                }
            });
        });
    }   

    compareParticipation(participationListByType, incParticipation, renew?): boolean {
        if ((!CwUtils.compare(participationListByType, incParticipation) && incParticipation.length > 0) || renew) {
            return true;
        } else {
            return false;
        }
    }
}

