
import { NgModule } from '@angular/core';

import { OperationEditProvider } from './operation-edit.provider';
import { OperationListProvider } from './operation-list.provider';

export * from './operation-edit.provider';
export * from './operation-list.provider';

export {
  OperationListProvider,
  OperationEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    OperationEditProvider,
    OperationListProvider
  ]
})
export class OperationBackendModule { }
