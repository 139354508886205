// default
import { Component, OnInit, ViewEncapsulation, ViewChild, TemplateRef, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { fuseAnimations } from '@fuse/animations';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { SnackBarService } from 'cw-utils';
import { UserService } from 'cw-backend';
import { takeUntil } from 'rxjs/operators';
import * as $ from 'jquery'
import { TenantService } from 'app/tenant/tenant.service';

@Component({
    selector: 'authenticate-user',
    templateUrl: './critical-request.component.html',
    styleUrls: ['./critical-request.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class CriticalRequestComponent implements OnInit {
    @ViewChild('dialogContent')
    dialogContent: TemplateRef<any>;
    user: any;
    action: string;
    authenticateUserForm: FormGroup;

    private _unsubscribeAll: Subject<any>;

    constructor(
        private _formBuilder: FormBuilder,
        public _matDialogRef: MatDialogRef<CriticalRequestComponent>,
        private userService: UserService,
        private tenantService: TenantService,
        private snackBar: SnackBarService,
        @Inject(MAT_DIALOG_DATA) private _data: any,
    ) {

        this._unsubscribeAll = new Subject();

        if (this._data.user) {
            this.user = this._data.user;
            this.action = this._data.action;
        }
    }

    ngOnInit(): void {
        if (!this.user) {
            this.snackBar.warnMessage("Ocorreu um erro, tente novamente.");
            this.dimiss();
        }
        let login = JSON.parse(localStorage.getItem('user')).login;

        this.authenticateUserForm = this._formBuilder.group({
            login: [login, [Validators.required]],
            password: ['', Validators.compose([
                Validators.required,
            ])],
        });
        // $('#user_password').focus();
    }

    autenthicateUser() {
        const data = this.authenticateUserForm.getRawValue();
        data.tenant = this.tenantService.getTenant();

        this.userService.autenthicateUser(data)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(res => {
                localStorage.setItem('tokenCriticalOperation', JSON.stringify(res.body));
                this.userService.userManagement(this.action, this.user.id)
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((res) => {
                        this.snackBar.successMessage(res.body.message);
                        this.refresh();
                    });
            },
                error => {
                    if (error.status == 403) {
                        this.snackBar.errorMessage(error.error.message)
                    } else {
                        this.snackBar.unexpectedMessage();
                    }

                });
    }

    refresh() {
        this._unsubscribeAll.next(true);
        this._unsubscribeAll.complete();
        this._matDialogRef.close("refresh");
    }

    dimiss() {
        this._unsubscribeAll.next(true);
        this._unsubscribeAll.complete();
        this._matDialogRef.close();
    }
}
