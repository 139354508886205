
import { NgModule } from '@angular/core';

import { PersonEditProvider } from './person-edit.provider';
import { PersonListProvider } from './person-list.provider';

export * from './person-edit.provider';
export * from './person-list.provider';

export {
  PersonListProvider,
  PersonEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    PersonEditProvider,
    PersonListProvider
  ]
})
export class PersonBackendModule { }
