
import { NgModule } from '@angular/core';
import { AccreditationListProvider } from './accreditation-list.provider';
import { AccreditationEditProvider } from './accreditation-edit.provider';


export {
  AccreditationListProvider,
  AccreditationEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    AccreditationEditProvider,
    AccreditationListProvider
  ]
})
export class AccreditationBackendModule { }
