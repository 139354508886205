
import { NgModule } from '@angular/core';

import { OrderTissEditProvider } from './order-tiss-edit.provider';
import { OrderTissListProvider } from './order-tiss-list.provider';

export * from './order-tiss-edit.provider';
export * from './order-tiss-list.provider';

export {
  OrderTissListProvider,
  OrderTissEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    OrderTissEditProvider,
    OrderTissListProvider
  ]
})
export class OrderTissBackendModule { }
