import { NgModule } from '@angular/core';

export * from './page';
export * from './codeMask';


@NgModule({
  declarations: [
  ],
  imports: [
  ],
  exports: [
  ],
  providers: []
})
export class BackendUtilModule { }