import { MatPaginatorIntl } from '@angular/material/paginator';
import { Injectable } from "@angular/core";

export class Page {
    content: any[];
    empty: boolean;
    last: boolean;
    totalPages: number;
    totalElements: number;
    first: boolean;
    numberOfElements: number;
    sort?: Sort;
    size: number;
    number: number
}

export interface Sort {
    direction: String,
    property: String,
    ignoreCase: Boolean,
    nullHandling: String,
    ascending: Boolean,
    descending: Boolean
}

export class PageOptions {
    argument: any = "";
    page: number = 0;
    sizePage: number = 25;
    fieldSort?: string = 'name'  || '';
    sortDirection: string = "asc";

}

@Injectable()
export class CustomPaginator extends MatPaginatorIntl {
    constructor() {
        super();
        this.itemsPerPageLabel = 'Registros por página';
        this.nextPageLabel = 'Próxima página';
        this.previousPageLabel = 'Página anterior';
        this.firstPageLabel = 'Primeira página';
        this.lastPageLabel = 'Última página';

        this.getRangeLabel = function (page, pageSize, length) {
            if (length === 0 || pageSize === 0) {
                return '0 de ' + length;
            }
            length = Math.max(length, 0);
            const startIndex = page * pageSize;

            const endIndex = startIndex < length ?
                Math.min(startIndex + pageSize, length) :
                startIndex + pageSize;
            return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
        };
    }
} 