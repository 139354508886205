
// Default
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';
import { CrudModule } from 'app/layout/components/crud/crud.module';
// Backend
import { SnackBarService, CanDeactivateGuard } from 'cw-utils';
import { CustomPaginator, RoleListProvider, RoleControlService, OrderTissListProvider, OrderTissEditProvider, TypeGuideListProvider, ServiceCharacterListProvider, AccidentIndicatorListProvider, AccommodationListProvider, TypeSurveyListProvider, TypeAttendanceListProvider, HospitalizationRegimeListProvider, TypeHospitalizationListProvider, DegreeParticipationListProvider } from 'cw-backend';
import { AuthGuard, RolePermissionService, HotKeyService } from 'cw-services';
// OrderTiss
import { OrderTissComponent } from './order-tiss.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { OccupationalHealthListProvider } from '../../../../../shared/cw-backend/providers/tiss/occupational-health/occupational-health-list.provider';
import { AttendanceRegimeListProvider } from '../../../../../shared/cw-backend/providers/tiss/attendance-regime/attendance-regime-list.provider';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
    declarations: [
        OrderTissComponent
    ],
    imports: [
        MatDividerModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatSelectModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatDialogModule,
        MatTooltipModule,
        MatDatepickerModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        NgxMaskModule.forRoot(),
        FlexLayoutModule,
        FuseSharedModule,
        FuseWidgetModule,
        CrudModule,
    ],
    providers: [
        CanDeactivateGuard,
        HotKeyService,
        MatSnackBarConfig,
        SnackBarService,
        RoleListProvider,
        RoleControlService,
        RolePermissionService,
        {
            provide: MatPaginatorIntl,
            useClass: CustomPaginator
        },
        OrderTissListProvider,
        OrderTissEditProvider,
        DegreeParticipationListProvider,
        TypeGuideListProvider,
        ServiceCharacterListProvider,
        AccidentIndicatorListProvider,
        AccommodationListProvider,
        TypeSurveyListProvider,
        TypeAttendanceListProvider,
        HospitalizationRegimeListProvider,
        OccupationalHealthListProvider,
        AttendanceRegimeListProvider,
        TypeHospitalizationListProvider
    ],
    exports: [OrderTissComponent]
})
export class OrderTissModule {
}
