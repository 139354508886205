<!-- LIST BUTTONS -->
<div *ngIf="pageType === 'list'" fxLayout="row" fxLayoutAlign="start center" class="mt-md-0 ml-44">
    <button matTooltip="Atualizar" id="crud-refresh" mat-stroked-button (click)="onClick('refresh')"
        class="refresh-button mr-12">
        Atualizar <mat-icon>refresh</mat-icon>
    </button>

    <button id="crud-os-btn" *ngIf="componentType == 'billing'" matTooltip="Atendimento" mat-stroked-button class="mr-sm-12" (click)="onClick('preBilling')">
        Pré Faturar <span class="material-icons">paid</span>
    </button>

    <button id="insert-{{viewCode}}" matTooltip="Novo" disabled (click)="onClick('new')" mat-stroked-button
        class="add-button" style="color:whitesmoke">
        Novo <mat-icon>add</mat-icon>
    </button>
</div>
<!-- / LIST BUTTONS -->

<!-- NEW BUTTONS -->
<div fxLayout="row" fxLayoutAlign="start center" *ngIf="pageType === 'new'">
    <button id="insert_new-{{viewCode}}" class="save-button" (click)="onClick('insert_new')"
        matTooltip="Salvar e incluir novo" mat-stroked-button class="mr-sm-12">
        Salvar e incluir novo <mat-icon>add</mat-icon>
    </button>

    <button id="insert-{{viewCode}}" class="save-button" (click)="onClick('insert')" matTooltip="Salvar"
        mat-stroked-button class="mr-sm-12">
        Salvar <mat-icon>backup</mat-icon>
    </button>

    <button id="crud-reset-btn" matTooltip="Cancelar" mat-stroked-button aria-label="Limpar registro"
        (click)="onClick('reset')" fxHide fxShow.gt-sm>
        Cancelar<mat-icon>clear</mat-icon>
    </button>
</div>
<!-- /NEW BUTTONS -->

<!-- EDIT BUTTONS -->
<div fxLayout="row" fxLayoutAlign="start center" *ngIf="pageType === 'edit'">

   
    <button *ngIf="!hideDeleteBtn" id="delete-{{viewCode}}" matTooltip="Deletar" disabled mat-stroked-button
        class="mr-sm-12" (click)="onClick('delete')">
        Apagar registro <mat-icon>delete</mat-icon>
    </button>

    <button id="insert-{{viewCode}}" matTooltip="Novo" disabled mat-stroked-button class="mr-sm-12"
        (click)="onClick('new')">
        Salvar e incluir novo <mat-icon>add</mat-icon>
    </button>

    <button id="update-{{viewCode}}" matTooltip="Salvar" disabled mat-stroked-button class="mr-sm-12"
        (click)="onClick('edit')" (dblclick)="onClick('db')" aria-label="Salvar registro">
        Salvar <mat-icon>backup</mat-icon>
    </button>

    <button id="crud-reset-btn" matTooltip="Cancelar" mat-stroked-button (click)="onClick('reset')" fxHide fxShow.gt-sm class="mr-sm-12"
        aria-label="Limpar registro">
        Limpar registro<mat-icon>clear</mat-icon>
    </button>

    <button id="crud-os-btn" *ngIf="componentType == 'patient'" matTooltip="Atendimento" mat-stroked-button class="mr-sm-12" (click)="onClick('attendance')">
        Atendimento <span class="material-icons">receipt</span>
    </button>


    <button id="crud-os-btn" *ngIf="functionType === 'print'" matTooltip="Ficha" mat-stroked-button (click)="onClick('print')">
         <span class="material-icons">print</span>
    </button>

    <!-- <button id="insert_new-{{viewCode}}" class="save-button" (click)="onClick('attendance')" matTooltip="Atendimento" mat-icon-button class="mr-sm-12">
        <mat-icon>add</mat-icon>
    </button> -->
</div>


<!-- / EDIT BUTTONS -->