
import { NgModule } from '@angular/core';

import { AddressTypeEditService } from './address-type-edit-service';
import { AddressTypeListService } from './address-type-list-service';

export * from './address-type-edit-service';
export * from './address-type-list-service';

export {
  AddressTypeListService,
  AddressTypeEditService
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    AddressTypeEditService,
    AddressTypeListService
  ]
})
export class AddressTypeBackendModule { }
