
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import {ApiGateway } from 'cw-backend';
import { SnackBarService } from 'cw-utils';
import { MeasureTiss } from '../../../models/accreditation/measure-tiss';

@Injectable()
export class MeasureTissEditProvider implements Resolve<any>
{
    routeParams: any;
    state: any;
    onDataChanged: BehaviorSubject<any>;
    measureTiss: MeasureTiss;

    constructor(
        public gw: ApiGateway,
        public snackBar: SnackBarService
    ) {
        this.onDataChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        return new Promise((resolve, reject) => {
            Promise.all([this.getMeasureTiss()]).then(
                () => {
                    resolve(true);
                },
                reject
            );
        });
    }

    getMeasureTiss(): Promise<any> {
        return new Promise((resolve, reject) => {
            let data =  this.checkLastModifications()
            if (data && data.id == this.routeParams.id) {
                this.measureTiss = data;
                this.onDataChanged.next(this.measureTiss);
                resolve(this.measureTiss);
            } else if (this.routeParams.id === 'novo') {
                this.onDataChanged.next(false);
                resolve(false);
            }
            else {
                this.gw.get('measureTiss/' + this.routeParams.id).subscribe({next:(res: MeasureTiss ) => {
                    this.measureTiss = res;
                    this.onDataChanged.next(this.measureTiss);
                    resolve(res);
                }, error: (err: any) => { reject(err) }});
            }
        });
    }

    insert(measureTiss): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('measureTiss/', measureTiss).subscribe({next:(response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, error: (err: any) => { reject(err) }});
        });
    }

    edit(measureTiss): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('measureTiss/', measureTiss).subscribe({next:(response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, error: (err: any) => { reject(err) }});
        });
    }

    delete(measureTiss): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.delete('measureTiss/' + measureTiss.id).subscribe({next:(response: HttpResponse<any>) => {
                resolve(response);
                 this.snackBar.successMessage(response.body.message);
            }, error: (err: any) => { reject(err) }});
        });
    }

    checkLastModifications(): MeasureTiss {
        let wasLocked = JSON.parse(localStorage.getItem('session_locked'));
        let data;
        if (wasLocked){
            data = JSON.parse(localStorage.getItem('last_modification'));
            localStorage.removeItem('session_locked');
        }  
        return data;
    }
}
