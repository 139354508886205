
import { NgModule } from '@angular/core';

import { OccupationEditProvider } from './occupation-edit.provider';
import { OccupationListProvider } from './occupation-list.provider';

export * from './occupation-edit.provider';
export * from './occupation-list.provider';

export {
  OccupationListProvider,
  OccupationEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    OccupationEditProvider,
    OccupationListProvider
  ]
})
export class OccupationBackendModule { }
