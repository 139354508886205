import { NgModule } from "@angular/core";

import { AttendanceListProvider } from "./attendance-list.provider";
import { AttendanceEditProvider } from "./attendance-edit.provider";
import { AttendancePatientListProvider } from "./attendance-patient-list.provider";

export * from "./attendance-list.provider";
export * from "./attendance-edit.provider";
export * from "./attendance-patient-list.provider";

export { AttendanceListProvider, AttendanceEditProvider, AttendancePatientListProvider };

@NgModule({
    declarations: [],
    imports: [],
    exports: [],
    providers: [AttendanceListProvider, AttendanceEditProvider, AttendancePatientListProvider],
})
export class AttendanceBackendModule {}
