
import { NgModule } from '@angular/core';

import { CostCenterEditService } from './cost-center-edit-service';
import { CostCenterListService } from './cost-center-list-service';

export * from './cost-center-edit-service';
export * from './cost-center-list-service';

export {
  CostCenterListService,
  CostCenterEditService
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    CostCenterEditService,
    CostCenterListService
  ]
})
export class CostCenterBackendModule { }
