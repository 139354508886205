import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'fuse-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss']
})
export class FuseConfirmDialogComponent {
    public action: string;
    public showMessage: string;
    public confirmMessage: string = "Tem certeza que deseja confirmar a operação?";
    public deleteMessage: string = "Tem certeza que deseja deletar o registro?";
    public clearMessage: string = "Tem certeza que deseja descartar as alterações?";
    public cancelMessage: string = "Tem certeza que deseja cancelar?";
    public scheduleAction: boolean = false;


    /**
     * Constructor
     *
     * @param {MatDialogRef<FuseConfirmDialogComponent>} dialogRef
     */
    constructor(
        public dialogRef: MatDialogRef<FuseConfirmDialogComponent>
    ) {
    }

}
