
import { NgModule } from '@angular/core';

import { GlossTypeEditProvider } from './gloss-type-edit.provider';
import { GlossTypeListProvider } from './gloss-type-list.provider';

export * from './gloss-type-edit.provider';
export * from './gloss-type-list.provider';

export {
  GlossTypeListProvider,
  GlossTypeEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    GlossTypeEditProvider,
    GlossTypeListProvider
  ]
})
export class GlossTypeBackendModule { }
