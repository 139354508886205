import { HttpResponse, HttpClient, HttpHeaders } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { Location } from "@angular/common";
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { ApiGateway, Patient, PersonImage } from "cw-backend";
import { SnackBarService } from "cw-utils";
import { Environment } from "app/decorators/environment.decorator";
import moment from "moment";
import { error } from "console";


@Injectable()
export class PatientEditProvider implements Resolve<any> {
    @Environment("baseUrl") baseUrl: string;
    routeParams: any;
    state: any;
    onDataChanged: BehaviorSubject<any>;
    patient: Patient;
    static errorValidationSus = new EventEmitter<any>();
    countryValue: any;
    country = { id: "" };

    constructor(
        public gw: ApiGateway,
        public snackBar: SnackBarService,
        private httpClient: HttpClient,
        private location: Location
    ) {
        this.onDataChanged = new BehaviorSubject({});
        this.getCountry();
    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;
        this.state = this.location.getState();
        return new Promise<void>((resolve, reject) => {
            Promise.all([this.getPatient()]).then(() => {
                resolve();
            }, reject);
        });
    }

    getPatient(): Promise<any> {
        return new Promise((resolve, reject) => {
            let data = this.checkLastModifications();
            if (data && data.id == this.routeParams.id) {
                this.patient = data;
                this.onDataChanged.next(this.patient);
                resolve(this.patient);
            } else if (this.routeParams.id === "novo") {
                if (this.state.person) {
                    this.patient = new Patient();
                    this.patient.person = this.state.person;
                    this.onDataChanged.next(this.state.person);
                    resolve(this.state.person);
                } else {
                    this.onDataChanged.next(this.state.person);
                    resolve(false);
                }
            } else {
                this.gw
                    .get("patient/wAll/" + this.routeParams.id)
                    .subscribe((res: Patient) => {
                        this.patient = res;
                        this.onDataChanged.next(this.patient);
                        resolve(res);
                    }, reject);
            }
        });
    }

    insert(patient): Promise<any> {
        this.country.id = this.countryValue;

        if (patient.person.address) {
            patient.person.address.country = this.country;
        }

        if (
            patient.person.address &&
            patient.person.address.country != null &&
            this.country.id != ""
        ) {
            return new Promise((resolve, reject) => {
              
                    this.gw.post("patient/", patient).subscribe({
                        next: (response: HttpResponse<any>) => {
                            resolve(response);
                            this.snackBar.successMessage(response.body.message);
                        },
                        error: error=>{reject(error)}
                    });
            });
        } else {
            return new Promise((resolve, reject) => {
                this.gw
                    .post("patient/", patient)
                    .subscribe({
                        next: (response: HttpResponse<any>) => {
                        resolve(response);
                        this.snackBar.successMessage(response.body.message);
                    }, error: error=>{reject(error)}
                });
            });
        }
    }

    associateDoctorAsPatient(personId): Promise<any>{
        return new Promise((resolve, reject) => {
              
            this.gw.post(`patient/associate-doctor-as-patient/${personId}`).subscribe({
                next: (response: HttpResponse<any>) => {
                    this.snackBar.successMessage("Dados cadastrados com sucesso");
                    resolve(response);
                },
                error: error=>{reject(error)}
            });
    });
    }


    getCountry() {
        this.getParameterCountry().then((value) => {
            value.forEach((country) => {
                if (country.group == "Gerais") {
                    country.parameterList.forEach((parameter) => {
                        if (parameter.id == 28) {
                            this.countryValue = parameter.value;
                        }
                    });
                }
            });
        });
    }

    edit(patient): Promise<any> {
        this.country.id = this.countryValue;

        if (patient.person.address) {
            patient.person.address.country = this.country;
        }

        if (
            patient.person.address &&
            patient.person.address.country != null &&
            this.country.id != ""
        ) {
            return new Promise((resolve, reject) => {
                this.gw.put("patient/", patient).subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                        this.snackBar.successMessage(response.body.message);
                    },
                    error: error=>{reject(error)}
                });
            });
        } else {
            return new Promise((resolve, reject) => {
                this.gw
                    .put("patient/", patient)
                    .subscribe({
                        next: (response: HttpResponse<any>) => {
                        resolve(response);
                        this.snackBar.successMessage(response.body.message);
                    },
                    error: error=>{reject(error)}
                });
            });
        }
    }

    delete(patient): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .delete("patient/", patient)
                .subscribe((response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                }, reject);
        });
    }

    uploadImage(
        file: File,
        patientId,
        main,
        description,
        fileList?: File[]
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            if (!description) {
                description = "Upload Múltiplos";
            }

            const endpoint =
                this.baseUrl +
                "patient/" +
                patientId +
                "/uploadImage/main/" +
                main +
                "/description/" +
                description;
            const formData: FormData = new FormData();
            if (fileList) {
                fileList.forEach((f) => {
                    formData.append("file", f, f.name);
                });
            } else {
                formData.append("file", file, file.name);
            }

            let authToken = localStorage.getItem("token");
            let headers = new HttpHeaders();
            headers = headers.append("X-Auth-Token", authToken);

            this.httpClient
                .post(endpoint, formData, { headers: headers })
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getImageMain(patientId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get("patient/mainImagem/patient/" + patientId).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);
                },
                error(err) {
                    reject(err);
                },
            });
        });
    }

    getFile(fileId) {
        return new Promise<any>((resolve, reject) => {
            this.gw.get("patient/file/" + fileId).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);
                },
                error(err) {
                    reject(err);
                },
            });
        });
    }

    deleteImage(personImageId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .delete("patient/image/" + personImageId)
                .subscribe((response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                }, reject);
        });
    }

    checkLastModifications(): Patient {
        let wasLocked = JSON.parse(localStorage.getItem("session_locked"));
        let data;
        if (wasLocked) {
            data = JSON.parse(localStorage.getItem("last_modification"));
            localStorage.removeItem("session_locked");
        }
        return data;
    }

    getMedicalRecorderNumber() {
        return new Promise((resolve, reject) => {
            this.gw
                .get("patient/new")
                .subscribe((response: HttpResponse<any>) => {
                    resolve(response);
                }, reject);
        });
    }

    getParameterCountry(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get("parameter/context/SYSTEM").subscribe({
                next: (res: HttpResponse<any[]>) => {
                    res.body ? resolve(res.body) : resolve(res);
                },
                error(error) {
                    reject(error);
                },
            });
        });
    }
}
