
import { NgModule } from '@angular/core';

import { ProcedureEditProvider } from './procedure-edit.provider';
import { ProcedureListProvider } from './procedure-list.provider';

export * from './procedure-edit.provider';
export * from './procedure-list.provider';

export {
  ProcedureListProvider,
  ProcedureEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    ProcedureEditProvider,
    ProcedureListProvider
  ]
})
export class ProcedureBackendModule { }
