import { Injectable, Output, EventEmitter, Directive } from "@angular/core";
import { ApiGateway } from '../../../api-gateway';

@Directive()
@Injectable()
export class UserLogService {
    @Output() userLogged: EventEmitter<any> = new EventEmitter();
    userLog: any[];
    constructor
        (
            private gw: ApiGateway,
    ) {
    }

    getUserLastLogons() {
        this.gw.get('user/lastLogons').subscribe(res => {
            localStorage.setItem('user_log', JSON.stringify(res.body));
        });
        this.userLogged.emit(true);
    }

    setUserLogAcess() {
        let lastLogon: any = localStorage.getItem('user_log_lastAccess');
        if (!lastLogon) {
            lastLogon = new Date;
            localStorage.setItem('user_log_access', JSON.stringify(lastLogon));
        }
    }

    getUserLog(): any {
        this.gw.get('user/lastLogons').subscribe(res => {
            this.userLog = res.body
        });
        return this.userLog;
    }

    getEventCodeList(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get("user/userLogonEventCode/simpleList").subscribe((res) => {
                resolve(res.body.values);
            }, reject);
        });
    }
}