
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Account, PageOptions, ApiGateway } from 'cw-backend';
import { CwUtils } from 'cw-utils';

@Injectable()
export class AccountListProvider implements Resolve<any>
{
    account: Account[];
    accountEdit: Account;
    onDataChanged: BehaviorSubject<any>;
    routeParams: any;
    state: any;
    pageOptions: PageOptions;
    currentPage: PageOptions;

    constructor(
        public gw: ApiGateway,
        private location: Location,
    ) {
        this.onDataChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        // Get state for current or new page
        this.state = this.location.getState();
        this.currentPage = this.state.currentPage;
        this.accountEdit = this.state.currentAccount;

        if (this.currentPage) {
            this.pageOptions = this.currentPage;
        }
        else {
            this.pageOptions = new PageOptions();
            this.pageOptions.fieldSort = 'code';
        }
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this.getPage(this.pageOptions)
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    get(id: number): Observable<Account> {
        if (!id) {
            return null;
        }
        return this.gw.get('account/:id', { id: id });
    }

    get currentAccount(): Account {
        return this.accountEdit;
    }

    getAll(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('account/').subscribe({
                next: (res: HttpResponse<any>) => {
                    this.account = res.body;
                    resolve(res);
                }, error(err) { reject(err) }
            });
        });

    }

    getAccountList(agencyId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('agency/' + agencyId + '/accounts').subscribe({
                next: (res: HttpResponse<any>) => {
                    this.account = res.body;
                    resolve(res);
                }, error(err) { reject(err) }
            });
        });

    }

    getPage(pageOptions: PageOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('account/search', pageOptions).subscribe({next:(res: HttpResponse<any>) => {
                if (!CwUtils.compare(this.account, res.body.content) && res.body.content.length > 0) {
                    this.account = res.body.content;
                    this.onDataChanged.next(this.account);
                }
                else {
                    this.account = res.body.content;
                };
                resolve(res);
            },error(err){reject(err)}});
        });
    }


    getAccountTypeList(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('account/accountType/simple-list')
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        resolve(res);
                    }
                });
        });
    }

    getPageSettings() {
        if (this.currentPage) {
            return this.pageOptions;
        } else {
            return;
        }
    }
}

