import { Country, City } from 'cw-backend';
import { AddressType } from './address-type';

export class Address {
    id: number;
    country: Country;
    city: City;
    zipcode: string;
    district: string;
    addressType: AddressType;
    address: string;
    number: number;
    addon: string;
    description: string;
    constructor(
    ) { }
}