
import { Injectable } from '@angular/core';
import { ViewList, UserProfile } from 'cw-backend';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  viewList: ViewList[];

  constructor() {

    this.viewList = new Array();
  }

  setAuthenticated(token: string, user: any) {
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('token', token);
  }

  setPermission(modules){
    localStorage.setItem('modules', JSON.stringify(modules));
  }

  updateUserInfo(user: UserProfile) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
  }

  get isAuthenticated(): boolean {
    if (this.currentUser == null) {
      return false;
    }
    return true;
  }

  get currentUser(): UserProfile {
    let storedUser = localStorage.getItem('user');
    if (storedUser != null && storedUser != 'null') {
      return JSON.parse(storedUser);
    }
    return null;
  }

  get currentUserToken(): string {
    let token = localStorage.getItem('token');

    if (token == null || token == 'null') {
      return null;
    }

    return token;
  }

  get criticalToken(): string {
    let tokenCriticalOperation = localStorage.getItem('tokenCriticalOperation');

    if (tokenCriticalOperation == null || tokenCriticalOperation == 'null') {
      return null;
    }

    return tokenCriticalOperation;
  }
}
