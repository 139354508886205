
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Agency, PageOptions, ApiGateway } from 'cw-backend';
import { CwUtils } from 'cw-utils';

@Injectable()
export class AgencyListProvider implements Resolve<any>
{
    agency: Agency[];
    agencyEdit: Agency;
    onDataChanged: BehaviorSubject<any>;
    routeParams: any;
    state: any;
    pageOptions: PageOptions;
    currentPage: PageOptions;

    constructor(
        public gw: ApiGateway,
        private location: Location,
    ) {
        this.onDataChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        // Get state for current or new page
        this.state = this.location.getState();
        this.currentPage = this.state.currentPage;
        this.agencyEdit = this.state.currentAgency;

        if (this.currentPage) {
            this.pageOptions = this.currentPage;
            this.pageOptions.fieldSort = 'name';
        }
        else {
            this.pageOptions = new PageOptions();
            this.pageOptions.fieldSort = 'name';
        }
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this.getPage(this.pageOptions)
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    get(id: number): Observable<Agency> {
        if (!id) {
            return null;
        }
        return this.gw.get('agency/:id', { id: id });
    }

    get currentAgency(): Agency {
        return this.agencyEdit;
    }

    getAll(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('agency/').subscribe((res: HttpResponse<any>) => {
                this.agency = res.body;
                resolve(res);
            }, reject);
        });

    }

    getPage(pageOptions: PageOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('agency/search', pageOptions).subscribe({
                next: (res: HttpResponse<any>) => {
                    if (!CwUtils.compare(this.agency, res.body.content) && res.body.content.length > 0) {
                        this.agency = res.body.content;
                        this.onDataChanged.next(this.agency);
                    }
                    else {
                        this.agency = res.body.content;
                    };
                    resolve(res);
                }, error(err) { reject(err) }
            });
        });
    }

    getPageSimpleList(pageOptions: PageOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('agency/search/simple-list', pageOptions).subscribe({
                next: (res: HttpResponse<any>) => {
                    if (!CwUtils.compare(this.agency, res.body.content) && res.body.content.length > 0) {
                        this.agency = res.body.content;
                        this.onDataChanged.next(this.agency);
                    }
                    else {
                        this.agency = res.body.content;
                    };
                    resolve(res);
                },error(err){reject(err)}
            });
        });
    }

    getPageSettings() {
        if (this.currentPage) {
            return this.pageOptions;
        } else {
            return;
        }
    }
}

