
import { NgModule } from '@angular/core';

import { CivilStatusEditProvider } from './civil-status-edit.provider';
import { CivilStatusListProvider } from './civil-status-list.provider';

export * from './civil-status-edit.provider';
export * from './civil-status-list.provider';

export {
  CivilStatusListProvider,
  CivilStatusEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    CivilStatusEditProvider,
    CivilStatusListProvider
  ]
})
export class CivilStatusBackendModule { }
