
import { NgModule } from '@angular/core';

import { ExameTypeEditProvider } from './exame-type-edit.provider';
import { ExameTypeListProvider } from './exame-type-list.provider';

export * from './exame-type-edit.provider';
export * from './exame-type-list.provider';

export {
  ExameTypeListProvider,
  ExameTypeEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    ExameTypeEditProvider,
    ExameTypeListProvider
  ]
})
export class ExameTypeBackendModule { }
