import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { AnyCnameRecord } from "dns";
import { Observable } from "rxjs";
import { ApiGateway } from "../../../api-gateway";

@Injectable()
export class ProfileProvider implements Resolve<any>{

    constructor(
        public gw: ApiGateway,
    ) {

    }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        throw new Error("Method not implemented.");
    }

    getByUserId(userId: number) {
        return new Promise<any>((resolve, reject) => {
            this.gw.get('profile/user/:userId', {userId:userId}).subscribe({
                next: (res: HttpResponse<any>) => {
                    resolve(res);
                }
            });
        });
    }

     getProfileAll(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('profile/').subscribe({
                next: (res: HttpResponse<any>) => {
                    resolve(res);
                }, error: (err) => { reject(err) }
            });
        });
    }
     getProfileChatByUserId(userId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('profile/profile-chat/user/:userId',{userId:userId}).subscribe({
                next: (res: HttpResponse<any>) => {
                    resolve(res);
                }, error: (err) => { reject(err) }
            });
        });
    }

    save(profile) {
        return new Promise<any>((resolve, reject) => {
            this.gw.post('profile/', profile).subscribe({
                next: (res: HttpResponse<any>) => {
                    resolve(res);
                }
            });
        });
    }

    getMessages(userFromId, userToId) {
        return new Promise<any>((resolve, reject) => {
            this.gw.get('profile/messages/:userFromId/:userToId',{userFromId:userFromId,userToId:userToId }).subscribe({
                next: (res: HttpResponse<any>) => {
                    resolve(res);
                }
            });
        });
    }

    


}
