
import { NgModule } from '@angular/core';

import { AccountEditProvider } from './account-edit.provider';
import { AccountListProvider } from './account-list.provider';

export * from './account-edit.provider';
export * from './account-list.provider';

export {
  AccountListProvider,
  AccountEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    AccountEditProvider,
    AccountListProvider
  ]
})
export class AccountBackendModule { }
