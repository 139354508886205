export class Category {
    id: number;
    name: string;
    parent?: SubCategory
}

export class SubCategory {
    id: number;
    name: string;
    parent?: CategoryItem
}

export class CategoryItem {
    id: number;
    name: string;
}
