
import { NgModule } from '@angular/core';

import { CouncilEditProvider } from './council-edit.provider';
import { CouncilListProvider } from './council-list.provider';

export * from './council-edit.provider';
export * from './council-list.provider';

export {
  CouncilListProvider,
  CouncilEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    CouncilEditProvider,
    CouncilListProvider
  ]
})
export class CouncilBackendModule { }
