
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Accommodation, PageOptions, ApiGateway } from 'cw-backend';
import { CwUtils } from 'cw-utils';

@Injectable()
export class AccommodationListProvider implements Resolve<any>
{
    accommodation: Accommodation[];
    accommodationEdit: Accommodation;
    onDataChanged: BehaviorSubject<any>;
    routeParams: any;
    state: any;
    pageOptions: PageOptions;
    currentPage: PageOptions;

    constructor(
        public gw: ApiGateway,
        private location: Location,
    ) {
        this.onDataChanged = new BehaviorSubject({}); 
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        // Get state for current or new page
        this.state = this.location.getState();
        this.currentPage = this.state.currentPage;
        this.accommodationEdit = this.state.currentAccommodation;

        if (this.currentPage) {
            this.pageOptions = this.currentPage;
        }
        else {
            this.pageOptions = new PageOptions();
            this.pageOptions.fieldSort = 'code';
        }
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this.getPage(this.pageOptions)
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    get(id: number): Observable<Accommodation> {
        if (!id) {
            return null;
        }
        return this.gw.get('accommodation/:id', { id: id });
    }

     get currentAccommodation(): Accommodation {
        return this.accommodationEdit;
    }

    getAll(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('accommodation/').subscribe((res: HttpResponse<any>) => {
                this.accommodation = res.body;
                resolve(res);
            }, reject);
        });

    }
 
    getPage(pageOptions: PageOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('accommodation/search', pageOptions).subscribe((res: HttpResponse<any>) => {
                if (!CwUtils.compare(this.accommodation, res.body.content) && res.body.content.length > 0) {
                    this.accommodation = res.body.content;
                    this.onDataChanged.next(this.accommodation);
                }
                else {
                    this.accommodation = res.body.content;
                };
                resolve(res);
            }, reject);
        });
    }

     getPageSettings() {
        if (this.currentPage) {
            return this.pageOptions;
        } else {
            return;
        }
    }
}

