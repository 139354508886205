
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { AccidentIndicator, ApiGateway } from 'cw-backend';
import { SnackBarService } from 'cw-utils';

@Injectable()
export class AccidentIndicatorEditProvider implements Resolve<any>
{
    routeParams: any;
    state: any;
    onDataChanged: BehaviorSubject<any>;
    accidentIndicator: AccidentIndicator;

    constructor(
        public gw: ApiGateway,
        public snackBar: SnackBarService
    ) {
        this.onDataChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        return new Promise<void>((resolve, reject) => {
            Promise.all([this.getAccidentIndicator()]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getAccidentIndicator(): Promise<any> {
        return new Promise((resolve, reject) => {
            let data =  this.checkLastModifications()
            if (data && data.id == this.routeParams.id) {
                this.accidentIndicator = data;
                this.onDataChanged.next(this.accidentIndicator);
                resolve(this.accidentIndicator);
            } else if (this.routeParams.id === 'novo') {
                this.onDataChanged.next(false);
                resolve(false);
            }
            else {
                this.gw.get('accidentIndicator/' + this.routeParams.id).subscribe((res: AccidentIndicator ) => {
                    this.accidentIndicator = res;
                    this.onDataChanged.next(this.accidentIndicator);
                    resolve(res);
                }, reject);
            }
        });
    }

    insert(accidentIndicator): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('accidentIndicator/', accidentIndicator).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }

    edit(accidentIndicator): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('accidentIndicator/', accidentIndicator).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }

    delete(accidentIndicator): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.delete('accidentIndicator/' + accidentIndicator.id).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                 this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }

    checkLastModifications(): AccidentIndicator {
        let wasLocked = JSON.parse(localStorage.getItem('session_locked'));
        let data;
        if (wasLocked){
            data = JSON.parse(localStorage.getItem('last_modification'));
            localStorage.removeItem('session_locked');
        }  
        return data;
    }
}
