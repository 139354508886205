import { Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from "@angular/router";
import { Location } from "@angular/common";
import { HttpResponse } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import {
    Alert,
    PageOptions,
    ApiGateway,
    AlertSearch,
    AlertResultList,
} from "cw-backend";
import { CwUtils } from "cw-utils";

@Injectable()
export class AlertListProvider implements Resolve<any> {
    alert: AlertResultList[];
    alertEdit: Alert;
    alertEditResult: AlertResultList;
    onDataChanged: BehaviorSubject<any>;
    routeParams: any;
    state: any;
    pageOptions: PageOptions;
    currentPage: PageOptions;

    constructor(public gw: ApiGateway, private location: Location) {
        this.onDataChanged = new BehaviorSubject({});
    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        // Get state for current or new page
        this.state = this.location.getState();
        this.currentPage = this.state.currentPage;
        this.alertEdit = this.state.currentAlert;

        if (this.currentPage) {
            this.pageOptions = this.currentPage;
            this.pageOptions.fieldSort = "module";
        } else {
            this.pageOptions = new PageOptions();
            this.pageOptions.fieldSort = "module";
        }
        return new Promise((resolve, reject) => {
            Promise.all([this.getPage(this.pageOptions)]).then(() => {
                resolve(true);
            }, reject);
        });
    }

    get(id: number): Observable<Alert> {
        if (!id) {
            return null;
        }
        return this.gw.get("alert/:id", { id: id });
    }

    get currentAlert(): AlertResultList {
        return this.alertEditResult;
    }

    getId(alertId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get("alert/:id", { id: alertId }).subscribe({
                next: (res: HttpResponse<any>) => {
                    this.alert = res.body;
                    resolve(res);
                },
                error: (err: any) => {
                    reject(err);
                },
            });
        });
    }

    getAll(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get("alert/").subscribe({
                next: (res: HttpResponse<any>) => {
                    this.alert = res.body;
                    resolve(res);
                },
                error: (err: any) => {
                    reject(err);
                },
            });
        });
    }

    getPage(pageOptions: PageOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post("alert/search", pageOptions).subscribe({
                next: (res: HttpResponse<any>) => {
                    if (
                        !CwUtils.compare(this.alert, res.body.content) &&
                        res.body.content.length > 0
                    ) {
                        this.alert = res.body.content;
                        this.onDataChanged.next(this.alert);
                    } else {
                        this.alert = res.body.content;
                    }
                    resolve(res);
                },
                error: (err: any) => {
                    reject(err);
                },
            });
        });
    }

    getFindContext(context: string, id: number, pageOptions) {
        return new Promise<any>((resolve, reject) => {
            this.gw
                .post(`alert/context/${context}/id/${id}/search`, pageOptions)
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        resolve(res);
                    },
                    error: (err: any) => {
                        reject(err);
                    },
                });
        });
    }

    getFindModule(alertSearch: AlertSearch): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post("alert/module", alertSearch).subscribe({
                next: (res: HttpResponse<any>) => {
                    resolve(res);
                },
                error: (err: any) => {
                    reject(err);
                },
            });
        });
    }

    getPageSettings() {
        if (this.currentPage) {
            return this.pageOptions;
        } else {
            return;
        }
    }
}
