import { NgModule } from '@angular/core';
import { ModuleListService } from './module-list-service';
import { ModuleEditService } from './module-edit-service';

export * from './module-list-service';
export * from './module-edit-service';

export {
  ModuleListService,
  ModuleEditService
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    ModuleListService,
    ModuleEditService
  ]
})
export class ModuleBackendModule { }
