

import { NgModule } from '@angular/core';
import { ApiGatewayModule } from '../../api-gateway-module';
import { DashboardAdminService } from './dashboard-admin.service';


export {
  DashboardAdminService
};

@NgModule({
  declarations: [

  ],
  imports: [
    ApiGatewayModule
  ],
  exports: [

  ],
  providers: [
    DashboardAdminService
  ]
})
export class DashboardAdminModule { }
