import { Agreement, CompanyCostCenter, Insurance, OrderAuthorization, Patient, Performer, Plan, Procedure } from "cw-backend";
import { OrderDiagnosis } from "./order-diagnosis";
import { OrderItem } from "./order-item";
import { OrderPatientDetail } from "./order-patient-detail";
import { Session } from "./session";

export class Order {
    id?: number;
    companyCostCenter: CompanyCostCenter;
    patient: Patient;
    insurance: Insurance;
    parentId: number;
    plan: Plan;
    agreement?: Agreement;
    exameType: any;
    orderSession?: any;
    number?: string;
    date: string;
    hour: string;
    status: string;
    state?: string;
    enrollment?: string;
    value?: number;
    discount?: number;
    mainPerformer: Performer;
    mainProcedure: Procedure;
    mainOrderItem: OrderItem;
    orderItemList: OrderItem[];
    paymentModel: string;
    orderPatientDetail: OrderPatientDetail;
    orderDiagnosisList: OrderDiagnosis[];
    orderAuthorization: OrderAuthorization;
    session: Session;
    quantity: number;
    requeryAutorization: boolean;
    userCreated?: string;
    validatyEnrollment?: string;

}