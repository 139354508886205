import { Company } from './company';
import { Moment } from 'moment';

export class Holiday {
    id: number;
    name: string;
    datestart: string;
    dateend: string;
    dayofmonth: string;
    company: Company;
    timestart: string;
}