import { FuseNavigation } from "@fuse/types";

export const navigation: FuseNavigation[] = [
    {
        id: "operational",
        title: "Operacional",
        type: "collapsable",
        icon: "dashboard",
        children: [
            {
                id: "operational-scheduling",
                title: "Agendamento",
                type: "collapsable",
                icon: "access_time",
                children: [
                    {
                        id: "operational-scheduling-reception",
                        title: "Agenda Recepção",
                        type: "item",
                        url: "/operacional/agendamento/agenda-recepcao",
                    },
                    {
                        id: "operational-scheduling-search_patient",
                        title: "Buscar paciente",
                        type: "item",
                        url: "operacional/agendamento/busca-paciente",
                    },
                    {
                        id: "operational-scheduling-available",
                        title: "Disponibilidade",
                        type: "item",
                        url: "operacional/agendamento/disponibilidade",
                    },

                    {
                        id: "operational-horary-view",
                        title: "Horários",
                        type: "item",
                        url: "operacional/agendamento/horarios-de-medicos",
                    },

                    {
                        id: "operational-scheduling-appointment",
                        title: "Marcação",
                        type: "item",
                        url: "operacional/agendamento/marcacao",
                    },

                    {
                        id: "operational-scheduling-view",
                        title: "Quadro de horários",
                        type: "item",
                        url: "operacional/agendamento/quadro-de-horarios",
                    },

                    {
                        id: "operational-scheduling-surgery_schedule",
                        title: "Agenda cirurgica",
                        type: "item",
                        url: "operacional/agendamento/agenda-cirurgica",
                    },
                ],
            },

            {
                id: "operational-reception",
                title: "Recepção",
                type: "collapsable",
                icon: "receipt_long",
                children: [
                    {
                        id: "operational-reception-os",
                        title: "Guia ( OS )",
                        type: "item",
                        url: "operacional/recepcao/os/",
                    },
                    {
                        id: "operational-reception-batchentry",
                        title: "Lançamento em Lote",
                        type: "item",
                        url: "operacional/recepcao/lancamento-em-lote/",
                    },
                    {
                        id: "operational-reception-authorizations",
                        title: "Autorizações",
                        type: "item",
                        url: "operacional/recepcao/autorizacoes/",
                    },
                    {
                        id: "operational-cahier-os",
                        title: "Caixa ( OS )",
                        type: "item",
                        url: "operacional/recepcao/caixa-os",
                    },
                    {
                        id: "operational-request-internment",
                        title: "Solicitação de Internamento ( TISS )",
                        type: "item",
                        url: "operacional/recepcao/solicitacao/list/I",
                    },
                    {
                        id: "operational-request-internment",
                        title: "Solicitação de Exame ( TISS )",
                        type: "item",
                        url: "operacional/recepcao/solicitacao/list/E",
                    },
                    {
                        id: "operational-reception-budget",
                        title: "Orçamento",
                        type: "item",
                        url: "operacional/recepcao/orcamento",
                    },

                ]
            },

            {
                id: "operational-billing",
                title: "Faturamento",
                type: "collapsable",
                icon: "attach_money",
                children: [
                    {
                        id: "operational-billing-billing",
                        title: "Fatura",
                        type: "item",
                        url: "operacional/faturamento/fatura",
                    },
                    {
                        id: "operational-billing-pay",
                        title: "Baixa",
                        type: "item",
                        url: "operacional/faturamento/baixa",
                    },
                    {
                        id: "operational-billing-transfer",
                        title: "Repasse",
                        type: "item",
                        url: "operacional/faturamento/repasse",
                    },
                    {
                        id: "electronic-billing-xml",
                        title: "Geração de Arquivos",
                        type: "item",
                        url: "operacional/faturamento/faturamento-eletronico",
                    },
                    {
                        id: "operational-billing-appeal",
                        title: "Recurso de Glosa",
                        type: "item",
                        url: "operacional/faturamento/recurso-glosa",
                    },
                    {
                        id: "generator-dmed",
                        title: "Geração de Dmed",
                        type: "item",
                        url: "operacional/faturamento/dmed",
                    },

                ]
            },

            {
                id: "operational-sus",
                title: "Sus",
                type: "collapsable",
                icon: "add_location",
                children: [
                    {
                        id: "operational-sus-sigtap",
                        title: "Integração Sigtap",
                        type: "item",
                        url: "operacional/sus/sigtap",
                    },
                    {
                        id: "operational-sus-report",
                        title: "Laudo Sus",
                        type: "item",
                        url: "operacional/sus/laudo",
                    }               

                ]
            },

            {
                id: "operational-tools",
                title: "Ferramentas",
                type: "collapsable",
                icon: "build_circle",
                children: [

                    {
                        id: "operational-tools-document_model-list",
                        title: "Modelo de Documento",
                        type: "item",
                        url: "operacional/ferramentas/modelo-documento",
                    },
                    {
                        id: "operational-tools-receipt_model-list",
                        title: "Modelo de Receita",
                        type: "item",
                        url: "operacional/ferramentas/receita-modelo/list/R",
                    },
                    {
                        id: "operational-tools-exam_model-list",
                        title: "Modelo de Exame",
                        type: "item",
                        url: "operacional/ferramentas/exame-modelo/list/X",
                    },
                    {
                        id: "operational-tools-def-list",
                        title: "Def",
                        type: "item",
                        url: "operacional/ferramentas/def",
                    }

                ]
            },

            {
                id: "operational-attendance",
                title: "Atendimento",
                type: "collapsable",
                icon: "perm_contact_calendar",
                children: [
                    {
                        id: "operational-medical_record-schedule",
                        title: "Agenda Profissional",
                        type: "item",
                        url: "operacional/atendimento/agenda-profissional",
                    },
                ]
            },



        ],
    },

    {
        id: "register",
        title: "Cadastro",
        type: "collapsable",
        icon: "person",
        children: [
            {
                id: "register-security",
                title: "Segurança",
                type: "collapsable",
                icon: "security",
                children: [
                    {
                        id: "register-security-role-list",
                        title: "Perfil",
                        type: "item",
                        url: "cadastro/seguranca/perfil",
                    },
                    {
                        id: "register-security-user-list",
                        title: "Usuário",
                        type: "item",
                        url: "cadastro/seguranca/usuario",
                    },
                    {
                        id: "register-security-audit",
                        title: "Auditoria",
                        type: "item",
                        url: "cadastro/seguranca/auditoria",
                    },
                ],
            },
            {
                id: "register-organizational",
                title: "Organizacional",
                type: "collapsable",
                icon: "location_city",
                children: [
                    {
                        id: "register-organizational-environment-list",
                        title: "Ambiente de Assistência a Saúde",
                        type: "item",
                        url: "cadastro/organizacional/ambiente-saude",
                    },
                    {
                        id: "register-organizational-professional-list",
                        title: "Profissional",
                        type: "item",
                        url: "cadastro/organizacional/profissional",
                    },
                    {
                        id: "register-organizational-company-list",
                        title: "Empresa",
                        type: "item",
                        url: "cadastro/organizacional/empresa",
                    },
                    {
                        id: "register-organizational-cost_center-list",
                        title: "Centro de custo",
                        type: "item",
                        url: "cadastro/organizacional/centro-de-custo",
                    },
                    {
                        id: "register-organizational-holiday-list",
                        title: "Feriado",
                        type: "item",
                        url: "cadastro/organizacional/feriado",
                    },
                    {
                        id: "register-organizational-contact-list",
                        title: "Contatos",
                        type: "item",
                        url: "cadastro/organizacional/contatos",
                    },
                    {
                        id: "register-organizational-thirdparty-list",
                        title: "Terceiros",
                        type: "item",
                        url: "cadastro/organizacional/terceiros",
                    },
                ],
            },
            {
                id: "register-accreditation",
                title: "Credenciamento",
                type: "collapsable",
                icon: "settings_applications",
                children: [
                    {
                        id: "register-accreditation-insurance-list",
                        title: "Convênio",
                        type: "item",
                        url: "/cadastro/credenciamento/convenio",
                    },
                    {
                        id: "register-accreditation-procedure-list",
                        title: "Procedimento",
                        type: "item",
                        url: "/cadastro/credenciamento/procedimento",
                    },
                    {
                        id: "register-accreditation-speciality-list",
                        title: "Especialidade",
                        type: "item",
                        url: "/cadastro/credenciamento/especialidade",
                    },
                    {
                        id: "register-accreditation-speciality-list",
                        title: "Cotação",
                        type: "item",
                        url: "/cadastro/credenciamento/cotacao",
                    },
                    {
                        id: "register-accreditation-currency-list",
                        title: "Indexador",
                        type: "item",
                        url: "/cadastro/credenciamento/indexador",
                    },
                    {
                        id: "register-accreditation-fee-list",
                        title: "Taxa",
                        type: "item",
                        url: "/cadastro/credenciamento/taxa",
                    },
                    {
                        id: "register-accreditation-M-list",
                        title: "Material",
                        type: "item",
                        url: "/cadastro/credenciamento/material",
                    },
                    {
                        id: "register-accreditation-D-list",
                        title: "Medicamento",
                        type: "item",
                        url: "/cadastro/credenciamento/medicamento",
                    },
                    {
                        id: "register-accreditation-O-list",
                        title: "OPME",
                        type: "item",
                        url: "/cadastro/credenciamento/opme",
                    },
                    {
                        id: "register-accreditation-auxiliary-list",
                        title: "Auxiliar",
                        type: "item",
                        url: "/cadastro/credenciamento/auxiliar",
                    },
                    {
                        id: "register-accreditation-measuretiss-list",
                        title: "Unidade de Medida",
                        type: "item",
                        url: "/cadastro/credenciamento/unidade-de-medida",
                    },
                    {
                        id: "register-accreditation-quota_lack_control",
                        title: "Controle de Cotas",
                        type: "item",
                        url: "/cadastro/credenciamento/controle-cotas",
                    },
                ],
            },
            {
                id: "register-operational",
                title: "Operacional",
                type: "collapsable",
                icon: "settings_applications",
                children: [
                    {
                        id: "register-operational-event-list",
                        title: "Evento",
                        type: "item",
                        url: "cadastro/operacional/evento",
                    },
                    {
                        id: "register-operational-operation-list",
                        title: "Operação",
                        type: "item",
                        url: "cadastro/operacional/operacao",
                    },
                    {
                        id: "register-operational-patient-list",
                        title: "Paciente",
                        type: "item",
                        url: "cadastro/operacional/paciente",
                    },
                    {
                        id: "register-operational-exame_type-list",
                        title: "Tipo de Atendimento",
                        type: "item",
                        url: "cadastro/operacional/tipo-de-atendimento",
                    },

                ],
            },
            // {
            //     id: "register_billing",
            //     title: "Faturamento",
            //     type: "collapsable",
            //     icon: "money",
            //     children: [
            //         {
            //             id: "login",
            //             title: "Login",
            //             type: "item",
            //             url: "/auth/login",
            //         },
            //     ],
            // },
        ],
    },
    {
        id: "tables",
        title: "Tabelas",
        type: "collapsable",
        icon: "archive",
        children: [
            {
                id: "tables-accreditation",
                title: "Credenciamento",
                type: "collapsable",
                icon: "settings_applications",
                children: [
                    {
                        id: "tables-accreditation-standard_table-list",
                        title: "Tabela padrão",
                        type: "item",
                        url: "tabelas/credenciamento/tabela-padrao",
                    },
                    {
                        id: "tables-accreditation-table_type-list",
                        title: "Tipo de tabela",
                        type: "item",
                        url: "tabelas/credenciamento/tipo-de-tabela",
                    },
                    {
                        id: "tables-accreditation-category-list",
                        title: "Categoria",
                        type: "item",
                        url: "tabelas/credenciamento/categoria",
                    },
                    {
                        id: "tables-accreditation-group-list",
                        title: "Grupo",
                        type: "item",
                        url: "tabelas/credenciamento/grupo",
                    },
                    {
                        id: "tables-accreditation-degree_participation-list",
                        title: "Grau de participação",
                        type: "item",
                        url: "tabelas/credenciamento/grau-de-participacao",
                    },
                    {
                        id: "tables-accreditation-plan-list",
                        title: "Plano",
                        type: "item",
                        url: "tabelas/credenciamento/plano",
                    },
                    {
                        id: "tables-accreditation-port-list",
                        title: "Porte",
                        type: "item",
                        url: "tabelas/credenciamento/porte",
                    },
                    {
                        id: "tables-accreditation-gloss-type",
                        title: "Tipo de Glosa",
                        type: "item",
                        url: "tabelas/credenciamento/tipo-glosa",
                    },
                ],
            },
            {
                id: "tables-tiss",
                title: "Tiss",
                type: "collapsable",
                icon: "settings_applications",
                children: [
                    {
                        id: "tables-tiss-type_guide",
                        title: "Tabela de Guia Tiss",
                        type: "item",
                        url: "tabelas/tiss/tipo-guia-tiss",
                    },
                    {
                        id: "tables-tiss-accident_indicator",
                        title: "Indicador de Acidente",
                        type: "item",
                        url: "tabelas/tiss/indicador-de-acidente",
                    },
                    {
                        id: "tables-tiss-via_administration",
                        title: "Via de Administração",
                        type: "item",
                        url: "tabelas/tiss/via-de-administracao",
                    },
                    {
                        id: "tables-tiss-via_access",
                        title: "Via de Acesso",
                        type: "item",
                        url: "tabelas/tiss/via-de-acesso",
                    },
                    {
                        id: "tables-tiss-type_survey",
                        title: "Tipo de Consulta",
                        type: "item",
                        url: "tabelas/tiss/tipo-de-consulta",
                    },
                    {
                        id: "tables-tiss-type_hospitalization",
                        title: "Tipo de Internamento",
                        type: "item",
                        url: "tabelas/tiss/tipo-de-internamento",
                    },
                    {
                        id: "tables-tiss-type_attendance",
                        title: "Tipo de Atendimento Tiss",
                        type: "item",
                        url: "tabelas/tiss/tipo-de-atendimento",
                    },
                    {
                        id: "tables-tiss-service_character",
                        title: "Carater de Atendimento",
                        type: "item",
                        url: "tabelas/tiss/carater-de-atendimento",
                    },
                    {
                        id: "tables-tiss-hospitalization_regime",
                        title: "Regime de Internação",
                        type: "item",
                        url: "tabelas/tiss/regime-de-internacao",
                    },
                    {
                        id: "tables-tiss-closing_reason",
                        title: "Motivo de Encerramento",
                        type: "item",
                        url: "tabelas/tiss/motivo-de-encerramento",
                    },
                    {
                        id: "tables-tiss-accommodation",
                        title: "Tipo de Acomodação",
                        type: "item",
                        url: "tabelas/tiss/acomodacao",
                    },
                ],

            },
            {
                id: "tables-operational",
                title: "Operacional",
                type: "collapsable",
                icon: "settings_applications",
                children: [
                    {
                        id: "tables-operational-alert",
                        title: "Aviso",
                        type: "item",
                        url: "tabelas/operacional/aviso",
                    },
                    {
                        id: "tables-operational-exclusion_motive",
                        title: "Motivo de Exclusão",
                        type: "item",
                        url: "tabelas/operacional/motivo-de-exclusao",
                    },
                    {
                        id: "tables-operational-return_on_marketing",
                        title: "Retorno de Marketing",
                        type: "item",
                        url: "tabelas/operacional/retorno-marketing",
                    },
                ],

            },
            {
                id: "tables-financial",
                title: "Financeiro",
                type: "collapsable",
                icon: "settings_applications",
                children: [
                    {
                        id: "tables-financial-bank",
                        title: "Banco",
                        type: "item",
                        url: "tabelas/financeiro/banco",
                    },
                    {
                        id: "tables-financial-agency",
                        title: "Agência",
                        type: "item",
                        url: "tabelas/financeiro/agencia",
                    },
                    {
                        id: "tables-financial-account",
                        title: "Conta Bancária",
                        type: "item",
                        url: "tabelas/financeiro/conta-bancaria",
                    },
                    {
                        id: "tables-financial-brand",
                        title: "Bandeira de Cartão",
                        type: "item",
                        url: "tabelas/financeiro/bandeira-cartao",
                    },
                    {
                        id: "tables-financial-pay_type",
                        title: "Forma de Pagamento",
                        type: "item",
                        url: "tabelas/financeiro/forma-pagamento",
                    },
                    {
                        id: "tables-financial-account_plan",
                        title: "Plano de Contas",
                        type: "item",
                        url: "tabelas/financeiro/plano-contas",
                    },
                    {
                        id: "tables-financial-company_account_plan",
                        title: "Plano de Contas da Empresa",
                        type: "item",
                        url: "tabelas/financeiro/plano-contas-empresa",
                    },
                ]
            },
            {
                id: "tables-general",
                title: "Gerais",
                type: "collapsable",
                icon: "settings_applications",
                children: [
                    {
                        id: "tables-general-city-list",
                        title: "Cidade",
                        type: "item",
                        url: "tabelas/gerais/cidade",
                    },
                    {
                        id: "tables-general-council-list",
                        title: "Conselho",
                        type: "item",
                        url: "tabelas/gerais/conselho",
                    },
                    {
                        id: "tables-general-schooling-list",
                        title: "Escolaridade",
                        type: "item",
                        url: "tabelas/gerais/escolaridade",
                    },
                    {
                        id: "tables-general-state-list",
                        title: "Estado",
                        type: "item",
                        url: "tabelas/gerais/estado",
                    },
                    {
                        id: "tables-general-civil_status-list",
                        title: "Estado Civil",
                        type: "item",
                        url: "tabelas/gerais/estado-civil",
                    },
                    {
                        id: "tables-general-ethnicity-list",
                        title: "Etnia Indígina",
                        type: "item",
                        url: "tabelas/gerais/etnia-indigena",
                    },
                    {
                        id: "tables-professional-firm-list",
                        title: "Firma",
                        type: "item",
                        url: "tabelas/gerais/firma",
                    },
                    {
                        id: "tables-general-gender-list",
                        title: "Gênero",
                        type: "item",
                        url: "tabelas/gerais/genero",
                    },
                    {
                        id: "tables-general-occupation-list",
                        title: "Ocupação",
                        type: "item",
                        url: "tabelas/gerais/ocupacao",
                    },
                    {
                        id: "tables-general-country-list",
                        title: "País",
                        type: "item",
                        url: "tabelas/gerais/pais",
                    },
                    {
                        id: "tables-general-breed-list",
                        title: "Raça",
                        type: "item",
                        url: "tabelas/gerais/raca",
                    },
                    {
                        id: "tables-general-address_type-list",
                        title: "Tipo de endereço",
                        type: "item",
                        url: "tabelas/gerais/tipo-de-endereco",
                    },
                    {
                        id: "tables-general-registry_type-list",
                        title: "Tipo de inscrição",
                        type: "item",
                        url: "tabelas/gerais/tipo-de-inscricao",
                    },
                    {
                        id: "tables-general-pending",
                        title: "Pendência",
                        type: "item",
                        url: "tabelas/gerais/pendencia",
                    },
                ],
            },
        ],

    },
    // {
    //     id: "scheduling",
    //     title: "Agendamento",
    //     type: "collapsable",
    //     icon: "calendar_today",
    //     children: [
    //         {
    //             id: "login",
    //             title: "Login",
    //             type: "item",
    //             url: "/auth/login",
    //         },
    //     ],
    // },
    // {
    //     id: "attendance",
    //     title: "Atendimento",
    //     type: "collapsable",
    //     icon: "perm_contact_calendar",
    //     children: [
    //         {
    //             id: "login",
    //             title: "Login",
    //             type: "item",
    //             url: "/auth/login",
    //         },
    //     ],
    // },
    // {
    //     id: "medic",
    //     title: "Médico",
    //     type: "collapsable",
    //     icon: "healing",
    //     children: [
    //         {
    //             id: "login",
    //             title: "Login",
    //             type: "item",
    //             url: "/auth/login",
    //         },
    //     ],
    // },
    // {
    //     id: "billing",
    //     title: "Faturamento",
    //     type: "collapsable",
    //     icon: "attach_money",
    //     children: [
    //         {
    //             id: "login",
    //             title: "Login",
    //             type: "item",
    //             url: "/auth/login",
    //         },
    //     ],
    // },
    // {
    //     id: "lowering",
    //     title: "Baixa",
    //     type: "collapsable",
    //     icon: "money",
    //     children: [
    //         {
    //             id: "login",
    //             title: "Login",
    //             type: "item",
    //             url: "/auth/login",
    //         },
    //     ],
    // },
    // {
    //     id: "passThrough",
    //     title: "Repasse",
    //     type: "collapsable",
    //     icon: "account_balance",
    //     children: [
    //         {
    //             id: "login",
    //             title: "Login",
    //             type: "item",
    //             url: "/auth/login",
    //         },
    //     ],
    // },
    {
        id: "application",
        title: "Aplicação",
        type: "collapsable",
        icon: "dashboard",
        children: [
            {
                id: "application-dashboards",
                title: "Dashboards",
                type: "collapsable",
                icon: "dashboard",
                children: [
                    {
                        id: "application-dashboards-admin",
                        title: "Painel Gerencial",
                        type: "item",
                        url: "aplicacao/dashboards/admin",
                    },
                    {
                        id: "application-dashboards-analytics",
                        title: "Painel Análitico",
                        type: "item",
                        url: "aplicacao/dashboards/analitico",
                    },
                ],
            },
            {
                id: "application-configurations",
                title: "Configurações",
                type: "collapsable",
                icon: "settings",
                children: [
                    {
                        id: "application-configurations-parameter",
                        title: "Parâmetros",
                        type: "item",
                        url: "aplicacao/configuracoes/parametros",
                    },
                ],
            },
            {
                id: "pages",
                title: "Aplicativos",
                type: "collapsable",
                icon: "pages",
                children: [
                    {
                        id: "application-chat",
                        title: "Chat",
                        type: "item",
                        icon: "chat",
                        url: "/chat"
                        // badge: {
                        //     title: "13",
                        //     bg: "#09d261",
                        //     fg: "#FFFFFF",
                        // },
                    },
                    {
                        id: "tv-call",
                        title: "Chamada por TV",
                        type: "item",
                        icon: "tv",
                        url: "/chamada-por-tv",
                    },
                    {
                        id: "from-to",
                        title: "De para",
                        type: "item",
                        icon: "sync_alt",
                        url: "/de-para",
                    },
                ]
            },
            {
                id: "application-report",
                title: "Relatórios",
                type: "collapsable",
                icon: "book",
                children: [
                    {
                        id: "application-report-operational",
                        title: "Operacional",
                        type: "item",
                        url: "/aplicacao/relatorio/operacional",
                    },
                    {
                        id: "application-report-schedule",
                        title: "Agenda",
                        type: "item",
                        url: "aplicacao/relatorio/agenda",
                    },
                    {
                        id: "application-report-attendance",
                        title: "Atendimento",
                        type: "item",
                        url: "aplicacao/relatorio/atendimento",
                    },
                    {
                        id: "application-report-patient",
                        title: "Paciente",
                        type: "item",
                        url: "aplicacao/relatorio/paciente",
                    },
                    {
                        id: "application-report-billing",
                        title: "Faturamento",
                        type: "item",
                        url: "aplicacao/relatorio/faturamento",
                    },
                    {
                        id: "application-report-finance",
                        title: "Financeiro",
                        type: "item",
                        url: "aplicacao/relatorio/financeiro",
                    },
                    {
                        id: "application-report-inventory",
                        title: "Estoque",
                        type: "item",
                        url: "aplicacao/relatorio/estoque",
                    },
                    {
                        id: "application-report-boxclosing",
                        title: "Fechamento de Caixa",
                        type: "item",
                        url: "aplicacao/relatorio/caixa",
                    },
                    {
                        id: "application-report-boxclosing",
                        title: "Repasse",
                        type: "item",
                        url: "aplicacao/relatorio/repasse",
                    },
                    {
                        id: "application-report-boxclosing",
                        title: "Auditoria",
                        type: "item",
                        url: "aplicacao/relatorio/auditoria",
                    },
                    {
                        id: "application-report-boxclosing",
                        title: "Outros",
                        type: "item",
                        url: "aplicacao/relatorio/outros",
                    },

                ],

            }
            // {
            //     id: "application-calendar",
            //     title: "Calendário",
            //     type: "item",
            //     icon: "today",
            //     url: "/calendar",
            // },
            // {
            //     id: "application-chat",
            //     title: "Chat",
            //     type: "item",
            //     icon: "chat",
            //     url: "/chat",
            //     badge: {
            //         title: "13",
            //         bg: "#09d261",
            //         fg: "#FFFFFF",
            //     },
            // },
            // {
            //     id: "application-contacts",
            //     title: "Usuários ",
            //     type: "item",
            //     icon: "account_box",
            //     url: "/contacts",
            // },
        ],
    },
    // {
    //     id: "pages",
    //     title: "Outros",
    //     type: "group",
    //     icon: "pages",
    //     children: [
    //         {
    //             id: "application-chat",
    //             title: "Chat",
    //             type: "item",
    //             icon: "chat",
    //             url: "/chat",
    //             badge: {
    //                 title: "13",
    //                 bg: "#09d261",
    //                 fg: "#FFFFFF",
    //             },
    //         },
    // {
    //     id: "authentication",
    //     title: "Authentication",
    //     type: "collapsable",
    //     icon: "lock",
    //     children: [
    //         {
    //             id: "login",
    //             title: "Login",
    //             type: "item",
    //             url: "/auth/login",
    //         },
    //     ],
    // },
    // ],
    // },

    // {
    //     id: "attendance",
    //     title: "Atendimento",
    //     type: "collapsable",
    //     icon: "person",
    //     children: [
    //         {
    //             id: "os",
    //             title: "Guia de Atendimento",
    //             type: "item",
    //             icon: "person",
    //             url: "atendimento/os",

    //         },

    //     ],
    // },
];
