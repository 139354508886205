import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { CwUtils } from 'cw-utils';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiGateway } from '../../../api-gateway';
import { City } from '../../../models/tables/address/city';
import { PageOptions } from '../../../util/backend-util-module';


@Injectable()
export class CityListProvider implements Resolve<any>
{
    city: City[];
    cityEdit: City;
    onDataChanged: BehaviorSubject<any>;
    routeParams: any;
    state: any;
    pageOptions: PageOptions;
    currentPage: PageOptions;

    constructor(
        public gw: ApiGateway,
        private location: Location,
    ) {
        this.onDataChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        // Get state for current or new page
        this.state = this.location.getState();
        if (this.state.currentPage && state.url.includes(this.state.path)) {
            this.currentPage = this.state.currentPage;
        }
        this.cityEdit = this.state.currentCity;

        if (this.currentPage) {
            this.pageOptions = this.currentPage;
        }
        else {
            this.pageOptions = new PageOptions();
            this.pageOptions.fieldSort = 'name';
        }
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this.getPage(this.pageOptions)
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    get(id: number): Observable<City> {
        if (!id) {
            return null;
        }
        return this.gw.get('city/:id', { id: id });
    }

    get currentCity(): City {
        return this.cityEdit;
    }

    getAll(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('city/').subscribe((res: HttpResponse<any>) => {
                this.city = res.body;
                this.onDataChanged.next(this.city);
                resolve(res);
            }, reject);
        });

    }

    getPageByEntity(pageOptions, entity?: String): Promise<any> {
        let urlPath = 'city/search';
        if (entity) {
            urlPath = entity + urlPath;
        }
        return new Promise((resolve, reject) => {
            this.gw.post(urlPath, pageOptions).subscribe((res: HttpResponse<any>) => {
                if (!CwUtils.compare(this.city, res.body.content) && res.body.content.length > 0) {
                    this.city = res.body.content;
                    this.onDataChanged.next(this.city);
                } else {
                    this.city = res.body.content;
                }
                resolve(res);
            }, reject);
        });
    }

    getSimplePage(pageOptions, entity?: String): Promise<any> {
        let urlPath = 'city/simple/search';
        if (entity) {
            urlPath = entity + urlPath;
        }
        return new Promise((resolve, reject) => {
            this.gw.post(urlPath, pageOptions).subscribe((res: HttpResponse<any>) => {
                this.city = res.body.content;
                resolve(res);
            }, reject);
        });
    }


    getPage(pageOptionsObj?, matPaginator?: MatPaginator, matSort?: MatSort, filter?: string): Promise<any> {
        let pageOptions
        if (pageOptionsObj) {
            pageOptions = pageOptionsObj;
        } else {
            pageOptions = CwUtils.getPageOptions(matPaginator, matSort, filter);
        }
        return new Promise((resolve, reject) => {
            this.gw.post('city/search', pageOptions).subscribe((res: HttpResponse<any>) => {
                if (!CwUtils.compare(this.city, res.body.content) && res.body.content.length > 0) {
                    this.city = res.body.content;
                    this.onDataChanged.next(this.city);
                } else {
                    this.city = res.body.content;
                }
                resolve(res);
            }, reject);
        });
    }

    getPageSettings() {
        if (this.currentPage) {
            return this.pageOptions;
        } else {
            return;
        }
    }
}

