import { Professional } from './professional';
import { Performer } from './performer';
import { Insurance } from '../../accreditation/insurance';
import { Group } from '../../tables/accreditation/group';
import { Procedure } from '../../accreditation/procedure';
import { Company } from './company';
import { Speciality } from '../../tables/accreditation/speciality';
import { Fee } from '../../accreditation/fee';
import { Product } from '../../accreditation/product';

export class Participation {
    id: number;
    professional: Professional;
    performer: Performer;
    insurance: Insurance;
    group: Group;
    procedure: Procedure;
    company: Company;
    speciality: Speciality;
    day: number;
    type: string;
    value: number;
    plantonist: boolean;
    useDefault: boolean;
    participationSpecList: ParticipationSpec[];
}

export class ParticipationSpec {
    id: number;
    participation: Participation;
    fee: Fee;
    product: Product;
    element: string;
    type: string;
    value: number;
}

export class ParticipationElement {
    id: number;
    participation: Participation;
    element: string;
    type: string;
    value: number;
}