
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiGateway } from '../../../api-gateway';
import { SnackBarService } from '../../../../cw-utils/snackbar.service';
import { AddressType } from '../../../models/backend-definitions.module';

@Injectable()
export class AddressTypeEditService implements Resolve<any>
{
    routeParams: any;
    state: any;
    onDataChanged: BehaviorSubject<any>;
    addressType: AddressType;

    constructor(
        public gw: ApiGateway,
        public snackBar: SnackBarService
    ) {
        this.onDataChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        return new Promise<void>((resolve, reject) => {
            Promise.all([this.getAddressType()]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getAddressType(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this.routeParams.id === 'novo') {
                this.onDataChanged.next(false);
                resolve(false);
            }
            else {
                this.gw.get('addressType/' + this.routeParams.id).subscribe((res: AddressType ) => {
                    this.addressType = res;
                    this.onDataChanged.next(this.addressType);
                    resolve(res);
                }, reject);
            }
        });
    }

    insert(addressType): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('addressType/', addressType).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }

    edit(addressType): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.put('addressType/', addressType).subscribe((res: HttpResponse<any>) => {
                resolve(res);
                this.snackBar.successMessage(res.body.message);
            }, reject);
        });
    }

    delete(addressType): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.delete('addressType/' + addressType.id).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                 this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }
}
