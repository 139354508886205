
import { NgModule } from '@angular/core';

import { ViaAdministrationEditProvider } from './via-administration-edit.provider';
import { ViaAdministrationListProvider } from './via-administration-list.provider';

export * from './via-administration-edit.provider';
export * from './via-administration-list.provider';

export {
  ViaAdministrationListProvider,
  ViaAdministrationEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    ViaAdministrationEditProvider,
    ViaAdministrationListProvider
  ]
})
export class ViaAdministrationBackendModule { }
