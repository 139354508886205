import { ContactList } from '../../others/contact-list';
import { Address } from '../address/address';
import { Bank } from './bank';

export class Agency {
    name: string;
    id: number;
    code: string;
    manager: string;
    address: Address;
    contactList: ContactList;
    bank: Bank;
}