
import { NgModule } from '@angular/core';

import { ParticipationEditProvider } from './participation-edit.provider';
import { ParticipationListProvider } from './participation-list.provider';

export * from './participation-edit.provider';
export * from './participation-list.provider';

export {
  ParticipationListProvider,
  ParticipationEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    ParticipationEditProvider,
    ParticipationListProvider
  ]
})
export class ParticipationBackendModule { }
