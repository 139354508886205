<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="header accent-700 p-24">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <div class="contact-name mt-8">Informações da Guia</div>
            <button mat-icon-button (click)="dimiss()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>


    <div mat-dialog-content class="p-12 pb-0 m-0">
        <order-tiss [order]="order"></order-tiss>
    </div>
</div>