
import { NgModule } from '@angular/core';

import { OrderPayDetailEditProvider } from './order-pay-detail-edit.provider';
import { OrderPayDetailListProvider } from './order-pay-detail-list.provider';

export * from './order-pay-detail-edit.provider';
export * from './order-pay-detail-list.provider';

export {
  OrderPayDetailListProvider,
  OrderPayDetailEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    OrderPayDetailEditProvider,
    OrderPayDetailListProvider
  ]
})
export class OrderPayDetailBackendModule { }
