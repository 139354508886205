
import { NgModule } from '@angular/core';

import { CityEditProvider } from './city-edit.provider';
import { CityListProvider } from './city-list.provider';

export * from './city-edit.provider';
export * from './city-list.provider';

export {
  CityListProvider,
  CityEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    CityEditProvider,
    CityListProvider
  ]
})
export class CityBackendModule { }
