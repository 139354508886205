
import { NgModule } from '@angular/core';

import { ProfessionalEditProvider } from './professional-edit.provider';
import { ProfessionalListProvider } from './professional-list.provider';

export * from './professional-edit.provider';
export * from './professional-list.provider';

export {
  ProfessionalListProvider,
  ProfessionalEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    ProfessionalEditProvider,
    ProfessionalListProvider
  ]
})
export class ProfessionalBackendModule { }
