import { Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from "@angular/router";
import { Location } from "@angular/common";
import { HttpResponse } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { Billing, PageOptions, ApiGateway } from "cw-backend";
import { CwUtils } from "cw-utils";
import { Filter } from "../../../models/operational/filter/filter";

@Injectable()
export class FilterListProvider {
    billing: Filter[];
    billingEdit: Filter;
    onDataChanged: BehaviorSubject<any>;
    routeParams: any;
    state: any;
    pageOptions: PageOptions;
    currentPage: PageOptions;

    constructor(public gw: ApiGateway, private location: Location) {
        this.onDataChanged = new BehaviorSubject({});
    }

    getAll(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get("filter").subscribe({
                next: (res: HttpResponse<any>) => {
                    resolve(res);
                },
                error: reject,
            });
        });
    }

    getByScope(scope:string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get("filter/scope/:scope", {scope:scope}).subscribe({
                next: (res: HttpResponse<any>) => {
                    resolve(res);
                },
                error: reject,
            });
        });
    }
}
