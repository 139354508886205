import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "maskNamePipe",
})
export class MaskNamePipe implements PipeTransform {
    transform(value: string, mask: string): string {
        console.log("🚀 ~ MaskNamePipe ~ transform ~ value:", value);
        if (!value) return value;

        if(mask == '0'){
            return value;
        }

        const names = value.split(" ");
        return names
            .map((name, index) => {
                if (index === 0) {
                    return name;
                }
                const visibleChars = Math.ceil(name.length / 3);
                return (
                    name.substring(0, visibleChars) +
                    "*".repeat(name.length - visibleChars)
                );
            })
            .join(" ");
    }
}
