import { Insurance } from './insurance';
import { Currency } from './currency';
import { Group } from 'cw-backend';
import { Procedure } from './procedure';
import { Moment } from 'moment';

export class Quotation {
    id: number;
    value: number;
    startat: Moment;
    endat: Moment;
    currency: Currency;
    group: Group;
    insurance: Insurance;
    procedure: Procedure;
}