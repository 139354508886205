import { Invoice } from "app/layout/components/invoice/invoice.model";
import { Eartefact } from "../../others/eartefact";
import { Billing, BillingPayOrderItem } from "../billing/billing";
import { Insurance } from "../../backend-definitions.module";

export class Pay {

    id: number;
    eartefact: Eartefact;
    dateAt: Date;
    closed: boolean;
    value: number;
    creditDate: Date;
    creditValue: number;
    billingPayList: BillingPay[];
    payInvoiceList: PayInvoice[];
    amountCharged: boolean;
    insuranceName?: Insurance;
}

export class BillingPay {
    id: number;
    pay: Pay;
    billing: Billing;
    billingPayOrderItemList: BillingPayOrderItem[];
}
export class PayInvoice {
    id: number;
    pay: Pay;
    invoice: Invoice;
}

export class PayInfoGloss {
    acceptedGloss: number = 0;
    appealableGloss: number = 0;
    accreditationValue: number = 0;
}

export class Payxml{
     id:number
     payId:number
     orderId:number
     protocol:string
     numberGuideProvider:string
     degreeParticipation:number
     code:string
     name:string
     patient:string
     typeGloss:number
     quantity:number
     status:number
     valueGloss:number
     valueProcess:number
     valueReleased:number
     valueReported:number


}
