
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { Atribute, ApiGateway } from 'cw-backend';
import { SnackBarService } from 'cw-utils';

@Injectable()
export class AtributeEditProvider implements Resolve<any>
{
    routeParams: any;
    state: any;
    onDataChanged: BehaviorSubject<any>;
    atribute: Atribute;

    constructor(
        public gw: ApiGateway,
        public snackBar: SnackBarService
    ) {
        this.onDataChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;
        let url = state.url.split('/')[3];
        let context = '';
        if (url === 'retorno-marketing') {
            context = 'return_on_marketing'
        }
        return new Promise((resolve, reject) => {
            Promise.all([this.getAtribute(context)]).then(
                () => {
                    resolve(true);
                },
                reject
            );
        });
    }

    getAtribute(context): Promise<any> {
        return new Promise((resolve, reject) => {
            let data = this.checkLastModifications()

            if (data && data.id == this.routeParams.id) {
                this.atribute = data;
                this.onDataChanged.next(this.atribute);
                resolve(this.atribute);
            } else if (this.routeParams.id === 'novo') {
                this.onDataChanged.next(false);
                resolve(false);
            }
            else {
                this.gw.get(`atribute/${this.routeParams.id}/${context}`).subscribe({
                    next: (res: Atribute) => {
                        this.atribute = res;
                        this.onDataChanged.next(this.atribute);
                        resolve(res);
                    }, error: (err: any) => { reject(err) }
                });
            }
        });
    }

    insert(atribute, context): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post(`atribute/${context}`, atribute).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                }, error: (err: any) => { reject(err) }
            });
        });
    }

    edit(atribute, context): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post(`atribute/${context}`, atribute).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                }, error: (err: any) => { reject(err) }
            });
        });
    }

    delete(atribute, context): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.delete(`atribute/${context}/` + atribute.id).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                }, error: (err: any) => { reject(err) }
            });
        });
    }

    checkLastModifications(): Atribute {
        let wasLocked = JSON.parse(localStorage.getItem('session_locked'));
        let data;
        if (wasLocked) {
            data = JSON.parse(localStorage.getItem('last_modification'));
            localStorage.removeItem('session_locked');
        }
        return data;
    }
}
