// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// let paramterIP: any = JSON.parse(localStorage.getItem('system_params'));
// let ipDocumentSever = paramterIP.find(paramter => paramter.name === 'IP_DOCUMENT_SERVER');

// let paramterPORT: any = JSON.parse(localStorage.getItem('system_params'));
// let portDocumentSever = paramterPORT.find(paramter => paramter.name === 'PORT_DOCUMENT_SERVER');

export const environment = {
    production: false,
    hmr: false,
    baseUrl: 'https://clinicall-backend-hml-rcbqj2mecq-rj.a.run.app/',
	baseUrlWs: "wss://clinicall-backend-hml-rcbqj2mecq-rj.a.run.app/",
    
    urlOnlyoffice: 'https://clinicall-backend-hml-rcbqj2mecq-rj.a.run.app/',
   
    apiKey: 'iJGiB4kjDGOLeDFPWMG3no9VnN7Abpqe3w1jEFm6olkhkZD6oSfSmYCm',
    secretKey: 'Xe8M5GvBGCr4FStKfxXKisRo3SfYKI7KrTMkJpCAstzu2yXVN4av5nmL',
    urlMemed: 'http://sandbox.api.memed.com.br/v1/',
    scriptSrc: 'http://sandbox.memed.com.br/modulos/plataforma.sinapse-prescricao/build/sinapse-prescricao.min.js'

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
