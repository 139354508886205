
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { ApiGateway } from 'cw-backend';
import { SnackBarService } from 'cw-utils';
import { reject } from 'lodash';
import { promise } from 'protractor';
import { BehaviorSubject, Observable } from 'rxjs';
import { Pay } from '../../../models/operational/pay/pay';
import { Environment } from 'app/decorators/environment.decorator';

@Injectable()
export class PayEditProvider implements Resolve<any>
{
    @Environment('baseUrl') baseUrl: string;
    routeParams: any;
    state: any;
    onDataChanged: BehaviorSubject<any>;
    pay: Pay;

    constructor(
        public gw: ApiGateway,
        public snackBar: SnackBarService,
        private _http: HttpClient
    ) {
        this.onDataChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        return new Promise((resolve, reject) => {
            Promise.all([this.getBillingPay()]).then(
                (res) => {
                    resolve(res);
                },
                reject
            );
        });
    }

    getBillingPay(): Promise<any> {
        return new Promise((resolve, reject) => {
            let data = this.checkLastModifications()
            if (data && data.id == this.routeParams.id) {
                this.pay = data;
                this.onDataChanged.next(this.pay);
                resolve(this.pay);
            } else if (this.routeParams.id === 'novo') {
                this.onDataChanged.next(false);
                resolve(false);
            }
            else {
                this.gw.get('pay/' + this.routeParams.id)
                    .subscribe({
                        next: (res: Pay) => {
                            this.pay = res;
                            this.onDataChanged.next(this.pay);
                            resolve(res);
                        }, error: (err: any) => { reject(err) }
                    });
            }
        });
    }

    insert(pay): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('pay/', pay)
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                        this.snackBar.successMessage(response.body.message);
                    }, error: (err: any) => { reject(err) }
                });
        });
    }
    insertWithXml(pay, file: File): Promise<any> {
        return new Promise((resolve, reject) => {
            const endpoint = this.baseUrl + 'pay/automatic';
            const formData: FormData = new FormData();
            formData.append('file', file, file.name);

            formData.append('pay', JSON.stringify(pay));

         
            let authToken = localStorage.getItem('token');
            let headers = new HttpHeaders();
            headers = headers.append('X-Auth-Token', authToken);

            this._http.post(endpoint, formData, { headers: headers }).subscribe({
                next: (response: any) => {
                    resolve(response);
                    this.snackBar.successMessage(response.message);
                }, error: (err: any) => { reject(err) }
            });
        });
    }

    edit(pay): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('pay/', pay)
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                        this.snackBar.successMessage(response.body.message);
                    }, error: (err: any) => { reject(err) }
                });
        });
    }

    insertGloss(gloss): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('pay/gloss', gloss)
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                        this.snackBar.successMessage('Informações de glosa salva');
                    }, error: (err: any) => { reject(err) }
                });
        });

    }
    recoveryBillingPayorderItem(payId, billingOrderItemList): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('pay/recovery/billing-order-item/pay/:payId', { payId: payId }, billingOrderItemList)
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                        this.snackBar.successMessage('Guia recuperada');
                    }, error: (err: any) => { reject(err) }
                });
        });

    }

    delete(pay): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.delete('pay/' + pay.id)
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                        this.snackBar.successMessage(response.body.message);
                    }, error: (err: any) => { reject(err) }
                });
        });
    }

    deleteGloss(glossId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.delete('pay/gloss/:glossId', { glossId: glossId })
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                        this.snackBar.successMessage(response.body.message);
                    }, error: (err: any) => { reject(err) }
                });
        });
    }



    closePay(payId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.put('pay/close/:payId', { payId: payId }).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);
                }, error: (error: HttpResponse<any>) => { reject(error) }
            });
        });
    }

    checkPayGloss(glossId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('pay/check-pay/gloss/:glossId', { glossId: glossId }).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);
                }, error: (error: HttpResponse<any>) => { reject(error) }
            });
        });
    }

    checkLastModifications(): Pay {
        let wasLocked = JSON.parse(localStorage.getItem('session_locked'));
        let data;
        if (wasLocked) {
            data = JSON.parse(localStorage.getItem('last_modification'));
            localStorage.removeItem('session_locked');
        }
        return data;
    }

    getLastModification(): Pay {
        let data;
        data = JSON.parse(localStorage.getItem('last_modification'));
        return data;
    }

    savePayInvoice(payInvoice): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('pay/invoice', payInvoice).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);

                }, error: (error: HttpResponse<any>) => { error }
            });
        });
    }

    deletePayInvoice(payInvoice): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.delete('pay/invoice', payInvoice).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);

                }, error: (error: HttpResponse<any>) => { error }
            });
        });
    }

    deleteBillingPayOrderItem(id): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.delete('pay/billing-pay-order-item/:id', { id: id }).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);

                }, error: (error: HttpResponse<any>) => { error }
            });
        });
    }

    calcImpostos(payId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('pay/invoice/impostos/:payId', { payId: payId }).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);

                }, error: (error: HttpResponse<any>) => { reject(error) }
            });
        });
    }

    updateValuePaid(billingPayOrderItemId: number, valuePaid: number) {
        return new Promise<any>((resolve, reject) => {
            this.gw.post('pay/billing-pay-order-item/:billingPayOrderItemId/value-paid', { billingPayOrderItemId: billingPayOrderItemId }, valuePaid).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);

                }, error: (error: HttpResponse<any>) => { reject(error) }
            });
        });
    }
}
