
import { NgModule } from '@angular/core';

import { EthnicityEditProvider } from './ethnicity-edit.provider';
import { EthnicityListProvider } from './ethnicity-list.provider';

export * from './ethnicity-edit.provider';
export * from './ethnicity-list.provider';

export {
  EthnicityListProvider,
  EthnicityEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    EthnicityEditProvider,
    EthnicityListProvider
  ]
})
export class EthnicityBackendModule { }
