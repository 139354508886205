
import { NgModule } from '@angular/core';

import { MeasureTissEditProvider } from './measuretiss-edit.provider';
import { MeasureTissListProvider } from './measuretiss-list.provider';

export * from './measuretiss-edit.provider';
export * from './measuretiss-list.provider';

export {
  MeasureTissListProvider,
  MeasureTissEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    MeasureTissEditProvider,
    MeasureTissListProvider
  ]
})
export class MeasureTissBackendModule { }
