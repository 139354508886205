
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { Contacts, ApiGateway } from 'cw-backend';
import { SnackBarService } from 'cw-utils';

@Injectable()
export class ContactsEditProvider implements Resolve<any>
{
    routeParams: any;
    state: any;
    onDataChanged: BehaviorSubject<any>;
    contacts: Contacts;

    constructor(
        public gw: ApiGateway,
        public snackBar: SnackBarService
    ) {
        this.onDataChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        return new Promise((resolve, reject) => {
            Promise.all([this.getContacts()]).then(
                () => {
                    resolve(true);
                },
                reject
            );
        });
    }

    getContacts(): Promise<any> {
        return new Promise((resolve, reject) => {
            let data = this.checkLastModifications()
            if (data && data.id == this.routeParams.id) {
                this.contacts = data;
                this.onDataChanged.next(this.contacts);
                resolve(this.contacts);
            } else if (this.routeParams.id === 'novo') {
                this.onDataChanged.next(false);
                resolve(false);
            }
            else {
                this.gw.get('contacts/' + this.routeParams.id).subscribe({
                    next: (res: Contacts) => {
                        this.contacts = res;
                        this.onDataChanged.next(this.contacts);
                        resolve(res);
                    }, error: (err: any) => { reject(err) }
                });
            }
        });
    }

    insert(contacts): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('contacts/', contacts).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                }, error: (err: any) => { reject(err) }
            });
        });
    }

    edit(contacts): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('contacts/', contacts).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                }, error: (err: any) => { reject(err) }
            });
        });
    }

    delete(contacts): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.delete('contacts/' + contacts.id).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                }, error: (err: any) => { reject(err) }
            });
        });
    }

    checkLastModifications(): Contacts {
        let wasLocked = JSON.parse(localStorage.getItem('session_locked'));
        let data;
        if (wasLocked) {
            data = JSON.parse(localStorage.getItem('last_modification'));
            localStorage.removeItem('session_locked');
        }
        return data;
    }


    uploadImage(currentFile: File, id: any) {
        return new Promise<any>((resolve, reject) => {
            resolve(true);
        });

    }
}
