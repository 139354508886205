<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="accent-600 m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <div class="contact-name mt-8">Últimas tentativas de login</div>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-12 pb-0 m-0" >

        <mat-table [dataSource]="lastAccessList" [@animateStagger]="{value:'50'}" #table
            class="data-table mat-elevation-z8" fusePerfectScrollbar>

            <!-- Access Date Column -->
            <ng-container matColumnDef="log_accessDate">
                <mat-header-cell *matHeaderCellDef> Data</mat-header-cell>
                <mat-cell *matCellDef="let log"> {{getAccessDate(log.accessDate) | amCalendar}} </mat-cell>
            </ng-container>

            <!-- Access Type Column -->
            <!-- <ng-container matColumnDef="log_accessType">
                <mat-header-cell *matHeaderCellDef> Tipo</mat-header-cell>
                <mat-cell *matCellDef="let log"> {{log.accessType}} </mat-cell>
            </ng-container> -->

            <!-- Event Code Column -->
            <ng-container matColumnDef="log_eventCode">
                <mat-header-cell *matHeaderCellDef> Status</mat-header-cell>
                <mat-cell *matCellDef="let log">
                    <div *ngFor="let eventCode of eventCodeList">
                        <p *ngIf="log.eventCode === eventCode.id" >
                            {{eventCode.description}}</p>
                    </div>
                </mat-cell>
            </ng-container>

            <!-- Ip Address Column -->
            <ng-container matColumnDef="log_ipAddress">
                <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-sm> Endereço IP</mat-header-cell>
                <mat-cell *matCellDef="let log" fxHide fxShow.gt-sm> {{log.ipAddress}} </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="['log_accessDate', 'log_eventCode', 'log_ipAddress']; sticky:true">
            </mat-header-row>
            <mat-row mat-row *matRowDef="let log; columns: ['log_accessDate', 'log_eventCode', 'log_ipAddress'];"
                [ngClass]="{'accent-50' : log.eventCode === '0'}">
            </mat-row>
        </mat-table>

    </div>

    <div mat-dialog-actions class=" m-0 p-16" fxLayout="row" fxLayoutAlign="end center">
        <button mat-button (click)="dimiss()">
            OK
        </button>
    </div>
</div>