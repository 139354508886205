import { State } from '../address/state';
import { Moment } from 'moment';

export class PersonIdentity {
    id: number;
    number: number;
    state: State;
    adjunct: string;
    emitter: string;
    dated: Moment;
}
