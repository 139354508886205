
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiGateway, Person } from 'cw-backend';
import { SnackBarService } from 'cw-utils';

@Injectable()
export class PersonEditProvider implements Resolve<any>
{
    routeParams: any;
    state: any;
    onDataChanged: BehaviorSubject<any>;
    person: Person;

    constructor(
        public gw: ApiGateway,
        public snackBar: SnackBarService
    ) {
        this.onDataChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        return new Promise<void>((resolve, reject) => {
            Promise.all([this.getPerson()]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getPerson(): Promise<any> {
        return new Promise((resolve, reject) => {
            let data = this.checkLastModifications()
            if (data && data.id == this.routeParams.id) {
                this.person = data;
                this.onDataChanged.next(this.person);
                resolve(this.person);
            } else if (this.routeParams.id === 'novo') {
                this.onDataChanged.next(false);
                resolve(false);
            }
            else {
                this.gw.get('person/' + this.routeParams.id).subscribe((res: Person) => {
                    this.person = res;
                    this.onDataChanged.next(this.person);
                    resolve(res);
                }, reject);
            }
        });
    }

    insert(person): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('person/', person).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }

    edit(person): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.put('person/', person).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }

    delete(person): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.delete('person/' + person.id).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                //this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    };

    deletePhoto(imageId, entity?): Promise<any> {
        let path = 'image/';
        if (entity) {
            path = entity + path;
        }
        return new Promise((resolve, reject) => {
            this.gw.delete(path + imageId ).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }


    checkLastModifications(): Person {
        let wasLocked = JSON.parse(localStorage.getItem('session_locked'));
        let data;
        if (wasLocked) {
            data = JSON.parse(localStorage.getItem('last_modification'));
            localStorage.removeItem('session_locked');
        }
        return data;
    }
}
