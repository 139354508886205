
import { NgModule } from '@angular/core';

import { AtributeEditProvider } from './atribute-edit.provider';
import { AtributeListProvider } from './atribute-list.provider';

export * from './atribute-edit.provider';
export * from './atribute-list.provider';

export {
  AtributeListProvider,
  AtributeEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    AtributeEditProvider,
    AtributeListProvider
  ]
})
export class AtributeBackendModule { }
