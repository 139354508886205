import { NgModule } from '@angular/core';
import { PersonService } from './person.service';

export { PersonService };

@NgModule({
  declarations: [

  ],
  imports: [

  ],
  exports: [

  ],
  providers: [
    PersonService,
  ]
})
export class PersonModule { }
