import { ImageObj } from '../../interface/image-obj';
import { State } from '../address/state';
import { Moment } from 'moment';

export class PersonWorkCard {
    id: number;
    state: State;
    number: number;
    sequence: number;
    dated: Moment;
}

