
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiGateway } from 'cw-backend';

@Injectable()
export class UtilProvider  
{
    simpleList: any[];
    onDataChanged: BehaviorSubject<any>;

    constructor(
        public gw: ApiGateway,
    ) {
        // Set the defaults
        this.onDataChanged = new BehaviorSubject({});

    }

    getSimpleList(path): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('util/' + path + '/simpleList').subscribe((res: HttpResponse<any>) => {
                this.simpleList = res.body;
                this.onDataChanged.next(this.simpleList);
                resolve(res);
            }, reject);
        });

    }
}

