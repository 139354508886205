import { Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from "@angular/router";
import { Location } from "@angular/common";
import { HttpResponse } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { Plan, PageOptions, ApiGateway, Insurance } from "cw-backend";
import { CwUtils } from "cw-utils";

@Injectable()
export class PlanListProvider implements Resolve<any> {
    plan: Plan[];
    planEdit: Plan;
    insuranceList: Insurance[];
    onDataChanged: BehaviorSubject<any>;
    onInsuranceListChanged: BehaviorSubject<any>;
    routeParams: any;
    state: any;
    pageOptions: PageOptions;
    currentPage: PageOptions;

    constructor(public gw: ApiGateway, private location: Location) {
        this.onDataChanged = new BehaviorSubject({});
        this.onInsuranceListChanged = new BehaviorSubject({});
    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        // Get state for current or new page
        this.state = this.location.getState();
        if (this.state.currentPage && state.url.includes(this.state.path)) {
            this.currentPage = this.state.currentPage;
        }
        this.planEdit = this.state.currentPlan;

        if (this.currentPage) {
            this.pageOptions = this.currentPage;
            let plan = new Plan();
            if (
                !Object.getOwnPropertyNames(plan).includes(
                    this.pageOptions.fieldSort
                )
            ) {
                this.pageOptions.fieldSort = "name";
            }
        } else {
            this.pageOptions = new PageOptions();
            this.pageOptions.fieldSort = "name";
        }
        return new Promise<void>((resolve, reject) => {
            Promise.all([this.getPage(this.pageOptions)]).then(() => {
                resolve();
            }, reject);
        });
    }

    get(id: number): Observable<Plan> {
        if (!id) {
            return null;
        }
        return this.gw.get("plan/:id", { id: id });
    }

    get currentPlan(): Plan {
        return this.planEdit;
    }

    getAll(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get("plan/").subscribe((res: HttpResponse<any>) => {
                this.plan = res.body;
                resolve(res);
            }, reject);
        });
    }

    getPlanSimpleList(insuranceId, pageSearch): Promise<any> {
        
        return new Promise((resolve, reject) => {
            this.gw
                .post(
                    "patient/plan/simpleList/insurance/" + insuranceId,
                    pageSearch
                )
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        this.plan = res.body;
                        resolve(res);
                    },
                    error: (err) => {
                        reject(err);
                    },
                });
        });
    }

    getPage(pageOptions: PageOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post("plan/search", pageOptions)
                .subscribe((res: HttpResponse<any>) => {
                    if (
                        !CwUtils.compare(this.plan, res.body.content) &&
                        res.body.content.length > 0
                    ) {
                        this.plan = res.body.content;
                        this.onDataChanged.next(this.plan);
                    } else {
                        this.plan = res.body.content;
                    }
                    resolve(res);
                }, reject);
        });
    }

    getPlanListByInsuranceId(
        insuranceId: number,
        pageOptions: PageOptions,
        refresh?: boolean
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post("plan/search/available/" + insuranceId, pageOptions)
                .subscribe((res: HttpResponse<any>) => {
                    if (
                        (!CwUtils.compare(
                            this.insuranceList,
                            res.body.content
                        ) &&
                            res.body.content.length > 0) ||
                        refresh
                    ) {
                        this.insuranceList = res.body.content;
                        this.onDataChanged.next(this.insuranceList);
                    } else {
                        this.insuranceList = res.body.content;
                    }
                    resolve(res);
                }, reject);
        });
    }

    getInsuranceList(
        planId: number,
        pageOptions: PageOptions,
        refresh?: boolean
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post(
                    "insurance/plan/" + planId + "/insuranceList",
                    pageOptions
                )
                .subscribe((res: HttpResponse<any>) => {
                    if (
                        (!CwUtils.compare(
                            this.insuranceList,
                            res.body.content
                        ) &&
                            res.body.content.length > 0) ||
                        refresh
                    ) {
                        this.insuranceList = res.body.content;
                        this.onInsuranceListChanged.next(this.insuranceList);
                    } else {
                        this.insuranceList = res.body.content;
                    }
                    resolve(res);
                }, reject);
        });
    }

    getPageSettings() {
        if (this.currentPage) {
            return this.pageOptions;
        } else {
            return;
        }
    }
}
