import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { MediaObserver } from "@angular/flex-layout";
import { CookieService } from "ngx-cookie-service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { FuseMatchMediaService } from "@fuse/services/match-media.service";
import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
import { MatDialog } from "@angular/material/dialog";
import { FuseProgressBarService } from "../progress-bar/progress-bar.service";
import { GeneralInformationComponent } from "app/layout/components/general-information/general-information.component";

import { fuseAnimations } from "@fuse/animations";
import { CwUtils } from "../../../../shared/cw-utils/cw-utils.service";
import { UserService } from "../../../../shared/cw-backend/providers/security/user/user-service";
import { AuthService } from "../../../../shared/cw-services/auth/auth.service";

@Component({
    selector: "fuse-shortcuts",
    templateUrl: "./shortcuts.component.html",
    styleUrls: ["./shortcuts.component.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class FuseShortcutsComponent
    implements OnInit, AfterViewInit, OnDestroy
{
    shortcutItems: any[];
    navigationItems: any[];
    filteredNavigationItems: any[];
    searching: boolean;
    mobileShortcutsPanelActive: boolean;
    isConnected: boolean = true;
    latency: any;
    intervalRef: any;
    @Input()
    navigation: any;

    @ViewChild("searchInput")
    searchInputField;

    @ViewChild("shortcuts")
    shortcutsEl: ElementRef;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {CookieService} _cookieService
     * @param {FuseMatchMediaService} _fuseMatchMediaService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {MediaObserver} _mediaObserver
     * @param {Renderer2} _renderer
     */
    constructor(
        private _cookieService: CookieService,
        private _fuseMatchMediaService: FuseMatchMediaService,
        private _fuseNavigationService: FuseNavigationService,
        private _mediaObserver: MediaObserver,
        private _matDialog: MatDialog,
        private progressBar: FuseProgressBarService,
        private _renderer: Renderer2,
        private cwUtils: CwUtils,
        private authService: AuthService,
        private userService: UserService
    ) {
        // Set the defaults
        this.shortcutItems = [];
        this.searching = false;
        this.mobileShortcutsPanelActive = false;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        
        //this.latencyInit();

        // Get the navigation items and flatten them
        this.filteredNavigationItems = this.navigationItems =
            this._fuseNavigationService.getFlatNavigation(this.navigation);

        if (this._cookieService.check("FUSE2.shortcuts")) {
            this.shortcutItems = JSON.parse(
                this._cookieService.get("FUSE2.shortcuts")
            );
        } else {
            // User's shortcut items
            this.shortcutItems = [
                {
                    title: "Painel Gerencial",
                    type: "item",
                    icon: "home",
                    url: "aplicacao/dashboards/admin",
                },
                {
                    title: "Agenda",
                    type: "item",
                    icon: "today",
                    url: "/operacional/agendamento/marcacao",
                },
                {
                    title: "Atendimento",
                    type: "item",
                    icon: "receipt_long",
                    url: "/operacional/recepcao/os",
                },
                {
                    title: "Paciente",
                    type: "item",
                    icon: "person_outline",
                    url: "/cadastro/operacional/paciente",
                },
                {
                    title: "Agenda Profissional",
                    type: "item",
                    icon: "today",
                    url: "operacional/atendimento/agenda-profissional",
                },
                {
                    title: "Perfil",
                    type: "item",
                    icon: "contact_mail",
                    url: "/cadastro/seguranca/perfil",
                },
                {
                    title: "Usuário",
                    type: "item",
                    icon: "person_pin",
                    url: "/cadastro/seguranca/usuario",
                },
                {
                    title: "Convênio",
                    type: "item",
                    icon: "local_hospital",
                    url: "/cadastro/credenciamento/convenio",
                },
                {
                    title: "Procedimento",
                    type: "item",
                    icon: "healing",
                    url: "/cadastro/credenciamento/procedimento",
                },
                {
                    title: "Profissional",
                    type: "item",
                    icon: "people",
                    url: "/cadastro/organizacional/profissional",
                },
                {
                    title: "Operação",
                    type: "item",
                    icon: "build",
                    url: "/cadastro/operacional/operacao",
                },
            ];
        }
    }

    ngAfterViewInit(): void {
        // Subscribe to media changes
        this._fuseMatchMediaService.onMediaChange
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                if (this._mediaObserver.isActive("gt-sm")) {
                    this.hideMobileShortcutsPanel();
                }
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(true);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Search
     *
     * @param event
     */
    search(event): void {
        const value = event.target.value.toLowerCase();

        if (value === "") {
            this.searching = false;
            this.filteredNavigationItems = this.navigationItems;

            return;
        }

        this.searching = true;

        this.filteredNavigationItems = this.navigationItems.filter(
            (navigationItem) => {
                return navigationItem.title.toLowerCase().includes(value);
            }
        );
    }

    /**
     * Toggle shortcut
     *
     * @param event
     * @param itemToToggle
     */
    toggleShortcut(event, itemToToggle): void {
        event.stopPropagation();

        for (let i = 0; i < this.shortcutItems.length; i++) {
            if (this.shortcutItems[i].url === itemToToggle.url) {
                this.shortcutItems.splice(i, 1);

                // Save to the cookies
                this._cookieService.set(
                    "FUSE2.shortcuts",
                    JSON.stringify(this.shortcutItems)
                );

                return;
            }
        }

        this.shortcutItems.push(itemToToggle);

        // Save to the cookies
        this._cookieService.set(
            "FUSE2.shortcuts",
            JSON.stringify(this.shortcutItems)
        );
    }

    /**
     * Is in shortcuts?
     *
     * @param navigationItem
     * @returns {any}
     */
    isInShortcuts(navigationItem): any {
        return this.shortcutItems.find((item) => {
            return item.url === navigationItem.url;
        });
    }

    /**
     * On menu open
     */
    onMenuOpen(): void {
        setTimeout(() => {
            this.searchInputField.nativeElement.focus();
        });
    }

    /**
     * Show mobile shortcuts
     */
    showMobileShortcutsPanel(): void {
        this.mobileShortcutsPanelActive = true;
        this._renderer.addClass(
            this.shortcutsEl.nativeElement,
            "show-mobile-panel"
        );
    }

    /**
     * Hide mobile shortcuts
     */
    hideMobileShortcutsPanel(): void {
        this.mobileShortcutsPanelActive = false;
        this._renderer.removeClass(
            this.shortcutsEl.nativeElement,
            "show-mobile-panel"
        );
    }

    generalInformation() {
        this.progressBar.hide();
        const dialogRef = this._matDialog.open(GeneralInformationComponent, {
            height: "80%",
            width: "80%",
            panelClass: "clearPadding",
            //data: this.surgerySchedule.patient,
        });

        dialogRef.afterClosed().subscribe((result) => {});
    }

    latencyInit() {
        let isAuthenticated = this.authService.currentUserToken;      

        this.userService.userAction
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(action => {
            if (action == 'LOGOUT') {
               this.latencyStop(); 
               return;              
            } 
        });

        if(isAuthenticated != null) {
         //   this.latencyStart();
        }
    }

    latencyStart() {
       /* this.intervalRef = setInterval(() => {
            this.cwUtils.getLatency().then((latency) => {
               // console.log("🚀 ~ this.cwUtils.getLatency ~ latency:", latency)
                if (latency == -1) {
                    this.isConnected = false;
                } else {
                    this.latency = latency;
                    this.isConnected = true;
                }
            });
        }, 60000);*/
    }

    latencyStop() {
        clearInterval(this.intervalRef);
    }
}
