
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiGateway } from 'cw-backend';
import { SnackBarService } from 'cw-utils';

@Injectable()
export class OrderPendingProvider implements Resolve<any>
{
    routeParams: any;
    state: any;
    onDataChanged: BehaviorSubject<any>;

    constructor(
        public gw: ApiGateway,
        public snackBar: SnackBarService
    ) {
        this.onDataChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        /*return new Promise((resolve, reject) => {
           Promise.all([this.getOrderAuthorization()]).then(
                () => {
                    resolve();
                },
                reject
            );
        });*/
    }

    getPendings() {
        return new Promise((resolve, reject) => {
            this.gw.get(`order/pending`).subscribe((response: HttpResponse<any>) => {
                resolve(response);
            }, reject);
        });
    }

    get(orderId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get(`order/pendings/${orderId}`).subscribe((response: HttpResponse<any>) => {
                resolve(response);
            }, reject);
        });
    }



    insert(orderPending): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('order/pending/', orderPending).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }

    edit(orderPending): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.put('order/pending/', orderPending).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }

    delete(orderPendingId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.delete('order/pending/' + orderPendingId).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }

    checkPending(orderId: any): Promise<any> {
        orderId = orderId == null ? 0 : orderId;
        return new Promise((resolve, reject) => {
            this.gw.get(`order/pending/check/:orderId`, { orderId: orderId}).subscribe({
                next: (res: HttpResponse<any>) => {
                    resolve(res);
                },
                error: (err: any) => { reject(err); }
            })
        })
    }

}
