
import { NgModule } from '@angular/core';

import { AgencyEditProvider } from './agency-edit.provider';
import { AgencyListProvider } from './agency-list.provider';

export * from './agency-edit.provider';
export * from './agency-list.provider';

export {
  AgencyListProvider,
  AgencyEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    AgencyEditProvider,
    AgencyListProvider
  ]
})
export class AgencyBackendModule { }
