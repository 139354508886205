import { Product } from '../../../accreditation/product';

export class Def {
    id: number;
    product?: Product;
    name: String;
    presentation: String;
    chemical: String;
    laboratory: String;
    pharmacological_group: String;
    therapeutic_indication: String;
    scientific_section: String;
    dosage: String;
    leaflet: String;
    via: String;
   
}