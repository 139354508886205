
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Horary, PageOptions, ApiGateway } from 'cw-backend';
import { CwUtils } from 'cw-utils';

@Injectable()
export class HoraryListProvider implements Resolve<any>
{
    horary: Horary[];
    horaryEdit: Horary;
    onDataChanged: BehaviorSubject<any>; 
    routeParams: any;
    state: any;
    pageOptions: PageOptions;
    currentPage: PageOptions;

    constructor(
        public gw: ApiGateway,
        private location: Location,
    ) {
        this.onDataChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        // Get state for current or new page
        this.state = this.location.getState();
           if (this.state.currentPage && state.url.includes(this.state.path)) {
            this.currentPage = this.state.currentPage;
        }
        this.horaryEdit = this.state.currentHorary;

        if (this.currentPage) {
            this.pageOptions = this.currentPage;
        }
        else {
            this.pageOptions = new PageOptions();
            this.pageOptions.fieldSort = 'day';
        }
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this.getPage(this.pageOptions)
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    get(id: number): Observable<Horary> {
        if (!id) {
            return null;
        }
        return this.gw.get('horary/:id', { id: id });
    }

    get currentHorary(): Horary {
        return this.horaryEdit;
    }

    getAll(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('horary/').subscribe((res: HttpResponse<any>) => {
                this.horary = res.body;
                resolve(res);
            }, reject);
        });
    }

    getHoraryCustomList(horaryId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('professional/horaryCustom/:horaryId', {horaryId: horaryId}).subscribe((res: HttpResponse<any>) => {
                this.horary = res.body;
                resolve(res);
            }, reject);
        });
    }

    getPage(pageOptions: PageOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('professional/horary/search', pageOptions).subscribe((res: HttpResponse<any>) => {
                if (!CwUtils.compare(this.horary, res.body.content) && res.body.content.length > 0) {
                    this.horary = res.body.content;
                    this.onDataChanged.next(this.horary);
                }
                else {
                    this.horary = res.body.content;
                };
                resolve(res);
            }, reject);
        });
    }

    getPageSettings() {
        if (this.currentPage) {
            return this.pageOptions;
        } else {
            return;
        }
    }

    getHoraryByPerformerId(performerId, pageOptions: PageOptions, renew?: boolean): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('professional/perfomer/' + performerId + '/search/horary', pageOptions).subscribe((res: HttpResponse<any>) => {
                if ((!CwUtils.compare(this.horary, res.body.content) && res.body.content.length > 0) || renew) {
                    this.horary = res.body.content;
                    this.onDataChanged.next(this.horary);
                } else {
                    this.horary = res.body.content;
                }
                resolve(res);
            }, reject);
        });
    }

    searchHoraryAndPerformer(filter): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('horaryPerformer/search', filter).subscribe((res: HttpResponse<any>) => {
                resolve(res);
            }, reject);
        });
    }

}

