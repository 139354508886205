import { NgModule } from "@angular/core";
import { BillingEditProvider } from "./billing-edit.provider";
import { BillingListProvider } from "./billing-list.provider";

export * from "./billing-edit.provider";
export * from "./billing-list.provider";

export { BillingListProvider, BillingEditProvider };

@NgModule({
    declarations: [],
    imports: [],
    exports: [],
    providers: [BillingEditProvider, BillingListProvider],
})
export class BillingBackendModule {}
