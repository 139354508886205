import { AuthService } from "./../auth/auth.service";
import { Injectable } from "@angular/core";

@Injectable()
export class RoleNavigationService {
    navigation: any;
    user: any;
    permission: any;
    moduleList: any;
    subModuleList: any;
    viewList: any;
    emptyMenu: boolean;
    authService: AuthService;

    constructor() {
        this.authService = new AuthService();
    }

    navigationRole(navigation): any {
        localStorage.setItem("navigation", JSON.stringify(navigation));

        // this.allowedRole();

        // if (this.user == null || this.permission == null) {
        //     return navigation;
        // }

        // navigation.forEach((menu, index) => {
      
        //     // MODULE
        //     const moduleIndex = this.moduleList.findIndex(
        //         (module) => module.code === menu.id
        //     );
        //     let moduleItem = navigation[index];

        //     if (moduleIndex == -1) {
        //         moduleItem.hidden = true;
        //     } else {
        //         moduleItem.hidden = false;
        //         this.emptyMenu = false;
        //     }

        //     // SUBMODULE
        //     if (menu.children) {
        //         menu.children.forEach((subModuleLevel, index2) => {
               
        //                     const viewIndex = this.viewList.findIndex(
        //                         (view) => view.code === viewLevel.id
        //                     );
        //                     let subModuleItem =
        //                         navigation[index].children[index1].children[
        //                             index2
        //                         ];

        //                     if (viewIndex == -1) {
        //                         subModuleItem.hidden = true;
        //                     } else {
        //                         subModuleItem.hidden = false;
        //                     }
        //                 });
        //             }
        //         });
        //     }
        //     let menuItem = navigation[index];
      
        //     // MENU LABEL
        //     if (this.emptyMenu) {
        //         menuItem.hidden = true;
        //     } else {
        //         menuItem.hidden = false;
        //     }
        //     this.emptyMenu = true;
        // });
        return navigation;
    }

    allowedRole(request?): any {
        this.user = this.authService.currentUser;

        this.permission = JSON.parse(localStorage.getItem("modules"));
        if (!this.permission) {
            setTimeout(() => {
                this.permission = JSON.parse(localStorage.getItem("modules"));
            }, 2000);
        }

        if (this.user == null) {
            return false;
        } else if (this.permission == null) {
            return false;
        }
        this.user.modules = [];

        this.moduleList = new Array();
        this.subModuleList = new Array();
        this.viewList = new Array();

        // get module list
        this.permission.forEach((module) => {
            this.moduleList.push(module);
        });

        // get submodule list
        this.permission.forEach((module) => {
            module.subModules.forEach((subModule) => {
                this.subModuleList.push(subModule);
            });
        });

        // get view list
        this.permission.forEach((module) => {
            module.subModules.forEach((subModules) => {
                subModules.views.forEach((view) => {
                    this.viewList.push(view);
                });
            });
        });

        if (request === "viewList") {
            localStorage.setItem("views", JSON.stringify(this.viewList));
            return this.viewList;
        }
    }
}
