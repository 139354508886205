<div id="dashboard-admin" class="page-layout simple right-sidebar" fxLayout="row">

    <!-- CENTER -->
    <div class="center" fusePerfectScrollbar>

        <!-- HEADER -->
        <div class="header accent-700 p-24 pb-0" fxLayout="column" fxLayoutAlign="space-between">

            <div fxLayout="row" fxLayoutAlign="space-between start">

                <span class="mat-display-1 my-0 my-sm-24 welcome-message" [@animate]="{value:'*',params:{x:'50px'}}">
                    <span class="userName">Olá, {{user.login}}!</span>
                </span>

                <button mat-icon-button class="sidebar-toggle mr-8" fxHide.gt-md
                    (click)="toggleSidebar('admin-dashboard-right-sidebar-1')">
                    <mat-icon>menu</mat-icon>
                </button>
            </div>

            <div fxLayout="row">

                <div class="selected-admin">{{selectedCompany?.alias}}</div>

                <button mat-icon-button class="admin-selector" [matMenuTriggerFor]="companyMenu"
                    aria-label="Select company">
                    <mat-icon>more_horiz</mat-icon>
                </button>

                <mat-menu #companyMenu="matMenu">
                    <button mat-menu-item *ngFor="let company of companys" (click)="selectedCompany = company">
                        <span>{{company?.alias}}</span>
                    </button>
                </mat-menu>
            </div>
        </div>
        <!-- / HEADER -->

        <!-- CONTENT -->
        <div class="content">

            <mat-tab-group class="tab-size" [disableRipple]="true" dynamicHeight>
                <!--    Agendamento    -->
                <mat-tab label="Agendamento">

                    <div class="widget-group p-12" fxLayout="row wrap" fxFlex="100" *fuseIfOnDom
                        [@animateStagger]="{value:'50'}">

                        <app-dashboard-schedule style="width: 100%;"
                            [company]="selectedCompany"></app-dashboard-schedule>

                    </div>
                    <!-- / WIDGET GROUP -->

                </mat-tab>

                <!--  <mat-tab label="Atendimento">

                    
                    <div class="widget-group" fxLayout="row wrap" fxFlex="100" *fuseIfOnDom
                        [@animateStagger]="{value:'50'}">

                       
                        <fuse-widget [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="column"
                            fxFlex="100" fxFlex.gt-sm="50">

                         
                            <div class="fuse-widget-front">
                                <div class="h3 px-16 py-24">
                                    {{widgets.widget8.title}}
                                </div>

                                <mat-divider></mat-divider>

                                <div class="h-400">
                                    <ngx-charts-pie-chart *fuseIfOnDom [scheme]="widget8.scheme"
                                        [results]="widgets.widget8.mainChart" [legend]="widget8.legend"
                                        [explodeSlices]="widget8.explodeSlices" [labels]="widget8.labels"
                                        [doughnut]="widget8.doughnut" [gradient]="widget8.gradient"
                                        (select)="widget8.onSelect($event)">
                                    </ngx-charts-pie-chart>
                                </div>

                            </div>
                            

                        </fuse-widget>
                    
                        <fuse-widget [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="column"
                            fxFlex="100" fxFlex.gt-sm="50">

                            <div class="fuse-widget-front">
                                <div class="px-16 py-12 border-bottom" fxLayout="row"
                                    fxLayoutAlign="space-between center">
                                    <div class="h3">{{widgets.widget9.title}}</div>
                                    <mat-form-field>
                                        <mat-select [(ngModel)]="widget9.currentRange" aria-label="Change range">
                                            <mat-option *ngFor="let range of widgets.widget9.ranges | keys"
                                                [value]="range.key">
                                                {{range.value}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div class="px-16 py-24" fxLayout="column" fxLayoutAlign="center" fxLayout.gt-xs="row"
                                    fxLayoutAlign.gt-xs="space-between end">
                                    <div fxFlex="0 1 auto">
                                        <div class="h4 secondary-text">{{widgets.widget9.weeklySpent.title}}</div>
                                        <div class="pt-8 mat-display-1 m-0 font-weight-300 text-nowrap">
                                            <span class="secondary-text">$</span>
                                            <span>{{widgets.widget9.weeklySpent.count[widget9.currentRange]}}</span>
                                        </div>
                                    </div>
                                    <div class="h-52" fxFlex>
                                        <ngx-charts-area-chart *fuseIfOnDom
                                            [results]="widgets.widget9.weeklySpent.chart[widget9.currentRange]"
                                            [scheme]="widget9.scheme" [gradient]="widget9.gradient"
                                            [xAxis]="widget9.xAxis" [yAxis]="widget9.yAxis" [legend]="widget9.legend"
                                            [showXAxisLabel]="widget9.showXAxisLabel"
                                            [showYAxisLabel]="widget9.showYAxisLabel" [xAxisLabel]="widget9.xAxisLabel"
                                            [yAxisLabel]="widget9.yAxisLabel" [curve]="widget9.curve">
                                        </ngx-charts-area-chart>
                                    </div>
                                </div>

                                <div class="px-16 py-24" fxLayout="column" fxLayoutAlign="center" fxLayout.gt-xs="row"
                                    fxLayoutAlign.gt-xs="space-between end">
                                    <div fxFlex="0 1 auto">
                                        <div class="h4 secondary-text">{{widgets.widget9.totalSpent.title}}</div>
                                        <div class="pt-8 mat-display-1 m-0 font-weight-300 text-nowrap">
                                            <span class="secondary-text">$</span>
                                            <span>{{widgets.widget9.totalSpent.count[widget9.currentRange]}}</span>
                                        </div>
                                    </div>
                                    <div class="h-52" fxFlex>
                                        <ngx-charts-area-chart *fuseIfOnDom
                                            [results]="widgets.widget9.totalSpent.chart[widget9.currentRange]"
                                            [scheme]="widget9.scheme" [gradient]="widget9.gradient"
                                            [xAxis]="widget9.xAxis" [yAxis]="widget9.yAxis" [legend]="widget9.legend"
                                            [showXAxisLabel]="widget9.showXAxisLabel"
                                            [showYAxisLabel]="widget9.showYAxisLabel" [xAxisLabel]="widget9.xAxisLabel"
                                            [yAxisLabel]="widget9.yAxisLabel" [curve]="widget9.curve">
                                        </ngx-charts-area-chart>
                                    </div>
                                </div>

                                <div class="px-16 py-24" fxLayout="column" fxLayoutAlign="center" fxLayout.gt-xs="row"
                                    fxLayoutAlign.gt-xs="space-between end">
                                    <div fxFlex="0 1 auto">
                                        <div class="h4 secondary-text">{{widgets.widget9.remaining.title}}</div>
                                        <div class="pt-8 mat-display-1 m-0 font-weight-300 text-nowrap">
                                            <span class="secondary-text">$</span>
                                            <span>{{widgets.widget9.remaining.count[widget9.currentRange]}}</span>
                                        </div>
                                    </div>
                                    <div class="h-52" fxFlex>
                                        <ngx-charts-area-chart *fuseIfOnDom
                                            [results]="widgets.widget9.remaining.chart[widget9.currentRange]"
                                            [scheme]="widget9.scheme" [gradient]="widget9.gradient"
                                            [xAxis]="widget9.xAxis" [yAxis]="widget9.yAxis" [legend]="widget9.legend"
                                            [showXAxisLabel]="widget9.showXAxisLabel"
                                            [showYAxisLabel]="widget9.showYAxisLabel" [xAxisLabel]="widget9.xAxisLabel"
                                            [yAxisLabel]="widget9.yAxisLabel" [curve]="widget9.curve">
                                        </ngx-charts-area-chart>
                                    </div>
                                </div>

                                <div class="px-16 py-24 border-top">
                                    <div class="h4 secondary-text">{{widgets.widget9.totalBudget.title}}</div>
                                    <div class="pt-8 mat-display-1 m-0 font-weight-300">
                                        <span class="secondary-text">$</span>
                                        <span>{{widgets.widget9.totalBudget.count}}</span>
                                    </div>
                                </div>

                            </div>

                        </fuse-widget>
                        <fuse-widget [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="row"
                            fxFlex="100">

                            <div class="fuse-widget-front">

                                <div class="simple-table-container" ms-responsive-table>
                                    <div class=" table-title">
                                        {{widgets.widget10.title}}
                                    </div>

                                    <table class="simple">

                                        <thead>
                                            <tr>
                                                <th *ngFor="let column of widgets.widget10.table.columns">
                                                    {{column.title}}
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr *ngFor="let row of widgets.widget10.table.rows">
                                                <td *ngFor="let cell of row">
                                                    <span class="p-4" [ngClass]="cell.classes">
                                                        {{cell.value}}
                                                    </span>
                                                    <mat-icon *ngIf="cell.icon" class="s-16">{{cell.icon}}
                                                    </mat-icon>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>

                        </fuse-widget>
                    

                    </div>
                 

                </mat-tab> -->

                <mat-tab label="Usuários">


                    <!-- WIDGET GROUP -->
                    <div class="widget-group" fxLayout="row wrap" fxFlex="100" *fuseIfOnDom
                        [@animateStagger]="{value:'50'}">

                        <!-- WIDGET 11 -->
                        <fuse-widget [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="row"
                            fxFlex="100">

                            <!-- Front -->
                            <div class="fuse-widget-front">

                                <div class="p-24 mb-8 border-bottom" fxLayout="row"
                                    fxLayoutAlign="space-between center">
                                    <div class="h2">Sessões ativas</div>
                                    <div *ngIf="dataSource.data?.length > 1" class="text-boxed accent m-0">
                                        {{dataSource.data?.length}}
                                        sessões
                                    </div>
                                    <div *ngIf="dataSource.data?.length == 1" class="text-boxed accent m-0">
                                        {{dataSource.data?.length}}
                                        sessão
                                    </div>
                                </div>

                                <mat-table #table [dataSource]="dataSource">
                                    <!-- Avatar Column -->
                                    <ng-container matColumnDef="avatar">
                                        <mat-header-cell fxFlex="96px" *matHeaderCellDef></mat-header-cell>
                                        <mat-cell fxFlex="96px" *matCellDef="let user">
                                            <!-- *ngIf="user.avatar" -->
                                            <img class="avatar" [alt]="user.name"
                                                [src]="user.photo? 'data:image/jpg;base64,' + user.photo: 'assets/images/avatars/profile.jpg'" />
                                        </mat-cell>
                                    </ng-container>

                                    <!-- Name Column -->
                                    <ng-container matColumnDef="name">
                                        <mat-header-cell *matHeaderCellDef>Nome</mat-header-cell>
                                        <mat-cell *matCellDef="let user">
                                            <p class="text-truncate font-weight-600">{{user.login}}
                                        </mat-cell>
                                    </ng-container>


                                    <!-- Data Column -->
                                    <ng-container matColumnDef="date">
                                        <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-sm>Quando
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let user" fxHide fxShow.gt-sm>
                                            {{getDate(user.accessDate) }}
                                        </mat-cell>
                                    </ng-container>

                                    <!-- Phone Column -->
                                    <ng-container matColumnDef="ip">
                                        <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-md>Endereço IP
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let user" fxHide fxShow.gt-md>
                                            <p class="phone text-truncate">
                                                {{user.ip}}
                                            </p>
                                        </mat-cell>
                                    </ng-container>

                                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                                    <mat-row *matRowDef="let user; columns: displayedColumns;">
                                    </mat-row>
                                </mat-table>


                                <!-- / Front -->
                            </div>

                        </fuse-widget>
                        <!-- / WIDGET 11 -->

                    </div>
                    <!-- / WIDGET GROUP -->

                </mat-tab>
            </mat-tab-group>

        </div>
        <!-- / CONTENT -->

    </div>
    <!-- / CENTER -->

    <!-- SIDEBAR -->
    <fuse-sidebar class="sidebar" name="admin-dashboard-right-sidebar-1" position="right" lockedOpen="gt-md">

        <!-- SIDEBAR CONTENT -->
        <div class="content">

            <!-- WIDGET GROUP -->
            <div class="widget-group" fxLayout="column" fxFlex="100" [@animateStagger]="{value:'50'}">

                <!-- NOW WIDGET -->
                <fuse-widget [@animate]="{value:'*',params:{y:'100%'}}" class="pb-0">

                    <!-- Front -->
                    <div class="fuse-widget-front">

                        <div class="pl-16 pr-8 py-16" fxLayout="row" fxLayoutAlign="space-between center">

                            <div class="h3 text-capitalize">{{dateNow | date: 'EEEE, HH:mm:ss'}}</div>

                            <div>
                                <button mat-icon-button [matMenuTriggerFor]="moreMenu" aria-label="more">
                                    <mat-icon>more_vert</mat-icon>
                                </button>

                                <mat-menu #moreMenu="matMenu">
                                    <button mat-menu-item aria-label="Flip widget">
                                        Details
                                    </button>
                                </mat-menu>
                            </div>
                        </div>

                        <div class="p-16 pb-24" fxLayout="column" fxLayoutAlign="center center">
                            <div class="h1 secondary-text text-capitalize">
                                <span>{{dateNow | date: 'MMMM'}}</span>
                            </div>

                            <div class="font-size-72 line-height-88 secondary-text font-weight-400">
                                {{dateNow | date: 'd'}}
                            </div>

                            <div class="h1 secondary-text">
                                <span>{{dateNow | date: 'y'}}</span>
                            </div>
                        </div>

                    </div>
                    <!-- / Front -->

                </fuse-widget>
                <!-- / NOW WIDGET -->

                <!-- WEATHER WIDGET -->
                <fuse-widget [@animate]="{value:'*',params:{y:'100%'}}">

                    <!-- Front -->
                    <div class="fuse-widget-front">

                        <div class="pl-16 pr-8 py-16" fxLayout="row" fxLayoutAlign="space-between center">

                            <div class="h4" fxLayout="row" fxLayoutAlign="start center">
                                <mat-icon class="secondary-text mr-8">place</mat-icon>
                                {{weatherForecast?.name}}
                            </div>

                            <div>
                                <button mat-icon-button [matMenuTriggerFor]="moreMenu" aria-label="more">
                                    <mat-icon class="secondary-text">more_vert</mat-icon>
                                </button>

                                <mat-menu #moreMenu="matMenu">
                                    <button mat-menu-item aria-label="Flip widget">
                                        Details
                                    </button>
                                </mat-menu>
                            </div>
                        </div>

                        <div class="p-16 pb-32" fxLayout="column" fxLayoutAlign="center center">

                            <div fxLayout="row" fxLayoutAlign="center center">
                                <mat-icon fontSet="meteocons" [fontIcon]="getIcon(weatherForecast?.weather[0]?.icon)"
                                    class="icon s-40 secondary-text mr-16"></mat-icon>

                                <span class="mat-display-2 m-0 font-weight-300 secondary-text">
                                    {{round(weatherForecast?.main.temp)}}
                                </span>
                                <span class="font-size-48 font-weight-300 hint-text text-super ml-8">°</span>
                                <span class="mat-display-2 mb-0 font-weight-300 hint-text ng-binding">C</span>
                            </div>
                        </div>

                        <div class="p-16 by" fxLayout="row" fxLayoutAlign="space-between center">
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <mat-icon fontSet="meteocons" fontIcon="icon-windy" class="s-16 secondary-text mr-8">
                                </mat-icon>
                                <span>
                                    {{round(weatherForecast?.wind?.speed)}}
                                </span>
                                <span class="secondary-text ml-5">{{widgets.weatherWidget.speedUnit}}</span>
                            </div>

                        </div>

                        <!-- <div class="py-16">
                            <div class="py-16 px-24" fxLayout="row" fxLayoutAlign="space-between center"
                                *ngFor="let day of widgets.weatherWidget.locations[widgets.weatherWidget.currentLocation].next3Days">
                                <span class="h4">{{day.name}}</span>
                                <div fxLayout="row" fxLayoutAlign="start center">
                                    <mat-icon fontSet="meteocons" [fontIcon]="day.icon" class="secondary-text mr-16">
                                    </mat-icon>
                                    <span class="h2">{{day.temp[widgets.weatherWidget.tempUnit]}}</span>
                                    <span class="h2 font-weight-300 secondary-text text-super">&deg;</span>
                                    <span class="h2 font-weight-300 secondary-text">
                                        {{widgets.weatherWidget.tempUnit}}
                                    </span>
                                </div>
                            </div>
                        </div> -->
                    </div>
                    <!-- / Front -->

                </fuse-widget>
                <!-- / WEATHER WIDGET -->

            </div>
            <!-- / WIDGET GROUP -->

        </div>
        <!-- / SIDEBAR CONTENT -->

    </fuse-sidebar>
    <!-- / SIDEBAR -->

</div>