
import { NgModule } from '@angular/core';

import { DefEditProvider } from './def-edit.provider';
import { DefListProvider } from './def-list.provider';

export * from './def-edit.provider';
export * from './def-list.provider';

export {
  DefListProvider,
  DefEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    DefEditProvider,
    DefListProvider
  ]
})
export class DefBackendModule { }
