<div class="page-layout carded fullwidth inner-scroll">
    <div>
        <h2 *ngIf="data.message">{{data.message}} <span class="mr-5">
                <button mat-icon-button (click)="showDetails()">
                    <mat-icon>info</mat-icon>
                </button>
            </span></h2>
    </div>
    <div class="content-card">

        <!-- CONTENT -->
        <div *ngIf="showDetail" class="content details">

            <div *ngIf="data.context === 'schedule'">
                <div *ngFor="let msg of data?.list">
                    {{msg.date}} {{msg.hour}}
                    <p> <b>{{msg.scheduleSource.date | date : 'dd/MM/yyyy'}} {{msg.scheduleSource.hour}}</b> para
                        <b>{{msg.scheduleTarget.date | date : 'dd/MM/yyyy'}}
                            {{msg.scheduleTarget.hour}}</b> {{msg.msg}}
                    </p>
                </div>
            </div>

            <div *ngIf="data.context === 'authorizerWS'">
                <div *ngFor="let msg of data?.list">
                    <p *ngIf="msg.code"> <b>Código: </b> {{msg.code}}</p>
                    <p *ngIf="msg.reason"> <b>Descrição: </b> {{msg.reason}}</p>
                    <p *ngIf="msg.reason2"> <b>Descrição: </b> {{msg.reason2}}</p>
                </div>
            </div>

            <div *ngIf="data.context === 'batchEntry'">
                <div *ngFor="let msg of data?.list">
                    <p *ngIf="msg.code"> <b>Paciente: </b> {{msg.code}}</p>
                    <p *ngIf="msg.reason"> <b>Descrição: </b> {{msg.reason}}</p>
                    <mat-divider></mat-divider>
                </div>
            </div>

            <div *ngIf="data.context === 'sendEmail'">
                <div *ngFor="let msg of data?.list">
                    <p *ngIf="msg.reason"> <b>Profissional: </b> {{msg.reason}}</p>
                    <p *ngIf="msg.code"> <b>Razão: </b> {{msg.code}}</p>
                    <mat-divider></mat-divider>
                </div>
            </div>

            <div *ngIf="data.context === 'simpleMessage'">
                <div *ngFor="let msg of data?.list">
                    <p class="textUpCaseFirst">{{msg}}</p>

                </div>
            </div>


        </div>
    </div>

    <div>
        <button mat-button (click)="snackBarRef.dismiss()">OK</button>
    </div>

</div>