
<div class="header mat-elevation-z4 primary" fxLayout="row" fxLayoutAlign="space-between center">
    <ng-container *ngIf="selectedProfile === null">

        <div class="title ml-16" fxLayout="row" fxLayoutAlign="start center" (click)="unfoldSidebarTemporarily()">
            <mat-icon class="s-32 fuse-white-fg">chat</mat-icon>
            <h3 class="ml-12">Envio de Menssagens</h3>
        </div>

    </ng-container>

    <ng-container *ngIf="selectedProfile !== null">

        <div class="title" fxLayout="row" fxLayoutAlign="start center">
            <img *ngIf="checkImagem(selectedProfile.avatar ?  selectedProfile.avatar : null)"
                [src]="checkImagem(selectedProfile.avatar ?  selectedProfile.avatar : null)" class="avatar mx-16">
            <img *ngIf="!checkImagem(selectedProfile.avatar ?  selectedProfile.avatar : null)"
                src="assets/icons/ronaldo.png" class="avatar mx-16">
            <h3 class="text-truncate">{{selectedProfile.name}}</h3>
        </div>

    </ng-container>

    <button mat-icon-button class="toggle-sidebar-folded mr-8" (click)="foldSidebarTemporarily();resetChat();" fxHide
        fxShow.gt-md>
        <mat-icon class="secondary-text s-20">close</mat-icon>
    </button>

    <button mat-icon-button class="toggle-sidebar-open mr-8" (click)="toggleSidebarOpen();resetChat();" fxHide.gt-md>
        <mat-icon class="secondary-text">close</mat-icon>
    </button>

</div>

<div class="content">

    <!-- Contacts -->
    <div id="contacts-list" fusePerfectScrollbar [fusePerfectScrollbarOptions]="{suppressScrollX: true}">

        <div *ngFor="let contact of profileList" class="contacts-list-item" [ngClass]="contact.status"
        [class.active]="contact.id === selectedProfile?.id" (click)="toggleChat(contact)" [@animate]="{value:'*',params:{y:'100%'}}">
    
        <div class="avatar-container">
            <!-- Avatar e ícone de status -->
            <img *ngIf="checkImagem(contact.avatar ?  contact.avatar: null)" class="avatar"
                [src]="checkImagem(contact.avatar  ?  contact.avatar  : null)" [matTooltip]="contact.name"
                matTooltipPosition="left">
            <img *ngIf="!checkImagem(contact.avatar  ?  contact.avatar  : null)" class="avatar"
                src="assets/icons/ronaldo.png" [matTooltip]="contact.name" matTooltipPosition="left">
            <div class="unread-count" *ngIf="contact.unread">{{contact.unread}}</div>
            <div class="status-icon" [ngClass]="contact.status"></div>
    
            <!-- Nome do usuário -->
            <span class="user-name">{{ contact.name.substring(0,10) }}</span>
        </div>
    </div>
    
    </div>
    <!-- / Contacts -->

    <!-- Chat -->
    <div id="chat" fxLayout="column" fxFlex="1 1 auto">

        <div id="messages" class="messages" fxFlex="1 1 auto" fusePerfectScrollbar>

            <ng-container *ngIf="chat && chat.length > 0" >

                <div *ngFor="let message of chat; let i = index; let first = first; let last = last;" class="message-row" [ngClass]="{
                            'me': message.profileFrom.id === profile.id,
                            'contact': message.profileFrom.id !== profile.id,
                            'first-of-group': isFirstMessageOfGroup(message, i),
                            'last-of-group': isLastMessageOfGroup(message, i)
                            }"[@animate]="{value:'*',params:{y:'100%'}}">

                    <img *ngIf="shouldShowContactAvatar(message, i) && checkImagem(selectedProfile.avatar  ?  selectedProfile.avatar  : null) 
                    && checkImagem(selectedProfile.avatar  ?  selectedProfile.avatar  : null)" 
                    [src]="checkImagem(selectedProfile.avatar  ?  selectedProfile.avatar  : null)"
                        class="avatar">

                    <img *ngIf="shouldShowContactAvatar(message, i) && !checkImagem(selectedProfile.avatar  ?  selectedProfile.avatar  : null)
                    && !checkImagem(selectedProfile.avatar  ?  selectedProfile.avatar  : null)" 
                    src="assets/icons/ronaldo.png" 
                        class="avatar">

                    <div class="bubble">
                        <div class="message">{{message.message}}</div>
                        <div class="time secondary-text">{{message.sended }}</div>
                        <!-- <div class="time secondary-text">{{message.sended | moment:'dd/MM/yyyy, hh:mm:ss'}}</div> -->
                    </div>

                </div>

            </ng-container>

            <ng-container *ngIf="selectedProfile && chat && chat.length === 0">

                <div class="no-messages-icon">
                    <mat-icon class="s-128 fade-text">chat</mat-icon>
                </div>

                <div class="no-messages secondary-text">
                    Inicie uma conversa digitando sua mensagem abaixo.
                </div>

            </ng-container>

            <ng-container *ngIf="!selectedProfile">

                <div class="no-contact-selected">

                    <div class="no-contact-icon">
                        <mat-icon class="s-128 fade-text">chat</mat-icon>
                    </div>

                    <div class="no-contact secondary-text">
                        Selecione um contato para iniciar uma conversa.
                    </div>

                </div>

            </ng-container>

        </div>

        <div class="reply-form" fxFlex="0 0 auto" fxLayout="row" fxLayoutAlign="center center" *ngIf="selectedProfile">

            <form #replyForm="ngForm" (ngSubmit)="reply($event)" (keydown.enter)="reply($event)" fxFlex fxLayout="row"
                fxLayoutAlign="start center">

                <mat-form-field class="message-text" fxFlex floatLabel="never" appearance="standard">
                    <textarea matInput #replyInput ngModel name="message" placeholder="Digite sua mensagem" [rows]="3"
                        [matTextareaAutosize]="true"></textarea >
                </mat-form-field>

                <button class="send-message-button" mat-icon-button type="submit" aria-label="Send message">
                    <mat-icon class="secondary-text">send</mat-icon>
                </button>

            </form>

        </div>

    </div>
    <!-- / Chat -->

</div>