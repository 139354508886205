
import { HttpResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiGateway } from 'cw-backend';
import { SnackBarService } from 'cw-utils';
import { BatchEntry } from '../../../../../models/attendance/batchEntry';

@Injectable()
export class BatchEntryEditProvider implements Resolve<any>
{
    routeParams: any;
    state: any;
    onDataChanged: BehaviorSubject<any>;
    batchEntry: BatchEntry;

    constructor(
        public gw: ApiGateway,
        public snackBar: SnackBarService
    ) {
        this.onDataChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        return new Promise((resolve, reject) => {
            Promise.all([this.getBatchEntry()]).then(
                () => {
                    resolve(true);
                },
                reject
            );
        });
    }

    getBatchEntry(): Promise<any> {
        return new Promise((resolve, reject) => {
            let data = this.checkLastModifications()
            if (data && data.id == this.routeParams.id) {
                this.batchEntry = data;
                this.onDataChanged.next(this.batchEntry);
                resolve(this.batchEntry);
            } else if (this.routeParams.id === 'novo') {
                this.onDataChanged.next(false);
                resolve(false);
            }
            else {
                this.gw.get('batchEntry/' + this.routeParams.id).subscribe({
                    next: (res: BatchEntry) => {
                        this.batchEntry = res;
                        this.onDataChanged.next(this.batchEntry);
                        resolve(res);
                    }, error: (err: any) => { reject(err) }
                });
            }
        });
    }

    insert(batchEntry): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('batchEntry/', batchEntry).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                }, error: (err: any) => { reject(err) }
            });
        });
    }

    insertBatchEntryItem(batchEntryItemTO): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('batchEntry/batch-entry-item/insert', batchEntryItemTO).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                }, error: (err: any) => { reject(err) }
            });
        });
    }

    deleteBatchEntryItem(batchEntryItemId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.delete(`batchEntry/batch-entry-item/remove/${batchEntryItemId}`).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                }, error: (err: any) => { reject(err) }
            });
        });
    }

    insertBatchEntryItemWhithSpec(batchEntryItemPO): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('batchEntry/batch-entry-item/whith-spec/insert', batchEntryItemPO).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                }, error: (err: any) => { reject(err) }
            });
        });
    }

    removeBatchEntrySpec(id): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.delete(`batchEntry/batch-entry-spec/remove/${id}`,).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                }, error: (err: any) => { reject(err) }
            });
        });
    }

    edit(batchEntry): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('batchEntry/', batchEntry).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                }, error: (err: any) => { reject(err) }
            });
        });
    }

    delete(batchEntry): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.delete('batchEntry/' + batchEntry.id).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                }, error: (err: any) => { reject(err) }
            });
        });
    }

    checkLastModifications(): BatchEntry {
        let wasLocked = JSON.parse(localStorage.getItem('session_locked'));
        let data;
        if (wasLocked) {
            data = JSON.parse(localStorage.getItem('last_modification'));
            localStorage.removeItem('session_locked');
        }
        return data;
    }

    // BATCH-ENTRY-SPEC
    insertBatchEntrySpec(batchEntrySpec: Promise<any>) {
        return new Promise((resolve, reject) => {
            this.gw.post('batchEntry/batch-entry-spec/insert', batchEntrySpec).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage("Dados salvo com sucesso");
                }, error: (err: any) => { reject(err) }
            });
        });
    }
    //BATCH-ENTRY-RESULT

    insertbatchEntryResult(batchEntryId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post(`batchEntry/insert/batchEntryResult/batchEntry/${batchEntryId}`).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage("Dados salvo com sucesso");
                }, error: (err: any) => { reject(err) }
            });
        });
    }

    static prossessPatient = new EventEmitter<any>();
    static prossessResult = new EventEmitter<any>();

}
