<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="accent-600 m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <div class="contact-name mt-8">Autenticar usuário</div>
            <button mat-icon-button (click)="dimiss()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 pb-0 m-0" fusePerfectScrollbar>
        <form name="authenticateUserForm" [formGroup]="authenticateUserForm">

            <div fxLayout="row" fxLayoutAlign="center center">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Usuário</mat-label>
                    <input matInput formControlName="login" readonly autocomplete="off">
                    <mat-icon matSuffix class="secondary-text ">person</mat-icon>
                    <mat-error *ngIf="authenticateUserForm.get('login').hasError('required')">
                        Usuário é obrigatório.
                    </mat-error>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="center center">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Senha</mat-label>
                    <input (keyup.enter)="autenthicateUser()" matInput id="user_password" type="text" formControlName="password" class="pw-mask"
                        autocomplete="off">
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error *ngIf="authenticateUserForm.get('password').hasError('required')">
                        Senha é obrigatório.
                    </mat-error>
                </mat-form-field>
            </div>
        </form>
    </div>

    <div mat-dialog-actions class=" m-0 p-16" fxLayout="row" fxLayoutAlign="center center">
        <button mat-raised-button fxFlex aria-label="VALIDATE LOGIN" (click)="autenthicateUser()" color="accent"
            [disabled]="authenticateUserForm.invalid">
            Continuar
        </button>
    </div>
</div>