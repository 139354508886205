import { LoginComponent } from './../../../main/pages/authentication/login/login.component';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatToolbarModule } from '@angular/material/toolbar';


import { FuseSharedModule } from '@fuse/shared.module';

import { FooterComponent } from 'app/layout/components/footer/footer.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { MomentModule } from 'ngx-moment';
import { LastAccessComponent } from './last-access-modal/last-access-modal.component';
import { MatButtonModule } from '@angular/material/button';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRippleModule } from '@angular/material/core';
import { MatSortModule } from '@angular/material/sort';
import { CwUtils } from 'cw-utils';
import { UserLogService } from 'cw-backend';
import { ScheduleService } from 'cw-services';
import { MedicalProvider } from '../../../../../shared/cw-backend/providers/operational/medical/medical.provider';



@NgModule({
    declarations: [
        FooterComponent,
        LastAccessComponent
    ],
    imports: [
        RouterModule,

        MatToolbarModule,
        MatDialogModule,
        MatButtonModule,

        MatIconModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSortModule,
        MatTableModule,
        MatDialogModule,

        FuseSharedModule,
        MomentModule,

        // idle
        NgIdleKeepaliveModule.forRoot(),
    ],
    exports: [
        FooterComponent,
    ],
    providers: [
        UserLogService,
        ScheduleService,
        CwUtils,
        MedicalProvider
    ],
    entryComponents: [
        LastAccessComponent
    ]
})
export class FooterModule {
}
