
import { NgModule } from '@angular/core';

import { FirmEditProvider } from './firm-edit.provider';
import { FirmListProvider } from './firm-list.provider';

export * from './firm-edit.provider';
export * from './firm-list.provider';

export {
  FirmListProvider,
  FirmEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    FirmEditProvider,
    FirmListProvider
  ]
})
export class FirmBackendModule { }
