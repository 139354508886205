import { Component, Input, OnChanges, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { tick } from '@angular/core/testing';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { User } from '../../../../../shared/cw-backend/models/backend-definitions.module';
import { Profile } from '../../../../../shared/cw-backend/models/security/user/profile';
import { ProfileProvider } from '../../../../../shared/cw-backend/providers/security/profile/profile.provider';
import { UserListProvider } from '../../../../../shared/cw-backend/providers/security/user/user-list.provider';
import { SnackBarService } from '../../../../../shared/cw-utils/snackbar.service';

@Component({
  selector: 'cw-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class ProfileComponent implements OnInit, OnDestroy {

  @Input('iUser') userId;

  loading = false;
  profileForm: FormGroup;
  profile = new Profile();
  user = new User();

  constructor(
    private _formBuilder: FormBuilder,
    private userProvider: UserListProvider,
    private profileProvider: ProfileProvider,
    private snackBarService: SnackBarService
  ) { }


  ngOnDestroy(): void {
    delete this.profileForm;
  }


  ngOnInit() {
    this.profileForm = this.createProfileForm();
    if (this.userId != null) {
      this.getProfile(this.userId);
    } else {
      this.getUser();
    }

  }


  createProfileForm() {

    return this._formBuilder.group({
      id: [this.profile.id],
      user: [this.profile.user],
      name: [this.profile.name, Validators.required],
      mood: [this.profile.mood],
      status: [this.profile.status, Validators.required],
    })
  }

  getUser() {
    const user = this.userLogged;
    if (user?.id) {
      this.getProfile(user.id);
    }

  }
  submitForm() {
    if(this.profileForm.valid){
      const profile = this.profileForm.getRawValue();
      this.save(profile);
    }else{
      this.profileForm.markAllAsTouched();
      this.snackBarService.warnMessage("Verifique os campos em destaques!")
      return;
    }
    
  }

  save(profile){
    this.profileProvider.save(profile)
    .then(res => {
      this.snackBarService.successMessage("Dados Salvo com sucesso");
        this.profileForm.reset();
        this.profile = res.body.data;
        this.setValues();
    })
  }

  setValues() {
    this.profileForm.patchValue({
      id: this.profile.id,
      user: this.profile.user,
      name: this.profile.name,
      mood: this.profile.mood,
      status: this.profile.status,

    })
  }

  get userLogged(): User {
    return JSON.parse(localStorage.getItem('user'));
  }

  getProfile(userId) {
    this.profileProvider.getByUserId(userId)
      .then(res => {
        this.profile = res.body;
        this.setValues();
      })
  }

}
