import { Injectable } from "@angular/core";
import { HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import {
    Patient,    
    ApiGateway,
    AuthorizationModel,
    ModelAuthorization,
    PageOptions,
} from "cw-backend";

@Injectable()
export class AuthorizationModelProvider {
    authorizationModel: AuthorizationModel[];
    patientEdit: Patient;

    constructor(public gw: ApiGateway) {}

    getById(id: number): Observable<HttpResponse<AuthorizationModel>> {
        if (!id) {
            return null;
        }
        return this.gw.get("authorizationModel/:id", { id: id });
    }

    getAll(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .get("authorizationModel/")
                .subscribe((res: HttpResponse<any>) => {
                    this.authorizationModel = res.body;
                    resolve(res);
                }, reject);
        });
    }

    insert(modelAuthorization: ModelAuthorization): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post("authorizationModel/", modelAuthorization).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);                    
                },
                error: (error) => {
                    reject(error);
                },
            });
        });
    }

    getPage(patientId: number, pageOptions: PageOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post(`authorizationModel/${patientId}/search`, pageOptions).subscribe((res: HttpResponse<any>) => {                               
                resolve(res);
            }, reject);
        });
    }
}
