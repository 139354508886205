
import { NgModule } from '@angular/core';

import { ContactsEditProvider } from './contacts-edit.provider';
import { ContactsListProvider } from './contacts-list.provider';

export * from './contacts-edit.provider';
export * from './contacts-list.provider';

export {
  ContactsListProvider,
  ContactsEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    ContactsEditProvider,
    ContactsListProvider
  ]
})
export class ContactsBackendModule { }
