
import { NgModule } from '@angular/core';
import { CurrencyListProvider } from './currency-list.provider';
import { CurrencyEditProvider } from './currency-edit.provider';

export {
  CurrencyListProvider,
  CurrencyEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    CurrencyEditProvider,
    CurrencyListProvider
  ]
})
export class CurrencyBackendModule { }
