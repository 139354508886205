import { SnackBarService } from '../../../../cw-utils/snackbar.service';

import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiGateway } from '../../../api-gateway';
import { View } from '../../../models/security/view-model';

@Injectable()
export class ViewEditService implements Resolve<any>
{
    routeParams: any;
    state: any;
    onDataChanged: BehaviorSubject<any>;
    view: View;

    constructor(
        public gw: ApiGateway,
        public snackBar: SnackBarService
    ) {
        // Set the defaults
        this.onDataChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        return new Promise<void>((resolve, reject) => {
            Promise.all([this.getView()]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getView(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this.routeParams.id === 'novo') {
                this.onDataChanged.next(false);
                resolve(false);
            }
            else {
                this.gw.get('view/wModule/' + this.routeParams.id).subscribe((res: View ) => {
                    this.view = res;
                    this.onDataChanged.next(this.view);
                    resolve(res);
                }, reject);
            }
        });
    }

    insert(view): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('view/', view).subscribe((res: HttpResponse<any>) => {
                resolve(res);
                this.snackBar.successMessage(res.body.message);
            }, reject);
        });
    }

    edit(view): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('view/', view).subscribe((res: HttpResponse<any>) => {
                resolve(res);
                this.snackBar.successMessage(res.body.message);
            }, reject);
        });
    }

    delete(view): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.delete('view/' + view.id).subscribe((res: HttpResponse<any>) => {
                resolve(res);
                this.snackBar.successMessage(res.body.message);
            }, reject);
        });
    }
}
