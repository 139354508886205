import { NgModule } from '@angular/core';
import { HotKeyService } from './hot-key.service';

export { HotKeyService };

@NgModule({
  declarations: [

  ],
  imports: [

  ],
  exports: [

  ],
  providers: [
    HotKeyService,
  ]
})
export class HotKeyModule { }
