import { Injectable } from '@angular/core';
import { CanDeactivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';


export interface CanComponentDeactivate {
    canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
}


@Injectable({
    providedIn: 'root',
})

export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
    constructor() {

    }

    canDeactivate(component: CanComponentDeactivate, currentRoute, currentState, nextState) {
        
        if (nextState.url == "/auth/tela-de-bloqueio") {
            return true;
        } else if (nextState.url.includes('/manter') && currentState.url.includes('/manter')) {
            return true
        }
        else {
            return component.canDeactivate && component.canDeactivate();
        }
    }
}