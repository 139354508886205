
import { NgModule } from '@angular/core';

import { TypeHospitalizationEditProvider } from './type-hospitalization-edit.provider';
import { TypeHospitalizationListProvider } from './type-hospitalization-list.provider';

export * from './type-hospitalization-edit.provider';
export * from './type-hospitalization-list.provider';

export {
  TypeHospitalizationListProvider,
  TypeHospitalizationEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    TypeHospitalizationEditProvider,
    TypeHospitalizationListProvider
  ]
})
export class TypeHospitalizationBackendModule { }
