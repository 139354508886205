
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { Environment, ApiGateway } from 'cw-backend';
import { SnackBarService } from 'cw-utils';
 
@Injectable()
export class EnvironmentEditProvider implements Resolve<any>
{
    routeParams: any;
    state: any;
    onDataChanged: BehaviorSubject<any>;
    environment: Environment;

    constructor(
        public gw: ApiGateway,
        public snackBar: SnackBarService
    ) {
        this.onDataChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        return new Promise<void>((resolve, reject) => {
            Promise.all([this.getEnvironment()]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getEnvironment(): Promise<any> {
        return new Promise((resolve, reject) => {
            let data =  this.checkLastModifications()
            if (data && data.id == this.routeParams.id) {
                this.environment = data;
                this.onDataChanged.next(this.environment);
                resolve(this.environment);
            } else if (this.routeParams.id === 'novo') {
                this.onDataChanged.next(false);
                resolve(false);
            }
            else {
                this.gw.get('environment/' + this.routeParams.id).subscribe((res: Environment ) => {
                    this.environment = res;
                    this.onDataChanged.next(this.environment);
                    resolve(res);
                }, reject);
            }
        });
    }

    insert(environment): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('environment/', environment).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }

    edit(environment): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('environment/', environment).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }

    delete(environment): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.delete('environment/' + environment.id).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                 this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }

    checkLastModifications(): Environment {
        let wasLocked = JSON.parse(localStorage.getItem('session_locked'));
        let data;
        if (wasLocked){
            data = JSON.parse(localStorage.getItem('last_modification'));
            localStorage.removeItem('session_locked');
        }  
        return data;
    }
}
