import { Module, Transaction } from 'cw-backend';

export class View {
    public id?: number;
    public module?: Module;
    public name?: string;
    public description?: string;
    public code?: string;
    public path?: string;
    public viewType?: string;
    public transactions?: Transaction[];
    constructor(
    ) {

    }

}