
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { RoleNavigationService } from 'cw-services';
import { SnackBarService } from 'cw-utils';
import { ViewList } from 'cw-backend';


@Injectable()
export class AuthGuard implements CanActivate {
    roleNavigationService: RoleNavigationService
    constructor(
        private router: Router,
        private snackBar: SnackBarService,

    ) {
        this.roleNavigationService = new RoleNavigationService();
    }
    user: any;
    views: ViewList[];
    permission: boolean;


    // list for ignore the guard
    exception = [
        '/auth/login',
        '/aplicacao/dashboards/admin',
        '/auth/tela-de-bloqueio',
    ]


    canActivate(route?: ActivatedRouteSnapshot, state?: RouterStateSnapshot):
        Observable<boolean> | boolean {

        this.views = JSON.parse(localStorage.getItem('views'));
        
        if (!this.views) {
            this.views = this.roleNavigationService.allowedRole('viewList');
        }
        
        if (localStorage['token'] != null) {
            // redirect to home if user is authenticate
            const viewUrl = this.exception.find(exceptionUrl => exceptionUrl === state.url)

            if (viewUrl) {
                if (viewUrl === "/auth/login") {
                    this.router.navigate(['aplicacao/dashboards/admin']);
                }
                return true;
            }

            this.permission = false;

            // looking is user had permission to the requested view
            this.views.forEach(view => {

                let viewType: any = view.code.split('-');
                viewType = viewType[viewType.length - 1]

                // user has permission if meets conditions above
                if (viewType !== 'list' && state.url.startsWith(view.path)) {
                    this.permission = true;
                }
                else if (view.path.indexOf(state.url) != -1) {
                    this.permission = true;
                }
            });

            //  access denied
            if (!this.permission) {
                let nameOfView: any = state.url.split('/');
                // this.snackBar.warnMessage("Acesso negado à " + nameOfView[3]);
                this.snackBar.warnMessage("Acesso não permitido");
                return false;
            }

            // access permited 
            return true;
        }
        else {
            const viewUrl = this.exception.find(exceptionUrl => exceptionUrl === state.url)
            if (viewUrl) {
                if (viewUrl === "/auth/login") {
                    return true;
                } else {
                    this.router.navigate(['auth/login']);
                    return false;
                }
            }
        }
    }
}
  
