import { Module, Transaction, View } from 'cw-backend';
import { RestrictableComponent } from '../restrictable-component';

export class RoleControl {
    constructor(
        public id?: number,
        public name?: string,
        public description?: string,
        public modules?: Module[],
        public views?: View[],
        public transactions?: Transaction[],
        public restrictableComponents?: RestrictableComponent[],
        public type?: string,
    ) {
    }

}