
import { NgModule } from '@angular/core';

import { OccupationTissEditProvider } from './occupation-tiss-edit.provider';
import { OccupationTissListProvider } from './occupation-tiss-list.provider';

export * from './occupation-tiss-edit.provider';
export * from './occupation-tiss-list.provider';

export {
  OccupationTissListProvider,
  OccupationTissEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    OccupationTissEditProvider,
    OccupationTissListProvider
  ]
})
export class OccupationTissBackendModule { }
