
import { NgModule } from '@angular/core';

import { HoraryEditProvider } from './horary-edit.provider'
import { HoraryListProvider } from './horary-list.provider'

export * from './horary-edit.provider'
export * from './horary-list.provider'

export {
  HoraryListProvider,
  HoraryEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    HoraryEditProvider,
    HoraryListProvider
  ]
})
export class HoraryBackendModule { }
