
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiGateway } from '../../../api-gateway';
import { SnackBarService } from '../../../../cw-utils/snackbar.service';
import { Address } from '../../../models/tables/address/address';

@Injectable()
export class AddressEditService implements Resolve<any>
{
    routeParams: any;
    state: any;
    onDataChanged: BehaviorSubject<any>;
    address: Address;

    constructor(
        public gw: ApiGateway,
        public snackBar: SnackBarService,       
     private http: HttpClient,
    ) {
        this.onDataChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        return new Promise<void>((resolve, reject) => {
            Promise.all([this.getAddress()]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getAddress(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this.routeParams.id === 'novo') {
                this.onDataChanged.next(false);
                resolve(false);
            }
            else {
                this.gw.get('address/' + this.routeParams.id).subscribe((res: Address ) => {
                    this.address = res;
                    this.onDataChanged.next(this.address);
                    resolve(res);
                }, reject);
            }
        });
    }

    insert(address): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('address/', address).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }

    edit(address): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.put('address/', address).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }

    delete(address): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.delete('address/' + address.id).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                 this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }

    getCep(cep): Promise<any> {
        return new Promise((resolve, reject) => {
          this.http
            .get(`https://viacep.com.br/ws/${cep}/json`)
            .subscribe({
              next: (res: HttpResponse<any[]>) => { res.body ? resolve(res.body) : resolve(res) },
              error(error) { reject(error) }
            });
        });
      }

    getCnpj(): Promise<any> {
        return new Promise((resolve, reject) => {
          this.http
            .get(`https://brasilapi.com.br/api/cnpj/v1/02459271000135`)
            .subscribe({
              next: (res: HttpResponse<any[]>) => { res.body ? resolve(res.body) : resolve(res) },
              error(error) { reject(error) }
            });
        });
      }

      getByZipCode(zipCode) {
        if (!zipCode) {
            return null;
        }
        return this.gw.get('address/zipCode/:zipCode', { zipCode: zipCode });
    }
}
