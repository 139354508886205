import { UserLogService } from 'cw-backend';
import { CwUtils } from 'cw-utils';
// default
import { Component, OnInit, ViewEncapsulation, ViewChild, TemplateRef, ElementRef, Input, Inject, AfterViewInit } from '@angular/core';
import { Subject } from 'rxjs';
// fuse
import { fuseAnimations } from '@fuse/animations';
// backend
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'last-access-list',
    templateUrl: './last-access-modal.component.html',
    styleUrls: ['./last-access-modal.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class LastAccessComponent implements OnInit {
    @ViewChild('dialogContent')
    dataSource: any;
    dialogContent: TemplateRef<any>;
    lastAccessList: any[];
    accessDate: Date;
    successDate: Date;
    eventCodeList: any[];


    /**
    * Constructor
    * @param {Router} _router
    * @param {MatDialog} _matDialog
    */

    private _unsubscribeAll: Subject<any>;
    public argument: string;

    constructor(
        private cwUtils: CwUtils,
        private userLogService: UserLogService,
        public matDialogRef: MatDialogRef<LastAccessComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
    ) {
        this._unsubscribeAll = new Subject();
        // Set the defaults 
    }
    /**
     * 
     * On init
     * 
     */
    ngOnInit(): void {
        this.getEventCodeList()

        if (this._data.lastAccessList) {
            this.lastAccessList = this._data.lastAccessList;
            this.lastAccessList.reverse();
        } else {
            this.lastAccessList = JSON.parse(localStorage.getItem('user_log'));
            if (this.lastAccessList != null) {
                this.lastAccessList.reverse();
            }
        }

    }

    getAccessDate(accessDate) {
        return CwUtils.dateConverter(accessDate);
    }

    getEventCodeList() {
        this.userLogService.getEventCodeList().then(res => {
            this.eventCodeList = res;
        })

    }

    dimiss() {
        this.matDialogRef.close();
    }
} 
