
import { NgModule } from '@angular/core';

import { CountryEditProvider } from './country-edit-provider';
import { CountryListProvider } from './country-list-provider';

export * from './country-edit-provider';
export * from './country-list-provider';

export {
  CountryListProvider,
  CountryEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    CountryEditProvider,
    CountryListProvider
  ]
})
export class CountryBackendModule { }
