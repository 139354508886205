import { Directive, Input, ElementRef, Renderer2 } from '@angular/core';
import { log } from 'console';

@Directive({
  selector: '[appHighlight]'
})
export class HighlightDirective {
  @Input('appHighlightText') text: string;
  @Input('appHighlightFilter') filter: string;

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnChanges() {
    if (this.text && this.filter) {
      const regex = new RegExp(`(${this.filter})`, 'gi');
      const innerHTML = this.text;
      const highlighted = innerHTML.replace(regex, '<span class="highlight">$1</span>');
      this.renderer.setProperty(this.el.nativeElement, 'innerHTML', highlighted);
    }
    else {
        const innerHTML = this.text;
        this.renderer.setProperty(this.el.nativeElement, 'innerHTML', innerHTML);
      }
  }

  ngOnDestroy() {
    const innerHTML = this.el.nativeElement.innerHTML;
    this.renderer.setProperty(this.el.nativeElement, 'innerHTML', innerHTML);
  }
}
