import { ImageObj } from './../../interface/image-obj';
import { ContactList } from '../../others/contact-list';
import { CostCenter } from './cost-center';
import { Address } from '../../tables/address/address';
import { Shift } from './shift';
import { RegistryType } from '../../backend-definitions.module';

export class Company {
    id: number;
    name: string;
    image: ImageObj;
    tradeName: string;
    alias: string;
    active: boolean;
    unity: boolean;
    issuingEntity: string;
    registryType: RegistryType;
    cnpj: string;
    cnes: string;
    address: Address;
    contactList: ContactList;
    companyParent: Company;
    costCenterList: CostCenter[];
    shiftList: Shift[];
    constructor() {
    }
}

export class CompanyCostCenter {
    id: number;
    company: Company;
    costCenter: CostCenter;
}