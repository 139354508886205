import { Order } from "./order";
import { OrderPayDetail } from "./order-pay-detail";

export class OrderPay{
    id: number;
    order: Order;
    account: any;
    name: string;
    payType: any;
    brand: any;
    date: string;
    receptDate: string;
    parcel: number;
    value: number
    orderPayDetail: OrderPayDetail
}