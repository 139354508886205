
import { NgModule } from '@angular/core';

import { AddressEditService } from './address-edit-service';
import { AddressListService } from './address-list-service';

export * from './address-edit-service';
export * from './address-list-service';

export {
  AddressListService,
  AddressEditService
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    AddressEditService,
    AddressListService
  ]
})
export class AddressBackendModule { }
