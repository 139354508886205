import { User } from "./user-model";

export class Profile {
    id: number;
    user: User;
    name: string;
    status: string;
    mood: string;
}

export class Message {
    id: number;
    profileFrom: Profile;
    profileTo: Profile;
    message: string;
    sended: string;
    group: string;
    readed: string;
}


export class ProfileChatTo{
    profile: Profile;
    id: number;
    name:string;
    userId: number;
    status: string;
    avatar: string;
    unread: number;
}
