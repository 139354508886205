
import { Injectable, EventEmitter } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Billing, PageOptions, ApiGateway } from 'cw-backend';
import { CwUtils } from 'cw-utils';
import { TypeGuide } from '../../../models/backend-definitions.module';

@Injectable()
export class BillingListProvider implements Resolve<any>
{
    billing: Billing[];
    billingEdit: Billing;
    onDataChanged: BehaviorSubject<any>;
    routeParams: any;
    state: any;
    pageOptions: PageOptions;
    currentPage: PageOptions;

    constructor(
        public gw: ApiGateway,
        private location: Location,
    ) {
        this.onDataChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        // Get state for current or new page
        this.state = this.location.getState();
        this.currentPage = this.state.currentPage;
        this.billingEdit = this.state.currentBilling;

        // if (this.currentPage) {
        //     this.pageOptions = this.currentPage;
        // }
        // else {
        this.pageOptions = new PageOptions();
        this.pageOptions.fieldSort = 'dateat';
        this.pageOptions.sortDirection = 'desc'
        // }
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this.getPageByRelease(this.pageOptions, "0")
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    static selectedInsurance = new EventEmitter<any>();

    get(id: number): Observable<Billing> {
        if (!id) {
            return null;
        }
        return this.gw.get('billing/:id', { id: id });
    }

    get currentBilling(): Billing {
        return this.billingEdit;
    }

    getAll(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('billing/').subscribe((res: HttpResponse<any>) => {
                this.billing = res.body;
                resolve(res);
            }, reject);
        });
    }

    getBillingFull(billingId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get(`billing/wAll/${billingId}`,)
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        res.body ? resolve(res.body) : resolve(res);
                    },
                    error: (err: any) => { reject(err); }
                });
        });
    }

    getPageBillingOrderItem(billingId: number, pageoptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post(`billing/page/billing-order-item/${billingId}`, pageoptions)
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        res.body ? resolve(res.body) : resolve(res);
                    },
                    error: (err: any) => { reject(err); }
                });
        });
    }

    fetchTotalizers(billingId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get(`billing/fetch-totalizers/${billingId}`)
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        res.body ? resolve(res.body) : resolve(res);
                    },
                    error: (err: any) => { reject(err); }
                });
        });
    }

    getBillingRealeaseById(billingId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get(`billing/wAll/release/${billingId}`,)
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        res.body ? resolve(res.body) : resolve(res);
                    },
                    error: (err: any) => { reject(err); }
                });
        });
    }

    getPreBillingInsurance(insuranceId: number): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.gw.get("billing/pre-billing/insurance/:insuranceId", { insuranceId: insuranceId })
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        res.body ? resolve(res.body) : resolve(res);
                    },
                    error: (err: any) => { reject(err); }
                })
        })
    }

    getBillingFilterListById(billingId: number): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.gw.get("billing/filter/:billingId", { billingId: billingId })
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        res.body ? resolve(res.body) : resolve(res);
                    },
                    error: (err: any) => { reject(err); }
                })
        })
    }

    getPage(pageOptions: PageOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('billing/search', pageOptions).subscribe((res: HttpResponse<any>) => {
                if (!CwUtils.compare(this.billing, res.body.content) && res.body.content.length > 0) {
                    this.billing = res.body.content;
                    this.onDataChanged.next(this.billing);
                }
                else {
                    this.billing = res.body.content;
                };
                resolve(res);
            }, reject);
        });
    }

    getPageByRelease(pageOptions: PageOptions, type): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post(`billing/search/${type}`, pageOptions).subscribe({
                next: (res: HttpResponse<any>) => {
                    if (!CwUtils.compare(this.billing, res.body.content) && res.body.content.length > 0) {
                        this.billing = res.body.content;
                        this.onDataChanged.next(this.billing);
                    }
                    else {
                        this.billing = res.body.content;
                    };
                    resolve(res);
                }, error: (err => { reject(err) })
            });
        });
    }

    getBillingRelease(insuranceId: number, pageSearchTO): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.gw.post(`billing/release/${insuranceId}`, pageSearchTO).subscribe({
                next: (res: HttpResponse<any>) => {
                    res.body ? resolve(res.body) : resolve(res);
                }, error: (err: any) => { reject(err) }
            })
        })
    }
    getBillingPendency(insuranceId: number): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.gw.get('billing/pendency/:insuranceId', { insuranceId: insuranceId }).subscribe({
                next: (res: HttpResponse<any>) => {
                    res.body ? resolve(res.body) : resolve(res);
                }, error: (err: any) => { reject(err) }
            })
        })
    }

    getBillingFullByTypeGuide(billing: Billing, typeGuide?: TypeGuide, pageOptions?:PageOptions): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            console.log(typeGuide)
            this.gw.post('billing/eletronic-billing/:billingId/:typeGuideId', { billingId: billing.id, typeGuideId: typeGuide == null ? 0 : typeGuide.id }, pageOptions).subscribe({
                next: (res: HttpResponse<any>) => {
                    res.body ? resolve(res.body) : resolve(res);
                }, error: (err: any) => { reject(err) }
            })
        })
    }

    ///billing/check-preBilling/34
    checkPreBilling(preBillingId) {
        return new Promise<any>((resolve, reject) => {
            this.gw.get("billing/check/preBilling/:preBillingId", { preBillingId: preBillingId })
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        res.body ? resolve(res.body) : resolve(res);
                    }, error: (err: any) => { reject(err) }
                })
        })
    }

    getPageSettings() {
        if (this.currentPage) {
            return this.pageOptions;
        } else {
            return;
        }
    }
}

