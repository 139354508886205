
import { NgModule } from '@angular/core';

import { QuotaLackControlEditProvider } from './quotalackcontrol-edit.provider';
import { QuotaLackControlListProvider } from './quotalackcontrol-list.provider';

export * from './quotalackcontrol-edit.provider';
export * from './quotalackcontrol-list.provider';

export {
  QuotaLackControlListProvider,
  QuotaLackControlEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    QuotaLackControlEditProvider,
    QuotaLackControlListProvider
  ]
})
export class QuotaLackControlBackendModule { }
