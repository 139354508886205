import * as localforage from 'localforage';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  /**
   *
   * @param key
   * @param value
   * @returns {any}
   */
  public setItem<T>(key: string, value: T): Observable<T> {
    return from(localforage.setItem(key, value))
  }

  /**
   *
   * @param key
   * @returns {any}
   */
  public getItem<T>(key: string): Observable<{}> {
    return from(localforage.getItem(key));
  }

  /**
   *
   * @param key
   * @returns {any}
   */
  public removeItem(key: string): Observable<void> {
    return from(localforage.removeItem(key))
  }
}