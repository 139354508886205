
import { NgModule } from '@angular/core';

import { StateEditService } from './state-edit-service';
import { StateListService } from './state-list-service';

export * from './state-edit-service';
export * from './state-list-service';

export {
  StateListService,
  StateEditService
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    StateEditService,
    StateListService
  ]
})
export class StateBackendModule { }
