
import { NgModule } from '@angular/core';
import { KitListProvider } from './kit-list.provider';


export * from './kit-list.provider';



@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    KitListProvider
  ]
})
export class KitBackendModule { }
