import { Injectable } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';

@Injectable()
export class FormProcessor {

    private processDefaultError(errorKey:string):string{
        let errorMsg = '';
        switch(errorKey){
            case 'maxlength':
            errorMsg =  'O tamanho máximo do campo foi atingido.';
            break;
            case 'minlength':
            errorMsg =  'O tamanho mínimo do campo não foi atingido.';
            break;
            case 'email':
            errorMsg =  'O e-mail digitado é inválido.';
            break;
            case 'required':
            errorMsg =  'Campo é obrigatório.';
            break;
            case 'requiredTrue':
            errorMsg =  'A opção não foi marcada.';
            break;
            case 'requiredTrue':
            errorMsg =  'A opção não foi marcada.';
            break;
            default:
            errorMsg =  'O valor digitado não passou na validação. Código: ' + errorKey + '.' ;
            break;
        }
        return errorMsg;

    }
    processFormGroup(form: any, frmMessages: any, frmErrors: any) {

        for (let field in frmErrors) {

            if (field == '_error') {
                continue;
            }

            const control = form.get(field);

            if (control instanceof FormGroup) {
                frmErrors[field]['_error'] = '';

                if (control && control.dirty && !control.valid) {                    
                    let messages = frmMessages[field];

                    if(!messages){
                        messages = [];
                    }

                    if (control.errors != null) {
                        const errorKey = Object.keys(control.errors)[0];
                        if(messages[errorKey]){
                            frmErrors[field]['_error'] = messages[errorKey];
                        }else{
                            frmErrors[field]['_error'] = this.processDefaultError(errorKey);
                        }
                    }
                    let group = <FormGroup>control;
                    let groupErrors = frmErrors[field];
                    this.processFormGroup(group, messages, groupErrors);
                }
            }
            else {
                frmErrors[field] = '';
                if (control && control.dirty && !control.valid) {
                    let messages = frmMessages[field];

                    if(!messages){
                        messages = [];
                    }

                    const errorKey = Object.keys(control.errors)[0];
                        if(messages[errorKey]){
                            frmErrors[field] = messages[errorKey];
                        }else{
                            frmErrors[field]= this.processDefaultError(errorKey);
                        }

                 
                }
            }
        }
    }
}
