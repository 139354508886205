export * from './loader-provider';
export * from './api-gateway-module';
export * from './util/backend-util-module';
export * from './util.provider';
export * from './../cw-directives/directives';

// MODELS
export * from './models/backend-definitions.module';

// ********************************** PROVIDERS ****************************

// REGISTER -> SECURITY
export * from './providers/security/role/role-backend-module';
export * from './providers/security/module/module-backend-module';
export * from './providers/security/transaction/transaction-backend-module';
export * from './providers/security/view/view-backend-module';
export * from './providers/security/user/user-backend-module';
export * from './providers/security/audit/audit-backend-module';

// REGISTER -> ORGANIZATIONAL
export * from './providers/organizational/company/company-backend-module';
export * from './providers/organizational/cost-center/cost-center-backend-module';
export * from './providers/organizational/professional/professional-backend-module';
export * from './providers/organizational/horary/horary-backend-module';
export * from './providers/organizational/participation/participation-backend-module';
export * from './providers/organizational/professional/professional-backend-module';
export * from './providers/organizational/holiday/holiday-backend-module';
export * from './providers/organizational/environment/environment-backend-module';
export * from './providers/organizational/thirdparty/thirdparty-backend-module';

// REGISTER -> OPERATIONAL
export * from './providers/register/operational/patient/patient-backend-module';
export * from './providers/register/operational/operation/operation-backend-module';
export * from './providers/register/operational/event/event-backend-module';
export * from './providers/register/operational/exame-type/exame-type-backend-module';


// REGISTER -> ACCREDITATION
export * from './providers/accreditation/product/product-backend-module';
export * from './providers/accreditation/quotation/quotation-backend-module';
export * from './providers/accreditation/insurance/insurance-backend-module';
export * from './providers/accreditation/currency/currency-backend-module';
export * from './providers/accreditation/fee/fee-backend-module';
export * from './providers/accreditation/procedure/procedure-backend-module';
export * from './providers/accreditation/kit/kit-backend-module';
export * from './providers/accreditation/agreement/agreement-backend-module';
export * from './providers/accreditation/accreditation/accreditation-backend-module';
export * from './providers/accreditation/speciality/speciality-backend-module';
export * from './providers/accreditation/auxiliary/auxiliary-backend-module';

// TABLES -> GENERAL 
export * from './providers/address/address/address-backend-module';
export * from './providers/address/address-type/address-type-backend-module';
export * from './providers/address/country/country-backend-module';
export * from './providers/address/state/state-backend-module';
export * from './providers/address/city/city-backend-module';
export * from './providers/address/city/city-backend-module';
export * from './providers/general/occupation-tiss/occupation-tiss-backend-module';
export * from './providers/general/occupation/occupation-backend-module';
export * from './providers/general/breed/breed-backend-module';
export * from './providers/general/schooling/schooling-backend-module';
export * from './providers/general/civil-status/civil-status-backend-module';
export * from './providers/general/ethnicity/ethnicity-backend-module';
export * from './providers/general/gender/gender-backend-module';
export * from './providers/general/firm/firm-backend-module';
export * from './providers/general/council/council-backend-module';
export * from './providers/general/firm/firm-backend-module';
export * from './providers/general/schooling/schooling-backend-module';
export * from './providers/general/civil-status/civil-status-backend-module';
export * from './providers/general/ethnicity/ethnicity-backend-module';
export * from './providers/general/gender/gender-backend-module';
export * from './providers/general/person/person-backend-module';
export * from './providers/general/registry-type/registry-type-backend-module';
export * from './providers/general/council/council-backend-module';
export * from './providers/def/def-backend-module';
export * from './providers/general/pending/pending-backend-module';
// TABLES -> ACCREDITATION
export * from './providers/accreditation/category/category-backend-module';
export * from './providers/accreditation/degree-participation/degree-participation-backend-module';
export * from './providers/accreditation/group/group-backend-module';
export * from './providers/accreditation/plan/plan-backend-module';
export * from './providers/accreditation/table-type/table-type-backend-module';
export * from './providers/accreditation/table-type-version/table-type-version-backend-module';
export * from './providers/accreditation/standard-table/standard-table-backend-module';
export * from './providers/accreditation/port/port-backend-module';
export * from './providers/accreditation/measuretiss/measuretiss-backend-module';
export * from './providers/accreditation/quotalackcontrol/quotalackcontrol-backend-module';
// TABLES -> OPEACCREDITATIONRATIONAL



// OPERATIONAL -> SCHEDULING
export * from './providers/operational/scheduling/scheduling-backend-module';

// ATTENDANCE -> ATTENDANCE
export * from './providers/attendance/attendance/attendance-backend-module';
export * from './providers/attendance/budget/budget-backend-module';
// ATTENDANCE -> BATCHENTRY
export * from './providers/operational/operational/reception/batchentry/batchentry-backend-module';
// ATTENDANCE -> AUTHORIZATION
export * from './providers/attendance/order-authorization/order-authorization-backend-module';

//ATTENDANCE -> TISS
export * from './providers/attendance/order-tiss/order-tiss-backend-module';

//ATTENDANCE -> PAY 
export * from './providers/attendance/order-pay/order-pay-backend-module';
export * from './providers/attendance/order-pay-detail/order-pay-detail-backend-module';

// ATTENDANCE -> CLOSSURE
export * from './providers/attendance/order-closure/order-closure-backend-module';


// ATTENDANCE -> PENDING
export * from './providers/attendance/order-pending/order-pending-backend-module';

// Billing -> billing
export * from './providers/billing/billing/billing-backend-module';
export * from './providers/billing/filter/filter-backend-module';
export * from './providers/billing/pay/pay-backend-module';
export * from './providers/billing/transfer/transfer-backend-module';





//EXPORT BY GENERATOR
export * from './providers/tiss/type-guide/type-guide-backend-module';
export * from './providers/tiss/accident-indicator/accident-indicator-backend-module';
export * from './providers/tiss/accommodation/accommodation-backend-module';
export * from './providers/tiss/closing-reason/closing-reason-backend-module';
export * from './providers/tiss/hospitalization-regime/hospitalization-regime-backend-module';
export * from './providers/tiss/service-character/service-character-backend-module';
export * from './providers/tiss/type-attendance/type-attendance-backend-module';
export * from './providers/tiss/type-hospitalization/type-hospitalization-backend-module';
export * from './providers/tiss/type-survey/type-survey-backend-module';
export * from './providers/tiss/via-access/via-access-backend-module';
export * from './providers/tiss/via-administration/via-administration-backend-module';

export * from './providers/financial/bank/bank-backend-module';
export * from './providers/financial/brand/brand-backend-module';
export * from './providers/financial/account/account-backend-module';
export * from './providers/financial/agency/agency-backend-module';
export * from './providers/financial/paytype/paytype-backend-module';
export * from './providers/financial/account-plan/account-plan-backend-module';
export * from './providers/financial/company-account-plan/company-account-plan-backend-module';
export * from './providers/gloss-type/gloss-type-backend-module'


export * from './providers/operational-system/exclusion-motive/exclusion-motive-backend-module';
export * from './providers/operational-system/alert/alert-backend-module';
export * from './providers/operational-system/atribute/atribute-backend-module';
export * from './providers/organizational/contacts/contacts-backend-module';



//export * from './providers/scheduling/surgeryschedule/surgeryschedule-backend-module';


//{exportModuleIndex}

// APPLICATIONS -> DASHBOARDS 
export * from './providers/dashboard-admin/dashboard-admin.module';
export * from './providers/application/parameter/parameter-backend-module';
// ****************************** END OF PROVIDERS *********************

// WEB SOCKET
export * from './web-socket-service';

// PARAMETER




 

