
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { ApiGateway, PageOptions } from 'cw-backend';
import { CwUtils } from 'cw-utils';
import { BehaviorSubject, Observable } from 'rxjs';
import { GlossType } from '../../models/tables/accreditation/gloss-type';

@Injectable()
export class GlossTypeListProvider implements Resolve<any>
{
    glossType: GlossType[];
    glossTypeEdit: GlossType;
    onDataChanged: BehaviorSubject<any>;
    routeParams: any;
    state: any;
    pageOptions: PageOptions;
    currentPage: PageOptions;

    constructor(
        public gw: ApiGateway,
        private location: Location,
    ) {
        this.onDataChanged = new BehaviorSubject({}); 
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        // Get state for current or new page
        this.state = this.location.getState();
        this.currentPage = this.state.currentPage;
        this.glossTypeEdit = this.state.currentGlossType;

        if (this.currentPage) {
            this.pageOptions = this.currentPage;
        }
        else {
            this.pageOptions = new PageOptions();
            this.pageOptions.fieldSort = 'name';
        }
        return new Promise((resolve, reject) => {
            Promise.all([
                this.getPage(this.pageOptions)
            ]).then(
                (res) => {
                    resolve(res);
                },
                reject
            );
        });
    }

    get(id: number): Observable<GlossType> {
        if (!id) {
            return null;
        }
        return this.gw.get('glossType/:id', { id: id });
    }

     get currentGlossType(): GlossType {
        return this.glossTypeEdit;
    }

    getAll(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('glossType/')
            .subscribe({
                next:(res: HttpResponse<any>) => {
                    this.glossType = res.body;
                    resolve(res);
                 },error: (err: any) => { reject(err); }
            });
        });

    }
 
    getPage(pageOptions: PageOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('glossType/search', pageOptions)
                .subscribe({
                    next:(res: HttpResponse<any>) => {
                        if (!CwUtils.compare(this.glossType, res.body.content) && res.body.content.length > 0) {
                            this.glossType = res.body.content;
                            this.onDataChanged.next(this.glossType);
                        }
                        else {
                            this.glossType = res.body.content;
                        };
                        resolve(res);
                    },error: (err: any) => { reject(err); }
                });
        });
    }

     getPageSettings() {
        if (this.currentPage) {
            return this.pageOptions;
        } else {
            return;
        }
    }
}

