
import { NgModule } from '@angular/core';

import { CompanyAccountPlanEditProvider } from './company-account-plan-edit.provider';
import { CompanyAccountPlanListProvider } from './company-account-plan-list.provider';

export * from './company-account-plan-edit.provider';
export * from './company-account-plan-list.provider';

export {
  CompanyAccountPlanListProvider,
  CompanyAccountPlanEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    CompanyAccountPlanEditProvider,
    CompanyAccountPlanListProvider
  ]
})
export class CompanyAccountPlanBackendModule { }
