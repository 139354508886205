
import { NgModule } from '@angular/core';

import { TableTypeVersionEditProvider } from './table-type-version-edit.provider';
import { TableTypeVersionListProvider } from './table-type-version-list.provider';

export * from './table-type-version-edit.provider';
export * from './table-type-version-list.provider';

export {
  TableTypeVersionListProvider,
  TableTypeVersionEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    TableTypeVersionEditProvider,
    TableTypeVersionListProvider
  ]
})
export class TableTypeVersionBackendModule { }
