import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiGateway } from '../../../api-gateway';

@Injectable()
export class ModuleEditService implements Resolve<any>
{
    routeParams: any;
    module: any;
    onDataChanged: BehaviorSubject<any>;

    /**
        * Constructor
        *
        * @param {ApiGateway} gw
        */
    constructor(
        public gw: ApiGateway
    ) {
        // Set the defaults
        this.onDataChanged = new BehaviorSubject({});
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        return new Promise<void>((resolve, reject) => {

            Promise.all([this.getModule()]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get product
     *
     * @returns {Promise<any>}
     */
    getModule(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this.routeParams.id === 'novo') {
                this.onDataChanged.next(false);
                resolve(false);
            }
            else {
                this.gw.get('module/' + this.routeParams.id).subscribe((response: any) => {
                    this.module = response;
                    this.onDataChanged.next(this.module);
                    resolve(response);
                }, reject);
            }
        });
    }

    /**
     * Edit module
     *
     * @param module
     * @returns {Promise<any>}
     */
    edit(module): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.put('module/', module).subscribe((res: any) => {
                resolve(res);
            }, reject);
        });
    }

    /**
     * Insert module
     *
     * @param module
     * @returns {Promise<any>}
     */
    insert(module): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('module/', module).subscribe((response: HttpResponse<any>) => {
                resolve(response);
            }, reject);
        });
    }

    /**
    * Delete module
    *
    * @param module
    * @returns {Promise<any>}
    */
    delete(module): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.delete('module/' + module.id).subscribe((response: HttpResponse<any>) => {
                resolve(response);
            }, reject);
        });
    }
}
