
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { Procedure, ApiGateway, Operation } from 'cw-backend';
import { SnackBarService } from 'cw-utils';

@Injectable()
export class OperationEditProvider implements Resolve<any>
{
    routeParams: any;
    state: any;
    onDataChanged: BehaviorSubject<any>;
    operation: Operation;

    constructor(
        public gw: ApiGateway,
        public snackBar: SnackBarService
    ) {
        this.onDataChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        return new Promise<void>((resolve, reject) => {
            Promise.all([this.getOperation()]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getOperation(): Promise<any> {
        return new Promise((resolve, reject) => {
            let data = this.checkLastModifications()
            if (data && data.id == this.routeParams.id) {
                this.operation = data;
                this.onDataChanged.next(this.operation);
                resolve(this.operation);
            } else if (this.routeParams.id === 'novo') {
                this.onDataChanged.next(false);
                resolve(false);
            }
            else {
                this.gw.get('operation/wAll/' + this.routeParams.id).subscribe((res: Operation) => {
                    this.operation = res;
                    this.onDataChanged.next(this.operation);
                    resolve(res);
                }, reject);
            }
        });
    }

    insert(operation, method?): Promise<any> {
      operation.id = null;
        operation.user = { id: JSON.parse(localStorage.getItem('user')).id };
      //  operation.actived = true;
        if (method == 'cancel') {
            operation.type = "C";
        } else if (method == 'unlock'){
            operation.type = "U";
        }
        return new Promise((resolve, reject) => {
            this.gw.post('operation/', operation).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }

    edit(operation): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.put('operation/', operation).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }

    delete(operation): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.delete('operation/' + operation.id).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }

    checkLastModifications(): Operation {
        let wasLocked = JSON.parse(localStorage.getItem('session_locked'));
        let data;
        if (wasLocked) {
            data = JSON.parse(localStorage.getItem('last_modification'));
            localStorage.removeItem('session_locked');
        }
        return data;
    }
}
