
import { NgModule } from '@angular/core';

import { OrderClosureProvider } from './order-closure.provider';

export * from './order-closure.provider';

export {
  OrderClosureProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    OrderClosureProvider
  ]
})
export class OrderClosureBackendModule { }
