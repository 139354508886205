
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { PageOptions, ApiGateway } from 'cw-backend';
import { CwUtils } from 'cw-utils';
import { Pay } from '../../../models/operational/pay/pay';

@Injectable()
export class PayListProvider implements Resolve<any>
{
    pay: Pay[];
    payEdit: Pay;
    onDataChanged: BehaviorSubject<any>;
    routeParams: any;
    state: any;
    pageOptions: PageOptions;
    currentPage: PageOptions;

    constructor(
        public gw: ApiGateway,
        private location: Location,
    ) {
        this.onDataChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        // Get state for current or new page
        this.state = this.location.getState();
        this.currentPage = this.state.currentPage;
        this.payEdit = this.state.currentBillingPay;

        if (this.currentPage) {
            this.pageOptions = this.currentPage;
            this.pageOptions.fieldSort = 'creditDate';
            this.pageOptions.sortDirection = 'desc';
        }
        else {
            this.pageOptions = new PageOptions();
            this.pageOptions.fieldSort = 'creditDate';
            this.pageOptions.sortDirection = 'desc';
        }
        return new Promise((resolve, reject) => {
            Promise.all([
                this.getPage(this.pageOptions)
            ]).then(
                (res) => {
                    resolve(res);
                },
                reject
            );
        });
    }

    get(id: number): Observable<any> {
        if (!id) {
            return null;
        }
        return this.gw.get('pay/:id', { id: id });
    }

    get currentPay(): Pay {
        return this.payEdit;
    }

    getAll(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('pay/')
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        this.pay = res.body;
                        resolve(res);
                    }, error: (err: any) => { reject(err); }
                });
        });

    }

    getPage(pageOptions: PageOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('pay/search', pageOptions)
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        if (!CwUtils.compare(this.pay, res.body.content) && res.body.content.length > 0) {
                            this.pay = res.body.content;
                            this.onDataChanged.next(this.pay);
                        }
                        else {
                            this.pay = res.body.content;
                        };
                        resolve(res);
                    }, error: (err: any) => { reject(err); }
                });
        });
    }

    getPageBillingOrderItem(pageOptions: PageOptions, payId:number): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post(`pay/search/billing-pay-order-item/${payId}`, pageOptions)
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        if (!CwUtils.compare(this.pay, res.body.content) && res.body.content.length > 0) {
                            this.pay = res.body.content;
                            this.onDataChanged.next(this.pay);
                        }
                        else {
                            this.pay = res.body.content;
                        };
                        resolve(res);
                    }, error: (err: any) => { reject(err); }
                });
        });
    }

    getBillingPayOrderItem(billingPayOrderItemId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('pay/billing-pay-order-item/:billingPayOrderItemId', { billingPayOrderItemId: billingPayOrderItemId })
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        resolve(res);
                    }, error: (err: any) => { reject(err); }
                });
        });
    }

    getInfoGlosa(billingPayId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('pay/info-gloss/:billingPayId', { billingPayId: billingPayId })
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        resolve(res);
                    }, error: (err: any) => { reject(err); }
                });
        });
    }
    getEartefact(payId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get(`pay/eartefact/${payId}`)
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        resolve(res);
                    }, error: (err: any) => { reject(err); }
                });
        });
    }

    getPayXml(payId:number, type:string, pageSearchTO){
        return new Promise<any>((resolve, reject) => {
            this.gw.post(`pay/pay-xml/${payId}/${type}`, pageSearchTO)
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        resolve(res);
                    }, error: (err: any) => { reject(err); }
                });
        });
    }

    findProtocol(payId:number){
        return new Promise<any>((resolve, reject) => {
            this.gw.get(`pay/pay-xml/protocol/${payId}`)
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        resolve(res);
                    }, error: (err: any) => { reject(err); }
                });
        });
    }

    getCreditValue(payId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('pay/calc/credit-value/:payId', { payId: payId })
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        resolve(res);
                    }, error: (err: any) => { reject(err); }
                });
        });
    }

    getRecoveryBillingOrderItem(billingIdLis): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('pay/recovery/billing-order-item/',  billingIdLis)
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        resolve(res);
                    }, error: (err: any) => { reject(err); }
                });
        });
    }

    getPageSettings() {
        if (this.currentPage) {
            return this.pageOptions;
        } else {
            return;
        }
    }
}

