
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { OccupationTiss, PageOptions, ApiGateway } from 'cw-backend';
import { CwUtils } from 'cw-utils';

@Injectable()
export class OccupationTissListProvider implements Resolve<any>
{
    occupationTiss: OccupationTiss[];
    occupationTissEdit: OccupationTiss;
    onDataChanged: BehaviorSubject<any>;
    routeParams: any;
    state: any;
    pageOptions: PageOptions;
    currentPage: PageOptions;

    constructor(
        public gw: ApiGateway,
        private location: Location,
    ) {
        this.onDataChanged = new BehaviorSubject({}); 
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        // Get state for current or new page
        this.state = this.location.getState();
           if (this.state.currentPage && state.url.includes(this.state.path)) {
            this.currentPage = this.state.currentPage;
        }
        this.occupationTissEdit = this.state.currentOccupationTiss;

        if (this.currentPage) {
            this.pageOptions = this.currentPage;
        }
        else {
            this.pageOptions = new PageOptions();
            this.pageOptions.fieldSort = 'name';
        }
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this.getPage(this.pageOptions)
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    get(id: number): Observable<OccupationTiss> {
        if (!id) {
            return null;
        }
        return this.gw.get('occupationTiss/:id', { id: id });
    }

     get currentOccupationTiss(): OccupationTiss {
        return this.occupationTissEdit;
    }

    getAll(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('occupationTiss/').subscribe((res: HttpResponse<any>) => {
                this.occupationTiss = res.body;
                this.onDataChanged.next(this.occupationTiss);
                resolve(res);
            }, reject);
        });

    }
 
    getPage(pageOptions: PageOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('occupationTiss/search', pageOptions).subscribe((res: HttpResponse<any>) => {
                this.occupationTiss = res.body.content;
                this.onDataChanged.next(this.occupationTiss);
                resolve(res);
            }, reject);
        });
    }

     getPageSettings() {
        if (this.currentPage) {
            return this.pageOptions;
        } else {
            return;
        }
    }
}

