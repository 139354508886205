

import { NgModule } from '@angular/core';
import { TransactionListService } from './transaction-list-service';
import { TransactionEditService } from './transaction-edit-service'; 

export * from './transaction-list-service';
export * from './transaction-edit-service';

export {
  TransactionListService,
  TransactionEditService
};

@NgModule({
  declarations: [
  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    TransactionListService,
    TransactionEditService,
  ]
})
export class TransactionBackendModule { }
