import {
    AccidentIndicator,
    Accommodation,
    HospitalizationRegime,
    Order,
    ServiceCharacter,
    TypeAttendance,
    TypeGuide,
    TypeHospitalization,
    TypeSurvey,
} from "cw-backend";
import { DegreeParticipation } from "../backend-definitions.module";
import { OccupationalHealth } from "../tables/tiss/occupational-health";
import { AttendanceRegime } from "../tables/tiss/attendance-regime";

export class OrderTiss {
    id: number;
    order: Order;
    typeGuide: TypeGuide;
    serviceCharacter: ServiceCharacter;
    accidentIndicator: AccidentIndicator;
    accommodation: Accommodation;
    typeSurvey: TypeSurvey;
    typeAttendance: TypeAttendance;
    hospitalizationRegime: HospitalizationRegime;
    typeHospitalization: TypeHospitalization;
    dobbyNumber: string;
    requestDate: Date;
    requestTime: Date;
    billingStartDate: Date;
    billingEndDate: Date;
    diseaseTime: number;
    principalGuideNumber: string;
    operatorGuideNumber: string;
    diseaseTimeUnit: string;
    notes: string;
    newBorn: Boolean;
    degreeParticipation: DegreeParticipation;
    billing_type: string;
    certificate_alive: string;
    certificate_death: string;
    certificate_death_newborn: boolean;
    occupationalHealth: OccupationalHealth;
    attendanceRegime: AttendanceRegime;
    indication: string;
}
