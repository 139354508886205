import { Address, Gender, Breed, Ethnicity, City, Country, CivilStatus, Schooling, Occupation, ContactList } from 'cw-backend';
import { PersonElector } from './person-elector';
import { PersonCertificate } from './person-certificate';
import { PersonIdentity } from './person-identity';
import { PersonImage } from './person-image';
import { PersonWorkCard } from './person-work-card';
import { Moment } from 'moment';
import { Foreign } from './foreing';
import { Professional } from '../../register/organizational/professional';

export class Person {
    name: string;
    id: number;
    birthday: Moment;
    age: string;
    address: Address;
    gender: Gender;
    breed: Breed;
    ethnicity: Ethnicity;
    city: City;
    country: Country;
    civilStatus: CivilStatus;
    schooling: Schooling;
    phoneStandart?:string;
    emailStandart?:string;
    occupation: Occupation;
    contactList: ContactList;
    alias: string;
    socialName: string;
    cpf: string;
    cns: string;
    nis: string;
    attendSchool: boolean;
    pcd: boolean;
    mother: string;
    dad: string;
    treatment: string;
    personCertificate: PersonCertificate;
    personElector: PersonElector;
    personIdentity: PersonIdentity;
    personImageList: PersonImage[];
    personWorkCard: PersonWorkCard;
    foreign: Foreign;
    professional?: Professional;
    photoMain?: string;
}