
import { NgModule } from '@angular/core';

import { SchoolingEditProvider } from './schooling-edit.provider';
import { SchoolingListProvider } from './schooling-list.provider';

export * from './schooling-edit.provider';
export * from './schooling-list.provider';

export {
  SchoolingListProvider,
  SchoolingEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    SchoolingEditProvider,
    SchoolingListProvider
  ]
})
export class SchoolingBackendModule { }
