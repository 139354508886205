
import { NgModule } from '@angular/core';

import { EnvironmentEditProvider } from './environment-edit.provider';
import { EnvironmentListProvider } from './environment-list.provider';

export * from './environment-edit.provider';
export * from './environment-list.provider';

export {
  EnvironmentListProvider,
  EnvironmentEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    EnvironmentEditProvider,
    EnvironmentListProvider
  ]
})
export class EnvironmentBackendModule { }
