
import { NgModule } from '@angular/core';

import { InsuranceEditProvider } from './insurance-edit.provider';
import { InsuranceListProvider } from './insurance-list.provider';

export * from './insurance-edit.provider';
export * from './insurance-list.provider';

export {
  InsuranceListProvider,
  InsuranceEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    InsuranceEditProvider,
    InsuranceListProvider
  ]
})
export class InsuranceBackendModule { }
