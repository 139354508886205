
import { NgModule } from '@angular/core';

import { SpecialityEditProvider } from './speciality-edit.provider';
import { SpecialityListProvider } from './speciality-list.provider';

export * from './speciality-edit.provider';
export * from './speciality-list.provider';

export {
  SpecialityListProvider,
  SpecialityEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    SpecialityEditProvider,
    SpecialityListProvider
  ]
})
export class SpecialityBackendModule { }
