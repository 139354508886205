
import { NgModule } from '@angular/core';

import { PayEditProvider } from './pay-edit.provider';
import { PayListProvider } from './pay-list.provider';

export * from './pay-edit.provider';
export * from './pay-list.provider';

export {
    PayListProvider,
    PayEditProvider as BillingPayEditProvider
};

@NgModule({
    declarations: [

    ],
    imports: [
    ],
    exports: [

    ],
    providers: [
        PayEditProvider,
        PayListProvider
    ]
})
export class PayBackendModule { }
