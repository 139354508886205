import { NgModule } from '@angular/core';
import { ParameterProvider } from './parameter.provider';


export { ParameterProvider };

@NgModule({
  declarations: [

  ],
  imports: [

  ],
  exports: [

  ],
  providers: [
    ParameterProvider,
  ]
})
export class ParameterBackendModule { }
