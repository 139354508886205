import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class TenantService {

    constructor() { }


    addTenantToHeaders(headers: HttpHeaders): HttpHeaders {
        return null;
        // return headers.append("X-TenantID", this.getTenant());
      }

    getTenant(): any {
        return this.getTenantForHostname(location.hostname);
      }

    getTenantForHostname(hostname: string) {
        return this.getTenantForHost(hostname.split(".")[0])
    }

    getTenantForHost(host: string): String {
        return this.getTenantForString(host);
    }

    getTenantForString(s: string) {
        return s;
        // for (const e in Tenant) {
        //     if (e.toLowerCase() === s.toLowerCase()) {
        //         return Tenant[e] as Tenant;
        //     }
        // }
        // return null;
    }

   
}

//Criar um para para salvar os tenents
export enum Tenant {
    tenant_production = "tenant_production",
    tenant_homologation = "tenant_homologation"
}