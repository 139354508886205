import { HttpResponse } from '@angular/common/http';

import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RoleControl, RestrictableComponent, Transaction } from '../../../models/backend-definitions.module';
import { RoleListProvider } from './role-list.provider';
import { RoleEditProvider } from './role-edit.provider';
import { ViewListService } from '../view/view-list-service';
import { SnackBarService } from '../../../../cw-utils/cw-utils.module';
import { ApiGateway } from '../../../api-gateway';

@Injectable()
export class RoleControlService {
    routeParams: any;

    public onRoleControlChanged: BehaviorSubject<RoleControl>;
    public onComponentChanged: BehaviorSubject<RestrictableComponent[]>;
    public onTransactionChanged: BehaviorSubject<Transaction[]>;

    roleControl: RoleControl;
    restrictableComponents: RestrictableComponent[];
    transactionList: Transaction[];

    constructor
        (
            private roleListService: RoleListProvider,
            private roleEditService: RoleEditProvider,
            private viewListService: ViewListService,
            private snackBar: SnackBarService,
            private gw: ApiGateway,
    ) {
        this.onRoleControlChanged = new BehaviorSubject({});
        this.onComponentChanged = new BehaviorSubject([]);
        this.onTransactionChanged = new BehaviorSubject([]);
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        return new Promise<void>((resolve, reject) => {
            Promise.all([this.getRoleControl(this.routeParams.id)]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getRoleControl(roleId: number) {
        this.roleListService.getRolePermission(roleId)
            .subscribe(res => {
                this.roleControl = res.body;
                this.roleControl.restrictableComponents = [];
                this.onRoleControlChanged.next(this.roleControl);
            });
    }

    getViewComponentsByRoleId(roleId, viewId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('role/:roleId/view/:viewId/components/', { roleId: roleId, viewId: viewId }).subscribe((res: HttpResponse<any>) => {
                resolve(res);
            }, reject);
        });
    }


    getTransactionsByViewId(viewId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('user/view/:viewId/transactions', { viewId: viewId }).subscribe((res: HttpResponse<any>) => {
                resolve(res);
            }, reject);
        });
    }

    getViewTransactionsByRoleId(roleId: number, viewId: number) {
        this.roleListService.getViewComponentsByRoleId(roleId, viewId)
            .then(res => {
                this.restrictableComponents = res.body;
                this.onTransactionChanged.next(this.transactionList);
            });
    }

    getTransactionByViewId(viewId: number) {
        this.viewListService.getTransactions(viewId)
            .then(res => {
                this.transactionList = res.body;
                this.onTransactionChanged.next(this.transactionList);
            });
    }

    saveRoleControl(roleControl: RoleControl) {
        this.roleEditService.edit(roleControl).then((res) => {
            this.snackBar.successMessage(res.body.message);
        })
    }
}