import { CompanyCostCenter } from '../../register/organizational/company';
import { Agency } from './agency';
import { CompanyAccountPlan } from './company-account-plan';

export class Account {
    id: number;
    agency: Agency;
    companyCostCenter: CompanyCostCenter;
    companyAccountPlan: CompanyAccountPlan;
    code: string;
    type: string;
    active: boolean;
    spin: boolean;
    appliance: boolean;
    limit: number;
    since: Date;
}