import { ImageObj } from '../../interface/image-obj';

export class PersonImage {
    id: number;
    image: ImageObj;
    main: boolean;
    description: string;
    type: number;
}


