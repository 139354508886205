<!-- LIST HEADER -->
<div *ngIf="header === 'list'" fxLayout="column" fxLayoutAlign="start start" style="background-color: #0097a7 !important;" class="mr-24">
    <div fxLayout="row" fxLayoutAlign="start center" fxHide fxShow.gt-md >
        <mat-icon class="secondary-text s-16">home</mat-icon>
        <div *ngFor="let item of breadcrumb" class="align-center secondary-text">
            <mat-icon class="s-16">chevron_right</mat-icon>
            <span>{{item}}</span>
        </div>
    </div>

    <div class="logo mb-20 mb-md-0 mt-4" fxLayout="row" fxLayoutAlign="start start">
        <mat-icon class="logo-icon s-24 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
            person
        </mat-icon>
        <span class="logo-text h2" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
            {{mainName}}
        </span>
    </div>
</div>
<!-- /LIST HEADER -->

<!-- EDIT HEADER -->
<div *ngIf="header === 'edit'" fxLayout="column" fxLayoutAlign="start start" style="background-color: #0097a7 !important; height: 52px!important;">
    <div fxLayout="row" fxLayoutAlign="start center" fxHide fxShow.gt-md class="mt-4">
        <mat-icon class="secondary-text s-16">home</mat-icon>
        <div *ngFor="let item of breadcrumb" class="align-center secondary-text">
            <mat-icon class="s-16">chevron_right</mat-icon>
            <span>{{item}}</span>
        </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="start start" class="mb-20" *ngIf="isBackPage == false">
        <button id="navigate_back" mat-icon-button [routerLink]="'/' + urlPath" (click)="scrollTop()">
            <mat-icon>arrow_back</mat-icon>
        </button>
        <div [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}" class="h2 mt-8 maxEditName">
            {{mainName}}
        </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="start start" class="mb-20"  *ngIf="isBackPage == true">
        <button id="navigate_back" mat-icon-button (click)="backPage()">
            <mat-icon>arrow_back</mat-icon>
        </button>
        <div [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}" class="h2 mt-8 maxEditName">
            {{mainName}}
        </div>
    </div>
</div>
<!-- /EDIT HEADER -->