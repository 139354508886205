
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Quotation, PageOptions, ApiGateway } from 'cw-backend';
import { SnackBarService } from '../../../../cw-utils/snackbar.service';
import { CwUtils } from 'cw-utils';

@Injectable()
export class QuotationListProvider implements Resolve<any>
{
    quotation: Quotation[];
    quotationEdit: Quotation;
    onDataChanged: BehaviorSubject<any>;
    routeParams: any;
    state: any;
    pageOptions: PageOptions;
    currentPage: PageOptions;

    constructor(
        public gw: ApiGateway,
        private location: Location,
    ) {
        this.onDataChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        // Get state for current or new page
        this.state = this.location.getState();
        if (this.state.currentPage && state.url.includes(this.state.path)) {
            this.currentPage = this.state.currentPage;
        }
        this.quotationEdit = this.state.currentQuotation;

        if (this.currentPage) {
            this.pageOptions = this.currentPage;
        }
        else {
            this.pageOptions = new PageOptions();
            this.pageOptions.fieldSort = 'currency';
        }
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this.getPage(this.pageOptions, null)
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    get(id: number): Observable<Quotation> {
        if (!id) {
            return null;
        }
        return this.gw.get('quotation/:id', { id: id });
    }

    get currentQuotation(): Quotation {
        return this.quotationEdit;
    }

    getAll(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('quotation/').subscribe((res: HttpResponse<any>) => {
                this.quotation = res.body;
                this.onDataChanged.next(this.quotation);
                resolve(res);
            }, reject);
        });

    }
    getQuotationPage(pageOptions: PageOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('quotation/search', pageOptions).subscribe({
                next: (res: HttpResponse<any>) => {
                    this.quotation = res.body;
                    this.onDataChanged.next(this.quotation);
                    resolve(res);
                },
                error: (err: any) => { reject(err) }
            });
        });

    }

    getQuotationByInsurance(insuranceId, startAt){
        return new Promise<any>((resolve, reject) => {
            this.gw.post('quotation/insurance/:insuranceId', {insuranceId: insuranceId || 0}, startAt)
            .subscribe({
                next: (res: any) => {
                    resolve(res);
                }
            })
        })
    }

    getPage(pageOptions: PageOptions, quotationType): Promise<any> {
        if (!quotationType) {
            return null;
        }
        return new Promise((resolve, reject) => {
            this.gw.post('currency/quotation/quotationItemList/' + quotationType, pageOptions).subscribe((res: HttpResponse<any>) => {
                if (!CwUtils.compare(this.quotation, res.body.content) && res.body.content.length > 0) {
                    this.quotation = res.body.content;
                    this.onDataChanged.next(this.quotation);
                } else {
                    this.quotation = res.body.content;
                }
                resolve(res);
            }, reject);
        });
    }

    getPageSettings() {
        if (this.currentPage) {
            return this.pageOptions;
        } else {
            return;
        }
    }

    getQuotationType(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('currency/quotation/quotationType').subscribe((res: HttpResponse<any>) => {
                resolve(res);
            }, reject);
        });
    }

    getQuotationSimpleList(type): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('quotation/quotationItemList/' + type).subscribe((res: HttpResponse<any>) => {
                resolve(res);
            }, reject);
        });
    }


    getQuotationByTypeAndRelationId(quotationType, relationId, pageOptions: PageOptions, renew?): Promise<any> {
        pageOptions.sizePage = 5;
        if (!quotationType) {
            return null;
        }
        let entity
        switch (quotationType) {
            case 'I':
                entity = 'insurance';
                break;
            case 'P':
                entity = 'procedure';
                break;
            case 'G':
                entity = 'group';
                break;
        }
        let path = entity + '/quotation/quotationItemList/'

        return new Promise((resolve, reject) => {
            this.gw.post(path + quotationType + '/' + relationId, pageOptions).subscribe((res: HttpResponse<any>) => {
                if ((!CwUtils.compare(this.quotation, res.body.content) && res.body.content.length > 0) || renew) {
                    this.quotation = res.body.content;
                    this.onDataChanged.next(this.quotation);
                } else {
                    this.quotation = res.body.content;
                }
                resolve(res);
            }, reject);
        });
    }

}

