
import { NgModule } from '@angular/core';

import { BudgetEditProvider } from './budget-edit.provider';
import { BudgetListProvider } from './budget-list.provider';

export * from './budget-edit.provider';
export * from './budget-list.provider';

export {
  BudgetListProvider,
  BudgetEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    BudgetEditProvider,
    BudgetListProvider
  ]
})
export class BudgetBackendModule { }
