
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiGateway, Horary } from 'cw-backend';
import { SnackBarService } from 'cw-utils';

@Injectable()
export class HoraryEditProvider 
{
    routeParams: any;
    state: any;
    onDataChanged: BehaviorSubject<any>;
    horary: Horary;

    constructor(
        public gw: ApiGateway,
        public snackBar: SnackBarService
    ) {
        this.onDataChanged = new BehaviorSubject({});
    }

    insert(horary): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('professional/horary/', horary).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }

    insertHoraryCustom(horaryCustom): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('professional/horaryCustom/', horaryCustom).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }

    edit(horary): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.put('professional/horary/', horary).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }

    editHoraryCustom(horaryCustom): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.put('professional/horaryCustom/', horaryCustom).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }

    delete(horary): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.delete('professional/horary/' + horary.id).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }

    deleteHoraryCustom(horaryCustom): Promise<any> {
            return new Promise((resolve, reject) => {
                this.gw.delete('professional/horaryCustom/' + horaryCustom.id).subscribe((response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                }, reject);
            });
        }

    checkLastModifications(): Horary {
        let wasLocked = JSON.parse(localStorage.getItem('session_locked'));
        let data;
        if (wasLocked) {
            data = JSON.parse(localStorage.getItem('last_modification'));
            localStorage.removeItem('session_locked');
        }
        return data;
    }
}
