import { NgModule } from '@angular/core';
import { RolePermissionService } from './role-permission.service';
import { RoleNavigationService } from './role-navigation.service';

export { RoleNavigationService, RolePermissionService };

@NgModule({
  declarations: [

  ],
  imports: [

  ],
  exports: [

  ],
  providers: [
    RoleNavigationService,
    RolePermissionService,
  ]
})
export class RoleServiceModule { }
