<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="accent-600 m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <div class="contact-name mt-8">Informações Gerais</div>
            <button mat-icon-button (click)="dimiss()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div class="mt-15" *ngIf="loading" fxLayoutAlign="center center">
        <mat-spinner></mat-spinner>
    </div>

    <div mat-dialog-content class="p-12 pb-0 m-0" *ngIf="!loading">        

        <form name="generalInformationForm" [formGroup]="generalInformationForm" class="generalInformation-edit w-100-p"
            fxLayout="column">

            <mat-form-field fxFlex="15" appearance="outline">
                <mat-label>Selecione</mat-label>
                <mat-select [formControl]="typeControl" disableRipple>
                  <mat-option value="1">Convênio</mat-option>
                  <mat-option value="2">Profissionais</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="typeControl.value == '1'" appearance="outline" fxFlex="15">
                <mat-label>Convênio</mat-label>
                <input matInput [matAutocomplete]="autoInsurance" formControlName="insurance" autocomplete="off"
                    type="search" />
                <mat-autocomplete autoActiveFirstOption #autoInsurance="matAutocomplete" autoActiveFirstOption
                    [displayWith]="displayInsuranceFn">
                    <mat-option *ngFor="let insurance of insuranceList" [value]="insurance">
                        <p *ngIf="insurance.name">
                            {{ insurance.name }}
                        </p>
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <mat-form-field *ngIf="typeControl.value == '2'" appearance="outline" fxFlex="20">
                <mat-label>Profissional</mat-label>
                <input matInput [matAutocomplete]="autoProfessional" formControlName="professional"
                    placeholder="Profissional" type="search">
                <mat-autocomplete autoActiveFirstOption #autoProfessional="matAutocomplete"
                    [displayWith]="displayPerformerFn">
                    <mat-option *ngFor="let professional of professionalList" [value]="professional">
                        {{professional.person.name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </form>

        <mat-tab-group class="tab-size" [disableRipple]="true" mat-stretch-tabs>           

            <mat-tab label="Aviso">
                <mat-tab-group class="tab-size" [disableRipple]="true" mat-stretch-tabs [selectedIndex]="selectedTabIndex"
                (selectedIndexChange)="changeViewByTab($event)">
                    <mat-tab *ngFor="let alert of alertList; let index = index" label="{{alert.name}}">
                        <quill-editor #quill id="quill" style="height: 80%;" [modules]="configEditor" [formControl]="alertForm"
                        [disabled]="true" format="html">
                    </quill-editor>
                    </mat-tab>
                </mat-tab-group>               
            </mat-tab>

            <mat-tab *ngIf="typeControl.value == '1'" label="Planos">

                <mat-list style="border: 1px solid; border-radius: 5px; padding: 5px; margin: 5px;" role="list" *ngFor="let plan of planList">
                    <mat-list-item role="listitem">{{plan.name}}</mat-list-item>                   
                </mat-list>
                  

            </mat-tab>

        </mat-tab-group>



    </div>
</div>