import { PlanListProvider } from './../../../../../shared/cw-backend/providers/accreditation/plan/plan-list.provider';
import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { InsuranceListProvider } from "../../../../../shared/cw-backend/providers/accreditation/insurance/insurance-list.provider";
import { PageOptions } from "../../../../../shared/cw-utils/cw-utils.service";
import {
    Alert,
    AlertSearch,
    Insurance,
    Plan,
    Professional,
} from "../../../../../shared/cw-backend/models/backend-definitions.module";
import { Subject, debounceTime, distinctUntilChanged, takeUntil } from "rxjs";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { AlertListProvider } from "../../../../../shared/cw-backend/providers/operational-system/alert/alert-list.provider";
import { fuseAnimations } from "@fuse/animations";
import { AttendanceListProvider } from "../../../../../shared/cw-backend/providers/attendance/attendance/attendance-list.provider";

@Component({
    selector: "app-general-information",
    templateUrl: "./general-information.component.html",
    styleUrls: ["./general-information.component.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class GeneralInformationComponent implements OnInit {
    loading: boolean = false;
    selectedTabIndex: number;
    typeControl = new FormControl("1");
    generalInformationForm: FormGroup;
    alertForm = new FormControl();

    //listagens
    insuranceList: Insurance[];
    planList: Plan[];
    alertList = new Array<Alert>();
    professionalList = new Array<Professional>();

    //page options
    insurancePageOptions: PageOptions;
    professionalPageOptions: PageOptions;
    planPageOptions: PageOptions;

    private _unsubscribeAll: Subject<any>;

    configEditor = {
        toolbar: [
        ],
    };

    constructor(
        public dialogRef: MatDialogRef<GeneralInformationComponent>,
        private insuranceListProvider: InsuranceListProvider,
        private AttendanceListProvider: AttendanceListProvider,
        private alertListProvider: AlertListProvider,
        private planListProvider : PlanListProvider,
        private _formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.insurancePageOptions = new PageOptions();
        this.insurancePageOptions.fieldSort = "name";
        this.insurancePageOptions.page = 0;
        this.insurancePageOptions.sizePage = 10;
        this.insurancePageOptions.argument = ""

        this.planPageOptions = new PageOptions();
        this.planPageOptions.fieldSort = "name";
        this.planPageOptions.page = 0;
        this.planPageOptions.sizePage = 10;
        this.planPageOptions.argument = ""

        this.professionalPageOptions = new PageOptions();
        this.professionalPageOptions.fieldSort = "person.name";
        this.professionalPageOptions.page = 0;
        this.professionalPageOptions.sizePage = 10;
        this.professionalPageOptions.argument = ""

        this._unsubscribeAll = new Subject();
    }

    ngOnInit() {
        this.generalInformationForm = this.createSurgeryScheduleForm();
        this.getAll();
        this.filterAll();
    }

    dimiss() {
        this.dialogRef.close();
    }

    getAll(){
        this.getInsurance();
        this.getProfessionalList();
    }

    filterAll(){
        this.filterInsurance();
        this.filterProfessional();
    }

    getInsurance() {
        this.insuranceListProvider
            .getPageSimpleList(this.insurancePageOptions)
            .then((res) => {
                this.insuranceList = res.body.content;
            });
    }

    filterInsurance() {
        this.generalInformationForm.controls["insurance"].valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(500),
                distinctUntilChanged()
            )
            .subscribe((res) => {
                
                if (res && res.id) {
                    this.insurancePageOptions.argument = res.name;                   
                    this.getPlanList(res.id)
                    this.generalInformationForm.controls["professional"].setValue(null);
                    this.getAlerts();                   
                } else if (res && typeof res === "string") {
                    this.insurancePageOptions.argument = res;
                } else {
                    this.insurancePageOptions.argument = "";
                }

                this.getInsurance();
            });
    }

    displayInsuranceFn(insurance?: any): string | undefined {
        if (!insurance) {
            return "";
        }
        if (insurance.name) {
            return insurance.name;
        }
        return insurance.name;
    }

    createSurgeryScheduleForm(): FormGroup {
        return this._formBuilder.group({
            insurance: new FormControl(null),
            professional: new FormControl(null),
        });
    }

    changeViewByTab(tab) {
        this.getAlerts();
         this.selectedTabIndex = tab;
        
         this.alertForm.setValue(this.alertList[this.selectedTabIndex].content);

    }

    getAlerts() {
        let alertSearch = new AlertSearch();      

        alertSearch.insuranceId = this.generalInformationForm.controls["insurance"]?.value?.id;
        alertSearch.professionalId = this.generalInformationForm.controls["professional"]?.value?.id;
        alertSearch.index = 6;

        this.alertListProvider.getFindModule(alertSearch).then(async (res) => {            
            console.log("🚀 ~ GeneralInformationComponent ~ this.alertListProvider.getFindModule ~ res:", res)
            this.alertList = new Array<Alert>();
            if (res.body.length > 0) {                
                this.alertList = res.body.sort((a, b) => a.sequence - b.sequence);;                
                this.alertForm.setValue(this.alertList[0].content);
                await new Promise((resolve) => setTimeout(resolve, 100));
                  
                this.alertForm.disable()
                
            } else {
                this.alertForm.setValue("");
            }
        });
    }

    async getProfessionalList() {
        await this.AttendanceListProvider.getProfessional(
            this.professionalPageOptions
        ).then((res) => {
            this.professionalList = res.body.content;
        });
    }

    filterProfessional() {
        this.generalInformationForm.controls['professional']
            .valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(500),
                distinctUntilChanged()
            ).subscribe({
                next: (res => {
                    if (res && res.id) {
                        this.professionalPageOptions.argument = res.name;                  
                        this.generalInformationForm.controls["insurance"].setValue(null);
                        this.getAlerts();                   
                    } else if (res && typeof res === "string") {
                        this.professionalPageOptions.argument = res;
                    } else {
                        this.professionalPageOptions.argument = "";
                    }
                    this.getProfessionalList();
                })
            })
    }

    displayPerformerFn(performer?: any): string | undefined {
        if (!performer) {
            return "";
        }
        if (performer.professional) {
            return performer.professional.person.name;
        }
        return performer.person.name;
    }

    getPlanList(insuranceId){
        this.planListProvider.getPlanSimpleList(insuranceId, this.planPageOptions).then(res =>{
            console.log(res)
            this.planList = res.body;
        })
    }
}
