import { Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from "@angular/router";
import { Location } from "@angular/common";
import { HttpResponse } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { Insurance, PageOptions, ApiGateway } from "cw-backend";
import { CwUtils } from "cw-utils";

@Injectable()
export class InsuranceListProvider implements Resolve<any> {
    insurance: Insurance[];
    insuranceEdit: Insurance;
    onDataChanged: BehaviorSubject<any>;
    routeParams: any;
    state: any;
    pageOptions: PageOptions;
    currentPage: PageOptions;

    constructor(public gw: ApiGateway, private location: Location) {
        this.onDataChanged = new BehaviorSubject({});
    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        // Get state for current or new page
        this.state = this.location.getState();
        if (this.state.currentPage && state.url.includes(this.state.path)) {
            this.currentPage = this.state.currentPage;
        }
        this.insuranceEdit = this.state.currentInsurance;

        if (this.currentPage) {
            this.pageOptions = this.currentPage;
        } else {
            this.pageOptions = new PageOptions();
            this.pageOptions.fieldSort = "name";
        }
        return new Promise<void>((resolve, reject) => {
            Promise.all([this.getPage(this.pageOptions)]).then(() => {
                resolve();
            }, reject);
        });
    }

    get(id: number): Observable<HttpResponse<Insurance>> {
        if (!id) {
            return null;
        }
        return this.gw.get("insurance/:id", { id: id });
    }

    get currentInsurance(): Insurance {
        return this.insuranceEdit;
    }

    getAll(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get("insurance/").subscribe({
                next: (res: HttpResponse<any>) => {
                    this.insurance = res.body;
                    this.onDataChanged.next(this.insurance);
                    resolve(res);
                },
                error: (err) => reject(err),
            });
        });
    }

    getPaymentsModel(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .get("insurance/insurancePaymentModelType/simpleList")
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        resolve(res);
                    },
                    error: (err) => reject(err),
                });
        });
    }

    getInsuranceListByProcedureId(
        pageOptions: PageOptions,
        procedureId: number,
        entity?: String
    ): Promise<any> {
        let urlPath = "insurance/search/";
        if (entity) {
            urlPath = entity + urlPath;
        }
        return new Promise((resolve, reject) => {
            this.gw.post(urlPath + procedureId, pageOptions).subscribe({
                next: (res: HttpResponse<any>) => {
                    if (res.body) {
                        this.insurance = res.body.content;
                        this.onDataChanged.next(this.insurance);
                    }
                    resolve(res);
                },
                error: (err) => reject(err),
            });
        });
    }

    getInsuranceCoparticipationList(pageOptions: PageOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post(
                    "insurance/findByPaymentModelCoparticipation",
                    pageOptions
                )
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        resolve(res);
                    },
                    error: (err) => reject(err),
                });
        });
    }

    getPage(pageOptions: PageOptions, entity?): Promise<any> {
        let urlPath = "insurance/search";
        if (entity) {
            urlPath = entity + urlPath;
        }
        return new Promise((resolve, reject) => {
            this.gw.post(urlPath, pageOptions).subscribe({
                next: (res: HttpResponse<any>) => {
                    if (
                        !CwUtils.compare(this.insurance, res.body.content) &&
                        res.body.content.length > 0
                    ) {
                        this.insurance = res.body.content;
                        this.onDataChanged.next(this.insurance);
                    } else {
                        this.insurance = res.body.content;
                    }
                    resolve(res);
                },
                error: (err) => reject(err),
            });
        });
    }

    getPageSimpleList(pageOptions: PageOptions, entity?): Promise<any> {
        let urlPath = "insurance/search/simpleList";
        if (entity) {
            urlPath = entity + urlPath;
        }
        return new Promise((resolve, reject) => {
            this.gw.post(urlPath, pageOptions).subscribe({
                next: (res: HttpResponse<any>) => {
                    resolve(res);
                },
                error: (err) => reject(err),
            });
        });
    }

    getPageWebservice(pageOptions: PageOptions): Promise<any> {
        let urlPath = "insurance/search/webService";
        return new Promise((resolve, reject) => {
            this.gw.post(urlPath, pageOptions).subscribe({
                next: (res: HttpResponse<any>) => {
                    resolve(res);
                },
                error: (err) => reject(err),
            });
        });
    }

    getInsuranceByMultipleIds(idList): Promise<any> {
        let urlPath = "insurance/find-multiple-ids";

        return new Promise((resolve, reject) => {
            this.gw.post(urlPath, idList).subscribe({
                next: (res: HttpResponse<any>) => {
                    resolve(res);
                },
                error: (err) => reject(err),
            });
        });
    }

    getInsurancePlanList(
        insuranceId: number,
        pageOptions: PageOptions,
        entity?
    ): Promise<any> {
        let urlPath = `insurance/insurance-plan/search/${insuranceId}`;
        if (entity) {
            urlPath = entity + urlPath;
        }
        return new Promise((resolve, reject) => {
            this.gw.post(urlPath, pageOptions).subscribe({
                next: (res: HttpResponse<any>) => {
                    resolve(res);
                },
                error: (err) => reject(err),
            });
        });
    }

    getInsurancePlanById(insurancePLanId: number): Promise<any> {
        let urlPath = `insurance/insurance-plan/${insurancePLanId}`;

        return new Promise((resolve, reject) => {
            this.gw.get(urlPath).subscribe({
                next: (res: HttpResponse<any>) => {
                    resolve(res);
                },
                error: (err) => reject(err),
            });
        });
    }

    getInsuranceByPerformer(
        pageOptions: PageOptions,
        professionalId
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post(
                    "scheduling/insurance/search/:professionalId",
                    { professionalId: professionalId },
                    pageOptions
                )
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        if (
                            !CwUtils.compare(
                                this.insurance,
                                res.body.content
                            ) &&
                            res.body.content.length > 0
                        ) {
                            this.insurance = res.body.content;
                            this.onDataChanged.next(this.insurance);
                        } else {
                            this.insurance = res.body.content;
                        }
                        resolve(res);
                    },
                    error: (err) => reject(err),
                });
        });
    }

    getMatriz(pageOptions: PageOptions, insuranceId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post(
                    "insurance/findByIdNotAndInsuranceParentNull/:insuranceId",
                    { insuranceId: insuranceId },
                    pageOptions
                )
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        resolve(res);
                    },
                    error: (err) => reject(err),
                });
        });
    }

    getFilteredPlanList(pageOptions: PageOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post("plan/search", pageOptions).subscribe({
                next: (res: HttpResponse<any>) => {
                    resolve(res);
                },
                error: (err) => reject(err),
            });
        });
    }

    getDiscountTypeEnum() {
        return new Promise<any>((resolve, reject) => {
            this.gw.get("insurance/discount-type/enum").subscribe({
                next: (res: HttpResponse<any>) => {
                    resolve(res);
                },
                error: (err) => reject(err),
            });
        });
    }

    getPlanListByInsuranceId(
        insuranceId: number,
        pageOptions: PageOptions,
        entity?: String
    ): Promise<any> {
        let path;
        if (entity) {
            path = entity + "plan/search/available/" + insuranceId;
        } else {
            path = "plan/search/available/" + insuranceId;
        }
        return new Promise((resolve, reject) => {
            this.gw.post(path, pageOptions).subscribe({
                next: (res: HttpResponse<any>) => {
                    resolve(res);
                },
                error: (err) => reject(err),
            });
        });
    }

    getPageSettings() {
        if (this.currentPage) {
            return this.pageOptions;
        } else {
            return;
        }
    }

    getInsuranceType(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get("insurance/insuranceType/simpleList").subscribe({
                next: (res: HttpResponse<any>) => {
                    resolve(res);
                },
                error: (err) => reject(err),
            });
        });
    }
}
