import { NgModule } from '@angular/core';
import { AuthService } from './auth.service';
import { AuthGuard } from './auth-guard.service';
import { RoleNavigationService } from '../role/role-service.module';

export * from './auth.service';
export * from './auth-guard.service'


@NgModule({
  declarations: [

  ],
  imports: [

  ],
  exports: [
  ],
  providers: [
    AuthService,
    AuthGuard,
    RoleNavigationService
  ]
})
export class AuthServiceModule { }
