import { EventEmitter, Injectable, Output, Directive } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ScheduleHotKeyService } from "../hot-key/schedule.hot-key.service";
import { ScheduleListProvider } from "../../cw-backend/providers/operational/scheduling/schedule-list.provider";

@Directive()
@Injectable()
export class ScheduleService {
    @Output() scheduleSource: EventEmitter<any> = new EventEmitter();
    constructor() {}

    public static scheduleStatusIcon(status) {
        const statusIcons = [
            { status: "B", icon: "lock" },
            { status: "X", icon: "group_add" },
            { status: "L", icon: "radio_button_unchecked" },
            { status: "V", icon: "filter_1" },
            { status: "R", icon: "description" },
            { status: "U", icon: "local_hospital" },
            { status: "A", icon: "how_to_reg" },
        ];
        const icon = statusIcons.find((el) => el.status === status);
        return icon ? icon.icon : "lens";
    }

    public static patientStatusIcon(status) {
        const statusIcons = [
            { status: "T", icon: "hourglass_empty" },
            { status: "R", icon: "accessibility_new" },
            { status: "E", icon: "edit_location" },
            { status: "X", icon: "offline_pin" },
            { status: "L", icon: "work_off" },
            { status: "P", icon: "where_to_vote" },
            { status: "U", icon: "local_hospital" },
            { status: "A", icon: "beenhere" },
            { status: "C", icon: "done" },
            { status: "Z", icon: "backspace" },
            { status: "V", icon: "filter_1" },
            { status: "W", icon: "not_interested" },
            { status: "Q", icon: "announcement" },
            { status: "F", icon: "call_missed_outgoing" },
            { status: "B", icon: "report_off" },
            { status: "I", icon: "new_releases" },
            { status: "S", icon: "update" },
        ];
        const icon = statusIcons.find((el) => el.status === status);
        return icon ? icon.icon : "lens";
    }

    public static setField(scheduleForm, schedule): FormGroup {
        scheduleForm.controls["id"].setValue(schedule.id);
        scheduleForm.controls["performer"].setValue(schedule.performer, {
            eventEmmiter: false,
        });
        scheduleForm.controls["insurance"].setValue(schedule.insurance, {
            eventEmmiter: false,
        });
        scheduleForm.controls["procedure"].setValue(schedule.procedure, {
            eventEmmiter: false,
        });
        scheduleForm.controls["horary"].setValue(schedule.horary);
        scheduleForm.controls["company"].setValue(schedule.company);

        scheduleForm.controls["date"].setValue(schedule.date);
        scheduleForm.controls["dateStatus"].setValue(schedule.dateStatus);
        scheduleForm.controls["hour"].setValue(schedule.hour);
        scheduleForm.controls["hourStatus"].setValue(schedule.hourStatus);
        scheduleForm.controls["patientStatus"].setValue(schedule.patientStatus);
        scheduleForm.controls["scheduleStatus"].setValue(
            schedule.scheduleStatus
        );
        scheduleForm.controls["priority"].setValue(schedule.priority);
        scheduleForm.controls["counter"].setValue(0);
        scheduleForm.controls["operation"].setValue(schedule.operation);
        schedule.session
            ? scheduleForm.controls["session"].setValue(schedule.session)
            : scheduleForm.controls["session"].setValue({
                  id: null,
                  company: null,
                  dateat: null,
                  frequency: null,
                  weekdays: null,
                  quantity: 0,
                  weekdayshorary: null,
                  blocked: false,
                  schedulebysession: null,
                  orderbysession: null,
              });

        schedule.scheduleNote
            ? scheduleForm.controls["scheduleNote"].setValue(
                  schedule.scheduleNote
              )
            : scheduleForm.controls["scheduleNote"].setValue({
                  id: null,
                  note: null,
              });

        if (schedule.patient) {
            scheduleForm.controls["patient"].setValue(schedule.patient);
        } else if (schedule.person) {
            scheduleForm.controls["patient"].setValue(schedule.person.name);
        } else {
            scheduleForm.controls["patient"].setValue(null);
        }
        return scheduleForm;
    }

    public static checkChanges(schedule, scheduleChanged) {
        // schedule.patientPersonName = scheduleChanged.patientPersonName
        if (scheduleChanged.patient) {
            schedule.patientPersonName = scheduleChanged.patient.person.name;
        }
        /* schedule.scheduleStatus = scheduleChanged.scheduleStatus;
        schedule.patientStatus = scheduleChanged.patientStatus;
        schedule.procedure = scheduleChanged.procedure;
        schedule.insurance = scheduleChanged.insurance;
        schedule.userScheduleTO = null;
        schedule.priority = null;
        schedule.priorityInput = null;
        if (scheduleChanged.priority != null && scheduleChanged.priority != '') {
            schedule.priority = scheduleChanged.priority
        } else {
            schedule.priorityInput = scheduleChanged.priorityInput;
        }

        schedule.scheduleNote = scheduleChanged.scheduleNote;


       
        if (schedule.person != scheduleChanged.person) {
            schedule.person = scheduleChanged.person;
            // schedule.procedure = scheduleChanged.procedure;
            // schedule.insurance = scheduleChanged.insurance;
        }
        // else
        //     if (schedule.markdown && !schedule.patient && !schedule.person) {
        //         schedule.patient = scheduleChanged.patient;
        //         schedule.person = scheduleChanged.person;
        //         schedule.procedure = scheduleChanged.procedure;
        //         schedule.insurance = scheduleChanged.insurance;
        //     }
        //     else {
        //         if (scheduleChanged.patient && !scheduleChanged.person) {
        //             schedule.patient = scheduleChanged.patient;
        //             schedule.procedure = scheduleChanged.procedure;
        //             schedule.insurance = scheduleChanged.insurance;
        //         }
        //         else if (scheduleChanged.person) {
        //             schedule.person = scheduleChanged.person;
        //             schedule.procedure = scheduleChanged.procedure;
        //             schedule.insurance = scheduleChanged.insurance;
        //         }
        //     }*/
        return schedule;
    }

    public setPatientSelect(set) {
        this.scheduleSource.emit(set);
    }
}
