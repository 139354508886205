export class ContactList {
    id: number;
    contactItem: ContactItem[];
    type: string;
    description
}

export class ContactItem {
    id: number;
    parentId: number;
    phone: string;
    email: string;
    others: string;
    description: string;
    type: string;
}
