
import { NgModule } from '@angular/core';

import { HolidayEditProvider } from './holiday-edit.provider';
import { HolidayListProvider } from './holiday-list.provider';

export * from './holiday-edit.provider';
export * from './holiday-list.provider';

export {
  HolidayListProvider,
  HolidayEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    HolidayEditProvider,
    HolidayListProvider
  ]
})
export class HolidayBackendModule { }
