import { SnackBarService } from '../../../../cw-utils/snackbar.service';
import { UserProfile } from '../../../models/security/user/user-profile';
import { Injectable, Output, EventEmitter, Directive } from '@angular/core';

import { Observable, BehaviorSubject } from "rxjs";

import { ApiGateway } from '../../../api-gateway';
import { HttpResponse } from '@angular/common/http';
import { WebSocketService } from '../../../web-socket-service';
import { Router, NavigationExtras } from '@angular/router';
import { ScheduleListProvider } from '../../operational/scheduling/schedule-list.provider';

@Directive()
@Injectable()
export class UserService {
    @Output() userAction: EventEmitter<string> = new EventEmitter();

    onRestrictableComponentChanged: BehaviorSubject<any>;
    onScheduleChanged: BehaviorSubject<any>;
    scheduleId: number;
    onTransactionChanged: BehaviorSubject<any>;
    webSocket: WebSocketService;


    private loggedIn = false;

    constructor(
        private gw: ApiGateway,
        private snackBar: SnackBarService,
        private router: Router,
    ) {
        this.onRestrictableComponentChanged = new BehaviorSubject({});
        this.onScheduleChanged = new BehaviorSubject({});
        this.webSocket = new WebSocketService();

    }

    ngOnInit(){
        this.onScheduleChanged.subscribe(res => {
            
        })
    }

    doAction(messageReceived) {
        let token = localStorage.getItem('token');
        if (token) {
            switch (messageReceived) {
                case "LOCK":
                case "INACTIVE":
                    localStorage.clear();
                    this.snackBar.errorMessage("Sessão expirada. Favor acessar novamente.");
                    this.router.navigate(['auth/login']);
                    break;
                case "EXPIREPASSWORD":
                    this.passwordExpired()
                    break;
                default:
                    break;
            }
        }
    };

    scheduleAction(message) {
        this.onScheduleChanged.next(message);
    }

    signup(user: {}): Observable<any> {
        var stream = this.gw.post(
            "profile/user/createAccount",
            user,
            null
        );
        return (stream);
    }

    login(userPassword): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post("authenticate", userPassword).subscribe(res => {
                this.userAction.emit('LOGIN');
                resolve(res);                
            }, reject);
        });
    }

    logout(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get("logout").subscribe(res => {
                this.userAction.emit('LOGOUT');
                resolve(res);
            }, reject);
        });
    }

    recoverPassword(userLogin: string): Observable<any> {
        var stream = this.gw.get(
            `user/recoverPassword?login=${userLogin}`, { userLogin: userLogin }
        );
        return (stream);
    }

    changePassword(changePasswordObj: {}): Observable<any> {
        var stream = this.gw.post(
            "user/changePassword",
            changePasswordObj,
            null
        );
        return (stream);
    }

    passwordExpired() {
        let user = JSON.parse(localStorage.getItem('user'));
        const navigationExtras: NavigationExtras = {
            state: { currentUser: user }
        };
        localStorage.removeItem('token');
        this.router.navigate(['auth/redefinir-senha' + '/editar'], navigationExtras);
    }

    get(userId: number) {
        var stream = this.gw.get(
            "profile/user/",
            userId
        );

        return (stream);
    }

    update(user: UserProfile): Observable<any> {

        var stream = this.gw.put(
            "profile/user/updateAccount",
            user,
            null
        );
        return (stream);
    }

    autenthicateUser(userObj: {}): Observable<any> {
        var stream = this.gw.post(
            "user/autenthicate/:userObj",
            userObj
        );
        return (stream);
    }

    userManagement(action: string, userId: number): Observable<any> {
        var stream = this.gw.post(
            "user/management/:action/:userId/",
            {
                action: action,
                userId: userId,
            },
        );
        return (stream);
    }

    getPermissions(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('user/modulesUserHasAccess').subscribe((res: HttpResponse<any>) => {
                resolve(res);
            }, reject);
        });
    }

    getRestrictedComponents(viewId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('user/view/:viewId/restrictedComponents', { viewId: viewId }).subscribe((res: HttpResponse<any>) => {
                resolve(res);
            }, reject);
        });
    }

    getTransactions(viewId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('user/view/:viewId/transactions', { viewId: viewId }).subscribe((res: HttpResponse<any>) => {
                resolve(res);
            }, reject);
        });
    }
}
