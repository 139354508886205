import { CwUtils } from "./../../../../cw-utils/cw-utils.service";

import { Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from "@angular/router";
import { Location } from "@angular/common";
import { HttpResponse } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { Accreditation, PageOptions, ApiGateway } from "cw-backend";

@Injectable()
export class AccreditationListProvider implements Resolve<any> {
    accreditation: Accreditation[];
    procedureAccreditation: Accreditation[];
    feeAccreditation: Accreditation[];
    materialAccreditation: Accreditation[];
    opmeAccreditation: Accreditation[];
    drugAccreditation: Accreditation[];
    auxiliaryAccreditation: Accreditation[];

    accreditationEdit: Accreditation;
    onDataChanged: BehaviorSubject<any>;
    onFeeChanged: BehaviorSubject<any>;
    onMaterialChanged: BehaviorSubject<any>;
    onDrugChanged: BehaviorSubject<any>;
    onOPMEChanged: BehaviorSubject<any>;
    onProcedureChanged: BehaviorSubject<any>;
    onAuxiliaryChanged: BehaviorSubject<any>;
    routeParams: any;
    state: any;
    pageOptions: PageOptions;
    currentPage: PageOptions;

    constructor(public gw: ApiGateway, private location: Location) {
        this.onDataChanged = new BehaviorSubject({});
        this.onMaterialChanged = new BehaviorSubject({});
        this.onDrugChanged = new BehaviorSubject({});
        this.onOPMEChanged = new BehaviorSubject({});
        this.onProcedureChanged = new BehaviorSubject({});
        this.onAuxiliaryChanged = new BehaviorSubject({});
        this.onFeeChanged = new BehaviorSubject({});
    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        // Get state for current or new page
        this.state = this.location.getState();
        if (this.state.currentPage && state.url.includes(this.state.path)) {
            this.currentPage = this.state.currentPage;
        }
        this.accreditationEdit = this.state.currentAccreditation;

        if (this.currentPage) {
            this.pageOptions = this.currentPage;
        } else {
            this.pageOptions = new PageOptions();
            this.pageOptions.fieldSort = "endDate";
        }
        return new Promise<void>((resolve, reject) => {
            Promise.all([this.getPage(this.pageOptions)]).then(() => {
                resolve();
            }, reject);
        });
    }

    get(id: number): Observable<Accreditation> {
        if (!id) {
            return null;
        }
        return this.gw.get("accreditation/:id", { id: id });
    }

    get currentAccreditation(): Accreditation {
        return this.accreditationEdit;
    }

    getAll(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .get("accreditation/")
                .subscribe((res: HttpResponse<any>) => {
                    this.accreditation = res.body;
                    this.onDataChanged.next(this.accreditation);
                    resolve(res);
                }, reject);
        });
    }

    getPortSimpleList(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .get("accreditation/port/")
                .subscribe((res: HttpResponse<any>) => {
                    resolve(res);
                }, reject);
        });
    }

    copyAccreditation(accreditationId, agreementId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post(
                    "accreditation/accreditationCopy/:id/agreement/:agreementId",
                    { id: accreditationId, agreementId: agreementId }
                )
                .subscribe((res: HttpResponse<any>) => {
                    resolve(res);
                }, reject);
        });
    }

    getPage(pageOptions: PageOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post("accreditation/search", pageOptions)
                .subscribe((res: HttpResponse<any>) => {
                    if (
                        !CwUtils.compare(
                            this.accreditation,
                            res.body.content
                        ) &&
                        res.body.content.length > 0
                    ) {
                        this.accreditation = res.body.content;
                        this.onDataChanged.next(this.accreditation);
                    } else {
                        this.accreditation = res.body.content;
                    }
                    resolve(res);
                }, reject);
        });
    }

    getPageByProcedureId(
        pageOptions: PageOptions,
        procedureId,
        renew?: boolean
    ): Promise<any> {
        // pageOptions.sizePage = 5;
        return new Promise((resolve, reject) => {
            this.gw
                .post(
                    "accreditation/searchByProcedureId/" + procedureId,
                    pageOptions
                )
                .subscribe((res: HttpResponse<any>) => {
                    if (
                        (!CwUtils.compare(
                            this.accreditation,
                            res.body.content
                        ) &&
                            res.body.content.length > 0) ||
                        renew
                    ) {
                        this.accreditation = res.body.content;
                        this.onDataChanged.next(this.accreditation);
                    } else {
                        this.accreditation = res.body.content;
                    }
                    resolve(res);
                }, reject);
        });
    }
    getByDiscountRuleByAccreditation(accreditationId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .get(
                    `accreditation/discount-rule/${accreditationId}`)
                .subscribe((res: HttpResponse<any>) => {
                    resolve(res);
                }, reject);
        });
    }

    getPageByAccreditationType(
        pageOptions: PageOptions,
        accreditationType,
        agreementId,
        renew?: boolean
    ): Promise<any> {
        if (!agreementId) {
            agreementId = 0;
        }
        // pageOptions.sizePage = 5;
        return new Promise((resolve, reject) => {
            this.gw
                .post(
                    "accreditation/search/type/:accreditationType/agreement/:agreementId",
                    {
                        accreditationType: accreditationType,
                        agreementId: agreementId,
                    },
                    pageOptions
                )
                .subscribe((res: HttpResponse<any>) => {
                    let incAccreditation = res.body.content;
                    let result = this.getAccreditationListByType(
                        accreditationType,
                        incAccreditation,
                        renew
                    );
                    if (result) {
                        this.getAccreditationListByType(
                            accreditationType,
                            incAccreditation,
                            renew
                        );
                    }
                    resolve(res);
                }, reject);
        });
    }

    getAvailableItemsForAccreditation(
        accreditationType,
        agreementId,
        pageOptions: PageOptions
    ): Promise<any> {
        if (!agreementId) {
            agreementId = 0;
        }
        return new Promise((resolve, reject) => {
            this.gw
                .post(
                    "kit/search/" +
                        accreditationType +
                        "/agreement/" +
                        agreementId,
                    pageOptions
                )
                .subscribe((res: HttpResponse<any>) => {
                    resolve(res);
                }, reject);
        });
    }

    getPageSettings() {
        if (this.currentPage) {
            return this.pageOptions;
        } else {
            return;
        }
    }

    getAccreditationListByType(
        accreditationType,
        incAccreditation?,
        renew?
    ): boolean {
        switch (accreditationType) {
            case "F":
                if (
                    this.compareAccreditation(
                        this.feeAccreditation,
                        incAccreditation,
                        renew
                    )
                ) {
                    this.onFeeChanged.next(incAccreditation);
                }
                this.feeAccreditation = incAccreditation;
                break;
            case "M":
                if (
                    this.compareAccreditation(
                        this.materialAccreditation,
                        incAccreditation,
                        renew
                    )
                ) {
                    this.onMaterialChanged.next(incAccreditation);
                }
                this.materialAccreditation = incAccreditation;
                break;
            case "D":
                if (
                    this.compareAccreditation(
                        this.drugAccreditation,
                        incAccreditation,
                        renew
                    )
                ) {
                    this.onDrugChanged.next(incAccreditation);
                }
                this.drugAccreditation = incAccreditation;
                break;
            case "O":
                if (
                    this.compareAccreditation(
                        this.opmeAccreditation,
                        incAccreditation,
                        renew
                    )
                ) {
                    this.onOPMEChanged.next(incAccreditation);
                }
                this.opmeAccreditation = incAccreditation;
                break;
            case "P":
                if (
                    this.compareAccreditation(
                        this.procedureAccreditation,
                        incAccreditation,
                        renew
                    )
                ) {
                    this.onProcedureChanged.next(incAccreditation);
                }
                this.procedureAccreditation = incAccreditation;
                break;
            case "A":
                if (
                    this.compareAccreditation(
                        this.auxiliaryAccreditation,
                        incAccreditation,
                        renew
                    )
                ) {
                    this.onAuxiliaryChanged.next(incAccreditation);
                }
                this.auxiliaryAccreditation = incAccreditation;
                break;
        }
        return true;
    }

    compareAccreditation(
        accreditationListByType,
        incAccreditation,
        renew?
    ): boolean {
        if (
            (!CwUtils.compare(accreditationListByType, incAccreditation) &&
                incAccreditation.length > 0) ||
            renew
        ) {
            return true;
        } else {
            return false;
        }
    }

    getKitByInsuranceAndCompany(
        pageOptions: PageOptions,
        kitType: string,
        insuranceId,
        companyId
    ): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.gw
                .post(
                    `accreditation/search/type/${kitType}/company/${companyId}/insurance/${insuranceId}`,
                    pageOptions
                )
                .subscribe((res: HttpResponse<any>) => {
                    resolve(res);
                }, reject);
        });
    }
}
