import { PageOptions } from './../../cw-backend/util/page';
import { Injectable } from '@angular/core';


@Injectable()
export class FilterAutoCompleteService {
    abstractList: any[];
    pageOptions: PageOptions;
    listProvider: any;
    provider: any;
    constructor(
    ) {
        this.pageOptions = new PageOptions();
    }

    getFilteredList(provider, value): Promise<any> {
        return new Promise((resolve) => {
            this.provider = provider;
            let filterValue;

            if (value.name) {
                filterValue = value.name.toLowerCase();
            } else {
                filterValue = value.toLowerCase()
            }
            this.pageOptions.argument = filterValue;
            this.provider.getPage(this.pageOptions).then(res => {
                this.abstractList = res.body.content
                resolve(this.abstractList);
            });
        });
    }
}