import { CwUtils } from 'cw-utils';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Procedure, PageOptions, ApiGateway } from 'cw-backend';

@Injectable()
export class ProcedureListProvider implements Resolve<any>
{
    procedure: Procedure[];
    procedureEdit: Procedure;
    onDataChanged: BehaviorSubject<any>;
    routeParams: any;
    state: any;
    pageOptions: PageOptions;
    currentPage: PageOptions;

    constructor(
        public gw: ApiGateway,
        private location: Location,
    ) {
        this.onDataChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        // Get state for current or new page
        this.state = this.location.getState();

        if (this.state.currentPage && state.url.includes(this.state.path)) {
            this.currentPage = this.state.currentPage;
        }
        this.procedureEdit = this.state.currentProcedure;

        if (this.currentPage) {
            this.pageOptions = this.currentPage;
        }
        else {
            this.pageOptions = new PageOptions();
            this.pageOptions.fieldSort = 'name';
        }
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this.getPage(this.pageOptions)
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    get(id: number): Observable<HttpResponse<Procedure>> {
        if (!id) {
            return null;
        }
        return this.gw.get('procedure/:id', { id: id });
    }

    get currentProcedure(): Procedure {
        return this.procedureEdit;
    }

    getAll(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('procedure/').subscribe({
                next: (res: HttpResponse<any>) => {
                    this.procedure = res.body;
                    this.onDataChanged.next(this.procedure);
                    resolve(res);
                },
                error: (err) => {
                    reject(err)
                }
            });
        });

    }

    getPage(pageOptions: PageOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('procedure/search', pageOptions).subscribe({
                next: (res: HttpResponse<any>) => {
                    if (!CwUtils.compare(this.procedure, res.body.content) && res.body.content.length > 0) {
                        this.procedure = res.body.content;
                        this.onDataChanged.next(this.procedure);
                    } else {
                        this.procedure = res.body.content;
                    }
                    resolve(res);
                },
                error: (err) => {
                    reject(err)
                }
            });
        });
    }

    findByCode(code: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get(`procedure/find/${code}`).subscribe({
                next: (res: HttpResponse<any>) => {
                    if (!CwUtils.compare(this.procedure, res.body.content) && res.body.content.length > 0) {
                        this.procedure = res.body.content;
                    } else {
                        this.procedure = res.body.content;
                    }
                    resolve(res);
                },
                error: (err) => {
                    reject(err)
                }
            });
        });
    }

    getProcedureByPerformer(pageOptions: PageOptions, professionalId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('scheduling/procedure/search/:professionalId', { professionalId: professionalId }, pageOptions).subscribe({
                next: (res: HttpResponse<any>) => {
                    if (!CwUtils.compare(this.procedure, res.body.content) && res.body.content.length > 0) {
                        this.procedure = res.body.content;
                        this.onDataChanged.next(this.procedure);
                    } else {
                        this.procedure = res.body.content;
                    }
                    resolve(res);
                },
                error: (err) => {
                    reject(err)
                }
            });
        });
    };

    getProcedureWithoutAccreditationForParticipation(pageOptions: PageOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('procedure/search/without-accreditation', pageOptions).subscribe({
                next: (res: HttpResponse<any>) => {
                    // if (!CwUtils.compare(this.procedure, res.body.content) && res.body.content.length > 0) {
                    //     this.procedure = res.body.content;
                    //     this.onDataChanged.next(this.procedure);
                    // } else {
                        this.procedure = res.body.content;
                    // }
                    resolve(res);
                },
                error: (err) => {
                    reject(err)
                }
            });
        });
    };


    getProcedureByInsuranceAndPerformer(
        insuranceId: number,
        performerId: any,
        companyId: any,
        pageOptions: PageOptions
    ): Promise<any> {
        const body = {
            insuranceId: insuranceId,
            performerId: performerId || "",
            companyId: companyId || "",
            pageSearchTO: pageOptions,
        }
        return new Promise((resolve, reject) => {
            this.gw
                .post(`attendance/procedure/searchByFilter/`, body)
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        resolve(res);
                    },
                    error: (err) => {
                        reject(err)
                    }
                });
        });
    }

    getProcedureByGroup(pageOptions: PageOptions, horaryId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('scheduling/procedure/searchByHoraryId/:horaryId', { horaryId: horaryId }, pageOptions)
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        if (!CwUtils.compare(this.procedure, res.body.content) && res.body.content.length > 0) {
                            this.procedure = res.body.content;
                            this.onDataChanged.next(this.procedure);
                        } else {
                            this.procedure = res.body.content;
                        }
                        resolve(res);
                    },
                    error: (err) => {
                        reject(err)
                    }
                });
        });
    }

    getProcedureByAccreditationId(agreementId, pageOptions: PageOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('kit/search/P/agreement/' + agreementId, pageOptions).subscribe({
                next: (res: HttpResponse<any>) => {
                    this.procedure = res.body.content;
                    resolve(res);
                },
                error: (err) => {
                    reject(err)
                }
            });
        });
    }

    getPageSettings() {
        if (this.currentPage) {
            return this.pageOptions;
        } else {
            return;
        }
    }
}

