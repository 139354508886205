
import { NgModule } from '@angular/core';

import { ScheduleListProvider } from './schedule-list.provider';
import { ScheduleEditProvider } from './schedule-edit.provider';

export * from './schedule-list.provider';
export * from './schedule-edit.provider';

export {
  ScheduleListProvider,
  ScheduleEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    ScheduleListProvider,
    ScheduleEditProvider

  ]
})
export class SchedulingBackendModule { }
