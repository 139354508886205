import { TransferOrderItem } from "./TransferOrderItem";

export class Transfer {

    id: number;
    closed: boolean;
    number: string;
    type: string;
    dateAt: Date;
    dateOpen: string; 
    dateClose: string;
    paymentList = Array<String>();
    transferOrderItemList = Array<TransferOrderItem>();
    dateLimit: Date;
}

