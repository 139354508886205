import { Contact } from "./../../../../src/app/main/apps/contacts/contact.model";
import { Procedure, ProcedureSpecs } from "./procedure";
import { Agreement } from "./agreement";
import { Quotation } from "./quotation";
import {
    Address,
    Plan,
    Performer,
    TableType,
    Company,
    Insurance,
    Speciality,
    Group,
} from "cw-backend";
import { ImageObj } from "../interface/image-obj";

export class QuotaLackControl {
    id: number;
    name: string;
    priority: number;
    company: Company;
    procedure: Procedure;
    insurance: Insurance;
    performer: Performer;
    speciality: Speciality;
    group: Group;
    parentId: QuotaLackControl;
    day: number;
    quota: number;
    lack: number;
    is_schedule: boolean;
    is_order: boolean;
    period: string;
    shift: string;
    is_only_warning: boolean;
}
