import { NgModule } from '@angular/core';
import { InputFocusDirective } from './input-focus-directive';


@NgModule({
    declarations: [
        InputFocusDirective
    ],
    imports     : [],
    exports     : [
        InputFocusDirective
    ]
})
export class CwDirectiveModule
{
}
