
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Contacts, PageOptions, ApiGateway } from 'cw-backend';
import { CwUtils } from 'cw-utils';

@Injectable()
export class ContactsListProvider implements Resolve<any>
{
    contacts: Contacts[];
    contactsEdit: Contacts;
    onDataChanged: BehaviorSubject<any>;
    routeParams: any;
    state: any;
    pageOptions: PageOptions;
    currentPage: PageOptions;

    constructor(
        public gw: ApiGateway,
        private location: Location,
    ) {
        this.onDataChanged = new BehaviorSubject({}); 
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        // Get state for current or new page
        this.state = this.location.getState();
        this.currentPage = this.state.currentPage;
        this.contactsEdit = this.state.currentContacts;

        if (this.currentPage) {
            this.pageOptions = this.currentPage;
            this.pageOptions.fieldSort = 'name';
        }
        else {
            this.pageOptions = new PageOptions();
            this.pageOptions.fieldSort = 'name';
        }
        return new Promise((resolve, reject) => {
            Promise.all([
                this.getPage(this.pageOptions)
            ]).then(
                () => {
                    resolve(true);
                },
                reject
            );
        });
    }

    get(id: number): Observable<Contacts> {
        if (!id) {
            return null;
        }
        return this.gw.get('contacts/:id', { id: id });
    }

     get currentContacts(): Contacts {
        return this.contactsEdit;
    }

    getAll(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('contacts/').subscribe({next:(res: HttpResponse<any>) => {
                this.contacts = res.body;
                resolve(res);
            }, error: (err: any) => { reject(err) }});
        });

    }
 
    getPage(pageOptions: PageOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('contacts/search', pageOptions).subscribe({next:(res: HttpResponse<any>) => {
                if (!CwUtils.compare(this.contacts, res.body.content) && res.body.content.length > 0) {
                    this.contacts = res.body.content;
                    this.onDataChanged.next(this.contacts);
                }
                else {
                    this.contacts = res.body.content;
                };
                resolve(res);
            }, error: (err: any) => { reject(err) }});
        });
    }

     getPageSettings() {
        if (this.currentPage) {
            return this.pageOptions;
        } else {
            return;
        }
    }
}

