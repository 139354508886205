
import { NgModule } from '@angular/core';

import { TypeGuideEditProvider } from './type-guide-edit.provider';
import { TypeGuideListProvider } from './type-guide-list.provider';

export * from './type-guide-edit.provider';
export * from './type-guide-list.provider';

export {
  TypeGuideListProvider,
  TypeGuideEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    TypeGuideEditProvider,
    TypeGuideListProvider
  ]
})
export class TypeGuideBackendModule { }
