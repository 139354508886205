export class RestrictableComponent {
    constructor(
        public id?: number,
        public view_id?: number,
        public name?: string,
        public description?: string,
        public type?: string,
        public restrictionType?: string,
        public code?: string,
    ) {
    }

}