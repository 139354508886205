
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { PayType, ApiGateway } from 'cw-backend';
import { SnackBarService } from 'cw-utils';

@Injectable()
export class PayTypeEditProvider implements Resolve<any>
{
    routeParams: any;
    state: any;
    onDataChanged: BehaviorSubject<any>;
    payType: PayType;

    constructor(
        public gw: ApiGateway,
        public snackBar: SnackBarService
    ) {
        this.onDataChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        return new Promise<void>((resolve, reject) => {
            Promise.all([this.getPayType()]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getPayType(): Promise<any> {
        return new Promise((resolve, reject) => {
            let data = this.checkLastModifications()
            if (data && data.id == this.routeParams.id) {
                this.payType = data;
                this.onDataChanged.next(this.payType);
                resolve(this.payType);
            } else if (this.routeParams.id === 'novo') {
                this.onDataChanged.next(false);
                resolve(false);
            }
            else {
                this.gw.get('payType/' + this.routeParams.id).subscribe({
                    next: (res: PayType) => {
                        this.payType = res;
                        this.onDataChanged.next(this.payType);
                        resolve(res);
                    },
                    error: (error: HttpResponse<any>) => { error }
                });
            }
        });
    }

    insert(payType): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('payType/', payType).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                },
                error: (error: HttpResponse<any>) => { error }
            });
        });
    }

    edit(payType): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('payType/', payType).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                },
                error: (error) => { reject(error); }
            });
        });
    }

    delete(payType): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.delete('payType/' + payType.id).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                },
                error: (error: HttpResponse<any>) => { error }
            });
        });
    }

    
    checkLastModifications(): PayType {
        let wasLocked = JSON.parse(localStorage.getItem('session_locked'));
        let data;
        if (wasLocked) {
            data = JSON.parse(localStorage.getItem('last_modification'));
            localStorage.removeItem('session_locked');
        }
        return data;
    }
}
