
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiGateway, Accreditation } from 'cw-backend';
import { SnackBarService } from 'cw-utils';

@Injectable()
export class AccreditationEditProvider implements Resolve<any>
{
    routeParams: any;
    state: any;
    onDataChanged: BehaviorSubject<any>;
    accreditation: Accreditation;

    constructor(
        public gw: ApiGateway,
        public snackBar: SnackBarService
    ) {
        this.onDataChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        return new Promise<void>((resolve, reject) => {
            Promise.all([this.getAccreditation()]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getAccreditation(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this.routeParams.id === 'novo') {
                this.onDataChanged.next(false);
                resolve(false);
            }
            else {
                this.gw.get('accreditation/wAll' + this.routeParams.id).subscribe((res: Accreditation) => {
                    this.accreditation = res;
                    this.onDataChanged.next(this.accreditation);
                    resolve(res);
                }, reject);
            }
        });
    }

    getProcedureAccreditationById(accreditationId): Promise<any> {
        return new Promise((resolve, reject) => {

            this.gw.get('accreditation/procedure/wAll/' + accreditationId).subscribe((res: Accreditation) => {
                this.accreditation = res;
                this.onDataChanged.next(this.accreditation);
                resolve(res);
            }, reject);
        });
    }
    getInsuranceAccreditationById(accreditationId): Promise<any> {
        return new Promise((resolve, reject) => {

            this.gw.get('accreditation/insurance/wAll/' + accreditationId).subscribe((res: Accreditation) => {
                this.accreditation = res;
                this.onDataChanged.next(this.accreditation);
                resolve(res);
            }, reject);
        });
    }


    insert(accreditation): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('accreditation/', accreditation).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }

    insertFromInsurance(accreditation): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('accreditation/insurance/', accreditation).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }


    insertFromProcedure(accreditation): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('accreditation/procedure/', accreditation).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }

    edit(accreditation): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.put('accreditation/', accreditation).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }

    editFromProcedure(accreditation): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.put('accreditation/procedure/', accreditation).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }
    editFromInsurance(accreditation): Promise<any> {
        console.log("🚀 ~ editFromInsurance ~ accreditation:", accreditation)
        return new Promise((resolve, reject) => {
            this.gw.put('accreditation/insurance/', accreditation).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }

    delete(accreditation): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.delete('accreditation/procedure/' + accreditation.id).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }

    deleteFromInsurance(accreditation): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.delete('accreditation/insurance/' + accreditation.id).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }

    accreditationTableStandart(accreditation) {
        return new Promise((resolve, reject) => {
            this.gw.post('accreditation/tableStandart/', accreditation)
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                        this.snackBar.successMessage(response.body.message);
                    },
                    error: (err: HttpResponse<any>) => {reject(err);}
                })
        })
    }

    findByProcedureCodes(accreditation) {
        return new Promise<any>((resolve, reject) => {
            this.gw.post('accreditation/tableStandart/procedures', accreditation)
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                    },
                    error: (err: HttpResponse<any>) => {reject(err);}
                })
        })
    }

}
