
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { OrderAuthorization, ApiGateway } from 'cw-backend';
import { SnackBarService } from 'cw-utils';
import { MAT_RADIO_GROUP_CONTROL_VALUE_ACCESSOR } from '@angular/material/radio';
import { Environment } from 'app/decorators/environment.decorator';

@Injectable()
export class OrderAuthorizationEditProvider implements Resolve<any>
{
    routeParams: any;
    state: any;
    onDataChanged: BehaviorSubject<any>;
    orderAuthorization: OrderAuthorization;
    @Environment('baseUrl') baseUrl: string;

    constructor(
        public gw: ApiGateway,
        public snackBar: SnackBarService,
        private httpClient: HttpClient,
    ) {
        this.onDataChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        return new Promise<void>((resolve, reject) => {
            Promise.all([this.getOrderAuthorization()]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getOrderAuthorization(): Promise<any> {
        return new Promise((resolve, reject) => {
            let data = this.checkLastModifications()
            if (data && data.id == this.routeParams.id) {
                this.orderAuthorization = data;
                this.onDataChanged.next(this.orderAuthorization);
                resolve(this.orderAuthorization);
            } else if (this.routeParams.id === 'novo') {
                this.onDataChanged.next(false);
                resolve(false);
            }
            else {
                this.gw.get('orderAuthorization/' + this.routeParams.id).subscribe({
                    next: (res: OrderAuthorization) => {
                        this.orderAuthorization = res;
                        this.onDataChanged.next(this.orderAuthorization);
                        resolve(res);
                    },
                    error: (err: any) => { reject(err); }
                });
            }
        });
    }

    insert(orderAuthorization): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('order/authorization/', orderAuthorization).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                }, error: (err: any) => { reject(err); }
            });
        });
    }

    edit(orderAuthorization): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.put('order/authorization/', orderAuthorization).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                }, error: (err: any) => { reject(err); }
            });
        });
    }

    delete(orderAuthorization): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.delete('orderAuthorization/' + orderAuthorization.id).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                }, error: (err: any) => { reject(err); }
            });
        });
    }

    authorizerWS(typeOfMethod, orderId, params?, patientId?, paramToken?): Promise<any> {
        let url: string = '';
        let formData: FormData = new FormData();
        if (params) {
            url += `?typeDocument=${params.statusTypeDocumentId}`;
            if (params.idModelDocument) {
                url += `&idDocumentModel=${params.idModelDocument}`;
            }
            if (params.attachmentDocument) {

                formData.append('file', params.attachmentDocument, params.attachmentDocument.name);
            }
            url += `&paciente=${patientId}`
            url += `&sistema=1`
        }
        if (paramToken) {
            url += `?orderItemId=${paramToken.orderItemId}`
            url += `&dateExecute=${paramToken.dateExecute}`
            url += `&token=${paramToken.token}`
        }
        let authToken = localStorage.getItem('token');
        let headers = new HttpHeaders();
        headers = headers.append('X-Auth-Token', authToken);

        if (params?.attachmentDocument) {
            return new Promise((resolve, reject) => {
                this.httpClient.post(`${this.baseUrl}authorizerTissController/${orderId}/${typeOfMethod}/${url}`, formData, { headers: headers })
                    .subscribe({
                        next: (response: HttpResponse<any>) => {
                            resolve(response);
                        },
                        error: (err) => {
                            reject(err)
                        }
                    })
            }); 
        }else{
            return new Promise((resolve, reject) => {
                this.gw.post(`authorizerTissController/${orderId}/${typeOfMethod}${url}`).subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                    }, error: (err: any) => {reject(err) }
                });
            });
        }


      
    }

    checkLastModifications(): OrderAuthorization {
        let wasLocked = JSON.parse(localStorage.getItem('session_locked'));
        let data;
        if (wasLocked) {
            data = JSON.parse(localStorage.getItem('last_modification'));
            localStorage.removeItem('session_locked');
        }
        return data;
    }


}
