export class Filter {
    id: number;
    key: string;
    alias:string;
    type: string;
    value: string;
    mandatory: boolean;
    scope: string;
    component: string;
}
