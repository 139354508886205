import { CwUtils } from '../../../../cw-utils/cw-utils.service';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { PageOptions } from '../../../util/backend-util-module';
import { User } from '../../../models/backend-definitions.module';
import { ApiGateway } from '../../../api-gateway';

@Injectable()
export class UserListProvider implements Resolve<any>
{
    user: User[];
    userEdit: User;
    onDataChanged: BehaviorSubject<any>;
    onAction: BehaviorSubject<any>;
    onUserAction: boolean = false;
    routeParams: any;
    state: any;
    pageOptions: PageOptions;
    currentPage: PageOptions;

    constructor(
        public gw: ApiGateway,
        private location: Location,
    ) {
        // Set the defaults
        this.onDataChanged = new BehaviorSubject({});
        this.onAction = new BehaviorSubject({});

    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        // Get state for current or new page
        this.state = this.location.getState();
        this.userEdit = this.state.currentUser;
        let currentPage = this.state.currentPage;

        if (currentPage && state.url.includes(this.state.path)) {
            this.pageOptions = currentPage;
            this.currentPage = currentPage;
        } else {
            this.pageOptions = new PageOptions();
            this.pageOptions.fieldSort = 'login';
        }
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this.getPage(this.pageOptions)
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    get(id: number): Observable<User> {
        if (!id) {
            return null;
        }
        return this.gw.get('user/:id', { id: id });
    }

    getSimpleUser(id: number): Observable<any> {
        if (!id) {
            return null;
        }
        return this.gw.get('user/simpleUser/:id', { id: id });
    }

    getUserType(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('user/userStatus/simpleList').subscribe({
                next: (res: HttpResponse<any>) => {
                    resolve(res);
                }, error: (err) => { reject(err) }
            });
        });
    }

    getPerson(cpf): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('user/person/:cpf', { cpf: cpf }).subscribe({
                next: (res: HttpResponse<any>) => {
                    resolve(res);
                }, error: (err) => { reject(err) }
            });
        });
    }

    getPersonByType(cpf, type): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('user/person/:cpf/:type', { cpf: cpf, type: type }).subscribe({
                next: (res: HttpResponse<any>) => {
                    resolve(res);
                }, error: (err) => { reject(err) }
            });
        });
    }

    getComponents(id: number): Observable<any> {
        if (!id) {
            return null;
        }
        return this.gw.get('user/components/:id', { id: id });
    }

    getAll(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('user').subscribe({
                next: (res: HttpResponse<any>) => {
                    this.user = res.body;
                    this.onDataChanged.next(this.user);
                    resolve(res);
                }, error: (err) => { reject(err) }
            });
        });
    }

    getPage(pageOptions: PageOptions, entity?): Promise<any> {
        let path = 'user/search';
        if (entity) {
            path = entity + path;
        }
        return new Promise((resolve, reject) => {
            this.gw.post(path, pageOptions).subscribe({
                next: (res: HttpResponse<any>) => {
                    if (!CwUtils.compare(this.user, res.body.content) || this.onUserAction) {
                        this.user = res.body.content;
                        this.onDataChanged.next(this.user)
                        this.onUserAction = false;
                    } else {
                        this.user = res.body.content;
                    }
                    resolve(res);
                }, error: (err) => { reject(err) }
            });
        });
    }

    getUserStatusList(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('user/userStatus/simpleList').subscribe({
                next: (res: HttpResponse<any>) => {
                    resolve(res);
                }, error: (err) => { reject(err) }
            });
        });
    }

    getUserSimpleList(pageOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('user/simpleList', pageOptions).subscribe({
                next: (res: HttpResponse<any>) => {
                    resolve(res);
                }, error: (err) => { reject(err) }
            });
        });
    }

    getPageUserSimpleList(pageOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('user/simpleList', pageOptions).subscribe({
                next: (res: HttpResponse<any>) => {
                    resolve(res);
                }, error: (err) => { reject(err) }
            });
        });
    }

    getUserByMultipleIds(idList): Promise<any> {
        let urlPath = 'user/find-multiple-ids';

        return new Promise((resolve, reject) => {
            this.gw.post(urlPath, idList).subscribe({
                next: (res: HttpResponse<any>) => {
                    resolve(res);
                }, error: (err => reject(err))
            });
        });
    }

    getUserCompany(userId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get(`user/company/${userId}`).subscribe({
                next: (res: HttpResponse<any>) => {
                    resolve(res);
                }, error: (err) => { reject(err) }
            });
        });
    }

    getPageSettings() {
        return this.pageOptions;
    }


    get currentUser(): User {
        return this.userEdit;
    }
}

