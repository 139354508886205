
import { NgModule } from '@angular/core';

import { PortEditProvider } from './port-edit.provider';
import { PortListProvider } from './port-list.provider';

export * from './port-edit.provider';
export * from './port-list.provider';

export {
  PortListProvider,
  PortEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    PortEditProvider,
    PortListProvider
  ]
})
export class PortBackendModule { }
