import { Fee } from './../accreditation/fee';
import { Product } from './../accreditation/product';
import { Person } from './../tables/general/person';
import { Agreement, Company, Insurance, Patient, Performer, Plan, Procedure } from "cw-backend";
import { OrderDiagnosis } from "./order-diagnosis";
import { OrderItem } from "./order-item";
import { OrderPatientDetail } from "./order-patient-detail";
import { Session } from "./session";

export class Budget {
    id?: number;  
    patient: Patient;
    insurance: Insurance; 
    performer: Performer;
    company: Company;
    person: Person;
    name: string;
    note: string;
    date: string;   
    budgetItemList: BudgetItem[];
    patientName :string;
    patientBirthday :string;
    patientCpf :string;
    patientPhone :string ;
}

export class BudgetItem {
    id?: number;  
    name: string;
    code: string; 
    procedure: Procedure;
    product: Product;
    fee: Fee;    
    quantity: number;
    value: number; 
    budget: Budget;   
}