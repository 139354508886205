import { CwUtils } from './../../../../cw-utils/cw-utils.service';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { PageOptions } from '../../../util/backend-util-module';
import { ApiGateway } from '../../../api-gateway';
import { State } from '../../../models/tables/address/state';

@Injectable()
export class StateListService implements Resolve<any>
{
    state: State[];
    stateEdit: State;
    onDataChanged: BehaviorSubject<any>;
    routeParams: any;
    stateRoute: any;
    pageOptions: PageOptions;
    currentPage: PageOptions;
    response: HttpResponse<any>;

    /**
     * Constructor
     *
     * @param {ApiGateway} gw
     */
    constructor(
        public gw: ApiGateway,
        private location: Location,
    ) {
        this.onDataChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        // Get state for current or new page
        this.stateRoute = this.location.getState();
        this.stateEdit = this.stateRoute.currentState;
        let currentPage = this.stateRoute.currentPage;

        if (currentPage) {
            this.pageOptions = currentPage;
            this.currentPage = currentPage;
        } else {
            this.pageOptions = new PageOptions();
            this.pageOptions.fieldSort = 'name';
        }
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this.getPage(this.pageOptions)
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    get(id: number): Observable<State> {
        if (!id) {
            return null;
        }
        return this.gw.get('state/:id', { id: id });
    }

    get currentState(): State {
        return this.stateEdit;
    }

    getAll(entity?: String): Promise<any> {
        let urlPath = 'state/';
        if (entity){
            urlPath = entity + urlPath
        }
        return new Promise((resolve, reject) => {
            this.gw.get(urlPath).subscribe((res: HttpResponse<any>) => {
                this.state = res.body;
                this.onDataChanged.next(this.state);
                resolve(res);
            }, reject);
        });

    }

    getPage(pageOptions: PageOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('state/search', pageOptions).subscribe((res: HttpResponse<any>) => {
                if (!CwUtils.compare(this.state, res.body.content)  && res.body.content.length > 0) {
                    this.state = res.body.content;
                    this.onDataChanged.next(this.state)
                } else {
                    this.state = res.body.content;
                }
                resolve(res);
            }, reject);
        });
    }

    getPageSettings() {
        return this.pageOptions;
    }

    
}

