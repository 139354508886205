import { SnackBarService } from '../../../../cw-utils/snackbar.service';

import { HttpResponse, HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiGateway } from '../../../api-gateway';
import { User } from '../../../models/backend-definitions.module';
import { Environment } from 'app/decorators/environment.decorator';

@Injectable()
export class UserEditProvider implements Resolve<any>
{
    @Environment('baseUrl') baseUrl: string;
    routeParams: any;
    state: any;
    onDataChanged: BehaviorSubject<any>;
    user: User;

    countryValue: any;
    country = { id: "" }

    constructor(
        public gw: ApiGateway,
        public snackBar: SnackBarService,
        private httpClient: HttpClient
    ) {
        this.onDataChanged = new BehaviorSubject({});
        this.getCountry();
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        return new Promise<void>((resolve, reject) => {
            Promise.all([this.getUser()]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getUser(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this.routeParams.id === 'novo') {
                this.onDataChanged.next(false);
                resolve(false);
            }
            else {
                this.gw.get('user/wRoles/' + this.routeParams.id).subscribe((res: User) => {
                    this.user = res;
                    this.onDataChanged.next(this.user);
                    resolve(res);
                }, reject);
            }
        });
    }

    insert(user): Promise<any> {

        this.country.id = this.countryValue

        if (user.person.address) {
            user.person.address.country = this.country
        }

        if (user.person.address && user.person.address.country != null && this.country.id != "") {
            return new Promise((resolve, reject) => {
                this.gw.post('user/register', user).subscribe((res: HttpResponse<any>) => {
                    resolve(res);
                    this.snackBar.successMessage(res.body.message);
                }, reject);
            });
        } else {
            return new Promise((resolve, reject) => {
                this.gw.post('user/register', user).subscribe((res: HttpResponse<any>) => {
                    resolve(res);
                    this.snackBar.successMessage(res.body.message);
                }, reject);
            });
        }
    }

    edit(user): Promise<any> {
        this.country.id = this.countryValue

        if (user.person.address) {
            user.person.address.country = this.country
        }

        if (user.person.address && user.person.address.country != null && this.country.id != "") {
            return new Promise((resolve, reject) => {
                this.gw.put('user/update', user).subscribe((res: HttpResponse<any>) => {
                    resolve(res);
                    this.snackBar.successMessage(res.body.message);
                }, reject);
            });
        } else {
            return new Promise((resolve, reject) => {
                this.gw.put('user/update', user).subscribe((res: HttpResponse<any>) => {
                    resolve(res);
                    this.snackBar.successMessage(res.body.message);
                }, reject);
            });
        }
    }

    getCountry() {

        this.getParameterCountry().then((value) => {

            value.forEach((country) => {

                if (country.group == 'Gerais') {
                    country.parameterList.forEach((parameter) => {

                        if (parameter.id == 28) {
                            this.countryValue = parameter.value;

                        }
                    })

                }
            })
        })

    }

    getParameterCountry(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .get('parameter/context/SYSTEM')
                .subscribe({
                    next: (res: HttpResponse<any[]>) => { res.body ? resolve(res.body) : resolve(res) },
                    error(error) { reject(error) }
                });
        });
    }

    delete(user): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.delete('user/' + user.id).subscribe((res: HttpResponse<any>) => {
                resolve(res);
                this.snackBar.successMessage(res.body.message);
            }, reject);
        });
    };

    uploadImage(file: File, userId, main, description): Promise<any> {
        return new Promise((resolve, reject) => {

            const endpoint = this.baseUrl + 'user/' + userId + '/uploadImage/main/' + main + '/description/' + description + "/extensaoImage/" + file.type.split("/")[1];
            const formData: FormData = new FormData();
            formData.append('file', file, file.name);

            let authToken = localStorage.getItem('token');
            let headers = new HttpHeaders();
            headers = headers.append('X-Auth-Token', authToken);

            this.httpClient.post(endpoint, formData, { headers: headers }).subscribe(response => {
                resolve(response)
            }, reject);
        })
    };
}
