export class UserChangePassword {
    public login: string;
    public password: string;
    public changePassword: {
        currentPassword: String;
        newPassword: string;
        confirmPassword: string;
        recoverPasswordToken: string;
    };
    constructor(
    ) { }
}