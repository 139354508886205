
import { NgModule } from '@angular/core';

import { CompanyEditService } from './company-edit-service';
import { CompanyListProvider } from './company-list.provider';

export * from './company-edit-service';
export * from './company-list.provider';

export {
  CompanyListProvider,
  CompanyEditService
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    CompanyEditService,
    CompanyListProvider
  ]
})
export class CompanyBackendModule { }
