
import { NgModule } from '@angular/core';

import { OrderPendingProvider } from './order-pending.provider';

export * from './order-pending.provider';

export {
  OrderPendingProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    OrderPendingProvider
  ]
})
export class OrderPendingBackendModule { }
