<div id="profile" class="page-layout simple left-sidebar inner-sidebar" fxLayout="column">

    <!-- HEADER -->
    <div class="header accent-700 p-24" fxLayout="row" fxLayoutAlign="center center"
        fxLayoutAlign.gt-sm="space-between center">


        <h1>Perfil</h1>

        <div>
            <button id="insert-application-configuration-parameter" mat-stroked-button class="save-button"
                (click)="submitForm()" matTooltip="Salvar" class="m-12"> Salvar
                <mat-icon>backup</mat-icon>
            </button>

        </div>

    </div>

    <!-- CONTENT -->
    <div class="content" fxLayout="column">


        <!-- CENTER -->
        <div class="center p-24" fxLayout="column" fusePerfectScrollbar [formGroup]="profileForm">

            <mat-form-field appearance="outline">
                <mat-label>Nome</mat-label>
                <input  matInput  formControlName="name" placeholder="Nome">
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="0 1 auto">
                <mat-label>Sobre</mat-label>
                <textarea matInput formControlName="mood" name="mood" rows="3"></textarea>
            </mat-form-field>

            <mat-radio-group formControlName="status" fxLayout="column">

                <mat-radio-button value="online" class="py-8">
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <mat-icon class="status online mr-8"></mat-icon>
                        <span class="mat-h4 m-0">Online</span>
                    </div>
                </mat-radio-button>
    
                <mat-radio-button value="away" class="py-8">
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <mat-icon class="status away mr-8"></mat-icon>
                        <span class="mat-h4 m-0">Ausente</span>
                    </div>
                </mat-radio-button>
    
                <mat-radio-button value="do-not-disturb" class="py-8">
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <mat-icon class="status do-not-disturb mr-8"></mat-icon>
                        <span class="mat-h4 m-0">Não Pertube</span>
                    </div>
                </mat-radio-button>
    
                <mat-radio-button value="offline" class="py-8">
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <mat-icon class="status offline mr-8"></mat-icon>
                        <span class="mat-h4 m-0">Offline</span>
                    </div>
                </mat-radio-button>
            </mat-radio-group>

        </div>
        <!-- / CONTENT -->
    </div>
    <!-- / CENTER -->

</div>