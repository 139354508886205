
import { NgModule } from '@angular/core';

import { AccountPlanEditProvider } from './account-plan-edit.provider';
import { AccountPlanListProvider } from './account-plan-list.provider';

export * from './account-plan-edit.provider';
export * from './account-plan-list.provider';

export {
  AccountPlanListProvider,
  AccountPlanEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    AccountPlanEditProvider,
    AccountPlanListProvider
  ]
})
export class AccountPlanBackendModule { }
