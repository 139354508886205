
import { NgModule } from '@angular/core';

import { ProductEditProvider } from './product-edit.provider';
import { ProductListProvider } from './product-list.provider';

export * from './product-edit.provider';
export * from './product-list.provider';

export {
  ProductListProvider,
  ProductEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    ProductEditProvider,
    ProductListProvider
  ]
})
export class ProductBackendModule { }
