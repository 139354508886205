import { Moment } from 'moment';
import { TableType, Currency } from 'cw-backend';

export class StandardTableItem {
    id: number;
    name: string;
    code: string;
    value: number;
    content: string;
    currency: Currency;
    standardTable: StandardTable;
    groupCode: string;
    groupName: string;
    subGroupCode: string;
    subGroupName: string;
    film: number;
    incidence: number;
    uco: number;
    auxiliary: number;
    port: number;

}

export class StandardTable {

    id: number;
    name: string;
    description: string;
    date: Moment;
    type: any;
    file: any;
    tableType: TableType;
    standardTableItemList: StandardTableItem[];

}