
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { TypeHospitalization, ApiGateway } from 'cw-backend';
import { SnackBarService } from 'cw-utils';

@Injectable()
export class TypeHospitalizationEditProvider implements Resolve<any>
{
    routeParams: any;
    state: any;
    onDataChanged: BehaviorSubject<any>;
    typeHospitalization: TypeHospitalization;

    constructor(
        public gw: ApiGateway,
        public snackBar: SnackBarService
    ) {
        this.onDataChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        return new Promise<void>((resolve, reject) => {
            Promise.all([this.getTypeHospitalization()]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getTypeHospitalization(): Promise<any> {
        return new Promise((resolve, reject) => {
            let data =  this.checkLastModifications()
            if (data && data.id == this.routeParams.id) {
                this.typeHospitalization = data;
                this.onDataChanged.next(this.typeHospitalization);
                resolve(this.typeHospitalization);
            } else if (this.routeParams.id === 'novo') {
                this.onDataChanged.next(false);
                resolve(false);
            }
            else {
                this.gw.get('typeHospitalization/' + this.routeParams.id).subscribe((res: TypeHospitalization ) => {
                    this.typeHospitalization = res;
                    this.onDataChanged.next(this.typeHospitalization);
                    resolve(res);
                }, reject);
            }
        });
    }

    insert(typeHospitalization): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('typeHospitalization/', typeHospitalization).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }

    edit(typeHospitalization): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('typeHospitalization/', typeHospitalization).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }

    delete(typeHospitalization): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.delete('typeHospitalization/' + typeHospitalization.id).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                 this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }

    checkLastModifications(): TypeHospitalization {
        let wasLocked = JSON.parse(localStorage.getItem('session_locked'));
        let data;
        if (wasLocked){
            data = JSON.parse(localStorage.getItem('last_modification'));
            localStorage.removeItem('session_locked');
        }  
        return data;
    }
}
