import { ErrorHandler, Injectable } from '@angular/core';
import { SnackBarService } from './snackbar.service';

@Injectable()
export class CustomErrorHandler implements ErrorHandler {
  constructor(
    private snackBar: SnackBarService
  ) { }
  handleError(error: any) {
  }
}




