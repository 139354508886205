import { NgModule } from "@angular/core";
export * from './backend-definitions.d';
 
// ***************************** TABLES ******************************* //
// SECURITY
export * from './security/role/role-models';
export * from './security/role/role-control-model';
export * from './security/module/module-model';
export * from './security/module/module-control-model';
export * from './security/view-model';
export * from './security/transaction-model';
export * from './security/restrictable-component';
export * from './security/audit/audit';
// ACCREDITATION
export * from './tables/accreditation/speciality';
export * from './tables/accreditation/category';
export * from './tables/accreditation/occupation';
export * from './tables/accreditation/degree-participation';
export * from './tables/accreditation/occupation-tiss';
export * from './tables/accreditation/group';
export * from './tables/accreditation/table-type';
export * from './tables/accreditation/table-type-version';
export * from './tables/accreditation/plan';
export * from './tables/accreditation/gloss-type'
// GENERAL
export * from './tables/general/breed';
export * from './tables/general/registry-type'
export * from './tables/general/council'
export * from './tables/general/schooling'
export * from './tables/general/civil-status'
export * from './tables/general/ethnicity'
export * from './tables/general/gender'
export * from './tables/general/firm'
export * from './tables/general/person'
export * from './tables/general/person-certificate'
export * from './tables/general/person-elector'
export * from './tables/general/person-identity'
export * from './tables/general/person-image'
export * from './tables/general/person-work-card'
// ************************* END OF TABLES ***************************** //

// **************************** REGISTER ******************************* //
// ACCREDITATION
export * from './accreditation/accreditation';
export * from './accreditation/agreement';
export * from './accreditation/currency';
export * from './accreditation/fee';
export * from './accreditation/insurance';
export * from './accreditation/occupation';
export * from './accreditation/port';
export * from './accreditation/procedure';
export * from './accreditation/product';
export * from './accreditation/quotation';
export * from './accreditation/standard-table';
export * from './accreditation/auxiliary';
export * from './accreditation/measure-tiss'
export * from './accreditation/quotalackcontrol'

// ORGANIZATIONAL
export * from './register/organizational/cost-center';
export * from './register/organizational/company';
export * from './register/organizational/professional';
export * from './register/organizational/performer';
export * from './register/organizational/horary';
export * from './register/organizational/participation';
export * from './register/organizational/holiday'
export * from './register/organizational/shift';
export * from './register/organizational/environment';
export * from './register/organizational/contacts'


// OPERATIONAL
export * from './register/operational/patient';
export * from './register/operational/operation'
export * from './register/operational/exame-type'


// ************************* END OF REGISTER **************************** //


// *************************** OPERATIONAL ****************************** //
// SCHEDULING
export * from './operational/scheduling/schedule';
// BILLING
export * from './operational/billing/billing'
export * from './operational/filter/filter'
export * from './operational/pay/pay'
export * from './operational/transfer/transfer'
// RECEPTION
export * from './attendance/attendance';
export * from './attendance/order';
export * from './attendance/order-authorization';
export * from './attendance/order-tiss';
export * from './attendance/disease-time-unit';
export * from './attendance/order-pay';
export * from './attendance/order-pay-min';
export * from './attendance/order-pay-detail';
export * from './attendance/order-closure';
export * from './attendance/order-pending';
export * from './attendance/budget'
export * from './attendance/batchEntry'

// ************************ END OF OPERATIONAL ************************** //




// ************************ END OF ATTENDANCE ************************** //

// USER
export * from './security/user/user-model';
export * from './security/user/user-change-password';

// UTIL
export * from './utils/enumType';
export * from './utils/dataModel';

// OTHERS
export * from './others/contact-list';
export * from './others/code';
export * from './interface/procedure-simple-obj';
export * from './interface/image-obj';

// ADDRESS 
export * from './tables/address/address';
export * from './tables/address/searched-address';
export * from './tables/address/address-type';
export * from './tables/address/country';
export * from './tables/address/state';
export * from './tables/address/city';
export * from './tables/address/address-zipcode';

//EXPORT BY GENERATOR
export * from './tables/tiss/type-guide'
export * from './tables/tiss/accident-indicator'
export * from './tables/tiss/accommodation'
export * from './tables/tiss/closing-reason'
export * from './tables/tiss/hospitalization-regime'
export * from './tables/tiss/service-character'
export * from './tables/tiss/type-attendance'
export * from './tables/tiss/type-hospitalization'
export * from './tables/tiss/type-survey'
export * from './tables/tiss/via-access'
export * from './tables/tiss/via-administration'
export * from './tables/financial/bank'
export * from './tables/financial/brand'
export * from './tables/financial/account'
export * from './tables/financial/agency'
export * from './tables/financial/pay-type'
export * from './tables/financial/account-plan'
export * from './tables/financial/company-account-plan'




export * from './operational/tools/def/def'
export * from './tables/general/pending'
export * from './tables/operational/exclusion-motive'
export * from './tables/operational/alert'



export * from './tables/operational/atribute'
//export * from './operational/scheduling/surgeryschedule'
export * from './register/operational/event'


//{exportModuleBackendDefinitions}



@NgModule({
  declarations: [

  ],
  imports: [

  ],
  exports: [

  ],
  providers: []
})
export class BackendDefinitionsModule {

}
