
import { NgModule } from '@angular/core';

import { HospitalizationRegimeEditProvider } from './hospitalization-regime-edit.provider';
import { HospitalizationRegimeListProvider } from './hospitalization-regime-list.provider';

export * from './hospitalization-regime-edit.provider';
export * from './hospitalization-regime-list.provider';

export {
  HospitalizationRegimeListProvider,
  HospitalizationRegimeEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    HospitalizationRegimeEditProvider,
    HospitalizationRegimeListProvider
  ]
})
export class HospitalizationRegimeBackendModule { }
