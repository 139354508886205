import { OrderItem } from "app/main/modules/operational/reception/os/components/os-items/os-items.component";
import { Order, User } from "cw-backend";
import { Pending } from "./pending";

export class OrderPending {
    id: number;
    pending: any;
    order: any;
    orderItem?: any;
    user?: User;
    note: string;
    resolutionNote: string;
    date: Date;
    resolution_date: Date;
}