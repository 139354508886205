import { Agreement } from './../../../models/accreditation/agreement';
import { HttpResponse, HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiGateway } from 'cw-backend';
import { SnackBarService } from 'cw-utils';
import { Environment } from 'app/decorators/environment.decorator';

@Injectable()
export class AgreementEditProvider implements Resolve<any>
{
    @Environment('baseUrl') baseUrl: string;
    routeParams: any;
    state: any;
    onDataChanged: BehaviorSubject<any>;
    agreement: Agreement;

    constructor(
        public gw: ApiGateway,
        public snackBar: SnackBarService,
        public httpClient: HttpClient,
    ) {
        this.onDataChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        return new Promise<void>((resolve, reject) => {
            Promise.all([]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getAgreement(id): Promise<any> {
        if (!id) {
            return null;
        }
        return new Promise((resolve, reject) => {
            this.gw.get('agreement/wAll/' + id).subscribe((res: Agreement) => {
                this.agreement = res;
                this.onDataChanged.next(this.agreement);
                resolve(res);
            }, reject => {
                if (reject.status == 403) {
                    this.snackBar.warnMessage("Acesso não permitido");
                }
            });
        });
    }

    getAgreementFromInsurance(id): Promise<any> {
        if (!id) {
            return null;
        }
        return new Promise((resolve, reject) => {
            this.gw.get('agreement/insurance/wAll/' + id).subscribe((res: Agreement) => {
                this.agreement = res;
                this.onDataChanged.next(this.agreement);
                resolve(res);
            }, reject => {
                if (reject.status == 403) {
                    this.snackBar.warnMessage("Acesso não permitido");
                }
            });
        });
    }

    uploadAgreementHttp(file: File, agreementId): Promise<any> {
        return new Promise((resolve, reject) => {

            const endpoint = this.baseUrl + 'agreement/uploadFileAgreement/' + agreementId;
            const formData: FormData = new FormData();
            formData.append('file', file, file.name);

            let authToken = localStorage.getItem('token');
            let headers = new HttpHeaders();
            headers = headers.append('X-Auth-Token', authToken);

            this.httpClient.post(endpoint, formData, { headers: headers }).subscribe(response => {
                resolve(response)
            }, reject => {
                if (reject.status == 403) {
                    this.snackBar.warnMessage("Acesso não permitido");
                }
            });
        })
    }

    insert(agreement): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('agreement/', agreement).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject)
        });
    }

    edit(agreement): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.put('agreement/', agreement).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject)
        });
    }

    delete(agreement): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.delete('agreement/' + agreement.id).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject)
        });
    }

    copyAgreement(agreementId, insuranceId,accreditationCopy): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post(`agreement/agreementCopy/${agreementId}/insurance/${insuranceId}`, accreditationCopy).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject => {
                this.snackBar.successMessage(reject.body.message);
              
            });
        });     }
}
