import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FuseSharedModule } from '@fuse/shared.module';
import { InsuranceListProvider } from '../../../../../../../../shared/cw-backend/providers/accreditation/insurance/insurance-list.provider';
import { FilterListProvider } from '../../../../../../../../shared/cw-backend/providers/billing/filter/filter-list.provider';
import { PatientListProvider } from '../../../../../../../../shared/cw-backend/providers/register/operational/patient/patient-list.provider';
import { TypeGuideListProvider } from '../../../../../../../../shared/cw-backend/providers/tiss/type-guide/type-guide-list.provider';
import { FilterComponent } from './filter.component';
import { ProfessionalListProvider } from '../../../../../../../../shared/cw-backend/providers/organizational/professional/professional-list.provider';
import { UserListProvider } from '../../../../../../../../shared/cw-backend/providers/security/user/user-list.provider';


@NgModule({
    imports: [
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        MatPaginatorModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatDialogModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
        MatAutocompleteModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatListModule,
        MatMenuModule,
        MatExpansionModule,
        MatButtonToggleModule,
        MatDividerModule,
        MatRadioModule,
        MatSlideToggleModule,
        MatTabsModule,
        FuseSharedModule
    ],
    exports: [FilterComponent],
    declarations: [FilterComponent],
    providers: [
        FilterListProvider,
        PatientListProvider,
        TypeGuideListProvider,
        InsuranceListProvider,
        ProfessionalListProvider,
        UserListProvider

    ],
})
export class FilterModule { }
