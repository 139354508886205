
import { Location } from '@angular/common';
import { HttpParamsOptions, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { ApiGateway, PageOptions } from 'cw-backend';
import { CwUtils } from 'cw-utils';
import { reject } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { Transfer } from '../../../models/operational/transfer/transfer';
import { TransferFilter } from '../../../models/operational/transfer/transfer-filter';

@Injectable()
export class TransferListProvider implements Resolve<any>
{
    transfer: Transfer[];
    transferEdit: Transfer;
    onDataChanged: BehaviorSubject<any>;
    routeParams: any;
    state: any;
    pageOptions: PageOptions;
    currentPage: PageOptions;

    constructor(
        public gw: ApiGateway,
        private location: Location,
    ) {
        this.onDataChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        // Get state for current or new page
        this.state = this.location.getState();
        this.currentPage = this.state.currentPage;
        this.transferEdit = this.state.currentTransfer;

        if (this.currentPage) {
            this.pageOptions = this.currentPage;
            this.pageOptions.fieldSort = 'dateAt';
            this.pageOptions.sortDirection = 'desc';
        }
        else {
            this.pageOptions = new PageOptions();
            this.pageOptions.fieldSort = 'dateAt';
            this.pageOptions.sortDirection = 'desc';
        }
        return new Promise((resolve, reject) => {
            Promise.all([
                this.getPage(this.pageOptions)
            ]).then(
                () => {
                    resolve(true);
                },
                reject
            );
        });
    }

    get(id: number): Observable<Transfer> {
        if (!id) {
            return null;
        }
        return this.gw.get('transfer/:id', { id: id });
    }

    get currentTransfer(): Transfer {
        return this.transferEdit;
    }

    getAll(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('transfer/').subscribe({
                next: (res: HttpResponse<any>) => {
                    this.transfer = res.body;
                    resolve(res);
                }, error: (err: any) => { reject(err); },
            });
        });

    }

    getPage(pageOptions: PageOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('transfer/search', pageOptions).subscribe({
                next: (res: HttpResponse<any>) => {
                    if (!CwUtils.compare(this.transfer, res.body.content) && res.body.content.length > 0) {
                        this.transfer = res.body.content;
                        this.onDataChanged.next(this.transfer);
                    }
                    else {
                        this.transfer = res.body.content;
                    };
                    resolve(res);
                }, error: (err: any) => { reject(err); },
            });
        });
    }

    getTransferItemByTransferId(transferId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('transfer/:transferId/transfer-item', { transferId: transferId })
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        resolve(res);
                    }, error: (err: any) => { reject(err); },
                })
        })
    }

    getPageSettings() {
        if (this.currentPage) {
            return this.pageOptions;
        } else {
            return;
        }
    }

    getTransferPerformer(transferFilter: TransferFilter): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('transfer/type', transferFilter).subscribe({
                next: (res: HttpResponse<any>) => {
                    resolve(res);
                }, error: (err: any) => { reject(err); },
            });
        });
    }
}

