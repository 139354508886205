
import { NgModule } from '@angular/core';

import { PayTypeEditProvider } from './paytype-edit.provider';
import { PayTypeListProvider } from './paytype-list.provider';

export * from './paytype-edit.provider';
export * from './paytype-list.provider';

export {
  PayTypeListProvider,
  PayTypeEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    PayTypeEditProvider,
    PayTypeListProvider
  ]
})
export class PayTypeBackendModule { }
