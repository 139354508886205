
import { FormProcessor } from './form-processor';
import { LocalStorageService } from './local-storage-service';
import { NgModule } from '@angular/core';
import { SnackBarService } from './snackbar.service';
import { CanDeactivateGuard } from './can-deactivate.service';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { CwUtils } from './cw-utils.service';
import { NotifierComponent } from './notifier/notifier.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {MatDividerModule} from '@angular/material/divider';

import { FuseSharedModule } from '@fuse/shared.module';

export { SnackBarService } from './snackbar.service';
export { FormProcessor } from './form-processor';
export { LocalStorageService } from './local-storage-service';
export { CwUtils } from './cw-utils.service';


@NgModule({
    declarations: [
        NotifierComponent
    ],
    imports: [
        MatButtonModule,
        MatIconModule,
        FuseSharedModule,
        MatDividerModule

    ],
    exports: [
        NotifierComponent
    ],
    providers: [
        LocalStorageService,
        FormProcessor,
        SnackBarService,
        CwUtils,
        CanDeactivateGuard
    ],
    entryComponents: [
        FuseConfirmDialogComponent
    ]
})
export class EabUtilsModule { }
