
import { NgModule } from '@angular/core';

import { AlertEditProvider } from './alert-edit.provider';
import { AlertListProvider } from './alert-list.provider';

export * from './alert-edit.provider';
export * from './alert-list.provider';

export {
  AlertListProvider,
  AlertEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    AlertEditProvider,
    AlertListProvider
  ]
})
export class AlertBackendModule { }
