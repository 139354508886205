<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="accent-600 m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <div class="contact-name mt-8">Trocar senha</div>
            <button mat-icon-button (click)="dimiss()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 pb-0 m-0" fusePerfectScrollbar>
        <form name="changePasswordForm" [formGroup]="changePasswordForm">

            <div fxLayout="row" fxLayoutAlign="center center">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Usuário</mat-label>
                    <input matInput formControlName="login" readonly autocomplete="off">
                    <mat-icon matSuffix class="secondary-text ">person</mat-icon>
                    <mat-error *ngIf="changePasswordForm.get('login').hasError('required')">
                        Usuário é obrigatório.
                    </mat-error>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="center center">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Senha</mat-label>
                    <input matInput type="text" formControlName="currentPassword" class="pw-mask" autocomplete="off">
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error *ngIf="changePasswordForm.get('currentPassword').hasError('required')">
                        Senha é obrigatório.
                    </mat-error>
                    <mat-error *ngIf="changePasswordForm.get('currentPassword').hasError('incorrect')">
                    </mat-error>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="center center">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Nova senha</mat-label>
                    <input matInput type="text" formControlName="newPassword" class="pw-mask" autocomplete="off">
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error *ngIf="changePasswordForm.get('newPassword').hasError('required')">
                        Senha é obrigatório.
                    </mat-error>
                    <mat-error *ngIf="changePasswordForm.get('currentPassword').hasError('incorrect')">
                    </mat-error>

                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="center center">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Confirmação de senha</mat-label>
                    <input matInput type="text" formControlName="newPasswordConfirm" class="pw-mask" autocomplete="off">
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error *ngIf="changePasswordForm.get('newPasswordConfirm').hasError('required')">
                        Confirmação de senha é obrigatória
                    </mat-error>
                    <mat-error *ngIf="!changePasswordForm.get('newPasswordConfirm').hasError('required') &&
                    changePasswordForm.get('newPasswordConfirm').hasError('passwordsNotMatching')">
                        As senhas precisam ser iguais
                    </mat-error>
                    <mat-error *ngIf="changePasswordForm.get('currentPassword').hasError('incorrect')">
                    </mat-error>
                </mat-form-field>
            </div>
        </form>

    </div>

    <div mat-dialog-actions class=" m-0 p-16" fxLayout="row" fxLayoutAlign="center center">
        <button mat-raised-button fxFlex aria-label="CHANGE MY PASSWORD" (click)="confirmResetPassword()" color="accent"
            [disabled]="changePasswordForm.invalid">
            Trocar minha senha
        </button>
    </div>
</div>