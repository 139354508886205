import { Insurance } from "../../accreditation/insurance";
import { OrderItem, OrderItemPO } from "../../attendance/order-item";
import { OrderItemTO } from "../../attendance/order-itemTO";
import { Agreement, BillingPay, Company, CompanyCostCenter, User } from "../../backend-definitions.module";
import { Gloss } from "../pay/gloss";

export class Billing {

    id: number;
    companyCostCenter: CompanyCostCenter;
    company: Company;
    insurance: Insurance;
    billingParent: Billing;
    cover: string;
    description: string;
    dateat: Date;
    forecast: string;
    shipping: string;
    prebilling: boolean;
    total: number;
    user: User;
    release: boolean;
    releaseUser: User;
    releaseDate: Date;
    billingEartefactList: any;
    billingOrderItemPOList: Array<BillingOrderItem>;
    agreement: Agreement;
}

export class BillingOrderItem {
    id: number;
    billing: Billing;
    orderItem: OrderItem;
    release: boolean;
    advice: string;
}
export class BillingPayOrderItem {
    id: number;
    billingPay: BillingPay;
    orderItem: OrderItemPO;
    value: number;
    gloss: Gloss;
}
