
import { NgModule } from '@angular/core';

import { PendingEditProvider } from './pending-edit.provider';
import { PendingListProvider } from './pending-list.provider';

export * from './pending-edit.provider';
export * from './pending-list.provider';

export {
  PendingListProvider,
  PendingEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    PendingEditProvider,
    PendingListProvider
  ]
})
export class PendingBackendModule { }
