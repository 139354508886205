<mat-toolbar>
    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">

        <div *ngIf="signatureWating" class="" fxFlex="0 1 auto" fxLayout="column" fxLayoutAlign="start center">
            <span class="h10 mt-12">Assinatura em andamento{{loadingDots}}</span>
        </div>

        <div class="" fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
            <div fxLayout="row" *ngIf="patientSelected">
                <span style="font-size: 12px;" class="mr-8 mt-4">Paciente: {{ patientSelected }}</span>
                <span style="font-size: 12px" class="mr-8 mt-4" >
                    Data: {{ dateSelected | amDateFormat: "DD/MM" }}
                </span>
                <span style="font-size: 12px" class="mr-8 mt-4">Horário: {{ timeSelected }}</span>
                <button mat-icon-button (click)="clearSelected()" >
                    <mat-icon style="font-size: 16px">clear</mat-icon>
                </button>
            </div>
        </div>

        <div class="" fxFlex="0 1 auto" fxLayout="column" fxLayoutAlign="start end">
            <span *ngIf="lastLogon" class="h5 mt-12">Login: {{lastLogon | amCalendar}}</span>
            <span (click)="openLastAccessModal()" class="h6" style="margin-top:-18px">Detalhes</span>
        </div>
    </div>

</mat-toolbar>