
import { NgModule } from '@angular/core';

import { OrderPayEditProvider } from './order-pay-edit.provider';
import { OrderPayListProvider } from './order-pay-list.provider';

export * from './order-pay-edit.provider';
export * from './order-pay-list.provider';

export {
  OrderPayListProvider,
  OrderPayEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    OrderPayEditProvider,
    OrderPayListProvider
  ]
})
export class OrderPayBackendModule { }
