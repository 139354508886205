import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { OrderPay, ApiGateway } from "cw-backend";
import { SnackBarService } from "cw-utils";
import { PageOptions } from "../../../util/page";

@Injectable()
export class OrderPayEditProvider implements Resolve<any> {
    routeParams: any;
    state: any;
    onDataChanged: BehaviorSubject<any>;
    orderPay: OrderPay;

    constructor(public gw: ApiGateway, public snackBar: SnackBarService) {
        this.onDataChanged = new BehaviorSubject({});
    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        return new Promise<void>((resolve, reject) => {
            Promise.all([this.getOrderPay()]).then(() => {
                resolve();
            }, reject);
        });
    }

    getOrderPay(): Promise<any> {
        return new Promise((resolve, reject) => {
            let data = this.checkLastModifications();
            if (data && data.id == this.routeParams.id) {
                this.orderPay = data;
                this.onDataChanged.next(this.orderPay);
                resolve(this.orderPay);
            } else if (this.routeParams.id === "novo") {
                this.onDataChanged.next(false);
                resolve(false);
            } else {
                this.gw
                    .get("orderPay/" + this.routeParams.id)
                    .subscribe((res: OrderPay) => {
                        this.orderPay = res;
                        this.onDataChanged.next(this.orderPay);
                        resolve(res);
                    }, reject);
            }
        });
    }

    get(id: number) {
        return new Promise((resolve, reject) => {
            this.gw
                .get(`order/pay/${id}`)
                .subscribe((response: HttpResponse<any>) => {
                    resolve(response);
                    //this.snackBar.successMessage(response.body.message);
                }, reject);
        });
    }

    applyDiscount(applyDiscount) {
        return new Promise((resolve, reject) => {
            this.gw.post("order/pay/apply-discount", applyDiscount).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    }

    getAccount(pageOption: PageOptions) {
        pageOption.fieldSort = "id";
        return new Promise((resolve, reject) => {
            this.gw
                .post("account/search/", pageOption)
                .subscribe((response: HttpResponse<any>) => {
                    resolve(response);
                    //this.snackBar.successMessage(response.body.message);
                }, reject);
        });
    }

    insert(orderPay): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post("order/pay/", orderPay)
                .subscribe((response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                }, reject);
        });
    }

    edit(orderPay): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .put("order/pay/", orderPay)
                .subscribe((response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                }, reject);
        });
    }

    delete(orderPay): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .delete("order/pay/" + orderPay)
                .subscribe((response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                }, reject);
        });
    }

    saveOrderPayMin(orderPayMin): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post("order/payGenerate/", orderPayMin)
                .subscribe((response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                }, reject);
        });
    }

    checkLastModifications(): OrderPay {
        let wasLocked = JSON.parse(localStorage.getItem("session_locked"));
        let data;
        if (wasLocked) {
            data = JSON.parse(localStorage.getItem("last_modification"));
            localStorage.removeItem("session_locked");
        }
        return data;
    }
}
