import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { Location } from '@angular/common';
import { PageOptions } from '../../../util/page';
import { Transaction } from '../../../models/backend-definitions.module';
import { ApiGateway } from '../../../api-gateway';
import { CwUtils } from 'cw-utils';

@Injectable()
export class TransactionListService implements Resolve<any>
{
    transaction: Transaction[];
    onDataChanged: BehaviorSubject<any>;
    routeParams: any;
    state: any;
    pageOptions: PageOptions;
    currentPage: PageOptions;

    constructor(
        public gw: ApiGateway,
        private location: Location,
    ) {
        // Set the defaults
        this.onDataChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        // Get state for current or new page
        this.state = this.location.getState();
           if (this.state.currentPage && state.url.includes(this.state.path)) {
            this.currentPage = this.state.currentPage;
        }
        if (this.currentPage) {
            this.pageOptions = this.currentPage;
        }
        else {
            this.pageOptions = new PageOptions();
        }

        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this.getPage(this.pageOptions)
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getTransaction(id: number): Observable<Transaction> {
        if (!id) {
            return null;
        }
        return this.gw.get('transaction/:id', { id: id });
    }

    getAll(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('transaction/').subscribe((res: HttpResponse<any>) => {
                this.transaction = res.body;
                this.onDataChanged.next(this.transaction);
                resolve(res);
            }, reject);
        });

    }

    getPage(pageOptions: PageOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('transaction/search', pageOptions).subscribe((res: HttpResponse<any>) => {
                this.transaction = res.body.content;
                this.onDataChanged.next(this.transaction);
                resolve(res);
            }, reject);

        });
    }

    getPageSettings() {
        if (this.currentPage) {
            return this.pageOptions;
        } else {
            return;
        }
    }
}

