
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { CostCenter, ApiGateway } from 'cw-backend';
import { SnackBarService } from 'cw-utils';

@Injectable()
export class CostCenterEditService implements Resolve<any>
{
    routeParams: any;
    state: any;
    onDataChanged: BehaviorSubject<any>;
    costCenter: CostCenter;

    constructor(
        public gw: ApiGateway,
        public snackBar: SnackBarService
    ) {
        this.onDataChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        return new Promise<void>((resolve, reject) => {
            Promise.all([this.getCostCenter()]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getCostCenter(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this.routeParams.id === 'novo') {
                this.onDataChanged.next(false);
                resolve(false);
            }
            else {
                this.gw.get('costCenter/' + this.routeParams.id).subscribe((res: CostCenter ) => {
                    this.costCenter = res;
                    this.onDataChanged.next(this.costCenter);
                    resolve(res);
                }, reject);
            }
        });
    }

    insert(costCenter): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('costCenter/', costCenter).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }

    edit(costCenter): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.put('costCenter/', costCenter).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }

    delete(costCenter): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.delete('costCenter/' + costCenter.id).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                 this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }
}
