import { Def } from '../operational/tools/def/def';
import { Accreditation } from './accreditation';
import { Category } from '../tables/accreditation/category';
import { Currency, MeasureTiss } from 'cw-backend';

export class Product {
    id: number;
    name: string;
    alias: string;
    active: boolean;
    description: string;
    type: string;
    productCodeList: ProductCode[];
    accreditationList: Accreditation[];
    category: Category;
    def?: Def;
    measureTiss: MeasureTiss;
    currency:Currency;
    value:number;
}

export class ProductCode {
    id: number;
    code: string;
    tableName: string;
    product: Product;
}