import { RoleControl } from '../../../models/security/role/role-control-model';
import { PageOptions } from '../../../util/page';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiGateway } from '../../../api-gateway';
import { RolePO, Role } from 'cw-backend';
import { HttpResponse } from '@angular/common/http';
import { CwUtils } from 'cw-utils';

@Injectable()
export class RoleListProvider implements Resolve<any>
{
    role: Role[];
    roleEdit: Role;
    roleNested: RoleControl[];
    onDataChanged: BehaviorSubject<any>;
    pageOptions: PageOptions;
    routeParams: any;

    constructor(
        public gw: ApiGateway
    ) {
        // Set the defaults
        this.onDataChanged = new BehaviorSubject({});
        this.pageOptions = new PageOptions();
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;
        return new Promise<void>((resolve, reject) => {
            Promise.all([this.getPage(this.pageOptions)]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    get(roleId: number): Observable<RolePO> {
        if (!roleId) {
            return null;
        }
        return this.gw.get('role/:roleId', { roleId: roleId });
    }

    getRoleSimpleList(entity?: String): Promise<any> {
        let urlPath = 'role/simpleList';
        if (entity){
            urlPath = entity + urlPath;
        }
        return new Promise((resolve, reject) => {
            this.gw.get(urlPath).subscribe((response: any) => {
                this.role = response.body;
                this.onDataChanged.next(this.role);
                resolve(response);
            }, reject);
        });
    }

    getRolePermission(roleId: number): Observable<any> {
        if (!roleId) {
            return null;
        }
        return this.gw.get('role/wAll/:roleId', { roleId: roleId });
    }

    getViewComponentsByRoleId(roleId: number, viewId: number): Promise<any> {
        if (!roleId && !viewId) {
            return null;
        }
        return new Promise((resolve, reject) => {
            this.gw.get('role/:roleId/view/:viewId/components/', { roleId: roleId, viewId: viewId })
                .subscribe((res: HttpResponse<any>) => {
                    resolve(res);
                }, reject);
        });
    }


    getPage(pageOptions: PageOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('role/search', pageOptions).subscribe((res: any) => {
                if (!CwUtils.compare(this.role, res.body.content) && res.body.content.length > 0) {
                    this.role = res.body.content;
                    this.onDataChanged.next(this.role);
                } else {
                    this.role = res.body.content;
                }
                resolve(res);
            }, reject);

        });
    }

    get currentRole(): Role {
        return this.roleEdit;
    }
}

