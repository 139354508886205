
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { PageOptions } from '../../../util/backend-util-module';
import { ApiGateway } from '../../../api-gateway';
import { Company } from 'cw-backend';
import { CwUtils } from 'cw-utils';

@Injectable()
export class CompanyListProvider implements Resolve<any>
{
    company: Company[];
    companyEdit: Company;
    onDataChanged: BehaviorSubject<any>;
    routeParams: any;
    state: any;
    pageOptions: PageOptions;
    currentPage: PageOptions;

    constructor(
        public gw: ApiGateway,
        private location: Location,
    ) {
        this.onDataChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        // Get state for current or new page
        this.state = this.location.getState();
        if (this.state.currentPage && state.url.includes(this.state.path)) {
            this.currentPage = this.state.currentPage;
        }
        this.companyEdit = this.state.currentCompany;

        if (this.currentPage) {
            this.pageOptions = this.currentPage;
        }
        else {
            this.pageOptions = new PageOptions();
            this.pageOptions.fieldSort = 'name';
        }
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this.getPage(this.pageOptions)
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    get(id: number): Observable<any> {
        if (!id) {
            return null;
        }
        return this.gw.get('company/:id', { id: id });
    }

    get currentCompany(): Company {
        return this.companyEdit;
    }

    getAll(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('company/').subscribe({
                next: (res: HttpResponse<any>) => {
                    this.company = res.body;
                    this.onDataChanged.next(this.company);
                    resolve(res);
                },
                error: (err) => {
                    reject(err);
                }
            });
        });
    }

    getCompanyCostCenterById(companyId: any, costCenterId: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get(`company/costCenter/${companyId}/${costCenterId}`).subscribe({
                next: (res: HttpResponse<any>) => {
                    resolve(res);
                }, error: (err: any) => reject(err)
            })
        })
    }
    
    getCostCenterByCompanyId(companyId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get(`company/companyCostCenter/${companyId}`).subscribe({
                next: (res: HttpResponse<any>) => {
                    resolve(res);
                }, error: (err: any) => reject(err)
            })
        })
    }

    getCompanySimpleList(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('company/mainCompany').subscribe({
                next: (res: HttpResponse<any>) => {
                    this.company = res.body;
                    this.onDataChanged.next(this.company);
                    resolve(res);
                },
                error: (err) => {
                    reject(err);
                }
            });
        });
    }

    getUnitySimpleListByCompany(companyId, entity?): Promise<any> {
        let path = entity + 'company/unitCompany/:companyId'
        return new Promise((resolve, reject) => {
            this.gw.get(path, { companyId: companyId }).subscribe({
                next: (res: HttpResponse<any>) => {
                    this.company = res.body;
                    this.onDataChanged.next(this.company);
                    resolve(res);
                },
                error: (err) => {
                    reject(err);
                }
            });
        });
    }

    getAllUnityList(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('company/unityCompany').subscribe({
                next: (res: HttpResponse<any>) => {
                    this.company = res.body;
                    this.onDataChanged.next(this.company);
                    resolve(res);
                },
                error: (err) => {
                    reject(err);
                }
            });
        });
    }

    getPage(pageOptions: PageOptions, type?:string): Promise<any> {
        if(type){
            type = `?type=${type}`
        }else{
            type = "";
        }

        return new Promise((resolve, reject) => {
            this.gw.post(`company/search${type}`, pageOptions).subscribe({
                next: (res: HttpResponse<any>) => {
                    if (!CwUtils.compare(this.company, res.body.content) && res.body.content.length > 0) {
                        this.company = res.body.content;
                        this.onDataChanged.next(this.company);
                    } else {
                        this.company = res.body.content;
                    }
                    resolve(res);
                },
                error: (err) => {
                    reject(err);
                }
            });
        });
    }

    getPageSimpleList(pageOptions: PageOptions, type?:string): Promise<any> {
        if(type){
            type = `?type=${type}`
        }else{
            type = "";
        }

        return new Promise((resolve, reject) => {
            this.gw.post(`company/search/simpleList/${type}`, pageOptions).subscribe({
                next: (res: HttpResponse<any>) => {
                    if (!CwUtils.compare(this.company, res.body.content) && res.body.content.length > 0) {
                        this.company = res.body.content;
                        this.onDataChanged.next(this.company);
                    } else {
                        this.company = res.body.content;
                    }
                    resolve(res);
                },
                error: (err) => {
                    reject(err);
                }
            });
        });
    }

    getPageSettings() {
        if (this.currentPage) {
            return this.pageOptions;
        } else {
            return;
        }
    }
}

