
import { NgModule } from '@angular/core';

import { BatchEntryEditProvider } from './batchentry-edit.provider';
import { BatchEntryListProvider } from './batchentry-list.provider';

export * from './batchentry-edit.provider';
export * from './batchentry-list.provider';

export {
  BatchEntryListProvider,
  BatchEntryEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    BatchEntryEditProvider,
    BatchEntryListProvider
  ]
})
export class BatchEntryBackendModule { }
