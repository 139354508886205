
import { NgModule } from '@angular/core';

import { AgreementEditProvider } from './agreement-edit.provider';
import { AgreementListProvider } from './agreement-list.provider';

export * from './agreement-edit.provider';
export * from './agreement-list.provider';

export {
  AgreementListProvider,
  AgreementEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    AgreementEditProvider,
    AgreementListProvider
  ]
})
export class AgreementBackendModule { }
