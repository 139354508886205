
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { Agency, ApiGateway } from 'cw-backend';
import { SnackBarService } from 'cw-utils';

@Injectable()
export class AgencyEditProvider implements Resolve<any>
{
    routeParams: any;
    state: any;
    onDataChanged: BehaviorSubject<any>;
    agency: Agency;

    constructor(
        public gw: ApiGateway,
        public snackBar: SnackBarService
    ) {
        this.onDataChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        return new Promise<void>((resolve, reject) => {
            Promise.all([this.getAgency()]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getAgency(): Promise<any> {
        return new Promise((resolve, reject) => {
            let data =  this.checkLastModifications()
            if (data && data.id == this.routeParams.id) {
                this.agency = data;
                this.onDataChanged.next(this.agency);
                resolve(this.agency);
            } else if (this.routeParams.id === 'novo') {
                this.onDataChanged.next(false);
                resolve(false);
            }
            else {
                this.gw.get('agency/' + this.routeParams.id).subscribe((res: Agency ) => {
                    this.agency = res;
                    this.onDataChanged.next(this.agency);
                    resolve(res);
                }, reject);
            }
        });
    }

    insert(agency): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('agency/', agency).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }

    edit(agency): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('agency/', agency).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }

    delete(agency): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.delete('agency/' + agency.id).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                 this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }

    checkLastModifications(): Agency {
        let wasLocked = JSON.parse(localStorage.getItem('session_locked'));
        let data;
        if (wasLocked){
            data = JSON.parse(localStorage.getItem('last_modification'));
            localStorage.removeItem('session_locked');
        }  
        return data;
    }
}
