import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiGateway } from 'cw-backend';
import { Transaction } from 'cw-backend';


@Injectable()
export class TransactionEditService implements Resolve<any>
{
    routeParams: any;
    state: any;
    onDataChanged: BehaviorSubject<any>;
    transaction: Transaction;

    constructor(
        public gw: ApiGateway,
    ) {
        // Set the defaults
        this.onDataChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this.getTransaction()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getTransaction(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this.routeParams.id === 'novo') {
                this.onDataChanged.next(false);
                resolve(false);
            }
            else {
                this.gw.get('transaction/wModule/' + this.routeParams.id).subscribe((res: Transaction) => {
                    this.transaction = res;
                    this.onDataChanged.next(this.transaction);
                    resolve(res);
                }, reject);
            }
        });
    }

    insert(transaction): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('transaction/', transaction).subscribe((response: HttpResponse<any>) => {
                resolve(response);
            }, reject);
        });
    }

    edit(transaction): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.put('transaction/', transaction).subscribe((res: HttpResponse<any>) => {
                resolve(res);
            }, reject);
        });
    }

    delete(transaction): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.delete('transaction/' + transaction.id).subscribe((response: HttpResponse<any>) => {
                resolve(response);
            }, reject);
        });
    }
}
