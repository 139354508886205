import { Moment } from "moment";
import { Company, CompanyCostCenter, Insurance, Order, Patient, Performer, Procedure, Professional } from "../backend-definitions.module";
import { Cid } from "../others/cid";

export class BatchEntry {
    id: number;
    batchEntrySpec: BatchEntrySpec;
    number: number;
    description: string;
    opened: Moment;
    closed: Moment;
}
export class BatchEntrySpec {
    id: number;
    companyConstCenter: CompanyCostCenter;
    company: Company;
    exameType: any;
    insurance: Insurance;
    procedure: Procedure;
    professional: Professional;
    performer: Performer;
    cid: Cid;
    dated: Moment;
    amount: number;
}
export class BatchEntryItem {
    id: number;
    batchEntry: BatchEntry;
    patient: Patient;
    batchEntrySpec: BatchEntrySpec;
    useDefault: boolean;
}

export class BatchEntryResult {
    id: number;
    batchEntryItem: BatchEntryItem;
    order: Order;
}
