
import { NgModule } from '@angular/core';

import { GenderEditProvider } from './gender-edit.provider';
import { GenderListProvider } from './gender-list.provider';

export * from './gender-edit.provider';
export * from './gender-list.provider';

export {
  GenderListProvider,
  GenderEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    GenderEditProvider,
    GenderListProvider
  ]
})
export class GenderBackendModule { }
