
import { NgModule } from '@angular/core';
import { AuxiliaryListProvider } from './auxiliary-list.provider';
import { AuxiliaryEditProvider } from './auxiliary-edit.provider';


export * from './auxiliary-edit.provider';
export * from './auxiliary-list.provider';

export {
  AuxiliaryListProvider,
  AuxiliaryEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    AuxiliaryEditProvider,
    AuxiliaryListProvider
  ]
})
export class AuxiliaryBackendModule { }
