
import { NgModule } from '@angular/core';

import { BrandEditProvider } from './brand-edit.provider';
import { BrandListProvider } from './brand-list.provider';

export * from './brand-edit.provider';
export * from './brand-list.provider';

export {
  BrandListProvider,
  BrandEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    BrandEditProvider,
    BrandListProvider
  ]
})
export class BrandBackendModule { }
