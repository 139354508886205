
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiGateway } from '../../../api-gateway';
import { Product } from '../../../models/backend-definitions.module';
import { SnackBarService } from '../../../../cw-utils/snackbar.service';

@Injectable()
export class ProductEditProvider implements Resolve<any>
{
    routeParams: any;
    state: any;
    onDataChanged: BehaviorSubject<any>;
    product: Product;

    constructor(
        public gw: ApiGateway,
        public snackBar: SnackBarService
    ) {
        this.onDataChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;
        let productType = state.url.split('/')[3];
        switch (productType) {
            case 'material':
                productType = 'M'
                break;
            case 'opme':
                productType = 'O'
                break;
            case 'medicamento':
                productType = 'D'
                break;
        }

        return new Promise<void>((resolve, reject) => {
            Promise.all([this.getProduct(productType)]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getProduct(productType): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this.routeParams.id === 'novo') {
                this.onDataChanged.next(false);
                resolve(false);
            }
            else {
                this.gw.get('product/wAll/' + productType + '/' + this.routeParams.id).subscribe((res: Product) => {
                    this.product = res;
                    this.onDataChanged.next(this.product);
                    resolve(res);
                }, reject);
            }
        });
    }

    insert(product): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('product/', product).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }

    edit(product): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.put('product/', product).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }

    delete(product, productType): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.delete('product/' + productType + '/' + product.id).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }
}
