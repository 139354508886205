export class Atribute {

    id: number

    context: string;

    name: string;

    code: string;

    active: boolean;
}
