
    <form name="orderTissForm" [formGroup]="orderTissForm" fxFlex="100" tyle="display: block!important;"
        *ngIf="orderTissForm" fxLayout="column">
        
        <!-- <div fxLayout="row" fxLayoutAlign="end center">

            <button matTooltip="salvar" id="edit" color="primary" *ngIf="!progress" mat-icon-button class="mr-12 button-tiss"
                (click)="insert()"> Salvar
                <span class="material-icons">backup</span>
            </button>

            <div fxLayout="row" fxLayoutAlign="space-evenly center" *ngIf="progress">
                <mat-spinner [diameter]="20" class="mr-8"></mat-spinner>
                <p>{{actionString}}...</p>
            </div>

        </div> -->

        <div fxLayout="row" fxLayoutAlign="end center">

            <button matTooltip="Salvar" id="save"  color="primary"
                *ngIf="!progress" mat-stroked-button class="mr-12 button-auth" (click)="insert()">Salvar
                <span class="material-icons">backup</span>
            </button>
    
            <button matTooltip="Salvar" id="loading" *ngIf="progress" mat-button class="mr-12">
                <div fxLayout="row" fxLayoutAlign="space-evenly center">
                    <mat-spinner color="primary" [diameter]="20" class="mr-8"></mat-spinner>
                    <p>{{actionString}}...</p>
                </div>
            </button>
    
    
        </div>

        <mat-divider></mat-divider> <br>

        <div fxLayout="row" fxFlex="100">


            <mat-form-field appearance="outline" class="mr-12">
                <mat-label>Tipo de Guia</mat-label>
                <mat-select id="operational-reception-order_tiss-typeGuide-edit-form_cs" [compareWith]="compareSelect"
                    name="typeGuide" formControlName="typeGuide">
                    <mat-option [value]="null">
                        Selecione
                    </mat-option>
                    <mat-option *ngFor="let typeGuide of typeGuideList" [value]="typeGuide">
                        {{typeGuide.name}}
                    </mat-option>
                </mat-select>

            </mat-form-field>


            <mat-form-field appearance="outline" class="w-100-p mr-12">
                <mat-label>Caráter de Atendimento</mat-label>
                <mat-select id="operational-reception-order_tiss-serviceCharacter-edit-form_cs"
                    [compareWith]="compareSelect" name="serviceCharacter" formControlName="serviceCharacter">
                    <mat-option [value]="null">
                        Selecione
                    </mat-option>
                    <mat-option *ngFor="let serviceCharacter of serviceCharacterList" [value]="serviceCharacter">
                        {{serviceCharacter.code}} | {{ serviceCharacter.name}}
                    </mat-option>
                </mat-select>


                <mat-error *ngIf="orderTissForm.get('serviceCharacter').hasError('required')">
                    O campo é obrigatório
                </mat-error>

            </mat-form-field>


            <mat-form-field appearance="outline" class="w-100-p mr-12">
                <mat-label>Indicação de Acidente</mat-label>
                <mat-select id="operational-reception-order_tiss-accidentIndicator-edit-form_cs"
                    [compareWith]="compareSelect" name="accidentIndicator" formControlName="accidentIndicator">
                    <mat-option [value]="null">
                        Selecione
                    </mat-option>
                    <mat-option *ngFor="let accidentIndicator of accidentIndicatorList" [value]="accidentIndicator">
                        {{accidentIndicator.code}} | {{accidentIndicator.name}}
                    </mat-option>
                </mat-select>


                <mat-error *ngIf="orderTissForm.get('accidentIndicator').hasError('required')">
                    O campo é obrigatório
                </mat-error>

            </mat-form-field>


            <mat-form-field appearance="outline" class="w-100-p mr-12">
                <mat-label>Acomodação</mat-label>
                <mat-select id="operational-reception-order_tiss-accommodation-edit-form_cs"
                    [compareWith]="compareSelect" name="accommodation" formControlName="accommodation">
                    <mat-option [value]="null">
                        Selecione
                    </mat-option>
                    <mat-option *ngFor="let accommodation of accommodationList" [value]="accommodation">
                        {{accommodation.code}} | {{accommodation.name}}
                    </mat-option>
                </mat-select>


                <mat-error *ngIf="orderTissForm.get('accommodation').hasError('required')">
                    O campo é obrigatório
                </mat-error>

            </mat-form-field>


            <mat-form-field appearance="outline" class="w-100-p mr-12">
                <mat-label>Tipo de Consulta</mat-label>
                <mat-select id="operational-reception-order_tiss-typeSurvey-edit-form_cs" [compareWith]="compareSelect"
                    name="typeSurvey" formControlName="typeSurvey">
                    <mat-option [value]="null">
                        Selecione
                    </mat-option>
                    <mat-option *ngFor="let typeSurvey of typeSurveyList" [value]="typeSurvey">
                        {{typeSurvey.code}} | {{typeSurvey.name}}
                    </mat-option>
                </mat-select>


                <mat-error *ngIf="orderTissForm.get('typeSurvey').hasError('required')">
                    O campo é obrigatório
                </mat-error>

            </mat-form-field>
        </div>
        <div fxLayout="row" class="w-100-p">


            <mat-form-field appearance="outline" class="w-100-p mr-12">
                <mat-label>Tipo de Atendimento</mat-label>
                <mat-select id="operational-reception-order_tiss-typeAttendance-edit-form_cs"
                    [compareWith]="compareSelect" name="typeAttendance" formControlName="typeAttendance">
                    <mat-option [value]="null">
                        Selecione
                    </mat-option>
                    <mat-option *ngFor="let typeAttendance of typeAttendanceList" [value]="typeAttendance">
                        {{typeAttendance.code}} | {{typeAttendance.name}}
                    </mat-option>
                </mat-select>


                <mat-error *ngIf="orderTissForm.get('typeAttendance').hasError('required')">
                    O campo é obrigatório
                </mat-error>

            </mat-form-field>


            <mat-form-field appearance="outline" class="w-100-p mr-12">
                <mat-label>Regime de Internação</mat-label>
                <mat-select id="operational-reception-order_tiss-hospitalizationRegime-edit-form_cs"
                    [compareWith]="compareSelect" name="hospitalizationRegime" formControlName="hospitalizationRegime"
                    required>
                    <mat-option [value]="null">
                        Selecione
                    </mat-option>
                    <mat-option *ngFor="let hospitalizationRegime of hospitalizationRegimeList"
                        [value]="hospitalizationRegime">
                        {{hospitalizationRegime.code}} | {{hospitalizationRegime.name}}
                    </mat-option>
                </mat-select>


                <mat-error *ngIf="orderTissForm.get('hospitalizationRegime').hasError('required')">
                    O campo é obrigatório
                </mat-error>

            </mat-form-field>


            <mat-form-field appearance="outline" class="w-100-p mr-12">
                <mat-label>Tipo de Internamento</mat-label>
                <mat-select id="operational-reception-order_tiss-typeHospitalization-edit-form_cs"
                    [compareWith]="compareSelect" name="typeHospitalization" formControlName="typeHospitalization">
                    <mat-option [value]="null">
                        Selecione
                    </mat-option>
                    <mat-option *ngFor="let typeHospitalization of typeHospitalizationList"
                        [value]="typeHospitalization">
                        {{typeHospitalization.code }} | {{typeHospitalization.name}}
                    </mat-option>
                </mat-select>


                <mat-error *ngIf="orderTissForm.get('typeHospitalization').hasError('required')">
                    O campo é obrigatório
                </mat-error>

            </mat-form-field>


            <mat-form-field appearance="outline" class="w-100-p mr-12">
                <mat-label>Maquineta</mat-label>
                <input id="operational-reception-order_tiss-dobbyNumber-edit-form_it" matInput maxlength="20"
                    name="dobbyNumber" formControlName="dobbyNumber">

                <mat-error *ngIf="orderTissForm.get('dobbyNumber').hasError('maxlength')">
                    Campo excedeu 20 caracteres
                </mat-error>

            </mat-form-field>

            <mat-form-field class="w-10-p mr-12 mr-12" appearance="outline">
                <mat-label>Data da Solicitação</mat-label>
                <input id="operational-reception-order_tiss-requestDate-edit-form_cl" autocomplete="off" matInput
                    [matDatepicker]="requestDatePicker" placeholder="Data da Solicitação" formControlName="requestDate">
                <mat-datepicker-toggle matSuffix [for]="requestDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #requestDatePicker></mat-datepicker>

            </mat-form-field>

            <mat-form-field class="w-10-p mr-12 mr-12" appearance="outline">
                <mat-label>Hora da Solicitação</mat-label>
                <input id="operational-reception-order_tiss-requestTime-edit-form_cl" autocomplete="off" matInput
                    placeholder="Hora da Solicitação" formControlName="requestTime" mask="Hh:m0"
                    [dropSpecialCharacters]="false">

                <mat-error *ngIf="orderTissForm.get('requestTime').hasError('required')">
                    O campo é obrigatório
                </mat-error>

            </mat-form-field>

        </div>
        <div fxLayout="row wrap" fxFlex="100" fxLayoutGap="10" fxLayoutAlign="start none" appearance="outline">

            <mat-form-field appearance="outline" fxFlex="20" class="mr-8">
                <mat-label>Unidade de Tempo de Doença</mat-label>
                <mat-select id="operational-reception-order_tiss-diseaseTimeUnit-edit-form_cs"
                    [compareWith]="compareSelect" name="diseaseTimeUnit" formControlName="diseaseTimeUnit">
                    <mat-option [value]="null">
                        Selecione
                    </mat-option>
                    <mat-option *ngFor="let diseaseTimeUnit of diseaseTimeUnitList" [value]="diseaseTimeUnit.value">
                        {{diseaseTimeUnit.description}}
                    </mat-option>
                </mat-select>

            </mat-form-field>

            <mat-form-field fxFlex="10" appearance="outline" class="mr-8">
                <mat-label>Tempo de Doença</mat-label>
                <input id="operational-reception-order_tiss-diseaseTime-edit-form_it" matInput maxlength=""
                    name="diseaseTime" formControlName="diseaseTime">
                <mat-error *ngIf="orderTissForm.get('diseaseTime').hasError('maxlength')">
                    Campo excedeu caracteres
                </mat-error>
            </mat-form-field>

            <mat-form-field fxFlex="16" appearance="outline" class="mr-8">
                <mat-label>Num. Guia Principal/Solicitação</mat-label>
                <input id="operational-reception-order_tiss-principalGuideNumber-edit-form_it" matInput maxlength=""
                    name="diseaseTime" formControlName="principalGuideNumber">

                <!--     <mat-error *ngIf="orderTissForm.get('diseaseTime').hasError('maxlength')">
                    Campo excedeu caracteres
                </mat-error>
                -->

            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="" class="mr-8">
                <mat-label>Num. Guia Operadora</mat-label>
                <input id="operational-reception-order_tiss-principalGuideNumber-edit-form_it" matInput maxlength=""
                    name="diseaseTime" formControlName="operatorGuideNumber">

                <!--     <mat-error *ngIf="orderTissForm.get('diseaseTime').hasError('maxlength')">
                    Campo excedeu caracteres
                </mat-error>
                -->
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="25" class="">
                <mat-label>Grau de Participação</mat-label>
                <mat-select id="operational-reception-order_tiss-degreeParticipation-edit-form_cs"
                    [compareWith]="compareSelect" name="degreeParticipation" formControlName="degreeParticipation">
                    <mat-option [value]="null">
                        Selecione
                    </mat-option>
                    <mat-option *ngFor="let degreeParticipation of degreeParticipationList"
                        [value]="degreeParticipation">
                        {{degreeParticipation.code}} | {{ degreeParticipation.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            
            <mat-form-field appearance="outline" fxFlex="10" class="">
                <mat-label>Tipo do Faturamento</mat-label>
                <mat-select id="operational-reception-order_tiss-billing_type-edit-form_cs"
                     name="billing_type" formControlName="billing_type">
                    <mat-option [value]="null">
                        Selecione
                    </mat-option>
                    <mat-option value="1">Parcial</mat-option>
                    <mat-option value="2">Final</mat-option>
                    <mat-option value="3">Complementar</mat-option>
                    <mat-option value="4">Total</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Declaração Nascido Vivo</mat-label>
                <input id="operational-reception-order_tiss-certificate_alive-edit-form_it" matInput maxlength="20"
                    name="certificate_alive" formControlName="certificate_alive">
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Declaração de Óbito</mat-label>
                <input id="operational-reception-order_tiss-certificate_death-edit-form_it" matInput maxlength="20"
                    name="certificate_death" formControlName="certificate_death">
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="25" class="">
                <mat-label>Saude Ocupacional</mat-label>
                <mat-select
                    [compareWith]="compareSelect" name="occupationalList" formControlName="occupationalHealth">
                    <mat-option [value]="null">
                        Selecione
                    </mat-option>
                    <mat-option *ngFor="let occupationalList of occupationalHealthList"
                        [value]="occupationalList">
                        {{occupationalList.code}} | {{occupationalList.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="25" class="">
                <mat-label>Regime de Atendimento</mat-label>
                <mat-select
                    [compareWith]="compareSelect" name="attendanceRegimeList" formControlName="attendanceRegime">
                    <mat-option [value]="null">
                        Selecione
                    </mat-option>
                    <mat-option *ngFor="let attendanceRegimeList of attendanceRegimeList"
                        [value]="attendanceRegimeList">
                        {{attendanceRegimeList.code}} | {{attendanceRegimeList.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
           
            <section style="padding-top:9px !important">
                <mat-checkbox formControlName="certificate_death_newborn">Óbito</mat-checkbox>
            </section>


            <section style="padding-top:9px !important">
                <mat-checkbox formControlName="newBorn">Recém Nascido</mat-checkbox>
            </section>



        </div>
        <div fxLayout="row" appearance="outline">
            <mat-form-field appearance="outline" fxFlex="30">
                <mat-label>Indicação Clinica</mat-label>
                <input id="operational-reception-order_tiss-indication-edit-form_it" matInput maxlength="250"
                    name="indication" formControlName="indication">
            </mat-form-field>

            <mat-form-field class="w-10-p mr-12 mr-12" appearance="outline">
                <mat-label>Data início faturamento</mat-label>
                <input id="operational-reception-order_tiss-billingStartDate-edit-form_cl" autocomplete="off" matInput
                    [matDatepicker]="billingStartDatePicker" placeholder="Data início faturamento" formControlName="billingStartDate">
                <mat-datepicker-toggle matSuffix [for]="billingStartDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #billingStartDatePicker></mat-datepicker>

            </mat-form-field>

            <mat-form-field class="w-10-p mr-12 mr-12" appearance="outline">
                <mat-label>Data fim faturamento</mat-label>
                <input id="operational-reception-order_tiss-billingEndDate-edit-form_cl" autocomplete="off" matInput
                    [matDatepicker]="billingEndDatePicker" placeholder="Data fim faturamento" formControlName="billingEndDate">
                <mat-datepicker-toggle matSuffix [for]="billingEndDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #billingEndDatePicker></mat-datepicker>

            </mat-form-field>
        </div>
        <div fxLayout="row" appearance="outline">
            <mat-form-field appearance="outline" fxFlex="100">
                <mat-label>Observação</mat-label>
                <textarea id="operational-reception-order_tiss-notes-edit-form_it" matInput maxlength="" name="notes"
                    formControlName="notes"></textarea>


                <mat-error *ngIf="orderTissForm.get('notes').hasError('required')">
                    O campo é obrigatório
                </mat-error>

                <mat-error *ngIf="orderTissForm.get('notes').hasError('maxlength')">
                    Campo excedeu caracteres
                </mat-error>

            </mat-form-field>

        </div>
    </form>