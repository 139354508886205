import { Company, Insurance, InsurancePlan, Patient, Professional } from "../../backend-definitions.module";

export class Alert {
    id: number;
    active: boolean;
    content: string;
    name: string;
    sequence: string;
    module: string;
    company: Company;
    patient: Patient;
    professional: Professional;
    insurance: Insurance;
    insurancePlanId: number;

}
export class AlertTO {
    id: number;
    active: boolean;
    content: string;
    name: string;
    sequence: string;
    module: string;
    company: Company;
    patient: Patient;
    insurance: Insurance;
    insurancePlanId: number;
    insurancePLan: InsurancePlan;
}


export class AlertSearch {
    patientId: number;
    insuranceId: number;
    professionalId: number;
    insurancePLanId: number;
    companyId: number;
    index: number;

} 

export class AlertResultList {
    id: number;
    module: string;
    patientName: string;
    insuranceName: string; 
    companyName: number;
    active:boolean;
    planName: string;
    index: number;

} 