import { Moment } from 'moment';
import { Professional } from './professional';
import { Horary } from './horary';
import { Participation } from './participation';
import { Insurance } from '../../accreditation/insurance';

export class Performer {
    id: number;
    admission: Moment;
    demission: Moment;
    professional: Professional;
    active: boolean;
    horaryList: Horary[];
    participationList: Participation[];
    unacceptedInsuranceList: Insurance[];
    rqe_number: string;
}