
import { NgModule } from '@angular/core';
import { DegreeParticipationListProvider } from './degree-participation-list.provider';
import { DegreeParticipationEditProvider } from './degree-participation-edit.provider';


export * from './degree-participation-edit.provider';
export * from './degree-participation-list.provider';

export {
  DegreeParticipationListProvider,
  DegreeParticipationEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    DegreeParticipationEditProvider,
    DegreeParticipationListProvider
  ]
})
export class DegreeParticipationBackendModule { }
