import { Company } from './company';
import { Speciality } from '../../tables/accreditation/speciality';
import { Group } from '../../tables/accreditation/group';
import { Time } from '@angular/common';

export class Horary {
    id: number;
    day: number;
    speciality: Speciality;
    company: Company;
    group: Group;
    performerId: number;
    started: string;
    ended: string;
    interval: string;
    active: boolean;
    extra: boolean;
    horaryCustomList: HoraryCustom[];
}

export class HoraryCustom {
    id: number;
    speciality: Speciality;
    group: Group;
    hour: Time;
    type: string;
    quantity: number;
    text: string;
}