import { RegistryType } from './registry-type';
import { Address } from '../address/address';

export class Firm {
    name: string;
    id: number;
    fullName: string;
    registryType: RegistryType;
    address: Address;
    registry: string;
    cnes: string;
}