
import { NgModule } from '@angular/core';

import { FeeEditProvider } from './fee-edit.provider';
import { FeeListProvider } from './fee-list.provider';

export * from './fee-edit.provider';
export * from './fee-list.provider';

export {
  FeeListProvider,
  FeeEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    FeeEditProvider,
    FeeListProvider
  ]
})
export class FeeBackendModule { }
