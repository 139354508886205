
import { NgModule } from '@angular/core';

import { TypeAttendanceEditProvider } from './type-attendance-edit.provider';
import { TypeAttendanceListProvider } from './type-attendance-list.provider';

export * from './type-attendance-edit.provider';
export * from './type-attendance-list.provider';

export {
  TypeAttendanceListProvider,
  TypeAttendanceEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    TypeAttendanceEditProvider,
    TypeAttendanceListProvider
  ]
})
export class TypeAttendanceBackendModule { }
