import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogOrderTissComponent } from './dialog-order-tiss.component';
import { OrderTissModule } from '../order-tiss/order-tiss.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FuseProgressBarModule, FuseWidgetModule } from '@fuse/components';
import { FuseCardModule } from '@fuse/components/card';
import { FuseSharedModule } from '@fuse/shared.module';
import { FilterModule } from 'app/main/modules/operational/billing/components/filter/filter.module';
import { CrudModule } from '../crud/crud.module';

@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        MatPaginatorModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatDialogModule,
        MatTooltipModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatAutocompleteModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatListModule,
        MatMenuModule,
        MatExpansionModule,
        MatButtonToggleModule,
        MatDividerModule,
        MatRadioModule,
        MatSlideToggleModule,
        MatTabsModule,
        FlexLayoutModule,
        FuseCardModule,
        OrderTissModule,
        FuseProgressBarModule,

        FuseSharedModule,
        FuseWidgetModule,
        CrudModule,
        /* Filtros */
        FilterModule,
    ],
    declarations: [DialogOrderTissComponent],
    exports:[DialogOrderTissComponent]
})
export class DialogOrderTissModule { }
